import * as yup from 'yup'

export const GynecologySchema = yup
    .object({
        last: yup.object({
            sti: yup.object({
                test: yup
                    .string()
                    .dateSmallerThan1900('This date is too far in the past.')
            }),
            pap: yup.object({
                test: yup
                    .string()
                    .dateSmallerThan1900('This date is too far in the past.')
            })
        }),
        date: yup.object({
            abnormal: yup.object({
                pap: yup.object({
                    test: yup
                        .string()
                        .dateSmallerThan1900(
                            'This date is too far in the past.'
                        )
                })
            })
        })
    })
    .required()
