import {
    Box,
    Container,
    Link,
    Paragraph,
    Button,
    Flex,
    Icon
} from '@asktia/tia-ui'
import { ampli } from 'src/ampli'
import { useLocalStorage } from 'src/hooks'
import { useLatestInsurance } from 'src/hooks/useLatestInsurance'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

export const InsuranceWarningBanner = () => {
    const { latestInsurance } = useLatestInsurance()
    const insuranceUnsureBanner = useAmpliFeatureFlag('insurance-unsure-banner')
    const [dismissed, setDismissed] = useLocalStorage(
        'tia:insurance-unsure-banner',
        false
    )

    if (insuranceUnsureBanner !== 'treatment' || dismissed) {
        return null
    }

    if (
        (latestInsurance?.eligibilityCheck?.eligibilityStatus !== 'unsure' &&
            latestInsurance?.eligibilityCheck?.verificationStatus !==
                'unsure') ||
        latestInsurance?.coverage?.cardPhotosUploaded
    ) {
        return null
    }

    function dismissBanner() {
        setDismissed(true)
    }

    return (
        <Box
            sx={{
                backgroundColor: 'poppy',
                py: 4,
                px: [5, 0]
            }}
        >
            <Container
                sx={{
                    p: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <Flex
                    sx={{
                        alignItems: 'space-between'
                    }}
                >
                    <Box>
                        <Paragraph>
                            Oh no! Looks like your insurance may be out of date.
                            Please update it at least 48 hours before your next
                            appointment or you may be charged the cash price.
                        </Paragraph>
                        <Link
                            href="/account-info"
                            onClick={() => ampli.updateInsuranceBannerClicked()}
                            sx={{ fontSize: 'inherit' }}
                        >
                            Update your insurance now.
                        </Link>
                    </Box>
                    <Button
                        variant="unstyled"
                        sx={{ fontSize: 7, display: 'flex', minWidth: 5 }}
                        onClick={dismissBanner}
                    >
                        <Icon.Close
                            color="text"
                            sx={{ '&:hover path': { fill: 'text' } }}
                        />
                    </Button>
                </Flex>
            </Container>
        </Box>
    )
}
