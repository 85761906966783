import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export type AppointmentCancellationPolicy = {
    cuttofTime?: Date
    cutoffHours: number
    chargeInDollars: number
    chargeInCents: number
    cancellationCopy: string
}

async function getCancellationPolicy(
    appointmentProfileUuid: string
): Promise<AppointmentCancellationPolicy> {
    const json = await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/cancellation_policy/?apiVersion=v2&appointmentProfileUuid=${appointmentProfileUuid}`
    )

    if (json.cutoffTime) {
        json.cutoffTime = new Date(json.cutoffTime)
    }

    return json
}

export function useAppointmentCancellationPolicy(
    appointmentProfileUuid?: string
) {
    const query = useQuery(
        ['cancellation_policy', appointmentProfileUuid],
        () => getCancellationPolicy(appointmentProfileUuid!),
        {
            staleTime: 1000 * 60 * 5, // refetch rendered data after 5min
            enabled: !!appointmentProfileUuid
        }
    )

    return {
        isLoading: query.isLoading,
        appointmentCancellationPolicy: query.data
    }
}
