import { Container, Flex, Heading, ThemeStyleObject } from '@asktia/tia-ui'
import React, { FC } from 'react'
import { BackButton } from 'src/components/Blocks/BackButton'

type NavBarHeadingProps = {
    sx?: ThemeStyleObject
    showBackButton?: boolean
    href?: string
    titleAlign?: 'center' | 'left'
}

export const NavBarHeading: FC<React.PropsWithChildren<NavBarHeadingProps>> = ({
    showBackButton = true,
    sx,
    href,
    children,
    titleAlign = 'left'
}) => {
    return (
        <Container>
            <Flex
                sx={{
                    display: 'flex',
                    justifyContent: ['flex-start', 'center'],
                    alignItems: 'center',
                    position: 'relative',
                    pt: 5,
                    ...sx
                }}
            >
                {showBackButton && (
                    <BackButton
                        href={href}
                        sx={{
                            display: ['none', 'flex'],
                            position: 'absolute',
                            left: 0,
                            top: 'calc(50% - 16px)'
                        }}
                    />
                )}
                <Flex
                    sx={{
                        flex: 1,
                        justifyContent: 'center'
                    }}
                >
                    <Heading
                        h2
                        sx={{
                            textAlign: titleAlign,
                            fontSize: 7
                        }}
                    >
                        {children}
                    </Heading>
                </Flex>
            </Flex>
        </Container>
    )
}
