import { Box, Button, Flex, Heading, Image, Paragraph } from '@asktia/tia-ui'
import { useState } from 'react'
import { Carousel, CarouselContainer } from 'src/components/Carousel'
import { useServiceLines } from 'src/flows/AppointmentBooking/useServiceLines'
import { ServiceLine as ServiceLineType } from 'src/types'

const ServiceLine = (props: {
    serviceLine: ServiceLineType
    // TODO: would be nice without prop drilling, but useAppointmentFilters isn't set up for that
    selectServiceLine: (serviceLineName: string) => void
}) => {
    const { imageUrl, imageBgColor, label, description, name } =
        props.serviceLine

    function onClick() {
        props.selectServiceLine(name)
    }

    return (
        <Flex sx={{ flexDirection: ['column', 'row'], width: '100%' }}>
            <Image
                src={imageUrl}
                sx={{
                    bg: imageBgColor,
                    minWidth: ['100%', '50%', '384px'],
                    height: ['auto', 'auto', '276px'],
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: [2, 0],
                    borderBottomLeftRadius: [0, 2]
                }}
            />
            <Flex
                sx={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    bg: 'cardBackground',
                    py: 6,
                    px: 5,
                    borderTopRightRadius: [0, 2],
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: [2, 0]
                }}
            >
                <Box>
                    <Heading h2>{label}</Heading>
                    <Paragraph sx={{ mb: 4 }}>{description}</Paragraph>
                </Box>

                <Box>
                    <Button variant="secondaryDark" onClick={onClick}>
                        See all {label} appointments
                    </Button>
                </Box>
            </Flex>
        </Flex>
    )
}

export const ServiceLinesCarousel = (props: {
    selectServiceLine: (serviceLineName: string) => void
}) => {
    const { serviceLines } = useServiceLines()

    return (
        <>
            <Heading h3>See more appointments</Heading>
            <CarouselContainer sx={{ pl: '0 !important' }}>
                <Carousel
                    paginationProps={{ variant: 'dark' }}
                    slickOverrides={{
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 1080,
                                settings: { slidesToShow: 1, slidesToScroll: 1 }
                            }
                        ]
                    }}
                >
                    {serviceLines?.map(sl => (
                        <ServiceLine
                            serviceLine={sl}
                            selectServiceLine={props.selectServiceLine}
                        />
                    ))}
                </Carousel>
            </CarouselContainer>
        </>
    )
}
