import { useEffect, useState } from 'react'
import { Button, Form, Input, Link } from '@asktia/tia-ui'
import { useForm, FieldValues } from 'react-hook-form'
import { useLoginByEmail } from '../hooks/useLogin'
import { ResendModal } from '../components/ResendModal'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

type FormValues = { code: string } | FieldValues

const PhoneLoginForm = ({
    setStepMessage,
    setChatBubbleCopy,
    successfulLogin = false,
    setSuccessfulLogin
}: {
    setStepMessage: (message: string) => void
    setChatBubbleCopy: (copy: string) => void
    successfulLogin: boolean
    setSuccessfulLogin: (login: boolean) => void
}) => {
    const [isResendModalOpen, setIsResendModalOpen] = useState(false)
    const [step, setStep] = useState(1)

    const sendEmailFailureMessage =
        '🤔...looks like something is wrong with that' +
        ' email address. Mind giving that another shot?'

    useEffect(() => {
        setStepMessage(`Step ${step} of ${chatBubbleCopy.length}`)
        setChatBubbleCopy(chatBubbleCopy[step - 1])
    }, [step])

    const sendPostEmailMutator = useLoginByEmail({
        onSuccess: () => {
            setStep(2)
            setSuccessfulLogin(true)
        },
        onFailure: () => {
            setChatBubbleCopy(sendEmailFailureMessage)
        }
    })

    const onSubmit = ({ email }: any) => {
        sendPostEmailMutator(email)
    }

    const formMethods = useForm<FormValues>({
        shouldUnregister: false,
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                email: yup.string().email(' ').required(' ')
            })
        ),
        defaultValues: {
            email: ''
        }
    })

    const email: string = formMethods.watch('email')

    const chatBubbleCopy = [
        'Oh 👋! To sign in to your Tia' +
            ' account, I’ll need some basic info!',

        'If you have a Tia account, we sent you an email to sign in.' +
            ' Check your inbox, it might take 5-10 min to arrive!'
    ]

    return (
        <>
            <ResendModal
                isOpen={isResendModalOpen}
                email={email}
                title="Does this email seem right? Sometimes our emails take 5-10 to arrive."
                ctaCopy="Send me another"
                backLinkCopy="Return to Sign In"
                onCTAClick={() => sendPostEmailMutator(email)}
                onClose={() => setIsResendModalOpen(false)}
                onBackLinkClick={e => {
                    e.preventDefault()
                    setStep(1)
                    setIsResendModalOpen(false)
                    setSuccessfulLogin(false)
                }}
            />
            <Form
                id="emailLoginForm"
                onSubmit={onSubmit}
                useFormMethods={formMethods}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center'
                }}
            >
                <Input
                    type="email"
                    placeholder="Email address"
                    name="email"
                    sx={{
                        'display': successfulLogin ? 'none' : 'block',
                        'mb': 6,
                        'appearance': 'none',
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button ':
                            {
                                appearance: 'none'
                            }
                    }}
                />
                {/* TODO should be disabled for non valid email */}
                {/* nothing to hit next for since user just goes to their email */}
                {!successfulLogin && (
                    <Button
                        sx={{ minWidth: 175, mb: 7 }}
                        disabled={!formMethods.formState.isValid}
                    >
                        Next
                    </Button>
                )}
                {step === 2 && (
                    <Link
                        href="#"
                        sx={{ mt: 7, fontSize: 0, color: 'text' }}
                        onClick={() => setIsResendModalOpen(true)}
                    >
                        Didn't receive our email?
                    </Link>
                )}
            </Form>
        </>
    )
}

export default PhoneLoginForm
