import { Box, Button, Card, Flex, Heading, Text } from '@asktia/tia-ui'
import { MouseEventHandler, useContext, useState } from 'react'
import {
    CardInput,
    CreditCardContext,
    CreditCardForm,
    TiaCreditCardSubmitButton
} from 'src/components/CreditCardForm'
import { useAmpli, useUserInfo } from 'src/hooks'
import { useUpdateAccountCard } from 'src/hooks/useUpdateAccountCard'
import { AccountInfoRow, AccountInfoRowLabel } from './AccountInfoRow'
import { v4 as uuidv4 } from 'uuid'
import logger from 'src/logger'

const SubmitButton = ({
    onCancel,
    loading
}: {
    onCancel: MouseEventHandler
    loading: boolean
}) => {
    const { showCardFields } = useContext(CreditCardContext)

    if (!showCardFields) {
        return (
            <Button disabled={true} loading={loading} onClick={onCancel}>
                My card information is correct
            </Button>
        )
    } else {
        return (
            <TiaCreditCardSubmitButton loading={loading}>
                My card information is correct
            </TiaCreditCardSubmitButton>
        )
    }
}

export const CreditCard = () => {
    const [editing, setEditing] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const { user, isLoading } = useUserInfo()
    const updateAccountCard = useUpdateAccountCard()
    const { paymentInformationSaved } = useAmpli()

    const cardSuccess = async (nonce: string, postalCode: string) => {
        setUpdating(true)
        try {
            await updateAccountCard.mutateAsync({
                nonce,
                idempotencyKey: uuidv4(),
                postalCode
            })
            paymentInformationSaved()
        } finally {
            setEditing(false)
            setUpdating(false)
        }
    }

    const cardError = (errors: string[]) => {
        logger.error(errors.join(', '))
        setErrorMessage(errors.join(', '))
    }

    const enableEditing = () => {
        setEditing(true)
        setErrorMessage('')
    }
    const onCancelForm = () => {
        setEditing(false)
        setErrorMessage('')
    }

    return (
        <AccountInfoRow
            title="Payment"
            isLoading={isLoading}
            onEdit={enableEditing}
            onCancel={onCancelForm}
            defaultEditValue={editing}
        >
            {!editing ? (
                <AccountInfoRowLabel onClick={() => setEditing(true)}>
                    {`---- ---- ---- ${user?.rawCard?.last4 || '----'}`}
                </AccountInfoRowLabel>
            ) : (
                <CreditCardForm
                    onSuccess={cardSuccess}
                    onError={cardError}
                    showCardOnLoad={false}
                >
                    <Card
                        sx={{
                            mb: 4,
                            pb: 4,
                            borderColor: undefined,
                            backgroundColor: 'secondaryBackground'
                        }}
                    >
                        <Heading variant="cardTitle">
                            Add new credit or debit card
                        </Heading>
                        <CardInput
                            cardOnFile={user?.rawCard || undefined}
                            onChange={() => setErrorMessage('')}
                        />
                        {errorMessage && (
                            <Flex
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center'
                                }}
                            >
                                <Text
                                    sx={{
                                        fontSize: 0,
                                        textAlign: 'center',
                                        color: 'inputOutlineError'
                                    }}
                                >
                                    {errorMessage}
                                </Text>
                            </Flex>
                        )}
                    </Card>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <SubmitButton
                            loading={updating}
                            onCancel={() => setEditing(false)}
                        />
                    </Box>
                </CreditCardForm>
            )}
        </AccountInfoRow>
    )
}
