import { Box, ThemeStyleObject } from '@asktia/tia-ui'
import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

type BodyProps = {
    sx?: ThemeStyleObject
}

/**
 * Body component that sets up transitions and the main wrapper
 */
export const Body: FC<React.PropsWithChildren<BodyProps>> = ({
    sx,
    children
}) => {
    const location = useLocation()
    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={{ enter: 800, exit: 400 }}
                unmountOnExit
            >
                <Box
                    sx={{
                        'minHeight': '100vh',
                        '&.fade-enter .view': {
                            opacity: 0
                        },
                        '&.fade-enter.fade-enter-active .view': {
                            opacity: 1,
                            transition: 'opacity 800ms ease-in-out'
                        },
                        '&.fade-exit .view': {
                            opacity: 1
                        },
                        '&.fade-exit.fade-exit-active .view': {
                            opacity: 0,
                            transition: 'opacity 400ms ease-in-out'
                        },
                        ...sx
                    }}
                >
                    {children}
                </Box>
            </CSSTransition>
        </TransitionGroup>
    )
}
