import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { Asserter, CodeableConcept, VerificationStatus } from '../../types'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'

type ConditionInput = {
    label: string
    snomedCode: string
    year: string
}

export type PatientOwnedClinicalStatus = 'active' | 'inactive'

type SubmittedConditionInput = ConditionInput & {
    clinicalStatus: PatientOwnedClinicalStatus
}

export type ClinicalStatus =
    | 'recurrence'
    | 'relapse'
    | 'remission'
    | 'resolved'
    | PatientOwnedClinicalStatus

type ConditionOnsetAbatement = { approximateYear: number }

export type Condition = {
    uuid?: string
    patientId: number
    asserterCategory: Asserter
    clinicalStatus: ClinicalStatus
    verificationStatus: VerificationStatus
    code: CodeableConcept
    onset?: ConditionOnsetAbatement
    abatement?: ConditionOnsetAbatement
}

export function useSaveCondition() {
    const { user } = useUserInfo()
    const { mutateAsync } = useMutation<Condition, unknown, Condition>(
        async condition =>
            fetcher(
                `${BASE_API_URL}/uhr-api/conditions/patient-owned-conditions`,
                JSON.stringify(condition),
                'POST'
            )
    )

    return async (condition: SubmittedConditionInput) => {
        if (user?.id) {
            const conditionPayload = {
                patientId: user.id,
                asserterCategory: 'patient' as Asserter,
                clinicalStatus: condition.clinicalStatus,
                verificationStatus: 'unconfirmed' as VerificationStatus,
                onset:
                    condition.clinicalStatus === 'active'
                        ? { approximateYear: Number(condition.year) }
                        : undefined,
                abatement:
                    condition.clinicalStatus === 'inactive'
                        ? { approximateYear: Number(condition.year) }
                        : undefined,
                code: {
                    // this API is about to change
                    // https://asktia.slack.com/archives/C04LDP88076/p1677629459588879?thread_ts=1677628703.995159&cid=C04LDP88076
                    coding: [
                        {
                            system: 'http://snomed.info/sct',
                            version: '3',
                            code: String(condition.snomedCode),
                            display: condition.label,
                            userSelected: true
                        }
                    ],
                    text: condition.label
                }
            }
            return mutateAsync(conditionPayload)
        }
    }
}
