import {
    Card,
    Heading,
    Link,
    Paragraph,
    Flex,
    Button,
    Text
} from '@asktia/tia-ui'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { THREAD_LABELS } from 'src/globals'

import { Bill, MockedBillEnum, useBillForEncounter } from './useBillingSummary'
import { useDevSidebar } from 'src/DevTools/DevSideBar'
import { NotCollectableMessage, NotRecentEnoughMessage } from './BillMessages'
import BillingSummary from './BillingSummary'
import { isAfter } from 'date-fns'
import { Invoice } from 'src/types'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { useAmpli } from 'src/hooks'

function useSetupDevTools() {
    const [mockedType, setMockedType] = useState<MockedBillEnum | undefined>()

    const { addAction, deleteAction } = useDevSidebar()

    useLayoutEffect(() => {
        addAction({
            id: 'insurance_remaining',
            children: <>IR</>,
            onClick: () => setMockedType(MockedBillEnum.INSURANCE_REMAINING)
        })
        addAction({
            id: 'cash_pay_remaining',
            children: <>CPR</>,
            onClick: () => setMockedType(MockedBillEnum.CASH_PAY_REMAINING)
        })
        addAction({
            id: 'insurance_no_remaining',
            children: <>INR</>,
            onClick: () => setMockedType(MockedBillEnum.INSURANCE_NO_REMANING)
        })
        addAction({
            id: 'cash_pay_no_remaining',
            children: <>CPNR</>,
            onClick: () => setMockedType(MockedBillEnum.CASH_PAY_NO_REMAINING)
        })
        addAction({
            id: 'non_collectable',
            children: <>NC</>,
            onClick: () => setMockedType(MockedBillEnum.NON_COLLECTABLE)
        })

        return () => {
            deleteAction('insurance_remaining')
            deleteAction('cash_pay_remaining')
            deleteAction('insurance_no_remaining')
            deleteAction('cash_pay_no_remaining')
            deleteAction('non_collectable')
        }
    }, [])

    return mockedType
}

const recentEnoughForBillSummary = (scheduledTime: Date) => {
    const cuttoff = new Date('2023-11-01')
    const apptScheduledTime = new Date(scheduledTime)
    return isAfter(apptScheduledTime, cuttoff)
}

export const BillContents: FC<{ bill: Bill; scheduledTime: Date }> = ({
    bill,
    scheduledTime
}) => {
    const { viewAppointmentBillingDetails } = useAmpli()

    useEffect(() => {
        viewAppointmentBillingDetails({
            encounterId: bill?.encounterId,
            processedAs: bill?.processedAs,
            collectable: bill?.collectable,
            insuranceCoveredInCents: bill?.insuranceCoveredInCents,
            patientAdjustmentsInCents: bill?.patientAdjustmentsInCents,
            patientPaidInCents: bill?.patientPaidInCents,
            patientResponsibilityInCents: bill?.patientResponsibilityInCents,
            remainingBalanceInCents: bill?.remainingBalanceInCents,
            totalChargesInCents: bill?.totalChargesInCents
        })
    }, [])

    if (recentEnoughForBillSummary(scheduledTime)) {
        return (
            <>
                {bill.collectable ? (
                    <>
                        <BillingSummary bill={bill} />
                    </>
                ) : (
                    <NotCollectableMessage />
                )}
            </>
        )
    } else {
        return (
            <>
                <NotRecentEnoughMessage />
            </>
        )
    }
}

export const BillSupport: FC<{ usingInsurance: boolean }> = ({
    usingInsurance
}) => {
    return (
        <>
            <Heading h4 sx={{ mt: 5 }}>
                Bill Support
            </Heading>
            {usingInsurance && (
                <Card sx={{ p: 5, my: 0, mb: 0, color: 'secondary' }}>
                    <Paragraph sx={{ mb: 0, fontSize: 0 }}>
                        If you are using insurance, your plan determines how
                        your visit is covered along with your out-of-pocket
                        cost. As a healthcare provider, we don't have control
                        over this. If you have questions about your coverage, we
                        recommend contacting your insurance provider.
                    </Paragraph>
                </Card>
            )}
            {/* TODO: needs links for faq */}
            <Card sx={{ p: 5, my: 2, color: 'secondary' }}>
                <Paragraph sx={{ mb: 0, fontSize: 0 }}>
                    We offer a payment window of 30 days from the invoice date.
                    If we do not receive payment within 30 days, we will
                    automatically charge your card on file. If you have
                    questions about your bill, would like to discuss a different
                    way to pay, or would like a payment plan, please message
                    your{' '}
                    <Link
                        href={`/r/chat/new?label=${THREAD_LABELS.billing}&subject=I'd like to discuss a different way to pay my bill&message=I'd like to discuss a different way to pay my bill`}
                        sx={{ fontSize: 0 }}
                    >
                        Care Team.
                    </Link>{' '}
                    For common questions please see our{' '}
                    <Link
                        href={
                            'https://intercom.help/ask-tia/en/collections/3752830-insurance-and-billing'
                        }
                        sx={{ fontSize: 0 }}
                    >
                        FAQs.
                    </Link>
                </Paragraph>
            </Card>
        </>
    )
}

export const BillSummary: FC<{
    claimId: string
    scheduledTime: Date
    hasInvoice: boolean
    encounterInvoice: Invoice | undefined
}> = ({ claimId, scheduledTime, hasInvoice, encounterInvoice }) => {
    const mockedType = useSetupDevTools()
    const { bill } = useBillForEncounter(claimId, mockedType, {
        suspense: true
    })

    if (!bill) {
        // it's safe to bail here because we're using Suspense
        // nothing renders until bill is ready
        return null
    }

    const usingInsurance = bill.processedAs === 'Insurance-pay'
    const predictablePaymentsNOTReleased =
        useAmpliFeatureFlag('predictable-payments-released') !== 'treatment'

    return (
        <>
            <Heading h4>Bill Summary</Heading>
            <BillContents
                bill={bill}
                scheduledTime={scheduledTime}
            ></BillContents>
            {hasInvoice && predictablePaymentsNOTReleased && (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Button
                        sx={{ mt: 4 }}
                        onClick={() =>
                            window
                                ?.open(encounterInvoice!.hostedUrl, '_blank')
                                ?.focus()
                        }
                    >
                        Pay your bill
                    </Button>
                    <Text sx={{ mt: 2, color: 'supportText', fontSize: 0 }}>
                        Unpaid invoices are automatically charged to the card on
                        file 30 days after your invoice is finalized
                    </Text>
                </Flex>
            )}
            <BillSupport usingInsurance={usingInsurance}></BillSupport>
        </>
    )
}
