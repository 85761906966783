import { Box, Button, Flex, Label, Spinner } from '@asktia/tia-ui'
import {
    PropsWithChildren,
    useState,
    useEffect,
    MouseEventHandler
} from 'react'
import { Divider } from 'src/components/Blocks'

// helper component for each account-info row
export const AccountInfoRow = (
    props: PropsWithChildren<{
        title: string
        isLoading?: boolean
        onEdit?: () => void
        onSave?: () => void
        onCancel?: () => void
        defaultEditValue?: boolean
    }>
) => {
    const [toggleEdit, setToggleEdit] = useState(!props.defaultEditValue)

    useEffect(() => {
        setToggleEdit(!props.defaultEditValue)
    }, [props.defaultEditValue])

    const onClickEdit = () => {
        setToggleEdit(!toggleEdit)
        props.onEdit && props.onEdit()
    }

    const onClickSave = () => {
        setToggleEdit(!toggleEdit)
        props.onSave && props.onSave()
    }

    const onClickCancel = () => {
        setToggleEdit(!toggleEdit)
        props.onCancel && props.onCancel()
    }

    const labelStyles = { textDecoration: 'underline', color: 'supportText' }

    const EditableRighHandSideButtons = () => {
        if (toggleEdit && props.onEdit) {
            return (
                <Flex>
                    <Label sx={labelStyles} onClick={onClickEdit}>
                        Edit
                    </Label>
                </Flex>
            )
        }

        if (!toggleEdit) {
            return (
                <Flex sx={{ columnGap: 20 }}>
                    {props.onCancel && (
                        <Label sx={labelStyles} onClick={onClickCancel}>
                            Cancel
                        </Label>
                    )}
                    {props.onSave && (
                        <Label sx={labelStyles} onClick={onClickSave}>
                            Save
                        </Label>
                    )}
                </Flex>
            )
        }
    }

    return (
        <Box
            sx={
                props.onEdit && !props.defaultEditValue
                    ? { '& *:hover': { cursor: 'pointer' } }
                    : {}
            }
            onClick={() =>
                props.onEdit && !props.defaultEditValue && onClickEdit()
            }
            role="button"
        >
            <Divider sx={{ bg: 'inputOutline' }} />
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Label>{props.title}</Label>
                {EditableRighHandSideButtons()}
            </Flex>
            {props.isLoading ? (
                <Spinner sx={{ height: 5, mt: -1 }} />
            ) : (
                props.children
            )}
        </Box>
    )
}

// helper component for bold clickable labels in account-info
export const AccountInfoRowLabel = (
    props: PropsWithChildren<{ onClick: MouseEventHandler }>
) => {
    return (
        <Button
            variant="unstyled"
            sx={{
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: 4
            }}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    )
}
