import { MedplumClient } from '@medplum/core'
import { Bundle, Immunization } from '@medplum/fhirtypes'
import { useMedplumContext } from '@medplum/react'
import { useQuery, UseQueryOptions } from 'react-query'
import { MedplumImmunizationItem } from 'src/flows/questionnaires/MedicalHistory/types'
import { useUserInfo } from 'src/hooks'
import { UserInfo } from 'src/types'

const PAGE_SIZE = 100
const FILTERED_STATUS = 'completed'

function getNextPageParams(bundle?: Bundle<Immunization>) {
    const url = bundle?.link?.find(l => l.relation === 'next')?.url
    if (url) {
        return new URL(url).searchParams
    }
}

function getImmunization(medplum: MedplumClient, user?: UserInfo) {
    return async () => {
        const results = await medplum.searchResources(
            'Immunization',
            new URLSearchParams(
                `patient.identifier=${user?.id}&_sort=-date&status=${FILTERED_STATUS}&_count=${PAGE_SIZE}`
            )
        )

        let nextPageParams = getNextPageParams(results?.bundle)
        while (nextPageParams) {
            const nextPageResults = await medplum.searchResources(
                'Immunization',
                nextPageParams
            )
            // @ts-ignore
            results.push(...nextPageResults)
            nextPageParams = getNextPageParams(nextPageResults?.bundle)
        }

        return results
    }
}

export const useGetImmunizations = (
    options?: Omit<UseQueryOptions<any, any, any, any>, 'queryFn'>
) => {
    const { user, isLoading: userIsLoading } = useUserInfo()
    const { medplum, loading } = useMedplumContext()

    const { data, isLoading: immunizationLoading } = useQuery(
        ['get-immunizations'],
        getImmunization(medplum, user),
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            enabled: !!user,
            refetchOnWindowFocus: false,
            ...(options ?? {})
        }
    )
    const results = data as MedplumImmunizationItem[]
    return {
        isLoading: userIsLoading || immunizationLoading || loading,
        immunizations: results || []
    }
}
