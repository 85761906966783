import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { EligibilityCheck, InsuranceCoverage } from 'src/types'

export type LatestInsurance = {
    coverage?: InsuranceCoverage
    eligibilityCheck?: EligibilityCheck
    outstandingInsuranceAlerts: string[]
    // TODO: incomplete type, we aren't using this
    demographicDiscrepancies: unknown
}

export const getLatestInsurance = async (userId: number) => {
    return await fetcher(
        `${BASE_API_URL}/tmd-api/patients/${userId}/latest-insurance`
    )
}

export const useLatestInsurance = () => {
    const userInfo = useUserInfo()
    const query = useQuery<LatestInsurance>(
        ['get-latest-insurance', userInfo?.user?.id],
        () => getLatestInsurance(userInfo?.user?.id as number),
        {
            enabled: !!userInfo?.user
        }
    )
    return {
        latestInsurance: query.data,
        isLoading: query.isLoading || userInfo.isLoading
    }
}
