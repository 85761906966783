import { ReactComponent as IconClinic } from 'src/assets/icon-clinic.svg'
import { ReactComponent as IconVirtual } from 'src/assets/icon-virtual.svg'
import { ReactComponent as IconLocation } from 'src/assets/icon-location.svg'
import { LocationModality, LocationDetails } from 'src/types'
import { PropsWithChildren } from 'react'

import { Flex, Text, Box, ThemeStyleObject } from '@asktia/tia-ui'

const LocationBadge = (
    props: PropsWithChildren<{
        sx?: ThemeStyleObject
    }>
) => {
    return (
        <Flex
            sx={{
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    borderRadius: '37px',
                    paddingX: 3,
                    paddingY: 1,
                    display: 'flex',
                    alignItems: 'center',
                    svg: {
                        width: 4,
                        height: 4
                    },
                    ...props.sx
                }}
            >
                {props.children}
            </Box>
        </Flex>
    )
}

const LocationModalityBadgeContent = ({
    modalities
}: {
    modalities: LocationModality[]
}) => {
    let Icon = IconLocation
    if (modalities.length === 1) {
        if (modalities[0] === 'clinic') {
            Icon = IconClinic
        } else if (modalities[0] === 'virtual') {
            Icon = IconVirtual
        }
    }

    const labels = { clinic: 'In-Clinic', virtual: 'Virtual' }
    return (
        <>
            <Icon />
            <Text
                sx={{ ml: 2, fontSize: 0, color: 'linkText', fontWeight: 400 }}
            >
                {modalities
                    .sort()
                    .map(l => labels[l])
                    .join(' & ')}
            </Text>
        </>
    )
}

const SpecificLocationBadgeContent = ({
    locationDetails
}: {
    locationDetails: LocationDetails
}) => {
    const { modality, clinic } = locationDetails
    let Icon = IconClinic
    let label = clinic.name
    if (modality === 'virtual') {
        Icon = IconVirtual
        label = 'Virtual'
    }

    return (
        <>
            <Icon color="linkText" />
            <Text
                sx={{ ml: 2, fontSize: 0, color: 'linkText', fontWeight: 400 }}
            >
                {label}
            </Text>
        </>
    )
}

export type AppointmentLocationProps = {
    locationDetails?: LocationDetails
    sx?: ThemeStyleObject
}

export type LocationModalityProps = {
    modalities?: LocationModality[]
    sx?: ThemeStyleObject
}

export const AppointmentLocation = ({
    locationDetails,
    sx
}: AppointmentLocationProps) => {
    if (!locationDetails) {
        return null
    }

    return (
        <Flex
            sx={{
                mb: 2,
                justifyContent: 'left',
                alignItems: 'flex-end',
                color: 'supportText',
                svg: {
                    width: 4,
                    height: 4
                },
                ...sx
            }}
        >
            <SpecificLocationBadgeContent locationDetails={locationDetails} />
        </Flex>
    )
}

export const AppointmentLocationBadge = ({
    locationDetails,
    sx
}: AppointmentLocationProps) => {
    if (!locationDetails) {
        return null
    }

    return (
        <LocationBadge sx={sx}>
            <SpecificLocationBadgeContent locationDetails={locationDetails} />
        </LocationBadge>
    )
}

export const LocationModalityBadge = ({
    modalities,
    sx
}: LocationModalityProps) => {
    if (!modalities || !modalities.length) {
        return <Box sx={{ height: 5 }} />
    }

    return (
        <LocationBadge sx={sx}>
            <LocationModalityBadgeContent modalities={modalities} />
        </LocationBadge>
    )
}
