import { Box, Flex, Heading, Text, Image } from '@asktia/tia-ui'
import { FC } from 'react'
import { AppointmentV1, AppointmentWithLocationDetails } from 'src/types'
import { format } from 'date-fns'
import { ChevronRight } from 'src/components/Blocks/Icons'
import { Link } from 'react-router-dom'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { useInvoices } from 'src/hooks/useInvoices'

interface EncounterCardProps {
    variant: 'link' | 'with-photo'
    appointment: AppointmentV1 | AppointmentWithLocationDetails
    href?: string
    sx?: ThemeUIStyleObject
}

const cancelledStatuses = ['Cancelled', 'Cancelled Late']

const getLocationText = (
    appointment: AppointmentV1 | AppointmentWithLocationDetails
) => {
    if (cancelledStatuses.includes(appointment.status)) {
        return appointment.status
    }

    const providerName =
        appointment.providerGivenName && appointment.providerFamilyName
            ? `${appointment.providerGivenName} ${appointment.providerFamilyName}`
            : null

    if (!providerName) {
        return appointment.isVirtual ? 'Virtual' : 'In Clinic'
    }

    return appointment.isVirtual
        ? `Virtual with ${providerName}`
        : `In Clinic with ${providerName}`
}

export const EncounterCard: FC<EncounterCardProps> = ({
    appointment,
    variant,
    href,
    sx
}) => {
    const { invoices } = useInvoices()

    const invoiceCutoffDate = new Date('2023-11-02')
    const encounterInvoice = invoices?.find(
        invoice => invoice.referenceId === appointment.id
    )
    const hasInvoice = !!encounterInvoice
    const isAppointmentAfterCutoff =
        appointment.scheduledTime > invoiceCutoffDate
    const isClickable =
        !!appointment.claimId && (isAppointmentAfterCutoff || hasInvoice)

    return (
        <Box
            as={href && variant === 'link' && isClickable ? Link : undefined}
            // TS got confused with the "as" prop
            // @ts-ignore
            to={href}
            sx={{
                fontSize: 2,
                alignItems: 'center',
                background:
                    isClickable || variant === 'with-photo'
                        ? 'cardBackground'
                        : 'inputBackgroundInactive',
                textDecoration: 'none',
                border: t => `1px solid ${t.colors?.cardOutline}`,
                fontWeight: 'unset !important',
                userSelect: 'none',
                borderRadius: 2,
                py: 4,
                px: 5,
                mb: 2,
                ...sx
            }}
        >
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Flex
                    sx={{
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                    }}
                >
                    <Text variant="eyebrow" color="supportText">
                        {format(
                            appointment.scheduledTime,
                            'MMM dd, yyyy, hh:mm a'
                        )}
                    </Text>

                    <Heading
                        variant="h4"
                        sx={{
                            my: 1,
                            color: 'text'
                        }}
                    >
                        {appointment.label}
                    </Heading>

                    <Text
                        sx={{
                            fontSize: 0,
                            color: 'supportText'
                        }}
                    >
                        {getLocationText(appointment)}
                    </Text>
                </Flex>

                {variant === 'link' && isClickable && (
                    <Flex
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <ChevronRight color="text" />
                    </Flex>
                )}

                {variant === 'with-photo' && (
                    <Flex
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Image src={appointment.imageUrl} />
                    </Flex>
                )}
            </Flex>
        </Box>
    )
}
