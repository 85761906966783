import { AsyncSelect } from '@asktia/tia-ui'
import { debounce } from 'lodash'
import { FC, ReactNode } from 'react'
import { useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { ThemeUIStyleObject } from 'theme-ui'

export type SnomedOption = {
    text: string
    value: string
}

export type SemanticTag =
    | 'assessment scale'
    | 'attribute'
    | 'body structure'
    | 'cell structure'
    | 'disorder'
    | 'environment'
    | 'event'
    | 'finding'
    | 'foundation metadata concept'
    | 'geographic location'
    | 'medicinal product form'
    | 'morphologic abnormality'
    | 'observable entity'
    | 'occupation'
    | 'organism'
    | 'physical object'
    | 'procedure'
    | 'product'
    | 'quality value'
    | 'record artifact'
    | 'regime/therapy'
    | 'situation'
    | 'SNOMED RT+CTV3'
    | 'substance'

type SnomedTypeaheadProps = {
    name: string
    semanticTag?: SemanticTag
    placeholder?: string
    sx?: ThemeUIStyleObject
    errorMessage?: string
    disabled?: boolean
    noOptionsMessage?: (obj: { inputValue: string }) => ReactNode
}

async function fetchTypeahead(
    query: string,
    semanticTag?: SemanticTag
): Promise<SnomedOption[]> {
    const data = await debounce<() => Promise<{ options: SnomedOption[] }>>(
        () =>
            fetcher(
                `${BASE_API_URL}/data-api/data/typeahead/snomed?q=${query}&maxResults=20&semanticTag=${semanticTag}`
            ),
        50,
        {
            leading: true
        }
    )()

    return data.options
}

export const SnomedTypeahead: FC<SnomedTypeaheadProps> = props => {
    const queryClient = useQueryClient()

    // Can't do useQuery because need trigger on demand
    // Can't do useMutation because need cache
    async function loadOptions(inputValue: string) {
        if (inputValue.length < 3) {
            // backend rejects searches under 3 char
            return []
        }

        const data = await queryClient.fetchQuery<SnomedOption[]>(
            ['snomed-typeahead', inputValue],
            () => fetchTypeahead(inputValue, props.semanticTag),
            {
                // Snomed unlikely to change
                // during a user session
                staleTime: Infinity
            }
        )

        return data
    }

    return (
        <AsyncSelect
            name={props.name}
            placeholder={props.placeholder || 'Search snomed'}
            getOptionLabel={opt => opt.text}
            loadOptions={loadOptions}
            noOptionsMessage={props.noOptionsMessage}
            errorMessage={props.errorMessage}
            sx={props.sx}
            isDisabled={props.disabled}
        />
    )
}
