import { Link } from 'react-router-dom'
import { ServiceLine } from 'src/types'
import {
    Card,
    Grid,
    Heading,
    Box,
    ThemeStyleObject,
    Image
} from '@asktia/tia-ui'
import { LoadingCards } from 'src/components/Blocks/LoadingCards'
import { useServiceLines } from 'src/flows/AppointmentBooking/useServiceLines'

const CareTypeLink = ({ imageUrl, label, name, imageBgColor }: ServiceLine) => {
    const appointmentBrowsingLink = `/appointments?serviceLines=${name}`

    return (
        <Card
            as={Link}
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                color: 'text',
                textDecoration: 'none'
            }}
            // @ts-ignore: TS doesn't understand this is a RouterLink now
            to={appointmentBrowsingLink}
        >
            <Image
                src={imageUrl}
                sx={{
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    backgroundColor: imageBgColor
                }}
            />
            <Heading
                h4
                sx={{
                    px: 3,
                    py: 12,
                    mb: 0,
                    bg: 'white',
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: 2
                }}
            >
                {label}
            </Heading>
        </Card>
    )
}

export const ServiceLines = (props: { sx?: ThemeStyleObject }) => {
    const { serviceLines, isLoading } = useServiceLines()

    return (
        <Box sx={props.sx}>
            <Heading h2>Browse appointments by service</Heading>

            <Grid width={155} gap={4}>
                {isLoading && <LoadingCards cards={4} height={209} />}
                {serviceLines?.map(serviceLine => (
                    <CareTypeLink {...serviceLine} key={serviceLine.name} />
                ))}
            </Grid>
        </Box>
    )
}
