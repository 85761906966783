import { Link, Text, ThemeStyleObject } from '@asktia/tia-ui'
import React, { FC } from 'react'
import OGLinkify, { Props } from 'react-linkify'

type LinkifyProps = {
    sx?: ThemeStyleObject
    linkSx?: ThemeStyleObject
} & Props

const Linkify: FC<React.PropsWithChildren<LinkifyProps>> = props => {
    const { sx, linkSx, ...rest } = props
    return (
        <Text sx={sx}>
            <OGLinkify
                componentDecorator={(href, decoratedText, key) => (
                    <Link href={href} key={key} target="_blank" sx={linkSx}>
                        {decoratedText}
                    </Link>
                )}
                {...rest}
            />
        </Text>
    )
}

export default Linkify
