import {
    Box,
    Heading,
    Input,
    Label,
    LabeledCheckbox,
    Paragraph,
    Radio,
    Text,
    AsyncSelect
} from '@asktia/tia-ui'
import { useEffect } from 'react'
import { NameForm } from 'src/components/Blocks/NameForm'
import { Divider } from 'src/components/Blocks'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { DataBlob } from 'src/types'

type IssuerOption = {
    label: string
    value: string
}

// using reactQuery for this would be awkward
async function fetchIssuerTypeahead(
    searchTerm: string
): Promise<IssuerOption[]> {
    if (!searchTerm) {
        // empty search errors out on backend
        return []
    }

    const res = await fetcher(
        `${BASE_API_URL}/api/v1/account/typeahead?q=${searchTerm}&type=payer`
    )

    if (res && res.options) {
        return res.options.map((val: DataBlob) => ({
            label: val.text,
            value: val.value && (val.value as DataBlob).uuid
        }))
    } else {
        return []
    }
}

export const InsuranceInfoForm = () => {
    return (
        <>
            <Heading h2>Your Insurance Info</Heading>

            <Label>Insurance Company</Label>
            <AsyncSelect
                name="issuerName"
                loadOptions={async (searchTerm: string) =>
                    fetchIssuerTypeahead(searchTerm)
                }
                getOptionValue={opt => opt.value}
                errorMessage="Insurance Company is required"
            />

            <Label>Member ID</Label>
            <Input
                name="memberId"
                required
                errorMessage="Member ID is required"
            />

            <Box>
                <NameForm
                    label="Name on Plan"
                    fieldNames={['patientGivenName', 'patientFamilyName']}
                    defaultValues={['', '']}
                />
            </Box>

            <LabeledCheckbox name="patientIsADependent">
                I'm a dependent
            </LabeledCheckbox>
        </>
    )
}

export const AddInsuranceForm = (props: { setValue: Function }) => {
    useEffect(() => {
        return () => props.setValue('addInsurance', '')
    }, [])

    return (
        <>
            <Heading h2>Paying for Your Appointment</Heading>
            <Heading h4>Would you like to add insurance?</Heading>
            <Paragraph>
                We don’t have any insurance information on file.
            </Paragraph>

            <Radio name="addInsurance" value="yes" pretty sx={{ mb: 2 }}>
                Yes
            </Radio>
            <Radio name="addInsurance" value="no" pretty sx={{ mb: 4 }}>
                No
            </Radio>
            <Divider variant="blockSpacer" />
        </>
    )
}

export const ExistingInsuranceForm = (props: {
    issuerName: string
    memberId: string
    subscriberGivenName: string
    subscriberFamilyName: string
    patientIsADependent: boolean
    setValue: Function
}) => {
    // ReactHookForm isn't deregistering this control on unmount, so we reset its value
    useEffect(() => {
        return () => props.setValue('useExistingInsurance', '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Heading h2>Paying for Your Appointment</Heading>
            <Heading h4>
                Would you like to use the insurance information we have saved to
                your file?
            </Heading>

            <Label>Insurance Company</Label>
            <Paragraph sx={{ mb: 4 }}>{props.issuerName}</Paragraph>
            <Label>Member ID</Label>
            <Paragraph sx={{ mb: 4 }}>{props.memberId}</Paragraph>
            {props.patientIsADependent ? (
                <>
                    <Label>Policyholder's Name</Label>
                    <Text>
                        {props.subscriberGivenName} {props.subscriberFamilyName}
                    </Text>
                </>
            ) : null}

            <Radio
                name="useExistingInsurance"
                value="yes"
                pretty
                sx={{ mb: 2 }}
            >
                Yes
            </Radio>
            <Radio name="useExistingInsurance" value="no" pretty>
                No
            </Radio>
        </>
    )
}
