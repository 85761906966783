import { Flex, Heading } from '@asktia/tia-ui'
import { FC } from 'react'
import { AppointmentWithLocationDetails } from 'src/types'
import { EncounterCard } from '../components/EncounterCard'

export const AppointmentInfo: FC<{
    appointment?: AppointmentWithLocationDetails
}> = ({ appointment }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mb: 4 }}>
            <Heading h4>Appointment Info</Heading>
            <EncounterCard variant="with-photo" appointment={appointment!} />
        </Flex>
    )
}
