import { useEffect } from 'react'
import { Container, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { useAppointmentHistory } from 'src/hooks/useAppointmentHistory'
import { useAmpli } from 'src/hooks'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'

import { AppointmentHistoryRow } from './components/AppointmentHistoryRow'
import { AppointmentV1 } from 'src/types'
import { StandardPageHeader } from 'src/components/PageHeader'

export const AppointmentHistoryList = () => {
    const { appointments, isLoading } = useAppointmentHistory()
    const { appointmentHistoryViewed } = useAmpli()
    useEffect(() => appointmentHistoryViewed(), [appointmentHistoryViewed])

    return (
        <Body>
            <StandardPageHeader />
            <NavBarHeading>Appointment History</NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        appointments.map((appointment: AppointmentV1) => (
                            <AppointmentHistoryRow
                                appointment={appointment}
                                key={appointment.id}
                            />
                        ))
                    )}
                </LayoutGrid>
            </Container>
        </Body>
    )
}
