import { Button, ThemeStyleObject } from '@asktia/tia-ui'
import { SyntheticEvent } from 'react'
import { useAtAppointment } from 'src/flows/AutomatedCheckin/useAtAppointment'
import { useAppointmentStatus } from 'src/hooks/useAppointmentStatus'
import { AppointmentWithLocationDetails } from 'src/types'
import { useAmpli } from 'src/hooks'

export const AppointmentZoomLink = ({
    appointment,
    sx
}: {
    appointment: AppointmentWithLocationDetails
    sx?: ThemeStyleObject
}) => {
    const { canStart } = useAppointmentStatus(appointment)
    const atAppointment = useAtAppointment(appointment.id)
    const { appointmentStarted } = useAmpli()

    const handleClick = (event: SyntheticEvent) => {
        if (!canStart) {
            event.preventDefault()
            return
        }

        appointmentStarted(appointment)
        atAppointment()
        window.open(
            appointment.videoConferenceUrl,
            '_blank',
            'noreferrer,noopener'
        )
    }

    return (
        <Button disabled={!canStart} onClick={handleClick} sx={sx} fullWidth>
            Enter appointment
        </Button>
    )
}
