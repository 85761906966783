import { useState } from 'react'
import logger from 'src/logger'

// copypasta dependency borrowed from https://medium.com/javascript-in-plain-english/uselocalstorage-react-hook-2532e922d5b1
export function useLocalStorage<T>(
    key: string,
    initialValue: T
): [T, (s: ((storedValue: T) => T) | T) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // If error afso return initialValue
            logger.error((error as any).message, {
                indexMeta: true,
                meta: {
                    stack: (error as any).stack
                }
            })
            return initialValue
        }
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: ((storedValue: T) => T) | T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            // Save state
            setStoredValue(valueToStore)
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            logger.error((error as any).message, {
                indexMeta: true,
                meta: {
                    stack: (error as any).stack
                }
            })
        }
    }

    return [storedValue, setValue]
}
