import { Route, Routes, useLocation } from 'react-router-dom'
import { Header } from 'src/components/Blocks'
import { Container, LayoutGrid } from '@asktia/tia-ui'
import { AppointmentChangeQuestion } from './AppointmentChangeQuestion'
import { AppointmentCancelReason } from './AppointmentCancelReason'
import { AppointmentCancelConfirmation } from './AppointmentCancelConfirmation'
import { LinkAlreadyUsed } from './LinkAlreadyUsed'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'

export const AppointmentChange = () => {
    const location = useLocation()

    return (
        <Body>
            <Routes location={location}>
                <Route
                    path=":appointmentId/link-used"
                    element={
                        <>
                            <Header showClose></Header>
                            <LayoutGrid columns="one">
                                <Container>
                                    <LinkAlreadyUsed />
                                </Container>
                            </LayoutGrid>
                        </>
                    }
                />
                <Route
                    path=":appointmentId/cancel/confirm"
                    element={
                        <>
                            <Header showClose></Header>
                            <NavBarHeading showBackButton={false}>
                                Cancel or change appointment
                            </NavBarHeading>
                            <LayoutGrid columns="one">
                                <Container>
                                    <AppointmentCancelConfirmation />
                                </Container>
                            </LayoutGrid>
                        </>
                    }
                />
                <Route
                    path=":appointmentId/cancel"
                    element={
                        <>
                            <Header showClose></Header>
                            <NavBarHeading showBackButton={false}>
                                Cancel or change appointment
                            </NavBarHeading>
                            <LayoutGrid columns="one">
                                <Container>
                                    <AppointmentCancelReason />
                                </Container>
                            </LayoutGrid>
                        </>
                    }
                />

                <Route
                    path=":appointmentId"
                    element={
                        <>
                            <Header showClose></Header>
                            <NavBarHeading showBackButton={false}>
                                Cancel or change appointment
                            </NavBarHeading>
                            <LayoutGrid columns="one">
                                <Container>
                                    <AppointmentChangeQuestion />
                                </Container>
                            </LayoutGrid>
                        </>
                    }
                />
            </Routes>
        </Body>
    )
}
