import { Container, Flex } from '@asktia/tia-ui'
import { FC, ReactNode, useEffect, useState } from 'react'
import { isMobile } from 'src/utils'

export const FilterContainers: FC<{
    children: ReactNode
}> = ({ children }) => {
    const [isSticky, setIsSticky] = useState(false)
    const stickyTopPoints = [64, 84]

    useEffect(() => {
        // show / hide the container shadow when it sticks
        const onScrollHandler = (e: Event) => {
            const targetElement: any = e.target
            const scrollTop = targetElement.body.parentElement.scrollTop

            const breakPoint = stickyTopPoints[isMobile() ? 0 : 1] - 12 // 12 is a magic number that makes the shadow show/hide more smooth

            if (scrollTop >= breakPoint) {
                setIsSticky(true)
            }

            if (scrollTop < breakPoint) {
                setIsSticky(false)
            }
        }

        window.addEventListener('scroll', onScrollHandler)

        return () => {
            window.removeEventListener('scroll', onScrollHandler)
        }
    }, [])

    return (
        <Flex
            bg="cream"
            sx={{
                alignItems: 'flex-end',
                minHeight: 156,
                width: '100%',
                position: 'sticky',
                top: stickyTopPoints,
                boxShadow: isSticky
                    ? '0px 0px 24px rgba(118, 84, 84, 0.24)'
                    : undefined,
                pt: [7, 8],
                pb: [0, 6]
            }}
        >
            <Container>{children}</Container>
        </Flex>
    )
}
