import { useEffect } from 'react'
import { Box, Heading, LabeledCheckbox, Paragraph } from '@asktia/tia-ui'

import { Address } from 'src/types'
import { UseFormMethods } from 'react-hook-form'
import { AddressForm } from 'src/components/AddressForm'

export const ReferralAddressForm = (props: {
    mailingAddress?: Address
    referralAddress?: Address
    formMethods: UseFormMethods
}) => {
    const formMethods = props.formMethods
    const addressFields = [
        'referral_address1',
        'referral_address2',
        'referral_city',
        'referral_state',
        'referral_postalCode'
    ]

    const sameAsMailing = formMethods.watch('sameAsMailing')
    const address = formMethods.watch(addressFields)

    // populate address when clicking checkbox
    useEffect(() => {
        if (sameAsMailing && props.mailingAddress) {
            for (const key of addressFields) {
                formMethods.setValue(
                    key,
                    // @ts-ignore: TS doesn't understand objects can be indexed by string
                    props.mailingAddress[key.replace('referral_', '')],
                    {
                        shouldValidate: true
                    }
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sameAsMailing, JSON.stringify(props.mailingAddress)])

    // untick checkbox when address changes
    useEffect(() => {
        for (const key of Object.keys(address)) {
            const isDirty = formMethods.formState.dirtyFields[key]
            if (
                isDirty &&
                formMethods.getValues(key) !==
                    // @ts-ignore: TS doesn't understand objects can be indexed by string
                    props.mailingAddress[key.replace('referral_', '')]
            ) {
                if (sameAsMailing) {
                    formMethods.setValue('sameAsMailing', false)
                }
            }
        }
    }, [address, props.mailingAddress, sameAsMailing, formMethods])

    return (
        <Box>
            <Heading h2>Address for Referrals</Heading>
            <Paragraph sx={{ mb: 4 }}>
                In the instance Tia needs to refer you to another specialist,
                we’ll use this address to find the best options near you!
            </Paragraph>

            <LabeledCheckbox name="sameAsMailing" sx={{ mb: 4 }}>
                Same as mailing address
            </LabeledCheckbox>

            <AddressForm
                sx={sameAsMailing ? { display: 'none' } : {}}
                fieldnamePrefix="referral_"
                defaultValue={props.referralAddress}
            />
        </Box>
    )
}
