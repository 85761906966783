import { Box, Button, Heading, Paragraph } from '@asktia/tia-ui'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { THREAD_LABELS } from 'src/globals'
import { AppointmentProfile } from 'src/types'

export function useTalkTherapyWaitlist(appointmentProfile: AppointmentProfile) {
    const [searchParams] = useSearchParams()
    const isWaitlistEnabled =
        useAmpliFeatureFlag('enable-talk-therapy-waitlist') === 'on'

    const isRescheduling = !!searchParams.get('reschedule')

    return {
        showWaitlist:
            isWaitlistEnabled &&
            appointmentProfile.name === 'talk_therapy_program' &&
            !isRescheduling
    }
}

export const TalkTherapyWaitlist = () => {
    const subject = 'Talk Therapy Waitlist'
    const message = "I'd like to join the Talk Therapy waitlist."

    return (
        <Box sx={{ color: 'mainBackground' }}>
            <Heading h2 sx={{ color: 'mainBackground' }}>
                No appointments available
            </Heading>
            <Paragraph>
                Please check back in 4 weeks. If you’d like to join the
                waitlist, message your Care Coordinator and we’ll be happy to
                assist you.
            </Paragraph>
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    as={RouterLink}
                    // @ts-ignore: TS doesn't understand this is a RouterLink now
                    to={`/chat/new?label=${THREAD_LABELS.scheduling}&subject=${subject}&message=${message}`}
                    sx={{
                        minWidth: ['100%', '0%']
                    }}
                >
                    Join the list
                </Button>
            </Box>
        </Box>
    )
}
