import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { AppointmentProfile, AppointmentSuggestion } from 'src/types'
import useDeepCompareEffect from 'use-deep-compare-effect'
import _ from 'lodash/fp'
import { useServiceLines } from 'src/flows/AppointmentBooking/useServiceLines'

export type ServiceDropdownItem = {
    label: string
    value: string
}

export type ClinicDropdownItem = {
    label: string
    value: string
    uuid: string
}

type FormValues = {
    serviceLines?: ServiceDropdownItem[]
    locations?: ClinicDropdownItem[]
    symptom?: string
    appointment?: string
    showRecommended?: boolean
}

export const fakeVirtualClinicForDropdown = {
    name: 'Virtual',
    uuid: 'Virtual',
    timezone: '',
    externalId: '',
    businessUuid: '',
    phoneNumber: '',
    isVirtual: true,
    address1: '',
    city: '',
    state: '',
    postalCode: ''
}

export type FilterFunction = (offering: AppointmentProfile) => boolean

// To add a filter:
// 1. Add new config
// 2. Add to defaultValues
// 3. Add to updateFilters
const FilterConfigs = {
    serviceLines: {
        valueForURL: (values: FormValues) =>
            values?.serviceLines?.map(s => s.value).join(',') || [],
        readFromURL: function readFromURL(
            searchParams: URLSearchParams,
            offerings?: AppointmentProfile[]
        ) {
            if (searchParams.get('serviceLines')) {
                const allServiceLines = offerings
                    ?.map(o => o.marketingServiceLines)
                    .flat()

                // O(n^2) but N is small
                const serviceLineDropdown = searchParams
                    .get('serviceLines')
                    ?.split(',')
                    .map(name => {
                        const service = allServiceLines?.find(
                            s => s?.name === name
                        )
                        return {
                            label: service?.label,
                            value: service?.name || name
                        }
                    })

                return serviceLineDropdown
            } else {
                return []
            }
        },
        filter: function filterByServiceLine(values: FormValues) {
            const serviceLines = values?.serviceLines?.map(s => s.value) || []

            return (offering: AppointmentProfile) => {
                // empty is "Any"
                if (serviceLines.length === 0) {
                    return true
                } else {
                    const services = _.intersection(
                        offering.marketingServiceLines?.map(sl => sl.name),
                        serviceLines
                    )
                    return services.length > 0
                }
            }
        }
    },

    locations: {
        valueForURL: (values: FormValues) =>
            values?.locations?.map(s => s.value).join(',') || [],
        readFromURL: function readFromURL(
            searchParams: URLSearchParams,
            offerings?: AppointmentProfile[]
        ) {
            const locationsString = searchParams.get('locations')

            if (locationsString) {
                // O(n^2) but N is small
                const allLocations = offerings
                    ?.map(o => o.clinicsWithOffering)
                    .flat()

                allLocations?.push(fakeVirtualClinicForDropdown)

                const locationDropdownItems = locationsString
                    .split(',')
                    .map(location => {
                        const uuid = allLocations?.find(
                            l => l.name === location
                        )?.uuid

                        return {
                            label: location,
                            value: location,
                            uuid: uuid
                        }
                    })

                return locationDropdownItems
            } else {
                return []
            }
        },
        filter: function filterByLocations(values: FormValues) {
            const locationUuids = values?.locations?.map(s => s.uuid) || []

            return (offering: AppointmentProfile) => {
                if (locationUuids.length === 0) {
                    // empty is "Any"
                    return true
                } else if (
                    offering.clinicsWithOffering.find(
                        clinic => clinic.isVirtual
                    )
                ) {
                    // Always include virtual
                    return true
                } else {
                    const clinicsWithOffering =
                        offering.clinicsWithOffering.map(clinic =>
                            clinic.isVirtual
                                ? fakeVirtualClinicForDropdown.uuid
                                : clinic.uuid
                        )

                    const clinicsInCommon = _.intersection(
                        locationUuids,
                        clinicsWithOffering
                    )
                    return clinicsInCommon.length > 0
                }
            }
        }
    },

    symptom: {
        // react-router filters out [], but not null or ''
        valueForURL: (values: FormValues) => values?.symptom || [],
        readFromURL: function readFromURL(searchParams: URLSearchParams) {
            return searchParams.get('symptom')
        },
        filter: function filterByTag(values: FormValues) {
            const symptom = values?.symptom

            return (offering: AppointmentProfile) => {
                // empty is "Any"
                if (!symptom) {
                    return true
                } else {
                    return offering.appointmentProfileTags?.some(
                        t => t.name === symptom
                    )
                }
            }
        }
    },

    appointment: {
        // react-router filters out [], but not null or ''
        valueForURL: (values: FormValues) => values?.appointment || [],
        readFromURL: function readFromURL(searchParams: URLSearchParams) {
            return searchParams.get('appointment')
        },
        filter: function filterByProfile(values: FormValues) {
            const profileName = values?.appointment

            return (offering: AppointmentProfile) => {
                // empty is "Any"
                if (!profileName) {
                    return true
                } else {
                    return offering.name === profileName
                }
            }
        }
    },

    showRecommended: {
        // react-router filters out [], but not null or ''
        valueForURL: (values: FormValues) =>
            values?.showRecommended ? 'yes' : [],
        readFromURL: function readFromURL(searchParams: URLSearchParams) {
            return !!searchParams.get('showRecommended')
        },
        filter: function filterByProfile(
            values: FormValues,
            appointmentSuggestions?: AppointmentSuggestion[]
        ) {
            const showRecommended = values?.showRecommended
            const recommendedOfferings = new Map<string, boolean>(
                appointmentSuggestions?.map(s => [
                    s.appointmentProfileName,
                    true
                ])
            )

            if (!showRecommended) {
                return (offering: AppointmentProfile) => {
                    return true
                }
            } else {
                return (offering: AppointmentProfile) => {
                    return recommendedOfferings.has(offering.name)
                }
            }
        }
    }
}

function getNumActiveFilters(searchParams: URLSearchParams) {
    // Applies only to service lines, locations, and recommended filters
    const activeFilters = [
        !!FilterConfigs.serviceLines.readFromURL(searchParams)?.length,
        !!FilterConfigs.locations.readFromURL(searchParams)?.length,
        FilterConfigs.showRecommended.readFromURL(searchParams)
    ]

    return activeFilters.filter(Boolean).length
}

export function useAppointmentFilters(
    offerings?: AppointmentProfile[],
    appointmentSuggestions?: AppointmentSuggestion[]
) {
    const [searchParams, setSearchParams] = useSearchParams()
    // default filter shows everything
    const [filterFunction, setFilterFunction] = useState<FilterFunction>(
        // setState calls the function passed-in
        () => () => true
    )
    const formMethods = useForm<FormValues | FieldValues>({
        mode: 'onChange',
        defaultValues: {
            serviceLines: FilterConfigs.serviceLines.readFromURL(
                searchParams,
                offerings
            ),
            locations: FilterConfigs.locations.readFromURL(
                searchParams,
                offerings
            ),
            symptom: FilterConfigs.symptom.readFromURL(searchParams),
            appointment: FilterConfigs.appointment.readFromURL(searchParams),
            showRecommended:
                FilterConfigs.showRecommended.readFromURL(searchParams)
        }
    })

    const { serviceLines } = useServiceLines()

    function updateFilters(values: FormValues) {
        const filters = {
            serviceLines: FilterConfigs.serviceLines.valueForURL(values),
            locations: FilterConfigs.locations.valueForURL(values),
            symptom: FilterConfigs.symptom.valueForURL(values),
            appointment: FilterConfigs.appointment.valueForURL(values),
            showRecommended: FilterConfigs.showRecommended.valueForURL(values)
        }

        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([key, value]) => value.length > 0)
        )

        // type inference says that an empty array is type never[]
        // but we treat it as string[]
        // @ts-ignore
        const newSearchParams = new URLSearchParams(cleanedFilters)
        newSearchParams.sort()
        searchParams.sort()

        if (newSearchParams.toString() !== searchParams.toString()) {
            setSearchParams(filters)
        }
    }

    function resetFilters() {
        formMethods.setValue('serviceLines', [])
        formMethods.setValue('locations', [])
        formMethods.setValue('appointment', undefined)
        formMethods.setValue('symptom', undefined)
        formMethods.setValue('showRecommended', undefined)
    }

    function selectServiceLine(name: string) {
        resetFilters()
        formMethods.setValue('serviceLines', [
            {
                value: name,
                label: serviceLines?.find(sl => sl.name === name)?.label || name
            }
        ])
    }

    // for accessibility
    function onSubmit(fields: FormValues | FieldValues) {
        updateFilters(fields as FormValues)
    }

    // Update filter function when relevant values change
    useDeepCompareEffect(() => {
        const values = formMethods.getValues() as FormValues
        updateFilters(values)

        // compose individual filters into filterFunction
        const filterFunction = _.overEvery(
            Object.values(FilterConfigs).map(filter =>
                filter.filter(values, appointmentSuggestions)
            )
        )

        // setState calls the function passed-in
        setFilterFunction(() => filterFunction)
    }, [formMethods.watch(), appointmentSuggestions?.length])

    // Locations need to re-compute URL when offerings load
    useEffect(() => {
        if (offerings) {
            formMethods.setValue(
                'locations',
                FilterConfigs.locations.readFromURL(searchParams, offerings)
            )
        }
    }, [offerings])

    return {
        onSubmit,
        formMethods,
        filterFunction,
        getNumActiveFilters,
        resetFilters,
        selectServiceLine,
        numActiveFilters: getNumActiveFilters(searchParams)
    }
}
