import React, { FC, ReactNode } from 'react'
import { Button, Flex } from '@asktia/tia-ui'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { motion } from 'framer-motion'

export interface IActionPage {
    children: ReactNode
    sx?: ThemeUIStyleObject

    cta: {
        text: string
        disabled?: boolean
        onClick: () => void
    }
}

export const ActionPage: FC<IActionPage> = ({ children, cta, sx }) => {
    return (
        <motion.div
            animate={{ y: 0 }}
            initial={{ y: '100vh' }}
            style={{
                height: 'calc(100vh - 64px)', // header height
                overflow: 'auto'
            }}
            exit={{
                y: '-100vh',
                transition: {
                    delay: 0,
                    duration: 0.6
                }
            }}
            transition={{
                type: 'spring',
                stiffness: 16,
                damping: 6,
                delay: 0.1,
                duration: 0.3
            }}
        >
            <Flex
                sx={{
                    height: 'calc(100vh - 120px)',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 6,
                    px: 5,
                    ...sx
                }}
            >
                {children}

                <Flex
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        mt: [0, 5],
                        py: 5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        bg: 'cream',
                        border: 'none'
                    }}
                >
                    <Button
                        fullWidth
                        onClick={cta.onClick}
                        disabled={cta.disabled}
                    >
                        {cta.text}
                    </Button>
                </Flex>
            </Flex>
        </motion.div>
    )
}
