import { Heading } from '@asktia/tia-ui'
import { AppointmentCardSkeleton } from 'src/components/AppointmentCard'
import { View } from 'src/components/Blocks/View'
import { ScheduledWaitingRoom } from 'src/flows/AutomatedCheckin/views/CheckInComplete/ScheduledWaitingRoom'
import { AppointmentWithLocationDetails } from 'src/types'

export const CheckInComplete = (props: {
    appointment: AppointmentWithLocationDetails
}) => {
    return <ScheduledWaitingRoom {...props} />
}

export const CheckInCompleteSkeleton = () => {
    return (
        <View sx={{ pt: 5 }}>
            <Heading sx={{ textAlign: 'center' }}>
                You're all checked in!
            </Heading>

            <AppointmentCardSkeleton sx={{ mb: 5 }} />
        </View>
    )
}
