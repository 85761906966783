import { Route, Routes } from 'react-router-dom'
import { Box, LayoutGrid } from '@asktia/tia-ui'
import Login from './views/login'
import { Body } from 'src/components/Blocks/Body'

export const Authentication = () => {
    return (
        <Body>
            <Routes>
                <Route
                    path="/"
                    element={
                        <LayoutGrid columns="one">
                            <Box px={5} pb={6}>
                                <Login />
                            </Box>
                        </LayoutGrid>
                    }
                />
            </Routes>
        </Body>
    )
}
