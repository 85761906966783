import { Box, Flex, Grid, Text } from '@asktia/tia-ui'
import { isNil, merge, omit, uniq } from 'lodash'
import { FC, useEffect } from 'react'
import { Avatar } from 'src/components/Avatar'
import { BookingSlotWrapper } from 'src/flows/AppointmentBooking/BookingSlot'
import { useAvailableSlots } from 'src/flows/AppointmentBooking/useAvailableSlots'
import { useAmpli } from 'src/hooks'
import { useAppointmentHistory } from 'src/hooks/useAppointmentHistory'
import { AppointmentProfile, AvailableSlot, QueryParams } from 'src/types'

export interface LastProvidersSlotsProps {
    appointmentProfile: AppointmentProfile
    filterOverrides?: QueryParams
    queryOverrides?: QueryParams
    filterValues: {
        [k: string]: string | Date | never[]
    }
    rescheduleAppointmentUuid?: string
    appointmentSuggestionUuid?: string
}

export const LastProvidersSlots: FC<LastProvidersSlotsProps> = ({
    appointmentProfile,
    filterValues,
    filterOverrides,
    queryOverrides,
    rescheduleAppointmentUuid,
    appointmentSuggestionUuid
}) => {
    const { viewedLastProviderSlots, selectedLastProviderSlots } = useAmpli()
    const { appointments, isLoading: isHistoricalAppointmentsLoading } =
        useAppointmentHistory()

    const chosenFilters = omit(filterValues, 'startDate') as QueryParams
    const { slots: ProviderSlots, isLoading: isProviderSlotsLoading } =
        useAvailableSlots({
            appointmentProfileUuid: appointmentProfile.appointmentProfileUuid,
            startDate: filterValues.startDate as Date,
            maxSlots: 20,
            filters: merge(
                {
                    ...chosenFilters,
                    providerUuids: uniq(
                        appointments
                            .filter(appt => !isNil(appt.providerUuid))
                            .sort(
                                //@ts-ignore
                                (a, b) => a.scheduledTime - b.scheduledTime
                            )
                            .map(appt => appt.providerUuid)
                    )
                },
                filterOverrides
            ),
            appointmentType: appointmentProfile.name,
            rescheduleAppointmentUuid: rescheduleAppointmentUuid,
            appointmentSuggestionUuid: appointmentSuggestionUuid,
            ...queryOverrides,
            enabled:
                !isHistoricalAppointmentsLoading ||
                appointments?.length === 0 ||
                (filterValues?.providerUuids as string[])?.length === 0 // If we filter providers we should not see last providers
        })

    const availableProviderUuids = uniq(
        appointments
            .filter(appt =>
                ProviderSlots.some(
                    slot => slot.providerUuid === appt.providerUuid
                )
            )
            .sort(
                //@ts-ignore
                (a, b) => a.scheduledTime - b.scheduledTime
            )
            .slice(0, 2)
            .map(appt => appt.providerUuid)
            .filter(uuid => !isNil(uuid))
    )

    const minCardWidth = '180px'

    useEffect(() => {
        if (availableProviderUuids && availableProviderUuids.length > 0) {
            viewedLastProviderSlots({
                amountOfProviders: availableProviderUuids.length
            })
        }
    }, [])

    if (
        (filterValues?.providerUuids as string[])?.length > 0 || // If we filter providers we should not see last providers
        ProviderSlots.length === 0 ||
        appointments.length === 0 ||
        availableProviderUuids.length === 0 ||
        isProviderSlotsLoading ||
        isHistoricalAppointmentsLoading
    ) {
        return null
    }

    return (
        <Box>
            <Text sx={{ fontSize: 5, color: 'white' }}>
                Available appointments with providers you’ve seen before
            </Text>
            {availableProviderUuids.map(providerUuid => {
                const filteredProviderSlots = ProviderSlots.filter(
                    slot => slot.providerUuid === providerUuid
                )

                const providerName = filteredProviderSlots[0].providerName
                const avatarUrl = filteredProviderSlots[0].providerAvatarUrl
                const providerNameWithoutCertifications = `${filteredProviderSlots[0].providerGivenName} ${filteredProviderSlots[0].providerFamilyName}`

                return (
                    <Box key={`provider-${providerUuid}`}>
                        <Flex sx={{ alignItems: 'center', mb: 2, mt: 2 }}>
                            <Avatar
                                url={avatarUrl}
                                userName={
                                    providerNameWithoutCertifications || ''
                                }
                                nameSx={{
                                    bgColor: '#ECDCCD',
                                    textColor: '#831A4A'
                                }}
                                sx={{ mr: 2 }}
                            />
                            <Text sx={{ fontSize: 4, color: 'white' }}>
                                {providerName}
                            </Text>
                        </Flex>

                        <Box
                            sx={{
                                overflowX: 'auto',
                                mx: ['calc(50% - 50vw)', 0]
                            }}
                        >
                            <Grid
                                sx={{
                                    display: ['inline-grid', 'grid'],
                                    gap: 2,
                                    gridTemplateColumns: `repeat(auto-fill, minmax(${minCardWidth}, 1fr))`,
                                    gridAutoFlow: [
                                        'column',
                                        'initial',
                                        'initial'
                                    ],
                                    gridAutoColumns: [
                                        `minmax(${minCardWidth}, 1fr)`,
                                        'initial',
                                        'initial'
                                    ],
                                    mx: [5, 0],
                                    mb: 2
                                }}
                            >
                                {filteredProviderSlots.map(
                                    (slot: AvailableSlot) => (
                                        <Flex key={`slot-${slot.slotId}`}>
                                            <BookingSlotWrapper
                                                slot={slot}
                                                withDate
                                                onClick={() =>
                                                    selectedLastProviderSlots()
                                                }
                                            />
                                        </Flex>
                                    )
                                )}
                            </Grid>
                        </Box>
                    </Box>
                )
            })}

            <Box sx={{ borderTop: '1px solid white', mt: 5, pt: 2, pb: 2 }}>
                <Text sx={{ fontSize: 5, color: 'white' }}>
                    Available appointments
                </Text>
            </Box>
        </Box>
    )
}
