import { useMutation, UseMutationOptions } from 'react-query'
import {
    MedplumTransactionBuilder,
    TiaPatientIdSystem,
    createTokenSearch
} from '@asktia/tia-medplum-core'
import { Patient, Reference, Immunization } from '@medplum/fhirtypes'
import { useMedplumContext } from '@medplum/react'
import { MedplumClient } from '@medplum/core'
import { useUserInfo } from 'src/hooks'
import { UserInfo } from 'src/types'

export const createIdentityQuery = (patient: Patient) => {
    const tiaUserId = TiaPatientIdSystem.findOne(patient)
    if (!tiaUserId) {
        throw new Error('Illegal state: expected to find a Tia user id')
    }
    return createTokenSearch(tiaUserId).paramEqSystemCode()
}

export function createPatientReference({
    trx,
    actorId
}: {
    trx: MedplumTransactionBuilder
    actorId: string
}): Reference<Patient> {
    const patient: Patient = {
        resourceType: 'Patient',
        identifier: [TiaPatientIdSystem.createOne(actorId)]
    }
    trx.addResource(patient, {
        method: 'POST',
        url: 'Patient',
        ifNoneExist: createIdentityQuery(patient)
    })
    return trx.reference(patient)
}

function createImmunization(medplum: MedplumClient, user?: UserInfo) {
    return async (body: Omit<Immunization, 'patient'>[]) => {
        if (!user) {
            return
        }

        const trx = new MedplumTransactionBuilder()
        const patientReference = createPatientReference({
            trx,
            actorId: user.id.toString()
        })

        for (const immunization of body) {
            trx.addResource({
                ...immunization,
                resourceType: 'Immunization',
                patient: patientReference
            })
        }

        const bundle = trx.build()
        return await medplum.executeBatch(bundle)
    }
}

export const useCreateImmunizations = (
    options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn'>
) => {
    const { user } = useUserInfo()
    const { medplum } = useMedplumContext()
    return useMutation(createImmunization(medplum, user), options)
}
