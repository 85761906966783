import { Route, Routes } from 'react-router'
import { Show404 } from '../../404'
import { Body } from 'src/components/Blocks/Body'
import MedicalConditions from './pages/MedicalConditions'
import { QuestionnairePage } from '../components/QuestionnairePage'
import PersonalWellness from 'src/flows/questionnaires/Uhr/pages/PersonalWellness'
import Gynecology from 'src/flows/questionnaires/Uhr/pages/Gynecology'
import { QuestionnaireHeader } from '../components/QuestionnaireHeader'

export const Uhr = () => {
    return (
        <Body>
            <QuestionnaireHeader />
            <Routes>
                <Route
                    path={`medical-conditions/`}
                    element={
                        <QuestionnairePage
                            title="Your Health Record"
                            category="Medical History"
                        >
                            <MedicalConditions />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`personal-wellness/`}
                    element={
                        <QuestionnairePage
                            title="Your Health Record"
                            category="Personal Wellness"
                        >
                            <PersonalWellness />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`gynecology/`}
                    element={
                        <QuestionnairePage
                            title="Your Health Record"
                            category="Gynecology"
                        >
                            <Gynecology />
                        </QuestionnairePage>
                    }
                />
                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
