import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { useErrorModal } from 'src/components/ErrorModal'
import { fetcher } from 'src/fetcher'
import { useBookingConflictModal } from 'src/flows/AppointmentBooking/BookingConflictModal'
import { useAppointmentProfile } from 'src/flows/AppointmentBooking/useAppointmentProfile'
import { useAvailableSlot } from 'src/flows/AppointmentBooking/useAvailableSlots'
import { BASE_API_URL } from 'src/globals'
import { useAmpli, useLocalStorage, useUserInfo } from 'src/hooks'
import { useAppointment } from 'src/hooks/useAppointment'
import { useNavigation } from 'src/hooks/useNavigation'
import { useUserFlow } from 'src/hooks/useUserFlow'
import logger from 'src/logger'
import {
    AppointmentGoal,
    AppointmentProfile,
    AppointmentTreatment,
    AppointmentWithLocationDetails,
    AvailableSlot
} from 'src/types'
import { extendAppointment } from 'src/utils'
import { useAppointmentSuggestion } from './useAppointmentSuggestion'
import { pick } from 'lodash'

const CONFLICT_ERROR_MESSAGE = 'Conflict'

const shouldShowGoalsSelection = ({ profile }: BookingStepContext) => {
    const hasManyGoals = Number(profile.goals?.length) > 1

    // This is a A/B testing that we are doing only for TWHE1
    if (profile.name === 'tia_whole_health_exam') {
        return false
    }

    return hasManyGoals
}

// Booking steps configuration meant to:
// - consolidate route config
// - create named routes
// - help figure out the current step
// - methods to identify nextStep
// The flow isn't linear so we can't rely on index+1
// Keys are in order of full flow
// We keep "What to render" config in AppointmentBooking.tsx
//
// XState isn't a good fit here because there are no events
// we decide state purely based on current URL
type BookingStepContext = {
    profile: AppointmentProfile
    preselectedGoalId: number | undefined
}
export const BOOKING_STEPS = {
    // used to decide the first step
    init: {
        path: 'doesntmatch', // Should not match to a path
        nextStep: (context: BookingStepContext) => {
            const { profile } = context

            if (profile.preBookingQuestion) {
                return BOOKING_STEPS.preBookingQuestion
            } else if (shouldShowGoalsSelection(context)) {
                return BOOKING_STEPS.goalSelection
            } else if (profile.treatments?.length) {
                return BOOKING_STEPS.treatmentSelection
            } else {
                return BOOKING_STEPS.review
            }
        }
    },
    /**
     * also used to decide the first step
     * we're embedding a sub-state-machine in this one because it
     * has 3 additional states and shares everything else
     */
    deeplink: {
        /**
         * Will match deep booking links - once the deep link has been matched, the
         * correct sub-step will be returned
         */
        path: '/book-appointment/profile/:appointmentProfileUuid',
        nextStep: ({ profile }: BookingStepContext) => {
            if (profile.preBookingQuestion) {
                return BOOKING_STEPS.preBookingQuestionNoSlot
            }
            return BOOKING_STEPS.timeSelection
        }
    },
    preBookingQuestion: {
        path: `/book-appointment/profile/:appointmentProfileUuid/slot/:slotId/pre-questions`,
        nextStep: (context: BookingStepContext) => {
            const { profile } = context

            if (shouldShowGoalsSelection(context)) {
                return BOOKING_STEPS.goalSelection
            } else if (profile.treatments?.length) {
                return BOOKING_STEPS.treatmentSelection
            } else {
                return BOOKING_STEPS.review
            }
        }
    },
    preBookingQuestionNoSlot: {
        path: `/book-appointment/profile/:appointmentProfileUuid/pre-questions`,
        nextStep: () => {
            return BOOKING_STEPS.timeSelection
        }
    },
    timeSelection: {
        path: `/book-appointment/profile/:appointmentProfileUuid/time-selection`,
        nextStep: (context: BookingStepContext) => {
            const { profile, preselectedGoalId } = context

            if (
                preselectedGoalId === undefined &&
                shouldShowGoalsSelection(context)
            ) {
                return BOOKING_STEPS.goalSelection
            } else if (profile.treatments?.length) {
                return BOOKING_STEPS.treatmentSelection
            } else {
                return BOOKING_STEPS.review
            }
        }
    },
    goalSelection: {
        path: `/book-appointment/profile/:appointmentProfileUuid/slot/:slotId/goal-selection`,
        nextStep: ({ profile }: BookingStepContext) => {
            if (profile.treatments?.length) {
                return BOOKING_STEPS.treatmentSelection
            } else {
                return BOOKING_STEPS.review
            }
        }
    },
    treatmentSelection: {
        path: `/book-appointment/profile/:appointmentProfileUuid/slot/:slotId/treatment-selection`,
        nextStep: () => {
            return BOOKING_STEPS.review
        }
    },
    review: {
        path: `/book-appointment/profile/:appointmentProfileUuid/slot/:slotId/review`,
        nextStep: () => {
            logger.error('There is no next step after review')
            return BOOKING_STEPS.review
        }
    }
}

export async function bookAppointmentSlot(
    slot: AvailableSlot,
    goalId?: number,
    treatments?: AppointmentTreatment[],
    rescheduleApptUuid?: string,
    appointmentSuggestionUuid?: string
): Promise<AppointmentWithLocationDetails> {
    // TODO: pass all treatment ids
    const treatmentIds = treatments?.map(t => t.id) || []

    // FOLLOWUP: it is wrong to have cadence on slot at all
    // since it doesn't conform to our backend type
    // we should remove cadence from AvailableSlot and refactor
    // accordingly
    const cadence = slot.cadence

    // TODO: omit UI-only data
    // TODO: analyze not sending the entire object and sending only needed params
    const slotPost = pick(slot, ['slotId', 'startTime'])

    const body = JSON.stringify({
        slot: slotPost,
        goalId,
        treatmentIds,
        rescheduleApptUuid,
        appointmentSuggestionUuid,
        cadence,
        supportGroupUuid: slot.supportGroupUuid
    })
    let appointment = await fetcher(
        `${BASE_API_URL}/tmd-api/appointments?apiVersion=v2`,
        body,
        'POST',
        {
            customErrorHandler: res => {
                if (res.status === 409) {
                    return new Error(CONFLICT_ERROR_MESSAGE)
                } else {
                    return new Error('Booking unsuccessful')
                }
            }
        }
    )

    appointment = {
        ...appointment,
        scheduledTime: new Date(appointment.scheduledTime as unknown as string)
    }

    return appointment
}

function getSearchParams() {
    return new URLSearchParams(window.location.search)
}

function getRescheduleAppointmentUuid() {
    return getSearchParams().get('reschedule') || undefined
}

function getAppointmentSuggestionUuid() {
    return getSearchParams().get('context') || undefined
}

function usePreselectedGoal(
    appointmentProfileUuid: string,
    appointmentSuggestionUuid?: string,
    rescheduleAppointmentUuid?: string
) {
    // Load all the data – queries enabled when ID present
    const { suggestion, isLoading: suggestionLoading } =
        useAppointmentSuggestion(appointmentSuggestionUuid)

    const { appointment: rescheduleAppointment, isLoading: rescheduleLoading } =
        useAppointment(rescheduleAppointmentUuid)

    const { profile, isLoading: profileLoading } = useAppointmentProfile(
        appointmentProfileUuid,
        appointmentSuggestionUuid
    )

    let preselectedGoalId: number | undefined

    if (suggestion?.params.goalId) {
        preselectedGoalId = suggestion.params.goalId
    } else if (rescheduleAppointment) {
        preselectedGoalId = rescheduleAppointment.apptGoal?.id
    }

    // goalId is never 0 because db indexes start at 1
    const preselectedGoal =
        preselectedGoalId && profile
            ? profile.goals?.find(g => g.id === preselectedGoalId)
            : undefined

    return {
        preselectedGoal,
        isLoading: suggestionLoading || rescheduleLoading || profileLoading
    }
}

// Method meant to give you all the tools to manage the booking flow
// Loads appointment profiles
// Loads slot data
// Creates navigation function to go next step
// Handles booking a slot
export function useBookingFlow(slot?: AvailableSlot) {
    // TODO: expand support for errorTypes
    const showErrorModal = useErrorModal('booking')

    const queryClient = useQueryClient()

    // Get profileUuid and slotId from passed-in slot or URL
    const urlParams = useParams<{
        appointmentProfileUuid: string
        slotId: string
    }>()

    const appointmentProfileUuid = (slot || urlParams)
        .appointmentProfileUuid as string
    const slotId = (slot || urlParams).slotId

    const showBookingConflictModal = useBookingConflictModal()

    // Load all the data – queries enabled when ID present
    const profileQuery = useAppointmentProfile(
        appointmentProfileUuid,
        getAppointmentSuggestionUuid()
    )
    const slotQuery = useAvailableSlot(slotId)

    const { preselectedGoal, isLoading: goalIdLoading } = usePreselectedGoal(
        appointmentProfileUuid,
        getAppointmentSuggestionUuid(),
        getRescheduleAppointmentUuid()
    )

    // When profile is undefined we want to route users to the init step
    // We can do this by making context undefined and leveraging the fact that
    // useUserFlow will route users to init when context is undefined
    const context = profileQuery.profile
        ? {
              profile: profileQuery.profile,
              preselectedGoalId: preselectedGoal?.id
          }
        : undefined

    const { navigateToNextStep } = useUserFlow<BookingStepContext>(
        BOOKING_STEPS,
        {
            appointmentProfileUuid,
            slotId
        },
        getSearchParams(),
        context
    )
    const { navigate } = useNavigation()

    // Save/retrieve followup answers to local storage
    const goalSelectionState = useLocalStorage<AppointmentGoal | undefined>(
        `tia:appointment-goal:${appointmentProfileUuid}:${slotId}`,
        undefined
    )
    const treatmentSelectionState = useLocalStorage<AppointmentTreatment[]>(
        `tia:appointment-treatment:${appointmentProfileUuid}:${slotId}`,
        []
    )

    // Pretend preselectedGoalId is a user selection
    // this simplifies code paths
    useEffect(() => {
        if (preselectedGoal) {
            goalSelectionState[1](preselectedGoal)
        }
    }, [goalSelectionState, preselectedGoal])

    function onBookingSuccess(appointment: AppointmentWithLocationDetails) {
        queryClient.setQueryData(
            ['appointment', appointment.id],
            extendAppointment(appointment)
        )
        queryClient.refetchQueries('upcoming-appointments')

        navigate(`/automated-checkin/${appointment.id}`, 3400)
    }

    function onBookingError(err: Error) {
        if (err.message === CONFLICT_ERROR_MESSAGE) {
            showBookingConflictModal()
        } else {
            showErrorModal()
        }
    }

    const bookSlot = useMutation(
        (slot: AvailableSlot) => {
            const goalId = goalSelectionState[0]?.id

            return bookAppointmentSlot(
                slot,
                goalId,
                treatmentSelectionState[0],
                getRescheduleAppointmentUuid(),
                getAppointmentSuggestionUuid()
            )
        },
        {
            onSuccess: onBookingSuccess,
            onError: onBookingError
        }
    )

    return {
        navigateToNextStep,
        bookSlot: bookSlot.mutate,
        isBooking: bookSlot.isLoading,
        isBookingSuccess: bookSlot.isSuccess,
        appointmentProfile: profileQuery.profile,
        slot: slotQuery.slot,
        isLoading:
            profileQuery.isLoading || slotQuery.isLoading || goalIdLoading,
        selectedGoal: goalSelectionState[0],
        preselectedGoal,
        saveGoal: goalSelectionState[1],
        selectedTreatments: treatmentSelectionState[0],
        saveTreatments: treatmentSelectionState[1],
        rescheduleAppointmentUuid: getRescheduleAppointmentUuid(),
        appointmentSuggestionUuid: getAppointmentSuggestionUuid()
    }
}
