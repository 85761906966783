import { Heading, Text, Button } from '@asktia/tia-ui'
import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'
import { THREAD_LABELS } from 'src/globals'
import { View } from 'src/components/Blocks/View'

export const Show404 = (props: { redirectToCareCoordinator?: boolean }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const isRescheduling = location.search.includes('reschedule')

    // redirect to coordinator chat in some cases
    // layout effect redirects before 404 has time to show
    useEffect(() => {
        if (props.redirectToCareCoordinator) {
            // we can't know which appointment it is
            const subject = isRescheduling
                ? 'Help rescheduling appointment'
                : 'Help booking appointment'

            const message = isRescheduling
                ? 'I want to reschedule an appointment and need some help'
                : 'I want to book an appointment and need some help'

            navigate(
                `/chat/new?label=${THREAD_LABELS.scheduling}&subject=${subject}&message=${message}`
            )
        }
    }, [props.redirectToCareCoordinator, isRescheduling, navigate])

    // render 404
    if (props.redirectToCareCoordinator) {
        return null
    } else {
        return (
            <View
                sx={{
                    justifyContent: 'center'
                }}
            >
                <Heading h1>404</Heading>
                <Text
                    sx={{
                        mb: 5
                    }}
                >
                    Sorry, that page wasn't found
                </Text>
                <Button as="a" href="/">
                    Return to Home
                </Button>
            </View>
        )
    }
}
