import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    Container,
    Flex,
    Heading,
    Link,
    Logo,
    Spinner,
    ThemeStyleObject,
    Text,
    useTiaUI
} from '@asktia/tia-ui'
import { PropsWithChildren, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useAmpli, useNavigation, useScrollToTopOnPageLoad } from 'src/hooks'
import { useLocation } from 'react-router'
import { useUnreadMessages } from 'src/hooks/useUnreadMessages'
import { useYourCareDot } from 'src/hooks/useYourCareDot'
import { formatPhoneNumber } from 'src/utils'
import gPhoneNumber from 'google-libphonenumber'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Footer } from 'src/components/Blocks/Footer'
import {
    AlertIcon,
    CloseIcon,
    MessagesIcon,
    ProfileIcon
} from 'src/components/Blocks/Icons'
import MenuIcon from 'src/assets/icon-menu.svg'

import ReactTooltip from 'react-tooltip'

// Common blocks shared across views
// Good candidates for tia-ui, if they prove commonly useful

const TIA_CONTACT_PHONENUMBER = '+16466505337'

export const HomeButton = (
    props: PropsWithChildren<{
        sx?: ThemeStyleObject
    }>
) => {
    const { theme } = useTiaUI()
    return (
        <Box
            sx={{
                '& a': {
                    textDecoration: 'none',
                    color: 'text',
                    fontSize: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                },
                ...props.sx
            }}
        >
            <RouterLink to="/book-appointment">
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L12.2426 10.6967C12.5355 10.4038 13.0104 10.4038 13.3033 10.6967C13.5962 10.9896 13.5962 11.4645 13.3033 11.7574L9.06066 16L13.3033 20.2426C13.5962 20.5355 13.5962 21.0104 13.3033 21.3033C13.0104 21.5962 12.5355 21.5962 12.2426 21.3033L7.46967 16.5303ZM24 16.75L8 16.75L8 15.25L24 15.25L24 16.75Z"
                        fill={theme.colors?.text as string}
                    />
                </svg>
                <Text sx={{ ml: 1 }}>Home</Text>
            </RouterLink>
        </Box>
    )
}

// Helper component that renders same as <View>
// without the Router dependency
export const StorybookView = ({
    children,
    sx,
    withFooter
}: BoxProps & { withFooter?: boolean }) => {
    useScrollToTopOnPageLoad()
    return (
        <Flex
            sx={{
                minHeight: '100%',
                width: '100%',
                alignSelf: 'center',
                flexDirection: 'column',
                flex: 1,
                pb: withFooter ? [100, 3] : 3,
                mx: 'auto',
                textAlign: 'left',
                ...sx
            }}
            className="view" // used for css transitions
        >
            {children}
        </Flex>
    )
}

/**
 * Header: component for every page
 * @deprecated in favour of PageHeader (also we will create a new header for modals)
 */
export const Header = (
    props: PropsWithChildren<{
        showClose?: boolean
        sx?: ThemeStyleObject
        showBack?: boolean
        yourCare?: boolean
        rightActions?: ReactNode
    }>
) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'sticky',
                top: 0,
                zIndex: 5,
                bg: 'navBackground',
                color: 'white',
                py: [3, 5],
                ...props.sx
            }}
        >
            <Container sx={{ textAlign: 'center' }}>
                <Flex
                    sx={{
                        position: 'relative',
                        textAlign: ['center', 'left'],
                        alignItems: 'center'
                    }}
                >
                    {props.showBack && (
                        <BackButton
                            sx={{
                                display: ['flex', 'none', 'none'],
                                position: 'absolute',
                                left: 0
                            }}
                        />
                    )}
                    {props.yourCare && (
                        <HomeButton
                            sx={{
                                display: ['flex', 'none', 'none'],
                                position: 'absolute',
                                left: 0
                            }}
                        />
                    )}
                    <Flex
                        sx={{
                            flexGrow: 2,
                            order: [2, 1],
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            height: [40, 'auto']
                        }}
                    >
                        <Link href="/" sx={{ lineHeight: 0 }}>
                            <Logo sx={{ height: [24, 32] }} />
                        </Link>
                    </Flex>
                    {!props.showBack && !props.showClose && !props.yourCare && (
                        <>
                            <HeaderYourCareButton
                                sx={{ display: ['none', 'flex'] }}
                            />
                            <HeaderChatButton
                                sx={{ display: ['none', 'flex'] }}
                            />
                            <HeaderBookAppointmentButton />
                            <HeaderMenuContainer />
                        </>
                    )}
                    {props.yourCare && (
                        <>
                            <HeaderYourCareButton
                                sx={{
                                    display: ['none', 'flex']
                                }}
                            />
                            <HeaderChatButton
                                sx={{
                                    display: ['none', 'flex']
                                }}
                            />
                        </>
                    )}
                    {props.showClose ? (
                        <CloseButton
                            sx={{
                                position: 'absolute',
                                right: 0
                            }}
                            color="text"
                        />
                    ) : null}
                    {props.rightActions}
                </Flex>
                {props.children}
            </Container>
        </Flex>
    )
}

// These aren't really "Blocks"
// Maybe they need a better-named home?

export const CallTia = (
    props: PropsWithChildren<{ sx?: ThemeStyleObject }>
) => {
    const formattedPhone = formatPhoneNumber(
        TIA_CONTACT_PHONENUMBER,
        gPhoneNumber.PhoneNumberFormat.INTERNATIONAL
    )

    return (
        <Link href={`tel:${formattedPhone}`} sx={props.sx}>
            {props.children || formattedPhone}
        </Link>
    )
}

export const Pill = ({ children }: PropsWithChildren<{}>) => (
    <Text
        sx={{
            backgroundColor: 'communicationBanner',
            color: 'mainBackground',
            px: 2,
            py: 1,
            borderRadius: 2,
            fontFamily: 'monospace',
            fontSize: 0
        }}
    >
        {children}
    </Text>
)

export const Divider = (props: {
    sx?: ThemeStyleObject
    variant?: 'blockSpacer'
}) => {
    let sx = {}

    switch (props.variant) {
        case 'blockSpacer':
            sx = {
                bg: 'cardOutline',
                my: 6
            }
    }

    return (
        <Box
            sx={{
                height: '1px',
                my: 4,
                bg: 'cardOutline',
                ...sx,
                ...props.sx
            }}
        ></Box>
    )
}

export const CloseButton = ({
    sx,
    color = 'text',
    url = '/',
    onClick
}: {
    sx?: ThemeStyleObject
    color?: string
    url?: string
    onClick?: () => void
}) => {
    return (
        <Box
            as={RouterLink}
            // @ts-ignore: TS doesn't understand this is a RouterLink now
            to={url}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: 'fit-content',
                height: 6,
                textDecoration: 'none',
                ...sx
            }}
            onClick={onClick}
        >
            <Text sx={{ mr: 2, fontSize: 2, color }}>Close</Text>
            <CloseIcon color={color} />
        </Box>
    )
}

/**
 * Utility component for positioning a button on the bottom of the screen for mobile sizes
 */
export const FooterButton = (props: ButtonProps) => (
    <Footer>
        <Button {...props}></Button>
    </Footer>
)

export const HeaderChatButton = (props: { sx?: ThemeStyleObject }) => {
    const { value, isLoading } = useUnreadMessages()
    const { navigate } = useNavigation()

    const handleClick = () => {
        navigate('/chat')
    }

    return (
        <Button
            variant="unstyled"
            onClick={() => handleClick()}
            sx={{
                display: 'flex',
                height: 5,
                width: 'auto',
                pl: [0, 6, 8],
                order: 3,
                mx: 0,
                mt: [2, 0],
                alignItems: 'center',
                ...props.sx
            }}
        >
            <Flex
                sx={{
                    width: 6
                }}
            >
                <Flex
                    sx={{
                        flex: ['0 0 1', '1'],
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ display: ['block', 'none'] }}>
                        <MessagesIcon color="text" />
                    </Box>

                    <Box sx={{ display: ['none', 'flex'] }}>
                        <MessagesIcon color="white" />
                    </Box>
                </Flex>
                {!isLoading && value && (
                    <AlertIcon
                        variant="communicationBanner"
                        sx={{
                            position: 'relative',
                            top: '-8px'
                        }}
                    />
                )}
            </Flex>

            <Text
                variant="button"
                sx={{
                    display: ['none', 'flex'],
                    ml: 2,
                    mb: 0,
                    color: 'white'
                }}
            >
                Your Messages
            </Text>
            <Text
                sx={{
                    display: ['flex', 'none'],
                    pl: 3,
                    fontSize: 2,
                    color: 'text'
                }}
            >
                Your Messages
            </Text>
        </Button>
    )
}

export const HeaderYourCareButton = (props: { sx?: ThemeStyleObject }) => {
    const showYourCareDot = useYourCareDot()

    return (
        // needs to be Box because we set sx.display outside
        <Box
            sx={{
                'order': [1, 2],
                ...props.sx,
                '& a': {
                    display: 'flex',
                    flexDirection: 'row',
                    height: 5,
                    width: 'auto',
                    mx: 0,
                    alignItems: 'center',
                    textDecoration: 'none'
                }
            }}
        >
            <RouterLink to="/your-care">
                <Flex
                    sx={{
                        width: 6
                    }}
                >
                    {showYourCareDot && (
                        <AlertIcon
                            variant="communicationBanner"
                            sx={{
                                display: ['none', 'block'],
                                position: 'relative',
                                top: '-8px',
                                left: '3px'
                            }}
                        />
                    )}

                    <Flex
                        sx={{
                            flex: ['0 0 30%', '1'],
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ display: ['block', 'none'] }}>
                            <ProfileIcon color="text" />
                        </Box>

                        <Box sx={{ display: ['none', 'flex'] }}>
                            <ProfileIcon color="white" />
                        </Box>
                    </Flex>

                    {showYourCareDot && (
                        <AlertIcon
                            variant="communicationBanner"
                            sx={{
                                display: ['block', 'none'],
                                position: 'relative',
                                top: '-8px',
                                right: '-8px'
                            }}
                        />
                    )}
                </Flex>

                <Text
                    variant="button"
                    sx={{
                        display: ['none', 'block'],
                        ml: 2,
                        mb: 0,
                        color: 'white'
                    }}
                >
                    Your&nbsp;Care
                </Text>
                <Text
                    sx={{
                        display: ['flex', 'none'],
                        pl: 3,
                        fontSize: 2,
                        color: 'text'
                    }}
                >
                    Your&nbsp;Care
                </Text>
            </RouterLink>
        </Box>
    )
}

export const HeaderBookAppointmentButton = (props: {
    sx?: ThemeStyleObject
}) => {
    const { navBookAppointmentButtonClicked } = useAmpli()
    const location = useLocation()

    const handleBookAppointmentButtonClicked = () => {
        const pathname = location.pathname
        navBookAppointmentButtonClicked(pathname)
    }

    return (
        <Box
            sx={{
                ...props.sx,
                pl: [0, 6, 8],
                display: 'flex',
                order: 3
            }}
        >
            <RouterLink
                onClick={handleBookAppointmentButtonClicked}
                to="/appointments"
            >
                <Button
                    variant="default"
                    sx={{
                        py: [2, 2],
                        px: [5, 5]
                    }}
                >
                    Book&nbsp;appointment
                </Button>
            </RouterLink>
        </Box>
    )
}

export const HeaderMenuContainer = (props: { sx?: ThemeStyleObject }) => {
    const { value: showChatDot } = useUnreadMessages()
    const showYourCareDot = useYourCareDot()

    return (
        <Box
            sx={{
                ...props.sx,
                'order': 4,
                'px': 3,
                'pt': 2,
                'ml': 3,
                'display': ['flex', 'none'],
                '#menu-tooltip': {
                    opacity: '1 !important',
                    zIndex: 50
                },
                '.react-tooltip-clickable-link': {
                    pointerEvents: 'auto'
                }
            }}
        >
            <Button
                variant="unstyled"
                data-tip
                data-for="menu-tooltip"
                data-event="click"
            >
                <img src={MenuIcon} alt="Menu" />
                {(showChatDot || showYourCareDot) && (
                    <AlertIcon
                        variant="communicationBanner"
                        sx={{ position: 'absolute', top: '1px', right: '10px' }}
                    />
                )}
            </Button>
            <ReactTooltip
                className="override_tt"
                id="menu-tooltip"
                backgroundColor="transparent"
                clickable={true}
                place="bottom"
                offset={{
                    top: -8,
                    right: -76
                }}
            >
                <HeaderMenu />
            </ReactTooltip>
        </Box>
    )
}

export const HeaderMenu = () => {
    return (
        <Flex
            sx={{
                top: 8,
                right: 0,
                flexDirection: 'column',
                boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.25)',
                borderRadius: 2,
                justifyContent: 'left',
                background: 'white',
                p: 4,
                minWidth: '216px'
            }}
        >
            <HeaderYourCareButton />
            <HeaderChatButton />
        </Flex>
    )
}

export const ModalTitle = (props: {
    sx?: ThemeStyleObject
    title: ReactNode
    hideModal: Function
}) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 5,
            ...props.sx
        }}
    >
        {typeof props.title === 'string' ? (
            <Heading h2 sx={{ m: 0 }}>
                {props.title}
            </Heading>
        ) : (
            props.title
        )}

        <Box
            onClick={() => props.hideModal()}
            sx={{ cursor: 'pointer', minWidth: 5, height: 5 }}
        >
            <CloseIcon color="inactiveText" hoverColor="text" />
        </Box>
    </Flex>
)

export const CenteredSpinner = () => (
    <Flex sx={{ height: 9, justifyContent: 'center', alignItems: 'center' }}>
        <Spinner />
    </Flex>
)
