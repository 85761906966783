import { Box, Button, Flex } from '@asktia/tia-ui'
import { FC, useState } from 'react'
import { ChevronRight, CloseIcon } from 'src/components/Blocks/Icons'
import { keyframes } from '@emotion/react'

export interface IMarquee {
    options: string[]
    value?: string
    handleMarqueeClear: () => void
    onClick?: () => void
}

export const Marquee: FC<IMarquee> = ({
    options,
    value,
    handleMarqueeClear,
    onClick
}) => {
    const [isOpen, setIsOpen] = useState(true)
    const infinityScroll = keyframes({
        '0%': {
            transform: 'translateY(0px)'
        },
        '100%': {
            transform: 'translateY(calc(-100% - 36px))'
        }
    })

    return (
        <Button
            sx={{
                position: 'relative',
                overflow: 'hidden',
                height: '56px',
                width: ['calc(100vw - 48px)', 'fit-content'],
                marginTop: '-2px'
            }}
            onClick={() => {
                setIsOpen(false)
                onClick && onClick()
            }}
        >
            {value ? (
                <>
                    <Box
                        sx={{
                            fontFamily: 'inferi',
                            fontStyle: 'italic',
                            color: 'white',
                            lineHeight: '36px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '280px',
                            fontSize: 8,
                            height: 56,
                            mr: 6,
                            pl: [6, 2]
                        }}
                    >
                        {value}
                    </Box>
                    <Box
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation()
                            handleMarqueeClear()
                        }}
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16
                        }}
                    >
                        <CloseIcon color="white" />
                    </Box>
                </>
            ) : (
                <>
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            position: 'relative',
                            minWidth: 200,
                            mt: 7,
                            px: [6, 2],
                            height: options.length * 62,
                            animation: `${infinityScroll} ${
                                options.length * 2
                            }s linear infinite`
                        }}
                    >
                        {options.map((option, i) => (
                            <Flex
                                key={option}
                                as="span"
                                sx={{
                                    alignItems: 'center',
                                    fontFamily: 'inferi',
                                    fontStyle: 'italic',
                                    color: 'inactiveText',
                                    lineHeight: '36px',
                                    fontSize: 8,
                                    height: 62
                                }}
                            >
                                {option}
                            </Flex>
                        ))}
                    </Flex>
                    <ChevronRight
                        color="white"
                        sx={{
                            position: 'absolute',
                            transform: isOpen
                                ? 'rotate(90deg)'
                                : 'rotate(-90deg)',
                            transition: 'all 500ms',
                            top: 5,
                            right: 5,
                            marginLeft: 5
                        }}
                    />
                </>
            )}
        </Button>
    )
}
