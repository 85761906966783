import { AppointmentProfile } from 'src/types'
import { FeelingsFilter } from './FeelingFilters'
import { FilterHamburger } from './FilterHamburger'
import { UseFormMethods } from 'react-hook-form'
import { FC } from 'react'
import { Flex } from '@asktia/tia-ui'

export interface IOldAppointmentSearch {
    offerings: AppointmentProfile[] | undefined
    formMethods: UseFormMethods
    numActiveFilters: number
    onFilterHamburger: () => void
}

export const OldAppointmentSearch: FC<IOldAppointmentSearch> = ({
    offerings,
    formMethods,
    numActiveFilters,
    onFilterHamburger
}) => {
    return (
        <Flex
            sx={{
                flexDirection: ['column', 'column', 'row'],
                justifyContent: 'space-between',
                alignItems: ['flex-end', 'flex-end', 'center']
            }}
        >
            <FeelingsFilter offerings={offerings} formMethods={formMethods} />
            <FilterHamburger
                onClick={onFilterHamburger}
                numActiveFilters={numActiveFilters}
            />
        </Flex>
    )
}
