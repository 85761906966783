import { useEffect, useState } from 'react'
import { getAppointmentStatus, useAppointment } from 'src/hooks/useAppointment'
import { AppointmentWithLocationDetails, AppointmentUIStatus } from 'src/types'

/**
 * With on demand appts we modified this logic
 * if the appt is an on-demand appt then every 30 seconds we go to the api to retrieve a new version of this appt
 * if it is a normal appt we are not going to retrieve a new version and the only posible way to change its status is via
 * an interval method that verifies the status every 5 seconds
 * and even though the function `getAppointmentStatus` returns a string,
 * inside of that function there are methods that checks the current time, thus modifying the status
 */
export function useAppointmentStatus(
    appointment: AppointmentWithLocationDetails
) {
    const refetchInterval = undefined
    const { appointment: currentAppointment } = useAppointment(appointment.id, {
        refetchInterval
    })
    const [appointmentStatus, setAppointmentStatus] =
        useState<AppointmentUIStatus>(getAppointmentStatus(appointment))

    const updateStatus = () => {
        if (currentAppointment) {
            setAppointmentStatus(getAppointmentStatus(currentAppointment))
        }
    }

    useEffect(() => {
        const intId = setInterval(updateStatus, 5000)

        return () => {
            clearInterval(intId)
        }
    }, [currentAppointment])

    return {
        canStart: appointmentStatus === 'appointment_ready',
        appointmentStatus
    }
}
