import { Button, Card, Flex } from '@asktia/tia-ui'
import { FC, useState } from 'react'
import {
    LatestInsurance,
    useLatestInsurance
} from 'src/hooks/useLatestInsurance'
import { AccountInfoRow, AccountInfoRowLabel } from './AccountInfoRow'
import { InsuranceImagesUpload } from 'src/flows/AutomatedCheckin/views/InsuranceInfo/InsuranceCardForm'
import { isAfter } from 'date-fns'

interface IUpdateInsuranceInfo {
    issuerName?: string
    copy?: string
}

const UpdateInsuranceInfo: FC<IUpdateInsuranceInfo> = ({
    issuerName,
    copy
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [isUploadDone, setIsUploadDone] = useState(false)

    return (
        <>
            {!isEditing && issuerName && <p>{issuerName}</p>}

            {copy && !isEditing && !isUploadDone && (
                <Card variant="info" sx={{ mb: 2 }}>
                    {copy}
                </Card>
            )}

            {isEditing && isUploadDone && (
                <Card variant="info">Cards sent with success! 🎉</Card>
            )}

            <Flex
                sx={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    pt: 2
                }}
            >
                {!isEditing && (
                    <Button
                        variant="unstyled"
                        sx={{
                            textDecoration: 'underline',
                            display: 'block',
                            fontSize: 1
                        }}
                        onClick={() => setIsEditing(true)}
                    >
                        Update insurance information
                    </Button>
                )}
            </Flex>

            {isEditing && !isUploadDone && (
                <InsuranceImagesUpload
                    onImagesChange={state =>
                        state.front && state.back && setIsUploadDone(true)
                    }
                />
            )}
        </>
    )
}

// shown when editing enabled
const EditingInsurance = ({
    latestInsurance,
    setEdit
}: {
    latestInsurance: LatestInsurance
    setEdit: (edit: boolean) => void
}) => {
    const editingStatuses = {
        in_network: 'Tia is in-network! 🎉',
        out_of_network: 'Tia is "out-of-network"',
        unsure: `We're unable to verify your insurance. Please upload images of your insurance card.`,
        blocked: `Sorry, Tia can't take your insurance. Government-managed insurance requires you to see in-network providers and your plan does not include Tia.`
    }
    const { eligibilityCheck, coverage } = latestInsurance

    const editingStatus = eligibilityCheck?.verificationStatus
        ? editingStatuses[eligibilityCheck.verificationStatus]
        : ''

    // logic copied from views/member/account-info/index.pug
    if (
        (eligibilityCheck && !coverage) ||
        (eligibilityCheck &&
            coverage &&
            isAfter(
                new Date(coverage.updatedAt || coverage.createdAt),
                new Date(
                    eligibilityCheck.updatedAt || eligibilityCheck.createdAt
                )
            ))
    ) {
        if (eligibilityCheck.eligibilityStatus === 'ineligible') {
            return (
                <AccountInfoRow title="Insurance">
                    <p>{eligibilityCheck.issuerName}</p>
                    <UpdateInsuranceInfo copy={`Tia is "out-of-network"`} />
                </AccountInfoRow>
            )
        } else if (eligibilityCheck.verificationStatus === 'unsure') {
            return (
                <AccountInfoRow
                    title="Insurance"
                    onCancel={() => setEdit(false)}
                    defaultEditValue={true}
                >
                    <Card variant="info">
                        Your new insurance info has been sent! Your Care
                        Coordinator will reach out with your coverage details
                        soon.
                    </Card>
                    <UpdateInsuranceInfo />
                </AccountInfoRow>
            )
        } else if (eligibilityCheck.verificationStatus === 'in_network') {
            return (
                <AccountInfoRow title="Insurance">
                    <p>{eligibilityCheck.issuerName}</p>
                    <UpdateInsuranceInfo copy={`Tia is in-network! 🎉`} />
                </AccountInfoRow>
            )
        } else if (eligibilityCheck.verificationStatus === 'blocked') {
            return (
                <AccountInfoRow title="Insurance">
                    <p>{eligibilityCheck.issuerName}</p>
                    <UpdateInsuranceInfo
                        copy={`Sorry, Tia can’t take your insurance. Government-managed insurance requires you to see in-network providers and your plan does not include Tia.`}
                    />
                </AccountInfoRow>
            )
        } else {
            return (
                <AccountInfoRow title="Insurance">
                    <p>{eligibilityCheck.issuerName}</p>
                    <UpdateInsuranceInfo copy={`Tia is “out-of-network”`} />
                </AccountInfoRow>
            )
        }
    } else {
        return (
            <AccountInfoRow title="Insurance">
                <UpdateInsuranceInfo copy={editingStatus} />
            </AccountInfoRow>
        )
    }
}

// shown when not editing
const InsuranceState = ({
    latestInsurance,
    setEdit
}: {
    latestInsurance: LatestInsurance
    setEdit: (edit: boolean) => void
}) => {
    const { coverage, eligibilityCheck } = latestInsurance

    if (eligibilityCheck?.issuerName) {
        return (
            <AccountInfoRow title="Insurance" onEdit={() => setEdit(true)}>
                <AccountInfoRowLabel onClick={() => setEdit(true)}>
                    {eligibilityCheck.issuerName}
                </AccountInfoRowLabel>
            </AccountInfoRow>
        )
    } else if (coverage?.cardPhotosUploaded) {
        return (
            <AccountInfoRow title="Insurance">
                <AccountInfoRowLabel onClick={() => setEdit(true)}>
                    Pending review
                </AccountInfoRowLabel>
            </AccountInfoRow>
        )
    }

    return (
        <EditingInsurance latestInsurance={latestInsurance} setEdit={setEdit} />
    )
}

export const Insurance = () => {
    const [editing, setEditing] = useState(false)
    const { latestInsurance, isLoading } = useLatestInsurance()

    if (isLoading || !latestInsurance) {
        // show infinite spinner if there's a problem
        return (
            <AccountInfoRow title="Insurance" isLoading={true}></AccountInfoRow>
        )
    } else if (editing) {
        return (
            <EditingInsurance
                setEdit={setEditing}
                latestInsurance={latestInsurance}
            />
        )
    } else {
        return (
            <InsuranceState
                latestInsurance={latestInsurance}
                setEdit={(edit: boolean) => setEditing(edit)}
            />
        )
    }
}
