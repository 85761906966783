import { Flex, Heading, Text, Box, TiaModal, Button } from '@asktia/tia-ui'
import { useDismissApptSuggestion } from 'src/hooks/useDismissApptSuggestion'
import { AppointmentSuggestion } from 'src/types'
import { useAmpli } from 'src/hooks/useAmpli'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { useNavigation } from 'src/hooks'

export const DismissActionModal = (props: {
    hideModal: Function
    apptSuggestion: AppointmentSuggestion
}) => {
    const mutate = useDismissApptSuggestion()
    const { appointmentSuggestionDismissCta } = useAmpli()
    const { navigate } = useNavigation()
    const onDismiss = async () => {
        appointmentSuggestionDismissCta(props.apptSuggestion, location.pathname)
        await mutate.mutateAsync(props.apptSuggestion.uuid)
        props.hideModal()
        navigate('/')
    }

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 5
                }}
            >
                <Heading h2 sx={{ m: 0 }}>
                    Dismiss action item
                </Heading>
                <Button variant="unstyled" onClick={() => props.hideModal()}>
                    <CloseIcon color="text" />
                </Button>
            </Flex>
            <Text
                sx={{
                    mb: 1,
                    fontFamily: 'body',
                    color: 'supportText',
                    fontSize: 3
                }}
            >
                Your Care Team sent you a link to book this appointment. Are you
                sure you want to dismiss this action item?
            </Text>

            <Box
                sx={{
                    textAlign: 'center',
                    mt: 6
                }}
            >
                <Button
                    fullWidth
                    onClick={onDismiss}
                    sx={{ mb: 4 }}
                    loading={mutate.isLoading}
                >
                    Dismiss
                </Button>
            </Box>
        </TiaModal>
    )
}
