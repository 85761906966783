import { Input, Grid, Box, ThemeStyleObject } from '@asktia/tia-ui'
import * as yup from 'yup'

import { Address } from 'src/types'

export const yupAddressSchema = (fieldnamePrefix = '') => {
    return {
        [`${fieldnamePrefix}address1`]: yup.string().required(),
        [`${fieldnamePrefix}address2`]: yup.string(),
        [`${fieldnamePrefix}city`]: yup.string().required(),
        [`${fieldnamePrefix}state`]: yup
            .string()
            .matches(
                // US state regex from https://gist.github.com/nerdsrescueme/1237767#file-regex-txt-L163
                /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/i,
                'Enter a US state'
            )
            .required('State is required'),
        [`${fieldnamePrefix}postalCode`]: yup
            .string()
            // postalCode regex from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
            .matches(/^\d{5}(?:-\d{4})?$/, 'Enter a valid ZIP Code')
            .required('ZIP Code is required')
    }
}

export const AddressForm = (props: {
    fieldnamePrefix?: string
    defaultValue?: Address
    sx?: ThemeStyleObject
}) => {
    const { fieldnamePrefix = '' } = props
    return (
        <Box sx={props.sx}>
            <Input
                name={`${fieldnamePrefix}address1`}
                placeholder="Street name"
                errorMessage="Street name is required"
                defaultValue={props.defaultValue && props.defaultValue.address1}
                required
            />
            <Input
                name={`${fieldnamePrefix}address2`}
                placeholder="Apt / Unit number"
                defaultValue={props.defaultValue && props.defaultValue.address2}
            />
            <Grid gap={2} columns={['2fr 1fr']}>
                {/* boxes group error messages next to inputs */}
                <Box>
                    <Input
                        name={`${fieldnamePrefix}city`}
                        placeholder="City"
                        errorMessage="City is required"
                        defaultValue={
                            props.defaultValue && props.defaultValue.city
                        }
                        required
                    />
                </Box>
                <Box>
                    <Input
                        name={`${fieldnamePrefix}state`}
                        placeholder="State (e.g. NY)"
                        required
                        defaultValue={
                            props.defaultValue && props.defaultValue.state
                        }
                        maxLength={2}
                        sx={{ textTransform: 'uppercase' }}
                    />
                </Box>
            </Grid>
            <Input
                name={`${fieldnamePrefix}postalCode`}
                placeholder="ZIP Code"
                inputMode="numeric"
                defaultValue={
                    props.defaultValue && props.defaultValue.postalCode
                }
                required
            />
        </Box>
    )
}
