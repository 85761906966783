/* eslint-disable no-case-declarations */

import { Box, Button, Container, Flex, Link } from '@asktia/tia-ui'
import { useActor } from '@xstate/react'
import { addDays, subDays, startOfToday, format, isSameDay } from 'date-fns'
import {
    MouseEvent,
    MutableRefObject,
    useCallback,
    useLayoutEffect,
    useMemo,
    useState
} from 'react'
import {
    SetURLSearchParams,
    useLocation,
    useSearchParams
} from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import {
    DateFilterModal,
    GenderFilterModal,
    LocationFilterModal,
    CadenceFilterModal
} from './FilterModals'
import {
    AppointmentProfile,
    AppointmentCadenceType,
    Appointment
} from 'src/types'
import { assign, EventObject, interpret, Machine } from 'xstate'
import { APPOINTMENT_PROFILE_NAMES } from 'src/globals'
import { ProviderFilterModal } from './FilterModals/ProviderFilterModal'
import { FilterPill } from './FilterPill'
import { useOfferingDetails } from 'src/flows/AppointmentBooking/useOfferingDetails'
import { isEqual, isNil, omit, omitBy } from 'lodash'
import { getFiltersFromUrl } from 'src/flows/AppointmentBooking/useAvailableSlots'

export type FilterModalProps<T> = {
    hideModal: () => void
    onSelected: (values: T) => void
    onClearSelections: () => void
    initialValue?: T
    appointmentProfile: AppointmentProfile
    maxDate?: Date
    minDate?: Date
    rescheduledAppointment?: Appointment
}

// To add a new filter, copy an object in this list
// Each filter is identified by a unique key
// Key will be used as field name when sending data to backend
const DEFAULT_FILTERS = {
    startDate: {
        value: startOfToday(), // current value
        defaultValue: startOfToday(),
        isFiltering: false, // is changed by user
        label: 'Date', // label for button
        // contents of the modal when it opens
        // gets closeModal and selectValue methods
        modal: (props: FilterModalProps<Date>) => (
            <DateFilterModal {...props} />
        ),
        // decide if filter should be visible
        isEnabled: () => true
    },
    providerGender: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Gender',
        modal: (props: FilterModalProps<string[]>) => (
            <GenderFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowProviderGenderSelection
    },
    providerUuids: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Provider',
        modal: (props: FilterModalProps<string[]>) => (
            <ProviderFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowSpecificProviderSelection
    },
    clinicUuids: {
        value: [],
        defaultValue: [],
        isFiltering: false,
        label: 'Location',
        modal: (props: FilterModalProps<string[]>) => (
            <LocationFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.clinicsWithOffering.length > 1
    },
    cadence: {
        value: 'weekly',
        defaultValue: 'weekly',
        isFiltering: false,
        label: 'Frequency',
        modal: (props: FilterModalProps<AppointmentCadenceType[]>) => (
            <CadenceFilterModal {...props} />
        ),
        isEnabled: (appointmentProfile: AppointmentProfile) =>
            appointmentProfile.allowFrequencySelection
    }
}

// Collect filter keys into a type to be used for type safety
type filterKeys = keyof typeof DEFAULT_FILTERS

interface FilterEventObject extends EventObject {
    filterKey: filterKeys
    value?: Date | string[]
}

interface FilterAllEventObject extends EventObject {
    filters: { filterKey: filterKeys; value: Date | string[] }[]
}

interface PaginationEventObject extends EventObject {
    newStartDate?: Date
    oldStartDate?: Date
}

interface SetQueryParam extends EventObject {
    setSearchParams: SetURLSearchParams
}

const getAmountOfFilters = (
    filterKey: string,
    value: string | string[] | Date
) => {
    let amountOfFilters = ''

    if (value && Array.isArray(value)) {
        amountOfFilters = value.length > 0 ? ` (${value.length})` : ''
    } else {
        amountOfFilters = value ? ' (1)' : ''
    }

    if (filterKey === 'cadence' && value === 'weekly') {
        // Default frequency
        amountOfFilters = ''
    }

    if (filterKey === 'startDate' && isSameDay(value as Date, startOfToday())) {
        amountOfFilters = ''
    }

    return amountOfFilters
}

// Keeps current filter state as well as the list of filters
// Context represents current state
// Side-effect actions used to drive behavior
const slotsFilterMachine = Machine<
    {
        filters: typeof DEFAULT_FILTERS
        oldStartDates: Date[]
        setSearchParams?: SetURLSearchParams
    },
    | FilterEventObject
    | PaginationEventObject
    | FilterAllEventObject
    | SetQueryParam
>(
    {
        id: 'slotsFilterMachine',
        initial: 'initial',
        context: {
            filters: {
                ...DEFAULT_FILTERS
            },
            oldStartDates: []
        },
        states: {
            initial: {
                on: {
                    SELECTION: {
                        target: 'changingFilters',
                        actions: ['updateValue', 'setIsFiltering']
                    },
                    SET_QUERY_PARAMS: {
                        target: 'changingFilters',
                        actions: ['setUseParams']
                    },
                    CLEAR: {
                        target: 'changingFilters',
                        actions: ['clearFilter', 'resetPagination']
                    },
                    CLEAR_ALL: {
                        target: 'changingFilters',
                        actions: ['clearAllFilters', 'resetPagination']
                    },
                    RESET: {
                        target: 'changingFilters',
                        actions: ['resetFilters']
                    },
                    NEXT_PAGE: {
                        target: 'changingFilters',
                        actions: ['nextPage']
                    },
                    PREV_PAGE: {
                        target: 'changingFilters',
                        actions: ['prevPage']
                    },
                    UPDATE_ALL: {
                        target: 'changingFilters',
                        actions: ['updateValues', 'setIsFiltering']
                    }
                }
            },
            changingFilters: {
                after: {
                    1: 'initial'
                }
            }
        }
    },
    {
        actions: {
            setUseParams: assign((context, event) => {
                const ev = event as any
                const setSearchParams = ev.setSearchParams

                return {
                    ...context,
                    setSearchParams
                }
            }),
            updateValue: assign((context, event) => {
                const ev = event as FilterEventObject
                const value = ev.value as any

                const amountOfFilters = getAmountOfFilters(ev.filterKey, value)

                const searchParams = new URLSearchParams(window.location.search)

                const currentSearchParam: any = getFiltersFromUrl()

                const newFilters = {
                    ...context.filters,
                    [ev.filterKey]: {
                        ...context.filters[ev.filterKey],
                        label: `${
                            DEFAULT_FILTERS[ev.filterKey].label
                        }${amountOfFilters}`,
                        value: ev.value
                    }
                }

                if (currentSearchParam[ev.filterKey] !== value) {
                    searchParams.set(ev.filterKey, value)
                    context.setSearchParams &&
                        context.setSearchParams(
                            Object.fromEntries(searchParams),
                            { preventScrollReset: true }
                        )
                }

                return {
                    filters: newFilters
                }
            }),

            updateValues: assign((context, event) => {
                const ev = event as FilterAllEventObject
                const newContext = {
                    filters: {
                        ...context.filters
                    }
                }

                for (const { filterKey, value } of ev.filters) {
                    const amountOfFilters = getAmountOfFilters(filterKey, value)
                    newContext.filters[filterKey] = {
                        ...context.filters[filterKey],
                        // @ts-ignore - typescript's unhappy with union vs intersection types
                        value,
                        label: `${DEFAULT_FILTERS[filterKey].label}${amountOfFilters}`,
                        isFiltering: Array.isArray(value)
                            ? value.length > 0
                            : !isEqual(value, DEFAULT_FILTERS[filterKey].value)
                    }
                }

                return newContext
            }),

            setIsFiltering: assign((context, event: any) => {
                const isArray = Array.isArray(event.filters)
                let ev: FilterEventObject[]
                if (!isArray) {
                    ev = [event as any]
                } else {
                    ev = event.filters
                }

                let newFilters = context.filters

                for (const e of ev) {
                    let isFiltering = false

                    if (Array.isArray(e.value)) {
                        isFiltering = e.value.length > 0
                    } else if (e.value instanceof Date) {
                        isFiltering = !isSameDay(
                            e.value,
                            DEFAULT_FILTERS[e.filterKey].defaultValue as Date
                        )
                    } else {
                        isFiltering = !isEqual(
                            e.value,
                            DEFAULT_FILTERS[e.filterKey].defaultValue
                        )
                    }

                    newFilters = {
                        ...newFilters,
                        [e.filterKey]: {
                            ...newFilters[e.filterKey],
                            isFiltering
                        }
                    }
                }

                return {
                    ...context,
                    filters: newFilters
                }
            }),

            clearFilter: assign((context, event) => {
                const ev = event as FilterEventObject
                const searchParams = new URLSearchParams(window.location.search)
                searchParams.delete(ev.filterKey)

                DEFAULT_FILTERS[ev.filterKey].value =
                    DEFAULT_FILTERS[ev.filterKey].defaultValue

                context.setSearchParams &&
                    context.setSearchParams(Object.fromEntries(searchParams), {
                        preventScrollReset: true
                    })
                return {
                    filters: {
                        ...context.filters,
                        [ev.filterKey]: DEFAULT_FILTERS[ev.filterKey]
                    }
                }
            }),

            clearAllFilters: assign((context, event) => {
                const searchParams = new URLSearchParams(window.location.search)
                const reschedule = searchParams.get('reschedule')
                const contextFromQueryParams = searchParams.get('context')
                const backTo = searchParams.get('backTo')

                const newSearchParams: any = {}

                if (!isNil(reschedule)) {
                    newSearchParams.reschedule = reschedule
                }

                if (!isNil(backTo)) {
                    newSearchParams.backTo = backTo
                }

                if (!isNil(contextFromQueryParams)) {
                    newSearchParams.context = contextFromQueryParams
                }

                context.setSearchParams &&
                    context.setSearchParams(newSearchParams, {
                        preventScrollReset: true
                    })

                const keys = Object.keys(DEFAULT_FILTERS)
                keys.forEach(key => {
                    // @ts-ignore
                    DEFAULT_FILTERS[key].value =
                        // @ts-ignore
                        DEFAULT_FILTERS[key].defaultValue
                })

                return {
                    filters: {
                        ...DEFAULT_FILTERS
                    },
                    oldStartDates: []
                }
            }),

            resetFilters: assign((context, event) => ({
                filters: {
                    ...DEFAULT_FILTERS
                },
                oldStartDates: []
            })),

            nextPage: assign((context, event) => {
                const ev = event as PaginationEventObject
                const newStartDate = ev.newStartDate as Date
                const oldStartDate = ev.oldStartDate as Date
                return {
                    oldStartDates: [...context.oldStartDates, oldStartDate],
                    filters: {
                        ...context.filters,
                        startDate: {
                            ...context.filters.startDate,
                            value: newStartDate
                        }
                    }
                }
            }),

            prevPage: assign(context => {
                if (context.oldStartDates.length <= 0) {
                    return {}
                }

                const newStartDate = context.oldStartDates.pop() as Date
                return {
                    oldStartDates: context.oldStartDates,
                    filters: {
                        ...context.filters,
                        startDate: {
                            ...context.filters.startDate,
                            value: newStartDate
                        }
                    }
                }
            }),

            resetPagination: assign(context => ({
                oldStartDates: []
            }))
        }
    }
)

// Service acts as a global singleton so that we can share state
const slotsFilterService = interpret(slotsFilterMachine)
slotsFilterService.start()

export function useAvailableSlotsFilter(
    initialFilters?: {
        [key: string]: Date | string[]
    },
    appointmentProfile?: AppointmentProfile,
    rescheduledAppointment?: Appointment
) {
    const [state, dispatch] = useActor(slotsFilterService)
    const [currentModal, setCurrentModal] = useState<filterKeys>('startDate')
    const [_, setSearchParams] = useSearchParams()

    // Force rebuild of modal when selected value change
    // Didn't use useCallback or useMemo because the modal hook should not be called inside callback
    const [currentValue, setCurrentValue] = useState<any>(undefined)

    const resetToInitialState = useCallback(() => {
        if (initialFilters) {
            const filters = Object.entries(initialFilters)
                .filter(([key, _]) => key in DEFAULT_FILTERS)
                .map(([key, value]) => {
                    if (key === 'startDate' && typeof value === 'string') {
                        value = new Date(decodeURIComponent(value))

                        if (value < startOfToday()) {
                            value = startOfToday()
                        }
                    }
                    return {
                        filterKey: key as filterKeys,
                        value
                    }
                })

            const newParams = omitBy(
                {
                    ...initialFilters,
                    startDate: initialFilters.startDate
                        ? new Date(
                              decodeURIComponent(
                                  initialFilters.startDate as any
                              )
                          )?.toISOString()
                        : undefined
                } as any,
                value =>
                    isNil(value) ||
                    (Array.isArray(value) && value.length === 0) ||
                    value === ''
            )

            setTimeout(() => {
                dispatch({
                    type: 'UPDATE_ALL',
                    filters
                })
            }, 100)

            const currentSearchParam = getFiltersFromUrl()
            const notFilterParams = [
                'context',
                'reschedule',
                'backTo',
                'cadence'
            ]
            const currentSearchParamWithoutContext = omit(
                currentSearchParam,
                notFilterParams
            )
            const newParamsWithoutContext: any = omit(
                newParams,
                notFilterParams
            )

            if (
                !isEqual(
                    currentSearchParamWithoutContext,
                    newParamsWithoutContext
                )
            ) {
                notFilterParams
                    .filter(x => x !== 'context')
                    .forEach(param => {
                        const currentValue = currentSearchParam[param]
                        if (currentValue) {
                            newParamsWithoutContext[param] = currentValue
                        }
                    })

                setSearchParams(newParamsWithoutContext, {
                    preventScrollReset: true
                })
            }
        }
    }, [initialFilters, dispatch])

    // We call useEffect to avoid an infinite loop where initial filters are set, dispatch is fired,
    // and then the parent component is rerendered, causing useAvailableSlotsFilter
    // to be called again with the same initial filters
    useLayoutEffect(() => {
        resetToInitialState()
    }, [initialFilters, dispatch])

    // The modal uses currentModal state to choose
    // which modal is rendered based on configuration
    const [showModal, hideModal] = useModal(() => {
        if (appointmentProfile) {
            // appointmentProfile should be defined by now
            // we avoid poluting code with null checks
            let maxDate = addDays(new Date(), 107)
            let minDate
            if (
                (appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_INTAKE ||
                    appointmentProfile.name ===
                        APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM ||
                    appointmentProfile.name ===
                        APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_RETURNING) &&
                rescheduledAppointment?.scheduledTime
            ) {
                maxDate = addDays(
                    new Date(rescheduledAppointment?.scheduledTime),
                    5
                )
                const idealMinDate = subDays(
                    rescheduledAppointment?.scheduledTime,
                    5
                )
                const now = new Date()
                minDate = idealMinDate >= now ? idealMinDate : now
            }

            return state.context.filters[currentModal].modal({
                hideModal,
                onSelected: (value: Date | string[]) =>
                    dispatch({
                        type: 'SELECTION',
                        filterKey: currentModal,
                        value
                    }),
                onClearSelections: () =>
                    dispatch({
                        type: 'CLEAR',
                        filterKey: currentModal
                    }),
                initialValue: currentValue,
                appointmentProfile,
                maxDate,
                minDate,
                rescheduledAppointment
            })
        } else {
            return <></>
        }
    }, [currentModal, currentValue, appointmentProfile])

    const clearFilters = useCallback(() => {
        dispatch('CLEAR_ALL')
    }, [])

    const resetFilters = useCallback(() => {
        dispatch('RESET')
    }, [])

    const updateValue = useCallback(
        (filterKey: any, value: string[] | Date) => {
            dispatch({
                type: 'SELECTION',
                filterKey,
                value
            })
        },
        []
    )

    const nextDateRange = useCallback(
        (firstSlotTime: Date, lastSlotTime: Date) => {
            dispatch({
                type: 'NEXT_PAGE',
                newStartDate: lastSlotTime,
                oldStartDate: firstSlotTime
            })
        },
        []
    )

    const prevDateRange = useCallback(() => {
        dispatch({
            type: 'PREV_PAGE'
        })
    }, [])

    // Sets current modal and opens,
    // this is to avoid handling each modal separately
    const openModal = useCallback(
        (filterKey: filterKeys) => {
            setCurrentModal(filterKey)
            setCurrentValue(state.context.filters[filterKey].value)
            showModal()
        },
        [state]
    )

    // Collect filter values into digestible object
    const contextMemoized = useMemo(() => state.context, [state.context])
    const filtersMemoized = useMemo(
        () => state.context.filters,
        [state.context.filters]
    )
    const hasFilters = useMemo(
        () => Object.values(filtersMemoized).some(x => x.isFiltering),
        [filtersMemoized]
    )
    const hasPrevPages = useMemo(
        () => contextMemoized.oldStartDates.length > 0,
        [contextMemoized]
    )
    const filterValues = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(filtersMemoized).map(([key, filter]) => [
                    key,
                    filter.value
                ])
            ),
        [filtersMemoized]
    )

    return {
        filters: filtersMemoized,
        context: contextMemoized,
        hasFilters,
        resetFilters,
        clearFilters,
        dispatch,
        updateValue,
        filterValues,
        openModal,
        isChangingFilters: state.matches('changingFilters'),
        nextDateRange,
        prevDateRange,
        hasPrevPages
    }
}

export const AvailableSlotsFilter = ({
    appointmentProfile,
    scrollRef,
    rescheduledAppointment
}: {
    appointmentProfile: AppointmentProfile
    scrollRef: MutableRefObject<HTMLDivElement | null>
    rescheduledAppointment?: Appointment
}) => {
    const [_, setSearchParams] = useSearchParams()
    const { offeringDetails } = useOfferingDetails(
        appointmentProfile.appointmentProfileUuid
    )
    const {
        filters,
        context,
        hasFilters,
        clearFilters,
        openModal,
        updateValue,
        dispatch
    } = useAvailableSlotsFilter(
        undefined,
        appointmentProfile,
        rescheduledAppointment
    ) // avoid resetting initial filters

    const location = useLocation()

    // Hide disabled filters
    const enabledFilters = Object.entries(filters).filter(([key, filter]) => {
        if (
            (appointmentProfile.name ===
                APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_INTAKE ||
                appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_PROGRAM ||
                appointmentProfile.name ===
                    APPOINTMENT_PROFILE_NAMES.TALK_THERAPY_RETURNING) &&
            rescheduledAppointment
        ) {
            return key === 'startDate'
        }

        return filter.isEnabled(appointmentProfile)
    })

    function clearSelections(event: MouseEvent) {
        event.preventDefault()
        clearFilters()
    }

    useLayoutEffect(() => {
        dispatch({
            type: 'SET_QUERY_PARAMS',
            setSearchParams: setSearchParams
        })
    }, [context, setSearchParams, location.pathname])

    const capitalizeFirstLetter = (value: string) =>
        value[0].toUpperCase() + value.slice(1)

    const getFilterValue = (name: string, value: string | Date) => {
        switch (name) {
            case 'startDate':
                const currentDate = value as Date
                return format(currentDate, 'MM/dd/yyyy')
            case 'providerUuids':
                const staff = offeringDetails?.clinicStaff.find(
                    staff => staff.staffUuid === value
                )
                return staff?.name
            case 'clinicUuids':
                const clinic = appointmentProfile.clinicsWithOffering.find(
                    x => x.uuid === value
                )
                return clinic?.isVirtual ? 'Virtual' : clinic?.name
        }

        return capitalizeFirstLetter(value as string)
    }

    const onRemoveFilter = (filterKey: string, value: string) => {
        const currentFilter = (filters as any)[filterKey]
        const oldValues = currentFilter?.value
        let valueOverride

        if (oldValues) {
            if (filterKey === 'cadence') {
                if (value === 'bi-weekly') {
                    valueOverride = 'weekly'
                }

                if (value === 'weekly') {
                    valueOverride = 'bi-weekly'
                }
            }

            if (filterKey === 'startDate') {
                valueOverride = undefined
                return updateValue(filterKey, startOfToday())
            }

            updateValue(
                filterKey,
                valueOverride ||
                    oldValues.filter((oldValue: string) => oldValue !== value)
            )
        }
    }

    return (
        <Container sx={{ px: [0, 0] }}>
            <Box
                sx={{
                    bg: 'raspberry',
                    borderTopLeftRadius: 2,
                    borderTopRightRadius: 2,
                    borderBottomLeftRadius: [0, 2],
                    borderBottomRightRadius: [0, 2]
                }}
                ref={scrollRef}
            >
                {enabledFilters.map(([key, filter]) => (
                    <Button
                        variant="secondaryLight"
                        onClick={() => openModal(key as filterKeys)}
                        key={key}
                        sx={{
                            mr: 2,
                            mb: 2,
                            py: 3,
                            px: 4,
                            width: 'fit-content',
                            borderRadius: '44px',
                            backgroundColor: filter.isFiltering
                                ? '#69153B' // raspberry 20% darker, we don't have this on tia-ui
                                : undefined
                        }}
                    >
                        {filter.label}
                    </Button>
                ))}
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {
                        <Flex sx={{ gap: 1, alignItems: 'center', mt: 4 }}>
                            {hasFilters && (
                                <Link
                                    sx={{
                                        color: 'white',
                                        lineHeight: 1,
                                        mb: 4
                                    }}
                                    href="#"
                                    onClick={clearSelections}
                                >
                                    Clear filters
                                </Link>
                            )}
                        </Flex>
                    }
                </Flex>

                <Flex sx={{ flexWrap: 'wrap' }}>
                    {Object.getOwnPropertyNames(filters)
                        .filter(
                            filterName =>
                                (filters as any)[filterName].isFiltering
                        )
                        .map(filterName => {
                            const currentFilter = (filters as any)[filterName]

                            if (Array.isArray(currentFilter.value)) {
                                return currentFilter.value.map(
                                    (value: string) => ({
                                        name: filterName,
                                        value: value
                                    })
                                )
                            }

                            return {
                                name: filterName,
                                value: currentFilter.value
                            }
                        })
                        .flat()
                        .map(filter => {
                            return (
                                <FilterPill
                                    filterKey={filter.name}
                                    value={filter.value}
                                    onClose={onRemoveFilter}
                                    sx={{ mr: 2, mb: 2 }}
                                >
                                    {getFilterValue(filter.name, filter.value)}
                                </FilterPill>
                            )
                        })}
                </Flex>
            </Box>
        </Container>
    )
}
