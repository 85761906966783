import { FC } from 'react'
import { Container, Flex, Text, LayoutGrid, Image } from '@asktia/tia-ui'

export const ErrorComponent: FC<Record<string, never>> = () => {
    return (
        <Container>
            <LayoutGrid columns="one">
                <Flex sx={{ flexDirection: 'column' }}>
                    <Image src="https://assets.asktia.com/svgs/catastrophic-error.svg" />
                    <Text sx={{ pt: 1, fontSize: 4 }}>
                        Something went wrong, please try again. We've notified
                        our team about it.
                    </Text>
                </Flex>
            </LayoutGrid>
        </Container>
    )
}
