import { useEffect } from 'react'
import { Heading, Label, Input, Select, LabeledCheckbox } from '@asktia/tia-ui'

import { titleize } from 'src/utils'
import { NameForm } from 'src/components/Blocks/NameForm'
import { Address } from 'src/types'
import { UseFormMethods } from 'react-hook-form'
import { AddressForm } from 'src/components/AddressForm'

/**
 * shows policy holder form
 */
export const SubscriberForm = (props: {
    relationshipOptions: { [key: string]: string }
}) => (
    <>
        <Heading h2>Policyholder's Info</Heading>

        <NameForm
            label="Policyholder's Name"
            fieldNames={['subscriberGivenName', 'subscriberFamilyName']}
            defaultValues={['', '']}
        />

        <Label>Date of Birth</Label>
        <Input
            name="subscriberDateOfBirth"
            type="date"
            errorMessage="Date of Birth is required"
            required
        />

        <Label>Your Relationship to the Policyholder</Label>
        <Select
            name="relationshipToSubscriberCode"
            required
            errorMessage="Policyholder relationship is required"
            options={Object.keys(props.relationshipOptions).map(key => ({
                label: titleize(key),
                value: props.relationshipOptions[key]
            }))}
        />
    </>
)

export const SubscriberAddressForm = (props: {
    patientIsADependent?: boolean
    mailingAddress?: Address
    formMethods: UseFormMethods
}) => {
    const formMethods = props.formMethods

    const addressFields = [
        'address1',
        'address2',
        'city',
        'state',
        'postalCode'
    ]

    const sameAsMailing = formMethods.watch('sameAsMailing')
    const address = formMethods.watch(addressFields)

    // populate address when clicking checkbox
    useEffect(() => {
        if (sameAsMailing && props.mailingAddress) {
            for (const key of addressFields) {
                // @ts-ignore: TS doesn't understand objects can be indexed by string
                formMethods.setValue(key, props.mailingAddress[key], {
                    shouldValidate: true
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sameAsMailing])

    // untick checkbox when address changes
    useEffect(() => {
        for (const key of Object.keys(address)) {
            const isDirty = formMethods.formState.dirtyFields[key]
            if (
                isDirty &&
                // @ts-ignore: TS doesn't understand objects can be indexed by string
                formMethods.getValues(key) !== props.mailingAddress[key]
            ) {
                if (sameAsMailing) {
                    formMethods.setValue('sameAsMailing', false)
                }
            }
        }

        // eslint-disable-next-line
    }, [address])

    return (
        <>
            <Heading h2>
                {props.patientIsADependent
                    ? "Policyholder's Address"
                    : 'Address on Your Policy'}
            </Heading>

            <LabeledCheckbox name="sameAsMailing" sx={{ mb: 4 }}>
                Same as mailing address
            </LabeledCheckbox>

            <AddressForm sx={sameAsMailing ? { display: 'none' } : {}} />
        </>
    )
}
