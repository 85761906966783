import { FC, PropsWithChildren } from 'react'
import { format as formatTime } from 'date-fns'
import {
    Card,
    Heading,
    Text,
    Link,
    Paragraph,
    Checkbox,
    Label,
    Box
} from '@asktia/tia-ui'

import { Appointment } from 'src/types'
import {
    InsuranceValidationState,
    isEligibleAndInNetwork,
    MachineContext
} from './useInsuranceUIStateMachine'
import { THREAD_LABELS } from 'src/globals'
import GeneralInsuranceCWCT from 'src/components/CWCTLinks/GeneralInsuranceCWCT'

const MessageCCLink: FC<PropsWithChildren> = ({ children }) => (
    <Link
        href={`/r/chat/new?label=${THREAD_LABELS.insurance}&message=I+have+a+question+about+my+insurance`}
        target="_blank"
    >
        {children}
    </Link>
)

const PricingLink: FC<PropsWithChildren> = ({ children }) => (
    <Link href="https://asktia.com/tia-service-list" target="_blank">
        {children}
    </Link>
)

const CashAgreeCheckbox: FC<PropsWithChildren> = ({ children }) => (
    <Label
        variant="forms.boldLabel"
        sx={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '56px auto'
        }}
    >
        <Checkbox pretty name="confirmAgreeCashPay" sx={{ gridColumn: 1 }} />
        <Box sx={{ gridColumn: 2 }}>{children}</Box>
    </Label>
)

type CopyFragment = {
    heading: string
    body: (appointment: Appointment, updatedAt: string) => JSX.Element
}

// ACI eligibility states mapping from
// https://docs.google.com/spreadsheets/d/1HyxlPdAiU_bljx44x7azrilJzl14NkIA8wxSQiDUOaw/edit?usp=sharing

const COPY: CopyFragment[] = [
    {
        heading:
            'Tia works with your insurance for gynecology & primary care! 🎉',
        body: () => (
            <Paragraph>
                Please note that you may have a copay or other patient
                responsibility for this visit. Questions?{' '}
                <GeneralInsuranceCWCT />
            </Paragraph>
        )
    },
    {
        heading: 'Out of network',
        body: () => (
            <>
                <Paragraph sx={{ mb: 4 }}>
                    Your insurance is out of network with Tia and your card on
                    file will be charged for this appointment. See our{' '}
                    <PricingLink>price list</PricingLink> to estimate your
                    costs. If you think this information is incorrect, please{' '}
                    <MessageCCLink>message your Care Coordinator</MessageCCLink>
                    .
                </Paragraph>
                <CashAgreeCheckbox>
                    I agree to pay the full cash price for my visit
                </CashAgreeCheckbox>
            </>
        )
    },
    {
        heading: 'We cannot verify your insurance',
        body: (appointment: Appointment) => (
            <>
                <Paragraph sx={{ mb: 4 }}>
                    Please{' '}
                    <Link
                        href="/chat/new?label=Insurance+Question"
                        target="_blank"
                    >
                        message your Care Coordinator.
                    </Link>{' '}
                    to verify your insurance. If you do not get your insurance
                    verified by a Care Coordinator, we will charge the cash
                    price of your appointment to your card on file. See our{' '}
                    <PricingLink>price list</PricingLink> to estimate your
                    costs.
                </Paragraph>
                <CashAgreeCheckbox>
                    I agree to pay the cash price if my insurance is not
                    validated before my appointment.
                </CashAgreeCheckbox>
            </>
        )
    },
    {
        heading: 'Your insurance has been approved! 🎉',
        body: (appointment: Appointment, updatedAt: string) => (
            <>
                <Paragraph>
                    Your insurance has been approved as of {updatedAt}! If
                    anything has changed since then, please contact a Care
                    Coordinator.
                </Paragraph>
                <Paragraph>
                    Please note that you may have a copay or other patient
                    responsibility for this visit. Questions?{' '}
                    <GeneralInsuranceCWCT />
                </Paragraph>
            </>
        )
    },
    {
        heading: 'Out of network',
        body: (appointment: Appointment, updatedAt: string) => (
            <>
                <Paragraph sx={{ mb: 4 }}>
                    Your insurance is out of network with Tia and your card on
                    file will be charged for this appointment. See our{' '}
                    <PricingLink>price list</PricingLink> to estimate your
                    costs. If you think this information is incorrect, please{' '}
                    <MessageCCLink>message your Care Coordinator</MessageCCLink>
                    .
                </Paragraph>
                <CashAgreeCheckbox>
                    I agree to pay the full cash price for my visit
                </CashAgreeCheckbox>
            </>
        )
    },
    {
        heading: 'We cannot verify your insurance',
        body: () => (
            <>
                <Paragraph sx={{ mb: 4 }}>
                    Please{' '}
                    <Link
                        href="/chat/new?label=Insurance+Question"
                        target="_blank"
                    >
                        message your Care Coordinator.
                    </Link>{' '}
                    to verify your insurance. If you do not get your insurance
                    verified by a Care Coordinator, we will charge the cash
                    price of your appointment to your card on file. See our{' '}
                    <PricingLink>price list</PricingLink> to estimate your
                    costs.
                </Paragraph>
                <CashAgreeCheckbox>
                    I agree to pay the cash price if my insurance is not
                    validated before my appointment.
                </CashAgreeCheckbox>
            </>
        )
    },
    {
        heading: 'Sorry, your insurance doesn’t work at Tia',
        body: () => (
            <>
                <Paragraph>
                    Due to restrictions associated with Medicaid and managed
                    Medicaid plans, you are not able to use Tia at this time.
                </Paragraph>
                <Paragraph>
                    <MessageCCLink>Message your Care Coordinator</MessageCCLink>{' '}
                    to cancel your appointment and membership. We are sorry for
                    any issues this may have caused.
                </Paragraph>
                <Paragraph>
                    To find in-network clinics in your area, contact your
                    insurance company directly using the contact information on
                    the back of your insurance card.
                </Paragraph>
                <Paragraph>
                    If you believe you received this status in mistake, please
                    reach out to your Care Coordinator to re-verify your
                    insurance.
                </Paragraph>
            </>
        )
    }
]

// 1-indexed mapping from states to index in array above
// note: an admin is never unsure
const MAPPING: {
    [key: string]: {
        [key: string]: { [key: string]: { [key: string]: number | null } }
    }
} = {
    in_network: {
        admin: {
            eligible: { admin: 4, system: 4 },
            ineligible: { admin: 6, system: 6 },
            unsure: { system: 2, admin: 2 }
        },
        system: {
            eligible: { admin: 1, system: 1 },
            ineligible: { admin: 6, system: 6 },
            unsure: { system: 3 }
        }
    },
    out_of_network: {
        admin: {
            eligible: { admin: 5, system: 5 },
            ineligible: { admin: 6, system: 6 },
            unsure: { system: 2, admin: 2 }
        },
        system: {
            eligible: { admin: 2, system: 2 },
            ineligible: { admin: 6, system: 6 },
            unsure: { system: 3 }
        }
    },
    unsure: {
        system: {
            eligible: { system: 3, admin: 3 },
            ineligible: { system: 6 },
            unsure: { system: 3 }
        },
        admin: {
            eligible: { system: 3 },
            ineligible: { system: 6 },
            unsure: { system: 3 }
        }
    },
    blocked: {
        system: {
            eligible: { system: 7, admin: 7 },
            ineligible: { system: 6 },
            unsure: { system: 7 }
        },
        admin: {
            eligible: { system: 7 },
            ineligible: { system: 6 },
            unsure: { system: 7 }
        }
    }
}

// We use this copy when appt's insurance support is tentative
const tentativeAppointmentCopy: Record<string, CopyFragment> = {
    insuranceWasUsedBefore: {
        heading: 'Insurance info saved ✅',
        body: (appointment: Appointment) => {
            const isAcupuncture =
                appointment.appointmentProfileName === 'acupuncture'

            if (isAcupuncture) {
                return (
                    <>
                        <Paragraph>
                            If you use insurance for primary care and gynecology
                            at Tia, it does not necessarily mean you are
                            in-network for acupuncture!
                        </Paragraph>
                        <Paragraph>
                            Please{' '}
                            <Link
                                href="/chat/new?label=Insurance+Question"
                                target="_blank"
                            >
                                message your Care Coordinator.
                            </Link>{' '}
                            to verify your insurance. If you do not get your
                            insurance verified by a Care Coordinator, we will
                            charge the cash price of your appointment to your
                            card on file. See our{' '}
                            <PricingLink>price list</PricingLink> to estimate
                            your costs.
                        </Paragraph>
                        <CashAgreeCheckbox>
                            I agree to pay the cash price if my insurance is not
                            validated before my appointment.
                        </CashAgreeCheckbox>
                    </>
                )
            }

            return (
                <>
                    <Paragraph sx={{ mb: 4 }}>
                        Please note that you may have a copay or other patient
                        responsibility for this visit. If you need help checking
                        coverage, please{' '}
                        <Link
                            href="/chat/new?label=Insurance+Question"
                            target="_blank"
                        >
                            message your Care Coordinator.
                        </Link>
                    </Paragraph>
                    <CashAgreeCheckbox>
                        I agree to pay the cash price if my insurance is not
                        validated before my appointment.
                    </CashAgreeCheckbox>
                </>
            )
        }
    },
    notInsuranceWasUsedBefore: {
        heading: 'We cannot verify your insurance',
        body: () => (
            <>
                <Paragraph sx={{ mb: 4 }}>
                    Please{' '}
                    <Link
                        href="/chat/new?label=Insurance+Question"
                        target="_blank"
                    >
                        message your Care Coordinator.
                    </Link>{' '}
                    to verify your insurance. If you do not get your insurance
                    verified by a Care Coordinator, we will charge the cash
                    price of your appointment to your card on file. See our{' '}
                    <PricingLink>price list</PricingLink> to estimate your
                    costs.
                </Paragraph>
                <CashAgreeCheckbox>
                    I agree to pay the cash price if my insurance is not
                    validated before my appointment.
                </CashAgreeCheckbox>
            </>
        )
    }
}

function mapState({
    verificationStatus,
    verificationSource,
    eligibilityStatus,
    eligibilitySource
}: InsuranceValidationState) {
    try {
        let index =
            // @ts-ignore we know these are defined
            MAPPING[verificationStatus][verificationSource][eligibilityStatus][
                eligibilitySource
            ]

        // TODO: do we need a new UI state for this?
        if (index === null) {
            index = 3
        }

        // JS arrays are 0-indexed
        return index - 1
    } catch (e) {
        // TODO: what's the equivalent of crashing for a webapp?
        alert('You have reached an impossible state, contact support')
        return 0
    }
}

/**
 * shows insurance verification status
 */
export const VerificationStatus = (props: {
    machineContext: MachineContext
    appointment: Appointment
}) => {
    const updatedAt = formatTime(
        props.machineContext.insuranceValidationState.updatedAt,
        'MM/dd/yyyy'
    )
    let copy: CopyFragment

    if (
        props.appointment.canUseInsurance === 'tentative' &&
        isEligibleAndInNetwork(props.machineContext.insuranceValidationState)
    ) {
        if (props.machineContext.insuranceWasUsedBefore) {
            copy = tentativeAppointmentCopy.insuranceWasUsedBefore
        } else {
            copy = tentativeAppointmentCopy.notInsuranceWasUsedBefore
        }
    } else {
        copy = COPY[mapState(props.machineContext.insuranceValidationState)]
    }

    return (
        <Card variant="info" sx={{ mb: 4 }}>
            <Heading variant="cardTitle" sx={{ fontSize: 5 }}>
                {copy.heading}
            </Heading>
            <Text>{copy.body(props.appointment, updatedAt)}</Text>
        </Card>
    )
}
