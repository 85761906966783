import { Input } from '@asktia/tia-ui'
import { FC } from 'react'
import {
    BaseInput,
    IBaseInput
} from 'src/flows/questionnaires/components/Input/BaseInput'
import { QuestionFormGlue } from '../../types'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'
import { FollowUpQuestionsCard } from '../FollowUpQuestionsCard'
import { ThemeUIStyleObject } from '@theme-ui/css'

export interface IOpenInput
    extends Omit<IBaseInput, 'render' | 'readDefaultValue'> {
    placeholder?: string
}

export const OpenInput: FC<IOpenInput> = ({
    questionnaireItem,
    answersMap,
    variant,
    placeholder,
    sx,
    index,
    triggerSave
}) => {
    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            variant={variant}
            sx={sx}
            index={index}
            triggerSave={triggerSave}
            readDefaultValue={defaultValue =>
                defaultValue && defaultValue.length > 0
                    ? defaultValue[0].valueInteger
                    : undefined
            }
            render={props => (
                <Input
                    {...props}
                    type="text"
                    placeholder={placeholder}
                    sx={{ mb: 3 }}
                />
            )}
        />
    )
}

export const GluedOpenInputQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    questionnaireResponse,
    sx,
    variant,
    placeholder,
    index,
    followupCardSx,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    variant?: 'info'
    sx?: ThemeUIStyleObject
    followupCardSx?: ThemeUIStyleObject
    placeholder?: string
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    let isEnabled = true

    if (questionConfig && !!questionConfig.enableWhen) {
        isEnabled = questionnaireResponse
            ? isItemEnabled(questionConfig!, questionnaireResponse)
            : false
    }

    const shouldRender = questionConfig?.linkId && isEnabled

    if (!shouldRender) {
        return null
    }

    return (
        <FollowUpQuestionsCard hidden={variant !== 'info'} sx={followupCardSx}>
            <OpenInput
                questionnaireItem={questionConfig}
                answersMap={answersMap}
                sx={sx}
                variant={variant}
                placeholder={placeholder}
                index={index}
                triggerSave={triggerSave}
            />
        </FollowUpQuestionsCard>
    )
}
