import { ThreadLabel } from 'src/globals'
import reduce from 'lodash/reduce'
import { useNavigate } from 'react-router'

type ContactCareTeamQuery = {
    subject?: string
    message?: string
    label?: ThreadLabel
}

export const useContactWithCareTeam = (defaults?: ContactCareTeamQuery) => {
    const navigate = useNavigate()
    return (query?: ContactCareTeamQuery) => {
        const queryStr = reduce(
            { ...defaults, ...query },
            (a, v, k) => {
                return v ? `${a}&${k}=${encodeURIComponent(v)}` : a
            },
            ''
        )
        navigate(`/chat/new${queryStr ? '?' : ''}${queryStr}`)
    }
}
