import { Box, Button, Container, Flex, Heading, TiaModal } from '@asktia/tia-ui'
import { addMonths, format, isToday } from 'date-fns'
import { useState } from 'react'
import Calendar from 'react-calendar'
import calendarStyles from './styles'
import { useAmpli } from 'src/hooks'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import {
    ChevronLeft,
    ChevronRight,
    CloseIcon
} from 'src/components/Blocks/Icons'

export const DateFilterModal = ({
    hideModal,
    onSelected,
    initialValue,
    appointmentProfile,
    maxDate,
    minDate
}: FilterModalProps<Date>) => {
    const [date, setDate] = useState<Date>(initialValue || new Date())
    const today = new Date()
    const { appointmentFilteredByDate } = useAmpli()

    // This should never be an array of Dates, but technically
    // the Calendar component allows it, and we can handle the
    // case simply by recursing with the first date
    const onChange = (incoming: Date | Date[]) => {
        if (Array.isArray(incoming)) {
            onChange(incoming[0])
        } else {
            if (incoming > today && incoming < addMonths(today, 6)) {
                setDate(incoming)
            } else {
                setDate(new Date())
            }
        }
    }

    // Set the date and close the modal
    const selectDate = () => {
        appointmentFilteredByDate(date, appointmentProfile)
        onSelected(date)
        hideModal()
    }

    // We disallow selection of days before today and after three
    // months from today.  This function is used for that filter.
    const isOutsideWindow = () => {
        return (date < today && !isToday(date)) || date > addMonths(today, 6)
    }

    // Sets special classname on calendar days.  This is used to
    // visually "disable" days in the past, and put the ring around
    // today, via CSS.
    const tileClassName = ({ date }: { date: Date }): string => {
        if (isToday(date)) {
            return 'today'
        } else if (date < today || date > addMonths(today, 6)) {
            return 'outsideWindow'
        }

        return ''
    }

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 5
                }}
            >
                <Heading h2 sx={{ m: 0 }}>
                    Jump to a date
                </Heading>
                <Box onClick={() => hideModal()} sx={{ cursor: 'pointer' }}>
                    <CloseIcon color="inactiveText" hoverColor="text" />
                </Box>
            </Flex>
            <Container sx={calendarStyles}>
                <Calendar
                    onChange={onChange}
                    value={date}
                    prevLabel={<ChevronLeft color="text" />}
                    nextLabel={<ChevronRight color="text" />}
                    prev2Label={null}
                    next2Label={null}
                    calendarType={'US'}
                    showNeighboringMonth={false}
                    view={'month'}
                    tileClassName={tileClassName}
                    minDate={minDate || new Date()}
                    maxDate={maxDate}
                ></Calendar>
                <Flex
                    sx={{
                        my: 5,
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 0
                    }}
                >
                    <Button
                        fullWidth
                        onClick={selectDate}
                        disabled={isOutsideWindow()}
                    >
                        {isOutsideWindow()
                            ? 'Jump'
                            : `Jump to ${format(date, 'LLL d')}`}
                    </Button>
                </Flex>
            </Container>
        </TiaModal>
    )
}
