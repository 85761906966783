import {
    Box,
    Button,
    Card,
    Flex,
    Heading,
    Paragraph,
    ThemeStyleObject
} from '@asktia/tia-ui'
import { ArrowRightIcon, CheckmarkIcon } from 'src/components/Blocks/Icons'
import { UHRProgressCard } from 'src/components/UHRProgressCard'
import { HealthRecordEntry } from 'src/types'

const UhrChangedWarning = () => (
    <Card variant="info" sx={{ p: 5, my: 4 }}>
        <Heading h4>We need some information from you</Heading>
        <Paragraph sx={{ mb: 0 }}>
            Heads up, we’ve updated the Health Record with new questions
            designed to care for the whole you. Your Care Team is all about
            giving you the best care possible. To do that, they need the most
            up-to-date information about your health. You may have to fill out
            questions you have already answered before, and we apologize for any
            inconvenience. Rest assured, all data is protected for your privacy.
        </Paragraph>
    </Card>
)

export const SeasonalFluUpdateCard = (props: {
    record: Omit<HealthRecordEntry, 'url'>
    sx?: ThemeStyleObject
    title?: string
    clickedSeasonalUpdate: () => void
}) => {
    const { record, clickedSeasonalUpdate } = props

    return (
        <Box sx={props.sx}>
            {props.title && (
                <Heading h2 sx={{ mb: 0 }}>
                    {props.title}
                </Heading>
            )}

            <Flex
                sx={{
                    py: 4,
                    alignItems: 'center',
                    borderBottom: ({ colors }) =>
                        `1px solid ${colors?.cardOutline}`,
                    justifyContent: 'space-between'
                }}
            >
                <Heading h4 sx={{ mb: 0 }}>
                    {record.title}
                </Heading>

                <Button
                    variant={record.status === 'done' ? 'iconLight' : 'icon'}
                    as="a"
                    onClick={clickedSeasonalUpdate}
                >
                    {record.status === 'done' ? (
                        <CheckmarkIcon color="primaryButton" />
                    ) : (
                        <ArrowRightIcon color="white" />
                    )}
                </Button>
            </Flex>
        </Box>
    )
}

export const HealthRecordCard = (props: {
    records: HealthRecordEntry[]
    sx?: ThemeStyleObject
    title?: string
    variant: 'uhr' | 'intake'
}) => {
    const { records, variant } = props

    const showWarning =
        variant === 'uhr' && !records.every(r => r.status === 'done')

    let hasFirstNotDoneStep = false

    return (
        <>
            {props.title && <Heading h2>{props.title}</Heading>}
            {showWarning && <UhrChangedWarning />}
            {records.map(record => {
                let isFirstNotDone = false
                if (!hasFirstNotDoneStep && record.status !== 'done') {
                    hasFirstNotDoneStep = true
                    isFirstNotDone = true
                }

                return (
                    <UHRProgressCard
                        record={record}
                        isNext={isFirstNotDone}
                        status={record.status}
                        key={record.title}
                    />
                )
            })}
        </>
    )
}
