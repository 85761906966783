import {
    Button,
    Flex,
    Heading,
    Text,
    Link,
    Paragraph,
    TiaModal
} from '@asktia/tia-ui'
import { FC } from 'react'
import { ModalTitle } from 'src/components/Blocks'

// Copied from pugland
const simpleParsePhone = (inc: string) => {
    const rawNum = inc.toString().replace(/\D/g, '').replace(/^0+/, '')
    if (rawNum.length === 10) {
        // Assume 10 digit numbers are US based
        return rawNum.replace(/(\d{3})(\d{3})(\d{4})/, '+1-$1-$2-$3')
    } else {
        // Otherwise infer the country code from the input and structure in a +{country code}-{3}-{3}-{4} format
        return `+${rawNum.substring(0, rawNum.length - 10)}-${rawNum.substring(
            rawNum.length - 10,
            rawNum.length - 7
        )}-${rawNum.substring(
            rawNum.length - 7,
            rawNum.length - 4
        )}-${rawNum.substring(rawNum.length - 4)}`
    }
}

export interface IResendModal {
    isOpen: boolean
    onClose: () => void
    onCTAClick: () => void
    onBackLinkClick?: React.MouseEventHandler<HTMLAnchorElement>

    title: string
    ctaCopy: string
    backLinkCopy: string

    phone?: string
    email?: string
}

export const ResendModal: FC<IResendModal> = ({
    isOpen,
    onClose,
    onCTAClick,
    onBackLinkClick,
    title,
    ctaCopy,
    backLinkCopy,
    phone,
    email
}) => {
    return (
        <TiaModal isOpen={isOpen}>
            <ModalTitle title="Hmmmm..." hideModal={onClose} />
            <Flex sx={{ maxWidth: 300, flexDirection: 'column', mb: 30 }}>
                <Paragraph>{title}</Paragraph>

                <Heading
                    h2
                    sx={{
                        mb: 20,
                        textAlign: 'center',
                        wordBreak: 'break-all',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {phone && simpleParsePhone(phone)}
                    {email}
                </Heading>

                <Button
                    sx={{ mb: 20 }}
                    onClick={() => {
                        onCTAClick()
                        onClose()
                    }}
                >
                    {ctaCopy}
                </Button>

                <Link
                    href="/r/login"
                    sx={{ mb: 30, color: 'text', textAlign: 'center' }}
                    onClick={onBackLinkClick}
                >
                    {backLinkCopy}
                </Link>

                <Text sx={{ textAlign: 'center' }}>
                    Want to contact us?{' '}
                    <Link href="tel:+16466505337" sx={{ color: 'text' }}>
                        Give us a call
                    </Link>
                </Text>
            </Flex>
        </TiaModal>
    )
}
