import { useEffect } from 'react'
import { useAmpli } from 'src/hooks'
import {
    ClinicDropdownItem,
    ServiceDropdownItem
} from 'src/pages/Appointments/useAppointmentFilters'

// Helper hook to clean up the main component
export function useTrackingStuff() {
    const {
        navigatedToAppointmentsPage,
        filterAppointmentsByLocation,
        filterAppointmentsByServiceLine,
        clickServiceLineModuleOnAppointmentsPage
    } = useAmpli()

    function trackServiceLineChange(opt: ServiceDropdownItem[]) {
        filterAppointmentsByServiceLine(opt.map(o => o.value))
    }

    function trackLocationChange(opt: ClinicDropdownItem[]) {
        filterAppointmentsByLocation(opt.map(o => o.uuid))
    }

    function trackServiceLineBanner(name: string) {
        clickServiceLineModuleOnAppointmentsPage(name)
    }

    useEffect(() => {
        navigatedToAppointmentsPage()
    }, [])

    return {
        trackLocationChange,
        trackServiceLineBanner,
        trackServiceLineChange
    }
}
