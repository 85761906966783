import { Container } from '@asktia/tia-ui'
import { Route, Routes } from 'react-router-dom'
import { Show404 } from 'src/flows/404'

import { BOOKING_STEPS } from 'src/flows/AppointmentBooking/useBookingFlow'
import { BookingGoalSelection } from 'src/flows/AppointmentBooking/views/BookingGoalSelection'
import { BookingPreQuestions } from 'src/flows/AppointmentBooking/views/BookingPreQuestions'
import { BookingReview } from 'src/flows/AppointmentBooking/views/BookingReview'
import { BookingTimeSelection } from 'src/flows/AppointmentBooking/views/BookingTimeSelection'
import { BookingTreatmentSelection } from 'src/flows/AppointmentBooking/views/BookingTreatmentSelection'

import { DeeplinkRedirect } from 'src/flows/AppointmentBooking/DeeplinkRedirect'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { PageHeader } from 'src/components/PageHeader'
import { BackButton } from 'src/components/Blocks/BackButton'
import { useUserInfo } from 'src/hooks'
import { PaymentWarningBanner } from 'src/components/PaymentWarningBanner'

// React Router v6 requires relative nested paths
// we can't change BOOKING_STEPS because we need
// absolute paths for linking
function relativePath(path: string) {
    return path
        .replace(/^\/book-appointment\//, '')
        .replace(/^\/on-demand-care\/profile\//, '')
}

export const AppointmentBooking = () => {
    const { user } = useUserInfo()
    return (
        // rendering Body in the App leads to double flashing
        // because of nested <Routes> components
        // trying to use 1 <Routes> leads to infinite loops
        // result: Transitions between AB and ACI aren't animated
        <Body>
            <Routes>
                <Route
                    path={relativePath(BOOKING_STEPS.preBookingQuestion.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <NavBarHeading>Before you book...</NavBarHeading>
                            <Container>
                                <BookingPreQuestions />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(
                        BOOKING_STEPS.preBookingQuestionNoSlot.path
                    )}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <NavBarHeading>Before you book...</NavBarHeading>
                            <Container>
                                <BookingPreQuestions />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(BOOKING_STEPS.timeSelection.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <PaymentWarningBanner />

                            <NavBarHeading>Select an appointment</NavBarHeading>
                            <Container>
                                <BookingTimeSelection />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(BOOKING_STEPS.goalSelection.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <NavBarHeading>Follow-up questions</NavBarHeading>
                            <Container>
                                <BookingGoalSelection />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(BOOKING_STEPS.treatmentSelection.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <NavBarHeading>Follow-up questions</NavBarHeading>
                            <Container>
                                <BookingTreatmentSelection />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(BOOKING_STEPS.review.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <NavBarHeading>
                                {user?.membershipSignupStatus === 'preview'
                                    ? 'Resume signup to book'
                                    : 'Review your appointment'}
                            </NavBarHeading>
                            <Container>
                                <BookingReview />
                            </Container>
                        </>
                    }
                />

                <Route
                    path={relativePath(BOOKING_STEPS.deeplink.path)}
                    element={
                        <>
                            <PageHeader
                                mobileBack={<BackButton variant="white" />}
                            />
                            <Container>
                                <DeeplinkRedirect />
                            </Container>
                        </>
                    }
                />

                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
