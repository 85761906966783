import { Box, LayoutGrid } from '@asktia/tia-ui'
import { FC } from 'react'

export const ErrorSavingBanner: FC = () => {
    return (
        <Box
            sx={{
                backgroundColor: 'poppy',
                mb: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                // offsets must match paddings in Page.tsx
                mx: theme => `-${theme.sizes?.[5]}px`,
                mt: theme => `-${theme.sizes?.[6]}px`,
                py: 4,
                position: 'sticky',
                top: 0,
                zIndex: 9999
            }}
        >
            <LayoutGrid columns="one" variant="condensed">
                <Box sx={{ px: [4, 0] }}>
                    There was an issue saving your data. Please check that all
                    questions have been answered.
                </Box>
            </LayoutGrid>
        </Box>
    )
}
