import { Button, Flex, Form, Text, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { LoadingScreen } from 'src/pages/Home'
import {
    convertToFormPayload as otherImmunizationsConvertToFormPayload,
    OtherImmunizations
} from './components/OtherImmunizations'
import {
    convertToFormPayload,
    RecommendedImmunizations,
    useRecommendedImmunizations
} from './components/RecommendedImmunizations'
import { useGetImmunizations } from './hooks/useGetImmunizations'
import { useSubmitImmunizationSeen } from 'src/hooks/useSubmitImmunizationsSeen'
import { Bundle, BundleEntry, Immunization } from '@medplum/fhirtypes'

export const Immunizations: FC = () => {
    const [immunizations, setImmunizations] = useState<Immunization[]>([])

    const { isLoading: immunizationLoading } = useGetImmunizations({
        onSuccess(data) {
            setImmunizations(data)
        }
    })
    const onCreateSuccess = (data: Bundle) => {
        const createdImmunization = data?.entry?.find(
            (i: BundleEntry) => i?.resource?.resourceType === 'Immunization'
        )
        if (createdImmunization?.resource) {
            setImmunizations(immunizations => [
                ...immunizations,
                createdImmunization.resource as Immunization
            ])
        }
    }
    const onEditSuccess = (data: Immunization) => {
        setImmunizations(immunizations => {
            const editedIndex = immunizations.findIndex(i => i.id === data.id)
            if (editedIndex > -1) {
                immunizations[editedIndex] = data
            }
            return immunizations
        })
    }

    const mutateImmunizationSeen = useSubmitImmunizationSeen()
    const recommendedImmunizations = useRecommendedImmunizations()
    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
        //resolver: yupResolver(GynecologySchema)
    })

    useEffect(() => {
        if (immunizations?.length > 0) {
            otherImmunizationsConvertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
            convertToFormPayload(
                immunizations,
                recommendedImmunizations,
                formMethods
            )
        }
    }, [immunizations, recommendedImmunizations])

    const onSubmit = async () => {
        await mutateImmunizationSeen.submitImmunizationSeen()
        const searchParams = new URLSearchParams(window.location.search)
        const redirectTo = searchParams.get('redirectTo')

        window.location.href = redirectTo || '/r/your-care/health-record'
    }

    return (
        <LoadingScreen
            isLoading={immunizationLoading}
            loadingComponent={<Spinner color="supportText" />}
        >
            <LayoutGrid columns="one">
                <FormProvider {...formMethods}>
                    <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                        <RecommendedImmunizations
                            immunizations={immunizations}
                            isLoading={immunizationLoading}
                            onCreateSuccess={onCreateSuccess}
                            onEditSuccess={onEditSuccess}
                        />
                        <OtherImmunizations
                            immunizations={immunizations}
                            isLoading={immunizationLoading}
                            onCreateSuccess={onCreateSuccess}
                            onEditSuccess={onEditSuccess}
                        />

                        <Button
                            sx={{ mt: 5, mb: 8 }}
                            fullWidth
                            disabled={!formMethods.formState.isValid}
                        >
                            <Flex
                                sx={{
                                    width: '100%',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Text
                                    sx={{
                                        color: 'white',
                                        fontSize: 4,
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        mb: 0
                                    }}
                                >
                                    My Vaccination info is correct
                                </Text>
                                <Text
                                    sx={{
                                        color: 'white',
                                        fontSize: 0,
                                        textAlign: 'center',
                                        mb: 0
                                    }}
                                >
                                    Complete Medical History
                                </Text>
                            </Flex>
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </LoadingScreen>
    )
}
