import { Paragraph } from '@asktia/tia-ui'
import { FC } from 'react'

export const LegalDisclaimer: FC = () => {
    return (
        <>
            <Paragraph
                sx={{
                    fontSize: 0,
                    color: 'supportText',
                    mb: 3
                }}
            >
                This questionnaire is not monitored in real time by a medical or
                mental health professional. If you are experiencing a medical or
                mental health emergency, please call 911 or go to your nearest
                emergency room. If you are experiencing a mental health crisis,
                please call the 988 Suicide & Crisis Hotline.
            </Paragraph>
            <Paragraph
                sx={{
                    fontSize: 0,
                    color: 'supportText',
                    mb: 6
                }}
            >
                For clinical services that you may receive from Tia that are
                independent of Coaching or Mental Health Groups, this
                questionnaire may be used for purposes of assessment,
                diagnostic, or care planning as appropriate.
            </Paragraph>
        </>
    )
}
