import { Form, Input } from '@asktia/tia-ui'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useUserInfo } from 'src/hooks'
import { useUpdateUserInfo } from 'src/hooks/useUserInfo'
import { AccountInfoRow, AccountInfoRowLabel } from './AccountInfoRow'

type FormType =
    | {
          email: string
      }
    | FieldValues

export const EditEmail = () => {
    const { user, isLoading } = useUserInfo()
    const [editing, setEditing] = useState(false)
    const updateUserInfo = useUpdateUserInfo()
    const formMethods = useForm<FormType>({
        defaultValues: {
            email: user?.email || ''
        }
    })

    useEffect(() => {
        if (user && !formMethods.formState.isDirty) {
            formMethods.setValue('email', user.email)
        }
    }, [user, formMethods])

    const enableEditing = () => setEditing(true)

    const saveEmail = async (values: FormType) => {
        await updateUserInfo.mutateAsync(values.email)
        setEditing(false)
    }

    const onCancelForm = () => setEditing(false)

    const onSaveForm = () => formMethods.handleSubmit(saveEmail)()

    return (
        <AccountInfoRow
            title="Email"
            isLoading={isLoading || updateUserInfo.isLoading}
            onEdit={enableEditing}
            onSave={onSaveForm}
            onCancel={onCancelForm}
            defaultEditValue={editing}
        >
            <Form useFormMethods={formMethods} onSubmit={saveEmail}>
                {!editing ? (
                    <AccountInfoRowLabel onClick={enableEditing}>
                        {user?.email}
                    </AccountInfoRowLabel>
                ) : (
                    <Input type="email" name="email" />
                )}
            </Form>
        </AccountInfoRow>
    )
}
