import { Input } from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import * as yup from 'yup'

import { ThemeUIStyleObject } from '@theme-ui/css'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'
import {
    BaseInput,
    IBaseInput
} from 'src/flows/questionnaires/components/Input/BaseInput'
import { useFormContext } from 'react-hook-form'
import {
    BaseInputExtensions,
    getExtensionName
} from 'src/flows/questionnaires/components/Input/BaseInputExtensions'
import { FollowUpQuestionsCard } from '../FollowUpQuestionsCard'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'

export interface IDateInput
    extends Omit<IBaseInput, 'render' | 'readDefaultValue'> {
    placeholder?: string
    customNilnknownText?: string
}

export const DateInput: FC<IDateInput> = ({
    questionnaireItem,
    answersMap,
    variant,
    placeholder,
    customNilnknownText,
    sx,
    triggerSave
}) => {
    const [disabled, setDisabled] = useState(false)
    const { setValue, getValues } = useFormContext()

    // Date inputs don't support placeholder styling
    // So we have to use JavaScript 🤦‍♂️
    const hasValue = !!getValues(questionnaireItem.linkId)
    const defaultValue = answersMap.get(questionnaireItem.linkId)

    useEffect(() => {
        if (!questionnaireItem || !defaultValue) {
            return
        }

        const extensionsName = getExtensionName(questionnaireItem?.linkId)

        if (Array.isArray(defaultValue) && defaultValue.length === 0) {
            setDisabled(true)
            setValue(questionnaireItem?.linkId, [])
            setValue(extensionsName, true)
        }
    }, [defaultValue, getValues])

    const onExtensionChange = (checked: boolean, code?: string) => {
        if (code !== 'nilknown' || !questionnaireItem) {
            return
        }

        setDisabled(checked)

        if (checked) {
            setValue(questionnaireItem.linkId, [])
        }

        triggerSave(questionnaireItem.linkId)
    }

    return (
        <>
            <BaseInput
                questionnaireItem={questionnaireItem}
                answersMap={answersMap}
                variant={variant}
                triggerSave={triggerSave}
                readDefaultValue={answer =>
                    answer && answer.length > 0
                        ? answer[0].valueDate
                        : undefined
                }
                render={props => (
                    <Input
                        {...props}
                        type="date"
                        placeholder={placeholder || 'mm/dd/yyyy'}
                        disabled={disabled}
                        sx={{
                            ...sx,
                            'mb': 3,
                            'color': hasValue ? 'text' : 'placeholder',
                            '::-webkit-calendar-picker-indicator': {
                                filter: 'invert(71%) sepia(2%) saturate(5054%) hue-rotate(319deg) brightness(92%) contrast(68%)'
                            },
                            ':disabled': {
                                'borderColor':
                                    variant === 'info'
                                        ? 'inputOutlineHover'
                                        : undefined,
                                '::-webkit-calendar-picker-indicator': {
                                    visibility: 'visible',
                                    filter: 'invert(70%) sepia(5%) saturate(1779%) hue-rotate(314deg) brightness(91%) contrast(77%)'
                                }
                            }
                        }}
                    />
                )}
            />

            <BaseInputExtensions
                questionnaireItem={questionnaireItem}
                onExtensionChange={onExtensionChange}
                customNilnknownText={customNilnknownText}
            />
        </>
    )
}

export const GluedDateQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    questionnaireResponse,
    sx,
    variant,
    customNilnknownText,
    followupCardSx,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    variant?: 'info'
    customNilnknownText?: string
    followupCardSx?: ThemeUIStyleObject
    sx?: ThemeUIStyleObject
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    if (!questionConfig?.linkId) {
        return null
    }

    let isEnabled = true

    if (questionConfig && !!questionConfig.enableWhen) {
        isEnabled = questionnaireResponse
            ? isItemEnabled(questionConfig!, questionnaireResponse)
            : false
    }

    const shouldRender = questionConfig?.linkId && isEnabled

    if (!shouldRender) {
        return null
    }

    return (
        <FollowUpQuestionsCard hidden={variant !== 'info'} sx={followupCardSx}>
            <DateInput
                questionnaireItem={questionConfig}
                answersMap={answersMap}
                sx={sx}
                variant={variant}
                customNilnknownText={customNilnknownText}
                triggerSave={triggerSave}
            />
        </FollowUpQuestionsCard>
    )
}

declare module 'yup' {
    //@ts-ignore-next-line
    interface StringSchema extends yup.StringSchema {
        dateSmallerThan1900(message: string): StringSchema
    }
}

yup.addMethod(yup.string, 'dateSmallerThan1900', function (errorMessage) {
    return this.test(
        `test-date-smaller-than-1900`,
        errorMessage,
        function (value) {
            const { path, createError } = this

            if (value === '') {
                return true
            }

            if (value && new Date(value) > new Date('1900-01-01')) {
                return true
            }

            return createError({ path, message: errorMessage })
        }
    )
})
