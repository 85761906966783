import { Button, Input } from '@asktia/tia-ui'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Code, QuestionnaireAnswerOption } from '@asktia/tia-fhir-models'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'
import { getFieldName } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { BaseInput, IBaseInput } from './BaseInput'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'
import { FollowUpQuestionsCard } from 'src/flows/questionnaires/components/FollowUpQuestionsCard'

export interface ISingleChoice
    extends Omit<IBaseInput, 'render' | 'readDefaultValue'> {
    fullWidth?: boolean
}

const SingleChoiceRender = (props: {
    inputProps: {
        name: string
        onBlur: () => void
    }
    answerOption?: QuestionnaireAnswerOption[]
    currentValue?: any
    changeValue: (code: Code) => void
    fullWidth?: boolean
}) => {
    return (
        <>
            <Input type="hidden" {...props.inputProps} />

            {props.answerOption?.map((option, i) => {
                const code = option.valueCoding?.code as string
                const selected = props.currentValue === code

                return (
                    <Button
                        key={code}
                        variant="filter"
                        type="button"
                        selected={selected}
                        onClick={() => props.changeValue(code)}
                        sx={{
                            'background': !selected ? 'white' : undefined,
                            'border': '1px solid',
                            'borderColor': selected
                                ? 'transparent'
                                : 'inputOutline',
                            'paddingLeft': '16px !important',
                            'paddingRight': '16px !important',
                            'fontSize': 3,
                            'flex': `1 1 ${props.fullWidth ? '100%' : '160px'}`,
                            '&:hover': {
                                borderColor: !selected
                                    ? 'inputOutlineHover'
                                    : undefined
                            }
                        }}
                    >
                        {option.valueCoding?.display}
                    </Button>
                )
            })}
        </>
    )
}

export const SingleChoice: FC<ISingleChoice> = ({
    questionnaireItem,
    answersMap,
    variant,
    fullWidth,
    sx,
    index,
    triggerSave
}) => {
    const { answerOption } = questionnaireItem
    const { watch, setValue } = useFormContext()

    const name = getFieldName(questionnaireItem, index)
    const currentValue = watch(name)

    function changeValue(code: Code) {
        setValue(name, code)
        triggerSave(name)
    }

    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            variant={variant}
            sx={sx}
            index={index}
            triggerSave={triggerSave}
            readDefaultValue={defaultValue =>
                defaultValue && defaultValue.length > 0
                    ? (defaultValue[0].valueCoding?.code as string)
                    : undefined
            }
            render={props => (
                <SingleChoiceRender
                    inputProps={props}
                    currentValue={currentValue}
                    changeValue={changeValue}
                    answerOption={answerOption}
                    fullWidth={fullWidth}
                />
            )}
        />
    )
}

export const GluedSingleChoiceQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    questionnaireResponse,
    sx,
    index,
    variant,
    fullWidth,
    followupCardSx,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    variant?: 'info'
    fullWidth?: boolean
    sx?: ThemeUIStyleObject
    followupCardSx?: ThemeUIStyleObject
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    let isEnabled = true

    if (questionConfig && !!questionConfig.enableWhen) {
        isEnabled = questionnaireResponse
            ? isItemEnabled(questionConfig!, questionnaireResponse)
            : false
    }

    const shouldRender = questionConfig?.linkId && isEnabled

    if (!shouldRender) {
        return null
    }

    return (
        <FollowUpQuestionsCard hidden={variant !== 'info'} sx={followupCardSx}>
            <SingleChoice
                questionnaireItem={questionConfig}
                answersMap={answersMap}
                sx={sx}
                variant={variant}
                fullWidth={fullWidth}
                index={index}
                triggerSave={triggerSave}
            />
        </FollowUpQuestionsCard>
    )
}
