export function getFormDone(data: any): {
    formDone: boolean
} {
    const getAllValues = (data: any) => {
        const values: any = {}

        const drillDown = (data: any, name?: string) => {
            if (data && typeof data === 'object') {
                if (Object.values(data).length > 0) {
                    const keys = Object.keys(data)
                    keys.forEach(k =>
                        drillDown(data[k], name ? `${name}-${k}` : `${k}`)
                    )
                } else {
                    values[`${name}`] = data
                }
            } else {
                values[name as string] = data
            }
        }

        drillDown(data)

        return values
    }

    const formDone = (flattenedObj: any) => {
        const keys = Object.keys(flattenedObj)

        const badKeys = keys.filter(k => {
            const value = flattenedObj[k]

            if (
                value === null ||
                value === '' ||
                (Array.isArray(value) && value.length === 0)
            ) {
                if (k.includes('complications')) {
                    return false
                } else if (k === 'last-pap-test') {
                    // if user has said pap tests don't apply to them
                    // a date on their last pap test is not required
                    return !flattenedObj['extensions-last-pap-test']
                } else if (k === 'last-sti-test') {
                    return !flattenedObj['extensions-last-sti-test']
                } else if (k === 'menstrual') {
                    return !flattenedObj['extensions-menstrual']
                } else {
                    return true
                }
            } else {
                return false
            }
        })

        return badKeys.length === 0
    }

    return {
        formDone: formDone(getAllValues(data))
    }
}
