import { ThemeStyleObject, Whisper } from '@asktia/tia-ui'

type LoadingCardsProps = {
    /**
     * number of cards to show
     */
    cards: number
    /**
     * number height of the cards
     */
    height: number
    /**
     * ThemeStyleObject to override style of each card
     */
    sx?: ThemeStyleObject
}

/**
 * Render a series of Whisper cards for loading states
 */
export const LoadingCards = ({ cards, height, sx }: LoadingCardsProps) => {
    return (
        <>
            {[...Array(cards).keys()].map((_, idx) => (
                <Whisper
                    key={`loading-card-${idx}`}
                    sx={{
                        height: height,
                        ...sx
                    }}
                />
            ))}
        </>
    )
}
