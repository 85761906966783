import { FC } from 'react'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { NoActionItemsBanner } from './NoActionItemsBanner'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { UpcomingAppointments } from 'src/flows/AppointmentBooking/UpcomingAppointments'
import { AppointmentSuggestions } from './AppointmentSuggestions'
import { Blocks, Box } from '@asktia/tia-ui'
import { CarePlanBanner } from './CarePlanBanner'

export const ActionsToComplete: FC = () => {
    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })
    const { appointments } = useUpcomingAppointments()

    return (
        <Box
            sx={{
                backgroundColor: 'secondaryBackground',
                // custom value because this step isn't in our scale
                pb: [46, 96],
                pt: 3
            }}
        >
            <UpcomingAppointments />

            {!appointments.length && !appointmentSuggestions.length && (
                <NoActionItemsBanner />
            )}

            <AppointmentSuggestions />

            <CarePlanBanner />

            <Blocks.Wave
                position="bottom"
                fill="mainBackground"
                sx={{
                    backgroundColor: 'secondaryBackground'
                }}
            />
        </Box>
    )
}
