import { Flex, Radio, Text } from '@asktia/tia-ui'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import {
    QuestionnaireAnswerOption,
    QuestionnaireItem,
    QuestionnaireResponseAnswer
} from '@asktia/tia-fhir-models'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'
import { getFieldName } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { BaseInput } from 'src/flows/questionnaires/components/Input/BaseInput'

interface IChoiceLegend {
    min: string
    max: string
}

export interface ISingleNumericChoiceRender {
    inputProps: {
        name: string
        onBlur: () => void
    }
    legend?: IChoiceLegend
    currentValue?: number | string
    answerOption?: QuestionnaireAnswerOption[]
    sx?: ThemeUIStyleObject

    changeValue: () => void
}

const SingleNumericChoiceRender: FC<ISingleNumericChoiceRender> = ({
    legend,
    inputProps,
    sx,
    currentValue,
    answerOption,
    changeValue
}) => {
    return (
        <Flex sx={{ flexDirection: 'column', width: '100%', ...sx }}>
            <Flex
                sx={{ gap: 2, width: '100%', justifyContent: 'space-between' }}
            >
                {answerOption?.map((option, i) => {
                    const code = option.valueCoding?.code as string
                    const decimalValue = option.extension?.[0]?.valueDecimal
                    const selected = currentValue === code

                    return (
                        <Radio
                            key={code}
                            name={inputProps.name}
                            value={code}
                            defaultChecked={selected}
                            title={option.valueCoding?.display}
                            onClick={changeValue}
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column-reverse',
                                fontWeight: 400,
                                maxWidth: 40,
                                fontSize: 0,
                                div: {
                                    margin: 0
                                }
                            }}
                            pretty
                        >
                            {decimalValue}
                        </Radio>
                    )
                })}
            </Flex>
            <Flex sx={{ justifyContent: 'space-between', mt: 2 }}>
                <Text variant="supportingInfo" sx={{ fontSize: 0 }}>
                    {legend
                        ? legend.min
                        : answerOption && answerOption[0].valueCoding?.display}
                </Text>
                <Text variant="supportingInfo" sx={{ fontSize: 0 }}>
                    {legend
                        ? legend.max
                        : answerOption &&
                          answerOption[answerOption.length - 1]?.valueCoding
                              ?.display}
                </Text>
            </Flex>
        </Flex>
    )
}

export interface ISingleNumericChoice {
    legend?: IChoiceLegend
    variant?: 'info'
    sx?: ThemeUIStyleObject
    index?: number
    questionnaireItem: QuestionnaireItem
    answersMap: Map<string, QuestionnaireResponseAnswer[]>
    triggerSave: (triggerByFieldName: string) => void
}

export const SingleNumericChoice: FC<ISingleNumericChoice> = ({
    questionnaireItem,
    answersMap,
    variant,
    sx,
    index,
    legend,
    triggerSave
}) => {
    const { answerOption } = questionnaireItem
    const { watch } = useFormContext()

    const name = getFieldName(questionnaireItem, index)
    const currentValue = watch(name)

    function changeValue() {
        triggerSave(name)
    }

    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            variant={variant}
            sx={sx}
            index={index}
            triggerSave={triggerSave}
            readDefaultValue={defaultValue =>
                defaultValue && defaultValue.length > 0
                    ? (defaultValue[0].valueCoding?.code as string)
                    : undefined
            }
            render={props => (
                <SingleNumericChoiceRender
                    inputProps={props}
                    currentValue={currentValue}
                    changeValue={changeValue}
                    answerOption={answerOption}
                    legend={legend}
                />
            )}
        />
    )
}

export const GluedSingleNumericChoiceQuestion = ({
    linkId,
    legend,
    questionnaire,
    answersMap,
    sx,
    index,
    variant,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    legend?: IChoiceLegend
    variant?: 'info'
    fullWidth?: boolean
    sx?: ThemeUIStyleObject
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    return (
        <>
            {questionConfig?.linkId && (
                <SingleNumericChoice
                    questionnaireItem={questionConfig}
                    answersMap={answersMap}
                    legend={legend}
                    sx={sx}
                    variant={variant}
                    index={index}
                    triggerSave={triggerSave}
                />
            )}
        </>
    )
}
