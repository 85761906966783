import { Container, Flex, Grid, Heading, Text } from '@asktia/tia-ui'
import { FC } from 'react'
import { SuggestionItem } from '../SuggestionItem'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { groupBy, uniq } from 'lodash'
import { AppointmentSuggestion } from 'src/types'
import { SuggestionTitle } from './components/SuggestionTitle'

export const AppointmentSuggestions: FC = () => {
    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })

    const appointmentIds = uniq(
        appointmentSuggestions.map(
            x =>
                x?.interventionFollowup?.carePlanIntervention?.originAppointment
                    ?.id
        )
    )
    const groupedSuggestionsByAppointment = groupBy(
        appointmentSuggestions,
        (x: AppointmentSuggestion) =>
            x?.interventionFollowup?.carePlanIntervention?.originAppointment?.id
    )

    return appointmentSuggestions.length > 0 ? (
        <Container>
            <Heading h1 sx={{ pt: 6, mb: 6 }}>
                Next steps from your providers
            </Heading>
            {appointmentIds.map(id => {
                const suggestions =
                    groupedSuggestionsByAppointment[id || 'undefined']

                return (
                    <>
                        <SuggestionTitle suggestions={suggestions} />
                        <Grid
                            key={`${id}-suggestion`}
                            gap={4}
                            sx={{ alignItems: 'flex-start' }}
                            columns={['1fr', '1fr 1fr 1fr']}
                            pt="4"
                            mb={4}
                        >
                            {suggestions.map(suggestion => {
                                return (
                                    <SuggestionItem
                                        key={`${suggestion.uuid}`}
                                        {...suggestion}
                                    />
                                )
                            })}
                        </Grid>
                    </>
                )
            })}
        </Container>
    ) : (
        <></>
    )
}
