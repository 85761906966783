import { useParams } from 'react-router-dom'
import { Box, Button, Link, Flex, Heading, Paragraph } from '@asktia/tia-ui'
import { differenceInHours } from 'date-fns'
import { useNavigation } from 'src/hooks'
import { useAppointmentChangeModal } from './useAppointmentChangeModal'
import { useAppointment } from 'src/hooks/useAppointment'
import { useAppointmentCancellationPolicy } from 'src/hooks/useAppointmentCancellationPolicy'

export const LinkAlreadyUsed = () => {
    const { navigate } = useNavigation()
    const { appointmentId } = useParams<{
        appointmentId: string
    }>()
    const { appointment, isLoading } = useAppointment(appointmentId)
    const { showModal } = useAppointmentChangeModal({
        appointmentId: appointmentId,
        returnUrl: '/r/chat'
    })
    const { appointmentCancellationPolicy } = useAppointmentCancellationPolicy(
        appointment?.appointmentProfileUuid
    )

    const remainingHoursToAppointment = appointment
        ? differenceInHours(appointment?.scheduledTime, new Date())
        : undefined

    const onCancelClick = () => {
        if (!appointmentCancellationPolicy) {
            // no-op if we don't know the rules yet
            return
        }

        if (
            remainingHoursToAppointment &&
            remainingHoursToAppointment <=
                appointmentCancellationPolicy?.cutoffHours
        ) {
            showModal()
        } else {
            navigate(
                `/change-appointment/${appointment?.id}?returnUrl=/r/book-appointment`
            )
        }
    }

    return (
        <Box>
            <Heading h3>Link no longer active</Heading>
            <Paragraph sx={{ mt: 4, mb: 6 }}>
                It looks like this link has already been used. If you would like
                to cancel or re-schedule your appointment, you may do that here
            </Paragraph>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Button
                    onClick={onCancelClick}
                    disabled={isLoading || !appointment}
                >
                    Cancel or change appointment
                </Button>
                {appointment && (
                    <Link
                        href={`tel:${appointment?.clinic.phoneNumber}`}
                        sx={{ mt: 4 }}
                    >
                        Urgent? Give us a call
                    </Link>
                )}
            </Flex>
        </Box>
    )
}
