import { Flex, Heading, Paragraph, Box } from '@asktia/tia-ui'
import { FC, ReactNode } from 'react'

export interface IQuestionnairePage {
    title: string
    category: string
    children: ReactNode
}

export const QuestionnairePage: FC<IQuestionnairePage> = ({
    title,
    category,
    children
}) => {
    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Flex
                backgroundColor="secondaryBackground"
                sx={{
                    mb: 6,
                    height: 106,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Heading h1 sx={{ mb: 2 }}>
                    {title}
                </Heading>
                <Paragraph
                    sx={{
                        variant: 'text.supportingInfo',
                        fontSize: '12px',
                        lineHeight: '16px',
                        mb: 0
                    }}
                >
                    {category}
                </Paragraph>
            </Flex>
            <Box sx={{ px: 5 }}>{children}</Box>
        </Flex>
    )
}
