// Using the konmari method of code organization,
// util is where we put code that does not spark joy
//
// Good candidates for a commons lib
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns'
import { formatToTimeZone } from 'date-fns-timezone'
import gPhoneNumber from 'google-libphonenumber'
import * as yup from 'yup'
import { TIA_HOLIDAYS } from 'src/globals'
import { AppointmentWithLocationDetails, HealthRecordEntry } from 'src/types'

export function phoneValidation(value: string | null | undefined): boolean {
    if (!value) {
        return false
    }

    const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()
    try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'US')

        return phoneUtil.isValidNumber(phoneNumber)
    } catch {
        return false
    }
}

export function formatPhoneNumber(
    phoneNumber: string,
    format = gPhoneNumber.PhoneNumberFormat.E164
): string {
    const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()
    return phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phoneNumber, 'US'),
        format
    )
}

// titleize borrowed from https://gist.github.com/cskevint/5817477
// adapted to TS and modern code
export function titleize(sentence: string) {
    if (!sentence.split) {
        return sentence
    }

    const _titleizeWord = function (string: string) {
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            )
        },
        result: string[] = []

    sentence
        .replace(/[_-]/, ' ')
        .split(' ')
        .forEach(function (w) {
            result.push(_titleizeWord(w))
        })

    return result.join(' ')
}

// locale string list from https://stackoverflow.com/a/9893752
// changes made:
// M -> mm
// MM -> mm
// d -> dd
export function getLocaleDateString() {
    const formats: { [key: string]: string } = {
        'ar-sa': 'dd/mm/yy',
        'bg-bg': 'dd.mm.yyyy',
        'ca-es': 'dd/mm/yyyy',
        'zh-tw': 'yyyy/mm/dd',
        'cs-cz': 'dd.mm.yyyy',
        'da-dk': 'dd-mm-yyyy',
        'de-de': 'dd.mm.yyyy',
        'el-gr': 'dd/mm/yyyy',
        'en-us': 'mm/dd/yyyy',
        'fi-fi': 'dd.mm.yyyy',
        'fr-fr': 'dd/mm/yyyy',
        'he-il': 'dd/mm/yyyy',
        'hu-hu': 'yyyy. mm. dd.',
        'is-is': 'dd.mm.yyyy',
        'it-it': 'dd/mm/yyyy',
        'ja-jp': 'yyyy/mm/dd',
        'ko-kr': 'yyyy-mm-dd',
        'nl-nl': 'dd-mm-yyyy',
        'nb-no': 'dd.mm.yyyy',
        'pl-pl': 'yyyy-mm-dd',
        'pt-br': 'dd/mm/yyyy',
        'ro-ro': 'dd.mm.yyyy',
        'ru-ru': 'dd.mm.yyyy',
        'hr-hr': 'dd.mm.yyyy',
        'sk-sk': 'dd. mm. yyyy',
        'sq-al': 'yyyy-mm-dd',
        'sv-se': 'yyyy-mm-dd',
        'th-th': 'dd/mm/yyyy',
        'tr-tr': 'dd.mm.yyyy',
        'ur-pk': 'dd/mm/yyyy',
        'id-id': 'dd/mm/yyyy',
        'uk-ua': 'dd.mm.yyyy',
        'be-by': 'dd.mm.yyyy',
        'sl-si': 'dd.mm.yyyy',
        'et-ee': 'dd.mm.yyyy',
        'lv-lv': 'yyyy.mm.dd.',
        'lt-lt': 'yyyy.mm.dd',
        'fa-ir': 'mm/dd/yyyy',
        'vi-vn': 'dd/mm/yyyy',
        'hy-am': 'dd.mm.yyyy',
        'az-latn-az': 'dd.mm.yyyy',
        'eu-es': 'yyyy/mm/dd',
        'mk-mk': 'dd.mm.yyyy',
        'af-za': 'yyyy/mm/dd',
        'ka-ge': 'dd.mm.yyyy',
        'fo-fo': 'dd-mm-yyyy',
        'hi-in': 'dd-mm-yyyy',
        'ms-my': 'dd/mm/yyyy',
        'kk-kz': 'dd.mm.yyyy',
        'ky-kg': 'dd.mm.yy',
        'sw-ke': 'mm/dd/yyyy',
        'uz-latn-uz': 'dd/mm yyyy',
        'tt-ru': 'dd.mm.yyyy',
        'pa-in': 'dd-mm-yy',
        'gu-in': 'dd-mm-yy',
        'ta-in': 'dd-mm-yyyy',
        'te-in': 'dd-mm-yy',
        'kn-in': 'dd-mm-yy',
        'mr-in': 'dd-mm-yyyy',
        'sa-in': 'dd-mm-yyyy',
        'mn-mn': 'yy.mm.dd',
        'gl-es': 'dd/mm/yy',
        'kok-in': 'dd-mm-yyyy',
        'syr-sy': 'dd/mm/yyyy',
        'dv-mv': 'dd/mm/yy',
        'ar-iq': 'dd/mm/yyyy',
        'zh-cn': 'yyyy/M/d',
        'de-ch': 'dd.mm.yyyy',
        'en-gb': 'dd/mm/yyyy',
        'es-mx': 'dd/mm/yyyy',
        'fr-be': 'd/mm/yyyy',
        'it-ch': 'dd.mm.yyyy',
        'nl-be': 'd/mm/yyyy',
        'nn-no': 'dd.mm.yyyy',
        'pt-pt': 'dd-mm-yyyy',
        'sr-latn-cs': 'dd.mm.yyyy',
        'sv-fi': 'd.M.yyyy',
        'az-cyrl-az': 'dd.mm.yyyy',
        'ms-bn': 'dd/mm/yyyy',
        'uz-cyrl-uz': 'dd.mm.yyyy',
        'ar-eg': 'dd/mm/yyyy',
        'zh-hk': 'dd/mm/yyyy',
        'de-at': 'dd.mm.yyyy',
        'en-au': 'd/mm/yyyy',
        'es-es': 'dd/mm/yyyy',
        'fr-ca': 'yyyy-mm-dd',
        'sr-cyrl-cs': 'dd.mm.yyyy',
        'ar-ly': 'dd/mm/yyyy',
        'zh-sg': 'dd/mm/yyyy',
        'de-lu': 'dd.mm.yyyy',
        'en-ca': 'dd/mm/yyyy',
        'es-gt': 'dd/mm/yyyy',
        'fr-ch': 'dd.mm.yyyy',
        'ar-dz': 'dd-mm-yyyy',
        'zh-mo': 'd/M/yyyy',
        'de-li': 'dd.mm.yyyy',
        'en-nz': 'd/mm/yyyy',
        'es-cr': 'dd/mm/yyyy',
        'fr-lu': 'dd/mm/yyyy',
        'ar-ma': 'dd-mm-yyyy',
        'en-ie': 'dd/mm/yyyy',
        'es-pa': 'mm/dd/yyyy',
        'fr-mc': 'dd/mm/yyyy',
        'ar-tn': 'dd-mm-yyyy',
        'en-za': 'yyyy/mm/dd',
        'es-do': 'dd/mm/yyyy',
        'ar-om': 'dd/mm/yyyy',
        'en-jm': 'dd/mm/yyyy',
        'es-ve': 'dd/mm/yyyy',
        'ar-ye': 'dd/mm/yyyy',
        'en-029': 'mm/dd/yyyy',
        'es-co': 'dd/mm/yyyy',
        'ar-sy': 'dd/mm/yyyy',
        'en-bz': 'dd/mm/yyyy',
        'es-pe': 'dd/mm/yyyy',
        'ar-jo': 'dd/mm/yyyy',
        'en-tt': 'dd/mm/yyyy',
        'es-ar': 'dd/mm/yyyy',
        'ar-lb': 'dd/mm/yyyy',
        'en-zw': 'm/dd/yyyy',
        'es-ec': 'dd/mm/yyyy',
        'ar-kw': 'dd/mm/yyyy',
        'en-ph': 'mm/dd/yyyy',
        'es-cl': 'dd-mm-yyyy',
        'ar-ae': 'dd/mm/yyyy',
        'es-uy': 'dd/mm/yyyy',
        'ar-bh': 'dd/mm/yyyy',
        'es-py': 'dd/mm/yyyy',
        'ar-qa': 'dd/mm/yyyy',
        'es-bo': 'dd/mm/yyyy',
        'es-sv': 'dd/mm/yyyy',
        'es-hn': 'dd/mm/yyyy',
        'es-ni': 'dd/mm/yyyy',
        'es-pr': 'dd/mm/yyyy',
        'am-et': 'd/M/yyyy',
        'tzm-latn-dz': 'dd-mm-yyyy',
        'iu-latn-ca': 'd/mm/yyyy',
        'sma-no': 'dd.mm.yyyy',
        'mn-mong-cn': 'yyyy/M/d',
        'gd-gb': 'dd/mm/yyyy',
        'en-my': 'd/M/yyyy',
        'prs-af': 'dd/mm/yy',
        'bn-bd': 'dd-mm-yy',
        'wo-sn': 'dd/mm/yyyy',
        'rw-rw': 'mm/dd/yyyy',
        'qut-gt': 'dd/mm/yyyy',
        'sah-ru': 'mm.dd.yyyy',
        'gsw-fr': 'dd/mm/yyyy',
        'co-fr': 'dd/mm/yyyy',
        'oc-fr': 'dd/mm/yyyy',
        'mi-nz': 'dd/mm/yyyy',
        'ga-ie': 'dd/mm/yyyy',
        'se-se': 'yyyy-mm-dd',
        'br-fr': 'dd/mm/yyyy',
        'smn-fi': 'd.M.yyyy',
        'moh-ca': 'M/d/yyyy',
        'arn-cl': 'dd-mm-yyyy',
        'ii-cn': 'yyyy/M/d',
        'dsb-de': 'dd. mm. yyyy',
        'ig-ng': 'dd/mm/yyyy',
        'kl-gl': 'dd-mm-yyyy',
        'lb-lu': 'dd/mm/yyyy',
        'ba-ru': 'dd.mm.yy',
        'nso-za': 'yyyy/mm/dd',
        'quz-bo': 'dd/mm/yyyy',
        'yo-ng': 'dd/mm/yyyy',
        'ha-latn-ng': 'dd/mm/yyyy',
        'fil-ph': 'mm/dd/yyyy',
        'ps-af': 'dd/mm/yy',
        'fy-nl': 'dd-mm-yyyy',
        'ne-np': 'mm/dd/yyyy',
        'se-no': 'dd.mm.yyyy',
        'iu-cans-ca': 'dd/mm/yyyy',
        'sr-latn-rs': 'dd.mm.yyyy',
        'si-lk': 'yyyy-mm-dd',
        'sr-cyrl-rs': 'dd.mm.yyyy',
        'lo-la': 'dd/mm/yyyy',
        'km-kh': 'yyyy-mm-dd',
        'cy-gb': 'dd/mm/yyyy',
        'bo-cn': 'yyyy/mm/dd',
        'sms-fi': 'dd.mm.yyyy',
        'as-in': 'dd-mm-yyyy',
        'ml-in': 'dd-mm-yy',
        'en-in': 'dd-mm-yyyy',
        'or-in': 'dd-mm-yy',
        'bn-in': 'dd-mm-yy',
        'tk-tm': 'dd.mm.yy',
        'bs-latn-ba': 'dd.mm.yyyy',
        'mt-mt': 'dd/mm/yyyy',
        'sr-cyrl-me': 'dd.mm.yyyy',
        'se-fi': 'dd.mm.yyyy',
        'zu-za': 'yyyy/mm/dd',
        'xh-za': 'yyyy/mm/dd',
        'tn-za': 'yyyy/mm/dd',
        'hsb-de': 'dd. mm. yyyy',
        'bs-cyrl-ba': 'dd.mm.yyyy',
        'tg-cyrl-tj': 'dd.mm.yy',
        'sr-latn-ba': 'dd.mm.yyyy',
        'smj-no': 'dd.mm.yyyy',
        'rm-ch': 'dd/mm/yyyy',
        'smj-se': 'yyyy-mm-dd',
        'quz-ec': 'dd/mm/yyyy',
        'quz-pe': 'dd/mm/yyyy',
        'hr-ba': 'dd.mm.yyyy.',
        'sr-latn-me': 'dd.mm.yyyy',
        'sma-se': 'yyyy-mm-dd',
        'en-sg': 'dd/mm/yyyy',
        'ug-cn': 'yyyy-mm-dd',
        'sr-cyrl-ba': 'dd.mm.yyyy',
        'es-us': 'mm/dd/yyyy'
    }

    return formats[navigator.language.toLowerCase()] || 'mm/dd/yyyy'
}

export const yupBirthdateSchema = yup
    .date()
    .typeError(`Please enter a valid date - ${getLocaleDateString()}`)
    .min(new Date(1900, 0, 0), 'Date of Birth must be after Jan 1st, 1900')
    .max(subYears(new Date(), 18), 'You must be older than 18')

const getDay = (date: Date, timezone: string): string =>
    formatToTimeZone(date, 'ddd, MMM D', { timeZone: timezone })

const getDayOfWeek = (
    date: Date,
    timezone: string,
    verbose?: boolean
): string =>
    formatToTimeZone(date, verbose ? 'dddd' : 'ddd', { timeZone: timezone })

const getTime = (date: Date, timezone: string): string =>
    formatToTimeZone(date, `h:mma`, { timeZone: timezone })

export const getShortTimezoneAbbr = (date: Date, timezone: string): string => {
    const abbr = formatToTimeZone(date, 'z', { timeZone: timezone })
    return `${abbr[0]}${abbr[2]}`
}

const browserTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const browserInUSTimezone = (): boolean => {
    // From https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    return [
        // Alaska
        'US/Alaska',
        'US/Aleutian',
        'America/Adak',
        'America/Anchorage',
        'America/Atka',
        'America/Juneau',
        'America/Metlakatla',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
        // Hawaii
        'Pacific/Honolulu',
        'US/Hawaii',
        // Continental
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Fort_Wayne',
        'America/Indiana',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Knox_IN',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Los_Angeles',
        'America/Louisville',
        'America/Menominee',
        'America/New_York',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'US/Arizona',
        'America/Phoenix',
        'America/Shiprock',
        'Navajo',
        'US/Central',
        'US/East-Indiana',
        'US/Eastern',
        'US/Indiana-Starke',
        'US/Michigan',
        'US/Mountain',
        'US/Pacific'
    ].includes(browserTimezone())
}

/**
 * Convert to standard display format and timezone
 * - if the browser is located outside the US, use the passed in default timezone
 * @param date
 * @param defaultTimezone - IANA timezone (e.g. America/New_York)
 * @param verbose - provide verbose dates
 */
export function getDisplayTimestamp(
    date: Date,
    defaultTimezone: string,
    verbose?: boolean
) {
    let timezone = defaultTimezone
    if (browserInUSTimezone()) {
        timezone = browserTimezone()
    }

    return {
        day: getDay(date, timezone),
        dayOfWeek: getDayOfWeek(date, timezone, verbose),
        time: getTime(date, timezone),
        timezone: getShortTimezoneAbbr(date, timezone)
    }
}

// TODO: add UT
export function extendHealthRecord(
    type: 'goal' | 'health-record' | 'ros' | 'feedback',
    entries: HealthRecordEntry[] = [],
    appointmentId: string,
    redirectUrl: string,
    appointmentProfileUuid?: string,
    profileLabel?: string
) {
    return entries.map(entry => {
        const params = new URLSearchParams()

        let url = `/intake/${type}/${entry.name}`

        if (type === 'ros' || type === 'feedback') {
            url = `${url}/${appointmentId}`

            params.set('redirectTo', redirectUrl)
        } else {
            params.set('redirectTo', redirectUrl)
            params.set('apptId', appointmentId)
        }

        if (profileLabel) {
            params.set('profile', encodeURIComponent(profileLabel))
        }

        // TODO: smells like we could unify the redirectTo and onCompleteRelativePath stuff

        if (entry.name === 'medical-conditions') {
            url = '/r/uhr/medical-conditions'

            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/uhr`
            )
        }

        if (entry.name === 'personal-wellness') {
            url = `/r/uhr/personal-wellness`

            if (appointmentProfileUuid) {
                params.set('appointmentProfileUuid', appointmentProfileUuid)
            }
            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/uhr`
            )
        }

        if (entry.name === 'gynecology') {
            url = `/r/uhr/gynecology`

            if (appointmentProfileUuid) {
                params.set('appointmentProfileUuid', appointmentProfileUuid)
            }
            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/uhr`
            )
        }

        if (
            entry.name === 'qol-5' ||
            entry.name === 'gad-7' ||
            entry.name === 'phq-9'
        ) {
            url = `/r/mental-health/${entry.name}`

            if (appointmentProfileUuid) {
                params.set('appointmentProfileUuid', appointmentProfileUuid)
            }
            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/pre_appt_info`
            )
        }

        if (entry.name === 'som-6') {
            url = `/r/physical-health/${entry.name}`

            if (appointmentProfileUuid) {
                params.set('appointmentProfileUuid', appointmentProfileUuid)
            }
            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/pre_appt_info`
            )
        }

        if (entry.name === 'ros') {
            url = `/r/physical-health/${entry.name}-1`

            if (appointmentProfileUuid) {
                params.set('appointmentProfileUuid', appointmentProfileUuid)
            }
            params.set(
                'onCompleteRelativePath',
                `/automated-checkin/${appointmentId}/pre_appt_info`
            )
        }

        url = `${url}?${params.toString()}`

        return {
            ...entry,
            url
        }
    })
}

export function extendAppointment(
    json: AppointmentWithLocationDetails
): AppointmentWithLocationDetails {
    const appointment: AppointmentWithLocationDetails = {
        ...(json as AppointmentWithLocationDetails),
        scheduledTime: new Date(json.scheduledTime as unknown as string),
        uhr: {
            ...json.uhr,
            goals: extendHealthRecord(
                'goal',
                json.uhr?.goals,
                json.id,
                `/r/appointment/${json.id}/prepare/intake`,
                json.label
            ),
            records: extendHealthRecord(
                'health-record',
                json.uhr?.records,
                json.id,
                `/r/appointment/${json.id}/prepare/uhr`
            )
        }
    }
    return appointment
}

export function checkHoliday(date: Date, timezone?: string) {
    const dateFmt = formatToTimeZone(date, 'MM/DD/YYYY', {
        timeZone: timezone || 'America/New_York'
    })

    return TIA_HOLIDAYS.includes(dateFmt)
}

export function isCampTiaDay(date: Date) {
    return (
        isAfter(date, startOfDay(new Date('2022-04-12T00:00+07:00'))) &&
        isBefore(date, endOfDay(new Date('2022-04-14T23:59+07:00')))
    )
}

export const getFullStateName = (state: string | undefined) => {
    switch (state) {
        case 'CA':
            return 'California'
        case 'NY':
            return 'New York'
        case 'AZ':
            return 'Arizona'
        default:
            return ''
    }
}

export const getRefinedDate = function (dateString: string, part = false) {
    const dateTime = new Date(dateString)
    const now = new Date()

    const diffMinutes = (now.getTime() - dateTime.getTime()) / (60 * 1000)
    const diffHours = diffMinutes / 60
    const diffDays = diffHours / 24
    let strMinutes = dateTime.getMinutes().toString()
    let strMonth = dateTime.getMonth().toString()

    let formattedTime

    if (diffMinutes < 2) {
        formattedTime = `${part ? 'a' : 'A'} minute ago`
    } else if (diffMinutes < 60) {
        formattedTime = `${Math.floor(diffMinutes)} mins ago`
    } else if (diffHours < 1.5) {
        formattedTime = `${part ? 'a' : 'A'}n hour ago`
    } else if (diffHours < 24) {
        const floor = Math.floor(diffHours)
        if (floor === 1) {
            formattedTime = `${part ? 'a' : 'A'}n hour ago`
        } else {
            formattedTime = `${floor} hours ago`
        }
    } else if (diffDays === 1) {
        const hours = dateTime.getHours()
        const minutes = dateTime.getMinutes()
        if (minutes < 10) {
            strMinutes = `0${minutes}`
        }
        const amPm = hours > 12 ? 'pm' : 'am'
        formattedTime = `${
            part ? 'y' : 'Y'
        }esterday @ ${hours}:${minutes}${amPm}`
    } else {
        let hours = dateTime.getHours()
        const minutes = dateTime.getMinutes()
        if (minutes < 10) {
            strMinutes = `0${minutes}`
        }
        let amPm = 'am'
        if (hours > 12) {
            hours = hours - 12
            amPm = 'pm'
        } else if (hours == 0) {
            hours = 12
        }
        const month = dateTime.getMonth()
        const day = dateTime.getDate()
        switch (month) {
            case 0:
                strMonth = 'Jan'
                break
            case 1:
                strMonth = 'Feb'
                break
            case 2:
                strMonth = 'Mar'
                break
            case 3:
                strMonth = 'Apr'
                break
            case 4:
                strMonth = 'May'
                break
            case 5:
                strMonth = 'Jun'
                break
            case 6:
                strMonth = 'Jul'
                break
            case 7:
                strMonth = 'Aug'
                break
            case 8:
                strMonth = 'Sep'
                break
            case 9:
                strMonth = 'Oct'
                break
            case 10:
                strMonth = 'Nov'
                break
            case 11:
                strMonth = 'Dec'
                break
        }

        let year = ''
        if (dateTime.getFullYear() < now.getFullYear()) {
            year += ` ${dateTime.getFullYear()}`
        }

        formattedTime = `${strMonth} ${day}${year}, ${hours}:${strMinutes}${amPm}`
    }

    return formattedTime
}

export function isMobile() {
    const screenWidth = window.screen.width
    return screenWidth < 640
}
