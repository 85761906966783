import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

const NOT_FOUND = 'Flu shot status not found'

async function getFluShotStatusForPatient({
    patientId
}: {
    patientId: number
}) {
    return fetcher(
        `${BASE_API_URL}/tmd-api/appointments/flu-shot/${patientId}?apiVersion=v2`,
        undefined,
        'GET',
        {
            customErrorHandler: res => {
                if (res.status === 404) {
                    return new Error(NOT_FOUND)
                } else {
                    return new Error(res.statusText)
                }
            },
            errorMsg: 'Error fetching flu shot status for patient'
        }
    )
}

type FluShot = {
    answer: boolean
    patientId: number
}

export function useFluShotStatus(patientId?: number) {
    const response = useQuery<FluShot>(
        ['flu-shot', patientId],
        () =>
            getFluShotStatusForPatient({
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                patientId: patientId!
            }),
        {
            staleTime: Infinity,
            retry: (count, error: unknown) => {
                return (error as Error).message !== NOT_FOUND && count < 1
            },
            refetchOnWindowFocus: false,
            enabled: !!patientId
        }
    )

    return {
        fluShotResponse: response.data,
        isLoading: response.isLoading
    }
}
