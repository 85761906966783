import {
    Form,
    Heading,
    LabeledCheckbox,
    LayoutGrid,
    Spinner,
    Text,
    Box
} from '@asktia/tia-ui'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { FooterButton } from 'src/components/Blocks'
import { Show404 } from 'src/flows/404'
import { AppointmentTreatment } from 'src/types'
import { BookingCardDetails } from '../BookingCard'
import { useBookingFlow } from '../useBookingFlow'
import { useAmpli } from 'src/hooks'
import { View } from 'src/components/Blocks/View'

const question = 'Do you want to add anything to your appointment'
const compareTreatments = (
    a: AppointmentTreatment,
    b: AppointmentTreatment
): number => {
    if (a.name > b.name) {
        return 1
    } else if (a.name < b.name) {
        return -1
    } else {
        return 0
    }
}

type FormValues =
    | {
          selectedTreatmentIds: string[]
      }
    | FieldValues

function useBookingTreatment() {
    const formMethods = useForm<FormValues>()
    const {
        appointmentProfile,
        isLoading,
        selectedTreatments,
        saveTreatments,
        navigateToNextStep
    } = useBookingFlow()
    const { postQuestionSelected, postQuestionSubmitted } = useAmpli()
    const treatments = appointmentProfile?.treatments
    const selectedTreatmentIds =
        formMethods.watch('selectedTreatmentIds') ||
        selectedTreatments.map(treatment => `${treatment.id}`)
    const stringifiedSelectedTreatmentIds = selectedTreatmentIds.join(',')
    useEffect(() => {
        postQuestionSelected(question, stringifiedSelectedTreatmentIds)
    }, [stringifiedSelectedTreatmentIds, postQuestionSelected])

    if (!isLoading && !treatments) {
        navigateToNextStep()
        // Keep showing spinner while navigation happens
        return {
            formMethods,
            onSubmit,
            isLoading: true
        }
    }

    function onSubmit({ selectedTreatmentIds }: FormValues) {
        const selected =
            treatments?.filter(treatment =>
                selectedTreatmentIds.includes(treatment.id.toString())
            ) || []

        saveTreatments(selected)
        postQuestionSubmitted(
            question,
            selected
                .map(treatment => {
                    return treatment.id.toString()
                })
                .join(',')
        )
        navigateToNextStep()
    }

    return {
        isLoading,
        appointmentProfile,
        treatments,
        onSubmit,
        formMethods,
        selectedTreatmentIds: selectedTreatments.map(treatment => treatment.id)
    }
}

export const BookingTreatmentSelection = () => {
    const {
        isLoading,
        appointmentProfile,
        treatments,
        formMethods,
        onSubmit,
        selectedTreatmentIds
    } = useBookingTreatment()

    if (isLoading) {
        return <Spinner />
    }

    if (!appointmentProfile || !treatments) {
        return <Show404 redirectToCareCoordinator />
    }

    return (
        <View withFooter>
            <LayoutGrid columns="two">
                <Box>
                    <BookingCardDetails
                        appointmentProfile={appointmentProfile}
                        selectedTreatments={[]}
                    />
                </Box>
                <Form
                    id="treatmentForm"
                    onSubmit={onSubmit}
                    useFormMethods={formMethods}
                >
                    <Heading h3 sx={{ mb: 2 }}>
                        {question}?
                    </Heading>
                    {appointmentProfile.pricingDetails?.treatmentPrice && (
                        <Text sx={{ mb: 4, fontSize: 2, color: 'supportText' }}>
                            +$
                            {appointmentProfile.pricingDetails.treatmentPrice.toFixed(
                                2
                            )}{' '}
                            each
                        </Text>
                    )}

                    <Box sx={{ mb: [0, 4] }}>
                        {treatments.sort(compareTreatments).map(treatment => (
                            <LabeledCheckbox
                                name="selectedTreatmentIds"
                                key={treatment.id}
                                value={treatment.id}
                                sx={{ mb: 4 }}
                                defaultChecked={selectedTreatmentIds?.includes(
                                    treatment.id
                                )}
                            >
                                {treatment.name}
                            </LabeledCheckbox>
                        ))}
                    </Box>

                    <FooterButton type="submit">Next</FooterButton>
                </Form>
            </LayoutGrid>
        </View>
    )
}
