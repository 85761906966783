import { useParams, useLocation } from 'react-router-dom'
import {
    Button,
    Container,
    Flex,
    LayoutGrid,
    Spinner,
    Text
} from '@asktia/tia-ui'
import { StandardPageHeader } from 'src/components/PageHeader'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { Body } from 'src/components/Blocks/Body'
import { FC, Suspense, useEffect } from 'react'
import { useAppointment } from 'src/hooks/useAppointment'
import { AppointmentInfo } from './AppoinmentInfo'
import { BillSummary } from './BillSummary/index'
import { useInvoices } from 'src/hooks/useInvoices'
import Maintenance from 'src/pages/AppointmentHistory/maintenance'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

const InnerDetails: FC<{ appointmentUuid: string }> = ({ appointmentUuid }) => {
    const { invoices } = useInvoices() // Not passing referenceId to reuse the cache from the previous page
    const { appointment } = useAppointment(appointmentUuid, { suspense: true })

    const encounterInvoice = invoices?.find(
        invoice => invoice.referenceId === appointmentUuid
    )
    const hasInvoice = !!encounterInvoice

    return (
        <>
            <AppointmentInfo appointment={appointment} />
            {appointment?.claimId && appointment?.scheduledTime && (
                <BillSummary
                    claimId={appointment?.claimId}
                    scheduledTime={appointment.scheduledTime}
                    hasInvoice={hasInvoice}
                    encounterInvoice={encounterInvoice}
                />
            )}
        </>
    )
}

const EncounterDetail = () => {
    const { appointmentUuid = '' } = useParams<{
        appointmentUuid: string
    }>()

    const location = useLocation()

    const maintenanceModeForNonTotal =
        useAmpliFeatureFlag('billing-maintenance-for-non-total') === 'on'

    const maintenanceModeForTotal =
        useAmpliFeatureFlag('billing-maintenance-for-total') === 'on'

    // this page is shared between both the new and the old version
    // which have their own maintenance flags
    const maintenanceMode = location.pathname.includes(
        'appointment-billing-list'
    )
        ? maintenanceModeForTotal
        : maintenanceModeForNonTotal

    return (
        <Body>
            <StandardPageHeader />
            <NavBarHeading />
            <Container>
                {maintenanceMode ? (
                    <Maintenance />
                ) : (
                    <LayoutGrid columns="one">
                        <Suspense fallback={<Spinner />}>
                            <InnerDetails appointmentUuid={appointmentUuid} />
                        </Suspense>
                    </LayoutGrid>
                )}
            </Container>
        </Body>
    )
}

export default EncounterDetail
