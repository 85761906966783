import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import {
    Box,
    Container,
    LayoutGrid,
    Paragraph,
    Spinner,
    Text,
    Heading,
    Card,
    Link
} from '@asktia/tia-ui'
import { THREAD_LABELS } from 'src/globals'

import { Link as RouterLink } from 'react-router-dom'

import { HeaderChatButton, HeaderYourCareButton } from 'src/components/Blocks'
import { useCarePlanIntervention } from 'src/hooks/useCarePlanIntervention'
import { CenteredSpinner } from 'src/pages/YourCare/HealthRecord'
import { Intervention } from 'src/types'
import { PageHeader } from 'src/components/PageHeader'
import Linkify from 'src/components/Linkify'
import { useAmpli, useUserInfo } from 'src/hooks'
import { Body } from 'src/components/Blocks/Body'
import { BackButton } from 'src/components/Blocks/BackButton'
import { useAppointment } from 'src/hooks/useAppointment'
import { EncounterCard } from 'src/pages/AppointmentHistory/components/EncounterCard'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'

const DetailSection: FC<React.PropsWithChildren<{ title: string }>> = ({
    title,
    children
}) => {
    if (!children) {
        return null
    }

    return (
        <Card
            sx={{
                mb: 4,
                py: 4
            }}
        >
            <Text sx={{ color: 'supportText' }} variant="eyebrow">
                {title}
            </Text>
            <Paragraph variant="h3" sx={{ mt: 2, mb: 0 }}>
                {children}
            </Paragraph>
        </Card>
    )
}

const DetailInfo = (props: {
    intervention: Intervention
    appointmentId?: string
}) => {
    const { carePlanIntervention } = props.intervention
    const { appointment, isLoading: isLoadingAppointment } = useAppointment(
        props.appointmentId
    )
    const { user } = useUserInfo()
    const { ampli } = useAmpli()

    const interventionDetailsReady = props.appointmentId
        ? !isLoadingAppointment
        : !props.appointmentId

    return (
        <Box sx={{ mt: [5, 6] }}>
            {appointment && (
                <>
                    <Heading h2 sx={{ mb: 1 }}>
                        From
                    </Heading>
                    <EncounterCard
                        variant="with-photo"
                        appointment={appointment}
                        sx={{ mb: 4 }}
                    />
                </>
            )}
            {interventionDetailsReady &&
                (carePlanIntervention.patientEducation ||
                    carePlanIntervention.prescriptionDirections) && (
                    <>
                        <Heading h2 sx={{ mb: 1 }}>
                            Details
                        </Heading>
                        <DetailSection
                            title={
                                props?.intervention?.originActor?.displayName
                                    ? `${props.intervention.originActor.displayName}:`
                                    : ''
                            }
                        >
                            <Linkify
                                sx={{
                                    fontFamily: 'body',
                                    fontSize: 3,
                                    fontWeight: 400,
                                    lineHeight: '125%'
                                }}
                                linkSx={{
                                    fontSize: 3,
                                    fontWeight: '400 !important'
                                }}
                            >
                                {carePlanIntervention.patientEducation ||
                                    carePlanIntervention.prescriptionDirections}
                            </Linkify>
                        </DetailSection>
                        <Paragraph sx={{ mb: 4, fontSize: 0 }}>
                            Have questions about your care plan?{' '}
                            <Link
                                as={RouterLink}
                                onClick={() => {
                                    ampli.questionAboutCarePlanDetailsClicked({
                                        intervention: carePlanIntervention.uuid,
                                        userID:
                                            `${user?.id}` ||
                                            'somehow didnt have user id. This should not happen'
                                    })
                                }}
                                // @ts-ignore: TS doesn't understand this is a RouterLink now
                                to={`/chat/new?label=${THREAD_LABELS.followUp}&subject=Follow up care&message=I have a question about my Care Plan`}
                                sx={{ fontSize: 'inherit' }}
                            >
                                please message your Care Coordinator
                            </Link>
                        </Paragraph>
                    </>
                )}
        </Box>
    )
}

export const InterventionDetail = () => {
    const { user, isLoading: userIsLoading } = useUserInfo()
    const { ampli } = useAmpli()
    // Assume: won't route here without intervention uuid
    const { carePlanInterventionUuid = '' } = useParams<{
        carePlanInterventionUuid: string
    }>()

    const { isLoading: isLoadingIntervention, data } = useCarePlanIntervention(
        carePlanInterventionUuid
    )

    useEffect(() => {
        if (user && !userIsLoading) {
            ampli.carePlanDetailsViewed({
                intervention: carePlanInterventionUuid,
                userID: user.id.toString()
            })
        }
    }, [userIsLoading])

    return (
        <Body>
            {isLoadingIntervention && <Spinner />}
            {!isLoadingIntervention && (
                <>
                    <PageHeader
                        desktopActions={
                            <>
                                <HeaderYourCareButton />
                                <HeaderChatButton />
                            </>
                        }
                        mobileBack={<BackButton variant="white" />}
                    />
                    <Container>
                        <NavBarHeading>
                            {data?.carePlanIntervention.name}
                        </NavBarHeading>

                        <LayoutGrid columns="one">
                            {isLoadingIntervention && <CenteredSpinner />}
                            {data && (
                                <DetailInfo
                                    intervention={data}
                                    appointmentId={data?.originAppointment?.id}
                                />
                            )}
                        </LayoutGrid>
                    </Container>
                </>
            )}
        </Body>
    )
}
