import { Flex } from '@asktia/tia-ui'
import { FC, ReactNode } from 'react'

export interface IDevSidebarMenuItem {
    children: ReactNode
    onClick: () => void
}

export const DevSidebarMenuItem: FC<IDevSidebarMenuItem> = ({
    children,
    onClick
}) => {
    return (
        <Flex
            sx={{
                'width': '100%',
                'height': '40px',
                'alignItems': 'center',
                'justifyContent': 'center',
                'marginBottom': 2,
                'borderTopLeftRadius': 8,
                'borderBottomLeftRadius': 8,
                'cursor': 'pointer',
                '&:hover': { backgroundColor: '#F9F9F9' }
            }}
            onClick={onClick}
        >
            {children}
        </Flex>
    )
}
