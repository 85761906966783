import {
    Box,
    Color,
    Container,
    Flex,
    ThemeStyleObject,
    useTiaUI
} from '@asktia/tia-ui'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'

type CarouselPaginationProps = {
    sliderInstance: Slider | null
    current: string
    steps: string[]
    sx?: ThemeStyleObject
    variant?: 'light' | 'dark'
}

export type CarouselProps = PropsWithChildren<{
    paginationProps?: Omit<
        CarouselPaginationProps,
        'sliderInstance' | 'current' | 'steps'
    >
    slickOverrides?: Settings
}>

export const Carousel = ({
    children,
    paginationProps = {},
    slickOverrides = {}
}: CarouselProps) => {
    const { sx, variant = 'light' } = paginationProps

    const buildSettings = (sliderInstance: Slider | null) => ({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1080,
                settings: { slidesToShow: 2, slidesToScroll: 2 }
            },
            {
                breakpoint: 640,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ],
        arrows: false,
        appendDots: (dots: any) => {
            const index =
                dots.findIndex(
                    (d: any) => d.props.className === 'slick-active'
                ) || 0
            return (
                <CarouselPagination
                    variant={variant}
                    sliderInstance={sliderInstance}
                    current={index.toString()}
                    steps={dots.map((d: any) => d.key)}
                    sx={{ mt: 4, mr: 2, ...sx }}
                />
            )
        },
        ...slickOverrides
    })

    // Create initial settings without Slick mounted
    const [settings, setSettings] = useState(buildSettings(null))

    // Create a ref for the Slick instance
    const sliderRef = useRef<Slider | null>(null)
    const setRef = useCallback((sliderInstance: Slider | null) => {
        // Recreate settings upon Slick mount
        if (!sliderRef.current && sliderInstance) {
            sliderRef.current = sliderInstance
            setSettings(buildSettings(sliderInstance))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Slider ref={setRef} {...settings}>
            {children}
        </Slider>
    )
}

export const CarouselContainer = ({
    children,
    sx
}: PropsWithChildren<{ sx?: ThemeStyleObject }>) => {
    return (
        <Container
            sx={{
                'pr': '0 !important',
                '.slick-slider': {
                    mr: [-3, -4]
                },
                '.slick-list': {
                    borderTopLeftRadius: 2,
                    borderBottomLeftRadius: 2,
                    overflow: 'visible'
                },
                '.slick-track': {
                    display: 'flex !important',
                    ml: 'initial'
                },
                '.slick-slide': {
                    height: 'inherit !important',
                    pr: [3, 4]
                },
                '.slick-slide > div': {
                    height: '100%'
                },
                'overflow': 'hidden',
                ...sx
            }}
        >
            {children}
        </Container>
    )
}

const CarouselPagination = ({
    sliderInstance,
    current,
    steps,
    sx,
    variant
}: CarouselPaginationProps) => {
    const { theme } = useTiaUI()
    const svgStrokeColor =
        variant === 'dark' ? theme.colors?.clay : theme.colors?.mainBackground
    const activeDotColor =
        variant === 'dark' ? theme.colors?.text : svgStrokeColor

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: ['center', 'flex-end'],
                alignItems: 'center',
                ...sx
            }}
        >
            <Flex
                sx={{
                    mr: 4,
                    display: ['none', 'flex'],
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    width: 6,
                    height: 6
                }}
                onClick={() => sliderInstance && sliderInstance.slickPrev()}
            >
                <PaginationPrev fill={svgStrokeColor as string} />
            </Flex>
            {steps.map((step: string, index: number) => (
                <Flex
                    sx={{ mr: 2, cursor: 'pointer' }}
                    onClick={() =>
                        sliderInstance && sliderInstance.slickGoTo(index)
                    }
                    key={index}
                >
                    {step === current ? (
                        <PaginationDotActive key={step} fill={activeDotColor} />
                    ) : (
                        <PaginationDot
                            key={step}
                            fill={svgStrokeColor as string}
                        />
                    )}
                </Flex>
            ))}
            <Flex
                sx={{
                    ml: 2,
                    display: ['none', 'flex'],
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    width: 6,
                    height: 6
                }}
                onClick={() => sliderInstance && sliderInstance.slickNext()}
            >
                <PaginationNext fill={svgStrokeColor as string} />
            </Flex>
        </Box>
    )
}

const PaginationDot = ({
    fill = 'mainBackground',
    hover = 'text'
}: {
    fill: Color
    hover?: Color
}) => (
    <Box
        as="svg"
        sx={{
            'width': '8px',
            'height': '4px',
            '&:hover': {
                '& > circle': {
                    fill: hover
                }
            },
            '& > circle': {
                transition: 'all 0.3s',
                fill: fill
            }
        }}
    >
        <circle cx="2" cy="2" r="2" strokeWidth="1.5" />
    </Box>
)
const PaginationDotActive = ({
    fill = 'mainBackground',
    hover = 'text'
}: {
    fill: Color
    hover?: Color
}) => (
    <Box
        as="svg"
        sx={{
            'width': '8px',
            'height': '4px',
            '&:hover': {
                '& > circle': {
                    fill: hover
                }
            },
            '& > circle': {
                fill: fill
            }
        }}
    >
        <circle cx="2" cy="2" r="2" />
    </Box>
)

const PaginationPrev = ({ fill = 'mainBackground', hover = 'text' }) => (
    <Box
        as="svg"
        sx={{
            'width': '17px',
            'height': '12px',
            '&:hover': {
                '& > path': {
                    fill: hover
                }
            },
            '& > path': {
                transition: 'all 0.3s',
                fill: fill
            }
        }}
    >
        <path d="M0.469669 5.46967C0.176777 5.76256 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.469669 5.46967ZM17 5.25L1 5.25L1 6.75L17 6.75L17 5.25Z" />
    </Box>
)

const PaginationNext = ({ fill = 'mainBackground', hover = 'text' }) => (
    <Box
        as="svg"
        sx={{
            'width': '17px',
            'height': '12px',
            '&:hover': {
                '& > path': {
                    fill: hover
                }
            },
            '& > path': {
                transition: 'all 0.3s',
                fill: fill
            }
        }}
    >
        <path
            d="M16.5303 6.53033C16.8232 6.23744 16.8232 5.76256 16.5303 5.46967L11.7574 0.6967C11.4645 0.403807 10.9896 0.403807 10.6967 0.6967C10.4038 0.989593 10.4038 1.46447 10.6967 1.75736L14.9393 6L10.6967 10.2426C10.4038 10.5355 10.4038 11.0104 10.6967 11.3033C10.9896 11.5962 11.4645 11.5962 11.7574 11.3033L16.5303 6.53033ZM-6.55671e-08 6.75L16 6.75L16 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
            fill={fill}
        />
    </Box>
)
