import * as yup from 'yup'
import { yupBirthdateSchema } from 'src/utils'

// TODO: should these come from the backend?
export const RELATIONSHIP_OPTIONS = {
    SPOUSE: '01',
    CHILD: '43',
    STEP_CHILD: '17',
    MOTHER: '32',
    FATHER: '33',
    SIGNIFICANT_OTHER: '29',
    LIFE_PARTNER: '53',
    GRANDPARENT: '04',
    GRANDCHILD: '05',
    NIECE_NEPHEW: '07',
    OTHER: 'G8'
}

const requiredWhenCreatingInsurance = (
    // these gnarly types are needed because of a type mismatch after running npm update
    schema:
        | yup.StringSchema<string | null | undefined, object>
        | yup.BooleanSchema<boolean | null | undefined>
        | yup.DateSchema
        | yup.AnyObjectSchema,
    requiredMessage?: string
) => ({
    is: (
        addInsurance: 'yes' | 'no' | undefined,
        useExistingInsurance: 'yes' | 'no' | undefined
    ) => {
        return addInsurance === 'yes' || useExistingInsurance === 'no'
    },
    then: schema.required(requiredMessage),
    otherwise: schema.notRequired()
})

const requiredWhenCreatingInsuranceAndDependent = (
    // these gnarly types are needed because of a type mismatch after running npm update
    schema:
        | yup.StringSchema<string | null | undefined, object>
        | yup.DateSchema
        | yup.AnyObjectSchema
) => ({
    is: (
        addInsurance: 'yes' | 'no' | undefined,
        useExistingInsurance: 'yes' | 'no' | undefined,
        dependent: boolean
    ) => (addInsurance === 'yes' || useExistingInsurance === 'no') && dependent,
    then: schema.required(),
    otherwise: schema.notRequired()
})

export function getSchema() {
    return yup
        .object({
            addInsurance: yup.string().oneOf(['yes', 'no']),
            useExistingInsurance: yup.string().oneOf(['yes', 'no']),
            issuerName: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup.string()
                })
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.object())
                ),
            memberId: yup
                .string()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.string())
                ),
            patientGivenName: yup
                .string()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.string())
                ),
            patientFamilyName: yup
                .string()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.string())
                ),
            patientIsADependent: yup
                .boolean()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.boolean())
                ),

            subscriberGivenName: yup
                .string()
                .when(
                    [
                        'addInsurance',
                        'useExistingInsurance',
                        'patientIsADependent'
                    ],
                    requiredWhenCreatingInsuranceAndDependent(yup.string())
                ),
            subscriberFamilyName: yup
                .string()
                .when(
                    [
                        'addInsurance',
                        'useExistingInsurance',
                        'patientIsADependent'
                    ],
                    requiredWhenCreatingInsuranceAndDependent(yup.string())
                ),
            subscriberDateOfBirth: yupBirthdateSchema.when(
                ['addInsurance', 'useExistingInsurance', 'patientIsADependent'],
                requiredWhenCreatingInsuranceAndDependent(yupBirthdateSchema)
            ),
            relationshipToSubscriberCode: yup
                .object()
                .shape({
                    label: yup.string(),
                    value: yup
                        .string()
                        .oneOf(Object.values(RELATIONSHIP_OPTIONS))
                })
                .when(
                    [
                        'addInsurance',
                        'useExistingInsurance',
                        'patientIsADependent'
                    ],
                    requiredWhenCreatingInsuranceAndDependent(yup.object())
                ),

            sameAsMailing: yup.boolean(),

            // core structure copied from yupAddressSchema
            // .required('custom message').notRequired() doesn't work :(
            address1: yup
                .string()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.string())
                ),
            address2: yup.string(),
            city: yup
                .string()
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(yup.string())
                ),
            state: yup
                .string()
                .matches(
                    // US state regex from https://gist.github.com/nerdsrescueme/1237767#file-regex-txt-L163
                    /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/i,
                    'Enter a US state'
                )
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(
                        yup.string(),
                        'State is required'
                    )
                ),
            postalCode: yup
                .string()
                // postalCode regex from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
                .matches(/^\d{5}(?:-\d{4})?$/, 'Enter a valid ZIP Code')
                .when(
                    ['addInsurance', 'useExistingInsurance'],
                    requiredWhenCreatingInsurance(
                        yup.string(),
                        'ZIP Code is required'
                    )
                ),

            usedInsuranceBefore: yup.string().oneOf(['yes', 'no']),
            patientWantsReceipt: yup.string().oneOf(['yes', 'no']),
            patientWantsSuperbill: yup.boolean(),
            photoOptOut: yup.boolean(),
            test: yup.boolean(),
            confirmAgreeCashPay: yup
                .boolean()
                .oneOf([true, null, undefined], ''),
            hasInsuranceImages: yup
                .string()
                .oneOf(['on'])
                .when(['photoOptOut'], {
                    is: true,
                    then: yup.string().oneOf(['off']),
                    otherwise: yup.string().oneOf(['on'])
                })
        })
        .defined()
}
