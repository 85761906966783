import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { CarePlan } from 'src/types'
import { useLocalStorage } from './useLocalStorage'

async function fetchCarePlan() {
    // userId path param is being replaced in the www proxy
    return await fetcher<CarePlan>(
        `${BASE_API_URL}/tmd-api/patients/:userId/care-plan/interventions?apiVersion=v2&isActive=true`,
        undefined,
        'GET',
        {
            errorMsg: `Couldn't fetch interventions for user`
        }
    )
}

export const useCarePlan = () =>
    useQuery(['carePlan'], fetchCarePlan, {
        staleTime: 1000 * 60 * 60
    })

export const useLastSeenCarePlan = () => {
    return useLocalStorage<string>('tia:last-seen-care-plan-v2', '')
}

export const useHasCarePlan = () => {
    const { data: interventions } = useCarePlan()
    return interventions && interventions.length ? true : false
}

export const useGetUnseenCarePlanInterventions = () => {
    const { data: interventions } = useCarePlan()
    const [lastCarePlanSeen] = useLastSeenCarePlan()

    if (!interventions || !interventions.length) {
        return []
    }

    // THIS MIGHT NEED ACTUAL DATE OBJECTS BEING COMPARED WITH DATE-FNS
    return interventions.filter(
        intervention => intervention.originTimestamp > lastCarePlanSeen
    )
}
