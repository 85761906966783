import Cookies from 'js-cookie'
import { useMutation } from 'react-query'

export type InsuranceCardType = 'front' | 'back'

export function useUpdateInsuranceImages() {
    return useMutation(
        async (body: {
            cards: { name: InsuranceCardType; image: File }[]
            flow?: 'member-info' | 'member-signup'
        }) => {
            const formData = new FormData()

            for (const card of body.cards) {
                formData.append(card.name, card.image)
            }

            if (body.flow) {
                formData.append('flow', body.flow)
            }

            // we are not using `fetcher` because we are sending an image
            // and we need to return the response and not the response.json()
            const res = await fetch('/tmd-api/insurance-card', {
                method: 'PUT',
                body: formData,
                credentials: 'same-origin',
                headers: {
                    'X-Xsrf-Token': Cookies.get('xsrf-token') || ''
                }
            })

            if (!res.ok) {
                throw new Error('Server returned not-ok')
            }

            return res
        },
        {
            retry: false
        }
    )
}
