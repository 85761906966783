import { Box, Button, Grid, Paragraph } from '@asktia/tia-ui'
import { addDays, subWeeks, subYears } from 'date-fns'
import { sample } from 'lodash'
import { useMutation, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { getAppointmentProfile } from 'src/flows/AppointmentBooking/useAppointmentProfile'
import { findAvailableSlots } from 'src/flows/AppointmentBooking/useAvailableSlots'
import { bookAppointmentSlot } from 'src/flows/AppointmentBooking/useBookingFlow'
import { fetchOfferings } from 'src/hooks/useOfferings'
import { useUserInfo } from 'src/hooks'

const PREVENTIVE_CARE_APPOINTMENT_PROFILES = [
    'tia_whole_health_exam',
    'annual_preventative_exam'
]

async function deleteAllAppointments(userId: number) {
    return fetcher(
        `/tmd-api/appointments/do_not_use_in_prod/${userId}/delete_all?apiVersion=v2`,
        undefined,
        'DELETE'
    )
}

async function deleteRecentPreventiveCare(userId: number) {
    const since = subWeeks(subYears(new Date(), 1), 1)

    for (const apptProfile of PREVENTIVE_CARE_APPOINTMENT_PROFILES) {
        await fetcher(
            `/tmd-api/appointments/do_not_use_in_prod/${userId}/delete_appts?apiVersion=v2&since=${since.toISOString()}&appt_profile=${apptProfile}`,
            undefined,
            'DELETE'
        )

        await fetcher(
            `/tmd-api/appointments/do_not_use_in_prod/${userId}/delete_appts?apiVersion=v2&since=${since.toISOString()}&appt_profile=${apptProfile}`,
            undefined,
            'DELETE'
        )
    }
}

async function bookBackdatedAppt(apptProfileName: string, date: Date) {
    const offerings = await fetchOfferings([apptProfileName])()

    const apptProfile = await getAppointmentProfile(
        offerings[0].appointmentProfileUuid
    )

    const availableSlots = await findAvailableSlots({
        appointmentProfileUuid: apptProfile.appointmentProfileUuid,
        startDate: new Date(),
        maxSlots: 10,
        uniqueStartTime: true
    })

    if (!availableSlots.length) {
        return alert(`No availability found for ${offerings[0].name}`)
    }

    const bookedAppt = await bookAppointmentSlot(
        sample(availableSlots)!,
        apptProfile.goals?.[0]?.id || 1
    )

    await fetcher(
        `/tmd-api/appointments/do_not_use_in_prod/${bookedAppt.id}/backdate?apiVersion=v2`,
        JSON.stringify({
            scheduled_time: date
        }),
        'PATCH'
    )
}

export const PatientEngagementLevel = () => {
    const reactQueryClient = useQueryClient()
    const { user } = useUserInfo()

    const newbiefy = useMutation(deleteAllAppointments, {
        onSuccess: () => {
            reactQueryClient.refetchQueries(['current-user'])
        },
        onError: () => alert('That didnt work')
    })

    const makeNeedsCare = useMutation(
        async () => {
            if (user?.id) {
                await deleteRecentPreventiveCare(user.id)
                await bookBackdatedAppt(
                    'tia_whole_health_exam',
                    subYears(new Date(), 1)
                )
            }
        },
        {
            onSuccess: () => reactQueryClient.refetchQueries(['current-user']),
            onError: () => alert('That didnt work')
        }
    )

    const makeEngaged = useMutation(
        async () => {
            if (user?.id) {
                await bookBackdatedAppt(
                    'tia_whole_health_exam',
                    addDays(new Date(), 1)
                )
                await bookBackdatedAppt('acne', addDays(new Date(), 1))
            }
        },
        {
            onSuccess: () => reactQueryClient.refetchQueries(['current-user']),
            onError: () => alert('That didnt work')
        }
    )

    return (
        <Grid columns={1} gap={3}>
            <Paragraph>
                You are currently a <b>{user?.patientEngagementStatus}</b>
            </Paragraph>
            <Box>
                <Button
                    onClick={() => user?.id && newbiefy.mutate(user.id)}
                    loading={newbiefy.isLoading}
                >
                    Make me a new patient
                </Button>
                <br />
                <small>Deletes all your appointments and suggestions</small>
            </Box>
            <Box>
                <Button
                    onClick={() => makeEngaged.mutate()}
                    loading={makeEngaged.isLoading}
                >
                    Make me an engaged patient
                </Button>
                <br />
                <small>Adds twhe and other appt within past year</small>
            </Box>
            <Box>
                <Button
                    onClick={() => makeNeedsCare.mutate()}
                    loading={makeNeedsCare.isLoading}
                >
                    Make me a long time patient who needs preventive care
                </Button>
                <br />
                <small>Deletes any twhe or annual appts within past year</small>
            </Box>
        </Grid>
    )
}
