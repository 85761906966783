import { Flex, Text, ThemeStyleObject } from '@asktia/tia-ui'
import { FC, ReactNode } from 'react'
import { CloseIcon } from 'src/components/Blocks/Icons'

export interface FilterPillProps {
    filterKey: string
    value: string
    children: ReactNode
    sx?: ThemeStyleObject

    onClose: (filterKey: string, value: string) => void
}

export const FilterPill: FC<FilterPillProps> = ({
    filterKey,
    value,
    children,
    onClose,
    sx
}) => {
    return (
        <Flex
            sx={{
                'width': 'fit-content',
                'background': 'secondaryBackground',
                'py': '6px',
                'px': 2,
                'borderRadius': 2,
                'alignItems': 'center',
                'justifyContent': 'flex-start',
                '&:hover': {
                    background: 'inputOutlineHover'
                },
                ...sx
            }}
        >
            <Text
                sx={{
                    fontSize: 0,
                    userSelect: 'none',
                    whiteSpace: 'nowrap',
                    wordBreak: 'keep-all',
                    color: 'text'
                }}
            >
                {children}
            </Text>
            <CloseIcon
                color="text"
                sx={{ transform: 'scale(0.7)', cursor: 'pointer' }}
                onClick={() => onClose(filterKey, value)}
            />
        </Flex>
    )
}
