import { useEffect, useMemo } from 'react'
import { Card, Form, Heading, Paragraph } from '@asktia/tia-ui'
import { Control, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

import omit from 'lodash.omit'

import { Divider } from 'src/components/Blocks'
import {
    Address,
    CanUseInsurance,
    SaveStepFunction,
    DataBlob,
    Appointment
} from 'src/types'
import { useEnableButtonOnChange } from 'src/hooks'
import { InsuranceFooter } from './InsuranceFooter'
import { VerificationStatus } from './VerificationStatus'
import { HowPaymentWorks } from './HowPaymentWorks'
import { SubscriberForm, SubscriberAddressForm } from './SubscriberForm'
import {
    InsuranceValidationState,
    isDisallowedInsurance,
    MachineContext,
    useInsuranceUIStateMachine
} from './useInsuranceUIStateMachine'
import {
    AddInsuranceForm,
    ExistingInsuranceForm,
    InsuranceInfoForm
} from './InsuranceInfoForm'
import { PaymentMethodForm } from './PaymentMethodForm'
import { format } from 'date-fns'
import { useAmpli } from 'src/hooks'
import { isEmpty } from 'lodash'
import { InsuranceCardForm } from 'src/flows/AutomatedCheckin/views/InsuranceInfo/InsuranceCardForm'
import { View } from 'src/components/Blocks/View'
import { flushSync } from 'react-dom'
import { TentativeVerificationQuestion } from './TentativeVerificationQuestion'
import {
    getSchema,
    RELATIONSHIP_OPTIONS
} from 'src/flows/AutomatedCheckin/views/InsuranceInfo/yupSchema'

function getUsingInsurance(
    canUseInsurance: CanUseInsurance,
    machineContext: MachineContext,
    formValues: Record<string, string | boolean>
): boolean {
    if (canUseInsurance === 'no') {
        return false
    }

    const patientIntendsToUseInsurance =
        formValues.addInsurance === 'yes' || machineContext.hasLatestInsurance

    if (!isEmpty(machineContext.insuranceValidationState)) {
        if (isDisallowedInsurance(machineContext.insuranceValidationState)) {
            return false
        }

        if (canUseInsurance === 'tentative') {
            // tentative insurance hinges on a user question
            return formValues.usedInsuranceBefore === 'yes'
        }
    }

    return patientIntendsToUseInsurance
}

// Event tracking for confirm cash pay checkbox
function useTrackCashpayConfirmed(control: Control, appointment: Appointment) {
    const { cashPayConfirmed } = useAmpli()
    const confirmAgreeCashPay = useWatch({
        control,
        name: 'confirmAgreeCashPay'
    })

    useEffect(() => {
        if (confirmAgreeCashPay) {
            cashPayConfirmed(appointment.id)
        }
    }, [confirmAgreeCashPay])
}

type InsuranceInfoProps = {
    /**
     * mailing address on file
     */
    mailingAddress?: Address

    /**
     * info to populate the form
     */
    defaultFormValues?: DataBlob

    /**
     * can insurance be used at all
     */
    canUseInsurance: CanUseInsurance

    /**
     * does the user have existing insurance on file
     */
    hasExistingInsurance?: boolean

    /**
     * current appointment
     */
    appointment: Appointment

    saveStep?: SaveStepFunction

    isSaving: boolean
} & InsuranceValidationState

export const InsuranceInfo = (props: InsuranceInfoProps) => {
    const isInsuranceVerificationUnavailable =
        useAmpliFeatureFlag('show-insurance-verification-unavailable') === 'on'

    const schema = useMemo(() => getSchema(), [])

    const defaultValues: any = {
        ...props.defaultFormValues,
        issuerName:
            props.defaultFormValues?.issuerName &&
            props.defaultFormValues?.payerUuid
                ? {
                      label: props.defaultFormValues?.issuerName,
                      value: props.defaultFormValues?.payerUuid
                  }
                : props.defaultFormValues?.issuerName
    }

    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur'
    })
    const isFormValid = useEnableButtonOnChange(formMethods, schema)

    const { insuranceSelected, checkInInsuranceCompleted } = useAmpli()

    useTrackCashpayConfirmed(formMethods.control, props.appointment)

    // state machine that takes care of turning widgets on and off
    // also keeps track of "Have we submitted the first time yet"
    const {
        currentState,
        context: machineContext,
        send
    } = useInsuranceUIStateMachine(
        props.canUseInsurance,
        Boolean(props.hasExistingInsurance),
        formMethods.control,
        isFormValid
    )

    // TODO: move this function out of component
    async function onSubmit(values: any) {
        send('Submit')

        if (props.saveStep) {
            let putData: DataBlob
            const savingPayment = currentState === 'Payment Method'

            if (savingPayment) {
                // this defines how we're going to bill
                // and moves to next ACI step
                putData = {
                    paymentMethod: 'cardOnFile',
                    patientWantsReceipt: values.patientWantsReceipt === 'yes',
                    patientWantsSuperbill:
                        values.patientWantsSuperbill === 'yes',
                    // use insurance if all good
                    usingInsurance: getUsingInsurance(
                        props.canUseInsurance,
                        machineContext,
                        values
                    ),
                    useExisting: values.useExistingInsurance === 'yes'
                }
            } else {
                // this updates a person's insurance info
                // and runs an eligibility check
                putData = {
                    ...omit(values, [
                        'sameAsMailing',
                        'usingInsurance',
                        'useExistingInsurance',
                        'relationshipToSubscriberCode',
                        'hasInsuranceImages',
                        'addInsurance',
                        'usedInsuranceBefore',
                        'issureName'
                    ]),
                    // try to use insurance unless appointment
                    // says no
                    usingInsurance: getUsingInsurance(
                        props.canUseInsurance,
                        machineContext,
                        values
                    ),
                    useExisting: values.useExistingInsurance === 'yes',
                    relationshipToSubscriberCode:
                        values.relationshipToSubscriberCode?.value,
                    issuerName: values.issuerName?.label
                }
                if (values.subscriberDateOfBirth) {
                    putData.subscriberDateOfBirth = format(
                        values.subscriberDateOfBirth,
                        'yyyy-MM-dd'
                    )
                }

                insuranceSelected(values.issuerName?.label)
            }

            const [success, result] = await props.saveStep(
                putData,
                savingPayment,
                machineContext.insuranceVerified
                    ? 'payment_method'
                    : 'insurance'
            )

            if (success) {
                if (!savingPayment) {
                    const verificationResult = {
                        ...result,
                        updatedAt: result?.updatedAt
                            ? new Date(result.updatedAt as string)
                            : undefined
                    } as InsuranceValidationState

                    // new React 18 behavior causes a race condition with these state updates
                    // flushSync helps us avoid it for now
                    flushSync(() => {
                        checkInInsuranceCompleted(
                            props.appointment,
                            values.patientWantsReceipt === 'yes',
                            values.usingInsurance === 'yes',
                            values.useExistingInsurance === 'yes',
                            verificationResult.verificationStatus
                        )

                        send('success', {
                            insuranceValidationState: verificationResult
                        })
                    })
                } else {
                    send('success')
                }
            } else {
                send('error_submitting')
            }
        }
    }

    return (
        <View sx={{ pb: [9, 3] }}>
            <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                {isInsuranceVerificationUnavailable && (
                    <Card variant="info" sx={{ mb: 6 }}>
                        <Heading h3>
                            Insurance verification may be unavailable or require
                            manual review.
                        </Heading>
                        <Paragraph>
                            We're having some technical issues. Please allow for
                            extra time for us to review and verify your
                            insurance. Thank you for your patience.
                        </Paragraph>
                    </Card>
                )}
                {machineContext.showAddInsurance ? (
                    <AddInsuranceForm
                        setValue={formMethods.setValue}
                    ></AddInsuranceForm>
                ) : null}

                {machineContext.showUpdateInsurance &&
                props.defaultFormValues ? (
                    <ExistingInsuranceForm
                        issuerName={
                            props.defaultFormValues.issuerName as string
                        }
                        memberId={props.defaultFormValues.memberId as string}
                        patientIsADependent={
                            !!props.defaultFormValues.patientIsADependent
                        }
                        subscriberGivenName={
                            props.defaultFormValues
                                .subscriberGivenName as string
                        }
                        subscriberFamilyName={
                            props.defaultFormValues
                                .subscriberFamilyName as string
                        }
                        setValue={formMethods.setValue}
                    />
                ) : null}

                {machineContext.hasLatestInsurance &&
                props.defaultFormValues &&
                !machineContext.showInsuranceForm ? (
                    <Divider sx={{ bg: 'mainBackground' }} />
                ) : null}

                {machineContext.showInsuranceForm ? (
                    <>
                        <InsuranceInfoForm />
                    </>
                ) : null}

                {machineContext.showIsADependent ? (
                    <>
                        <Divider variant="blockSpacer" />
                        <SubscriberForm
                            relationshipOptions={RELATIONSHIP_OPTIONS}
                        />
                    </>
                ) : null}

                {machineContext.showInsuranceForm ? (
                    <>
                        <Divider variant="blockSpacer" />
                        <SubscriberAddressForm
                            patientIsADependent={
                                machineContext.showIsADependent
                            }
                            mailingAddress={props.mailingAddress}
                            formMethods={formMethods}
                        />
                    </>
                ) : null}

                {machineContext.showInsuranceCardUpload ? (
                    <InsuranceCardForm
                        formMethods={formMethods}
                        appointment={props.appointment}
                    />
                ) : null}

                {machineContext.showTentativeQuestion ? (
                    <>
                        <Divider variant="blockSpacer" />
                        <TentativeVerificationQuestion
                            appointmentLabel={props.appointment.label}
                        />
                    </>
                ) : null}

                {machineContext.showVerification ? (
                    <>
                        <VerificationStatus
                            machineContext={machineContext}
                            appointment={props.appointment}
                        />
                        <Divider variant="blockSpacer" />
                    </>
                ) : null}

                {machineContext.showPaymentMethod && (
                    <>
                        {/*
                            // @ts-ignore: insuranceValidationState values guaranteed defined */}
                        <PaymentMethodForm
                            canUseInsurance={props.canUseInsurance !== 'no'}
                            {...machineContext.insuranceValidationState}
                        />
                        <Divider sx={{ bg: 'mainBackground' }} />
                    </>
                )}

                {machineContext.showPaymentMethod ? <HowPaymentWorks /> : null}

                <InsuranceFooter
                    isFormValid={
                        isFormValid && !machineContext.insuranceIsBlocked
                    }
                    isSubmitting={formMethods.formState.isSubmitting}
                    currentState={currentState}
                />
            </Form>
        </View>
    )
}
