import { Button, Form, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { GluedSingleChoiceQuestion } from 'src/flows/questionnaires/components/Input/SingleChoice'
import { useQuestionnaire } from 'src/flows/questionnaires/hooks/useQuestionnaire'
import { useQuestionnaireResponse } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { ErrorSavingBanner } from 'src/flows/questionnaires/components/ErrorSavingBanner'
import { get as _get, omit as _omit } from 'lodash'
import { LegalDisclaimer } from '../../components/LegalDisclaimer'
import { QuestionnaireDivider } from 'src/flows/questionnaires/components/QuestionnaireDivider'
import { getFormDone } from 'src/flows/questionnaires/getFormDone'
import { useSearchParams } from 'react-router-dom'
import { useNavigation } from 'src/hooks'

export const PHQ9Page: FC = () => {
    const [searchParams] = useSearchParams()
    const { navigate } = useNavigation()
    const [showSavingError, setShowSavingError] = useState(false)
    const { questionnaire } = useQuestionnaire('phq-9')
    const { answersMap, questionnaireResponse, saveQuestionnaireResponse } =
        useQuestionnaireResponse(
            'phq-9',
            searchParams.get('appointmentProfileUuid') as string
        )

    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
    })

    const { formDone } = getFormDone(formMethods.getValues())

    const getValidFormValues = async (triggeredByFieldName?: string) => {
        // validate form against latest form values
        await formMethods.trigger(triggeredByFieldName)

        const formValues = formMethods.getValues()
        const formKeys = Object.keys(formValues)

        // TODO: not sure best way to handle this any
        // at the moment the formValues also have an any type
        const validFormValues: any = {}
        const fieldErrors = formMethods.formState.errors

        // Note - this only works for 1 nested level
        // i.e.
        // formValues = {nicotine-use: {polar: 'yes', quantity: 'asdfasdf'}}
        // errors = {nicotine-use: {quantity: 'must be a number'}}
        // validFormValues = {nicotine-use: {polar: 'yes'}
        formKeys.forEach((key: string) => {
            const fieldValue = _get(formValues, key)
            const nestedFieldErrors = _get(fieldErrors, key)
            validFormValues[key as string] =
                typeof fieldValue === 'object' && !Array.isArray(fieldValue)
                    ? _omit(fieldValue, Object.keys(nestedFieldErrors || {}))
                    : fieldValue
        })

        return validFormValues
    }

    // Save questionnaire and handle errors:
    // - clear triggering field, so user knows what's wrong
    // - show error toast
    async function triggerSave(triggeredByFieldName?: string) {
        setShowSavingError(false)
        try {
            await saveQuestionnaireResponse(
                await getValidFormValues(triggeredByFieldName)
            )
        } catch (e) {
            console.error(e)
            if (triggeredByFieldName) {
                formMethods.setValue(triggeredByFieldName, undefined)
            }
            setShowSavingError(true)
        }
    }

    const onComplete = async () => {
        triggerSave()
        navigate(searchParams.get('onCompleteRelativePath') as string)
    }

    if (!questionnaire.item) {
        return <Spinner />
    }

    const canProceed = formDone && Object.keys(formMethods.errors).length === 0

    const questionFormGlue = {
        questionnaire,
        questionnaireResponse,
        answersMap,
        triggerSave
    }

    return (
        <>
            {showSavingError && <ErrorSavingBanner />}
            <LayoutGrid columns="one" variant="condensed">
                <FormProvider {...formMethods}>
                    <Form
                        useFormMethods={formMethods}
                        onSubmit={() => console.log('on submit')}
                    >
                        <GluedSingleChoiceQuestion
                            linkId="little-interest"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="feeling-hopeless"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="sleep-trouble"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="feeling-tired"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="appetite-trouble"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="failure-feeling"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="trouble-concentrating"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="moving-trouble"
                            {...questionFormGlue}
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            linkId="self-harm"
                            {...questionFormGlue}
                        />

                        <Button
                            sx={{ mt: 6, mb: 6, py: '20px' }}
                            onClick={onComplete}
                            disabled={!canProceed}
                            fullWidth
                        >
                            Complete Depression Screening
                        </Button>
                    </Form>
                    <LegalDisclaimer />
                </FormProvider>
            </LayoutGrid>
        </>
    )
}
