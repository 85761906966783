import { isBefore } from 'date-fns'
import { useFetchPatientGeneticMutations } from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/useGeneticMutation'
import {
    useActiveConditionsQuery,
    useInactiveConditionsQuery
} from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/useGetConditions'
import { useUserInfo } from 'src/hooks'

export const useGetMedicalConditionsStatus = (): {
    data: 'not_started' | 'started' | 'done' | undefined
    isLoading: boolean
} => {
    const { user, isLoading: userIsLoading } = useUserInfo()
    const { data: inactiveConditions, isLoading: inactiveIsLoading } =
        useInactiveConditionsQuery(user?.id)
    const { data: activeConditions, isLoading: activeIsLoading } =
        useActiveConditionsQuery(user?.id)
    const { data: geneticMutations, isLoading: mutationsIsLoading } =
        useFetchPatientGeneticMutations()

    const isLoading =
        userIsLoading ||
        inactiveIsLoading ||
        activeIsLoading ||
        mutationsIsLoading

    const noConfirmationsFound =
        !inactiveConditions?.latestPatientConditionConfirmation &&
        !activeConditions?.latestPatientConditionConfirmation &&
        !geneticMutations?.latestPatientGeneticMutationConfirmation

    if (noConfirmationsFound && !isLoading) {
        return { data: 'not_started', isLoading }
    }

    const now = new Date()

    const inactiveLastConfirmed =
        inactiveConditions?.latestPatientConditionConfirmation
            ?.lastConfirmedAt || now
    const activeLastConfirmed =
        activeConditions?.latestPatientConditionConfirmation?.lastConfirmedAt ||
        now
    const geneticMutationsLastConfirmed =
        geneticMutations?.latestPatientGeneticMutationConfirmation
            ?.lastConfirmedAt || now

    const isDone =
        isBefore(inactiveLastConfirmed, now) &&
        isBefore(activeLastConfirmed, now) &&
        isBefore(geneticMutationsLastConfirmed, now)

    const response = isDone ? 'done' : 'started'

    return {
        data: isLoading ? undefined : response,
        isLoading
    }
}
