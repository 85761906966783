import React, { FC, MouseEventHandler } from 'react'
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Image,
    LayoutGrid,
    Text,
    Stepper
} from '@asktia/tia-ui'
import { CloseButton } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import { BackButton } from 'src/components/Blocks/BackButton'

type BlueInterstitialProps = {
    image: string
    title: string
    info: string

    btnText?: string
    /**
     * when defined enables CTA
     */
    btnOnClick?: MouseEventHandler

    backLink?: string

    /**
     * whether to show spinner in CTA
     */
    loading?: boolean

    /**
     * which step to higlight in the stepper
     */
    currentStep: 0 | 1 | 2
}

export const BlueInterstitial: FC<
    React.PropsWithChildren<BlueInterstitialProps>
> = ({
    image,
    title,
    info,
    btnText,
    btnOnClick,
    loading,
    backLink,
    currentStep
}) => {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100vw',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: 'raspberry',
                zIndex: 1000,
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <PageHeader
                desktopActions={<CloseButton color="white" />}
                mobileActions={<CloseButton color="white" />}
                mobileBack={
                    backLink && (
                        <BackButton variant="white" href={backLink}>
                            Back
                        </BackButton>
                    )
                }
                sx={{ bg: 'raspberry' }}
            />
            <Container>
                <LayoutGrid columns="one">
                    <Stepper
                        variant="white"
                        steps={['Booked', 'Your Info', 'Check In']}
                        currentStep={currentStep}
                        sx={{ mb: 6 }}
                    />
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            rowGap: 6
                        }}
                    >
                        <Image src={image} />
                        <Box>
                            <Heading
                                h1
                                sx={{
                                    mb: 2,
                                    color: 'white'
                                }}
                            >
                                {title}
                            </Heading>
                            <Text sx={{ fontSize: 3, color: 'white' }}>
                                {info}
                            </Text>
                        </Box>
                        {btnOnClick && (
                            <Button
                                onClick={btnOnClick}
                                variant="secondaryLight"
                                loading={loading}
                            >
                                {btnText}
                            </Button>
                        )}
                    </Flex>
                </LayoutGrid>
            </Container>
        </Box>
    )
}
