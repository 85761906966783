import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { AppointmentProfile, CareType } from 'src/types'

export async function getCareTypes(): Promise<CareType[]> {
    return await fetcher<CareType[]>(
        `${BASE_API_URL}/tmd-api/appointments/care-types?apiVersion=v2`
    )
}

export const useCareTypes = (name?: string) => {
    const { data, isLoading } = useQuery<CareType[]>(
        ['care-types'],
        getCareTypes,
        {
            staleTime: Infinity // data unlikely to change during a session
        }
    )

    // Filtering here instead of in the query
    // takes advantage of API request caching
    if (name) {
        return {
            careType: (data || []).find(careType => careType.name === name),
            isLoading
        }
    } else {
        return {
            careTypes: data || [],
            isLoading
        }
    }
}

async function getAppointmentProfiles(
    queryParams: string
): Promise<AppointmentProfile[]> {
    const profiles = await fetcher<AppointmentProfile[]>(
        `${BASE_API_URL}/tmd-api/appointments/patient-offerings?apiVersion=v2&${queryParams}`
    )

    return profiles.sort((a: AppointmentProfile, b: AppointmentProfile) => {
        return a.label < b.label ? -1 : 1
    })
}

export const useAppointmentProfilesByCareTypeName = (careTypeName: string) => {
    let queryParams = `careTypeNames[]=${careTypeName}`
    // TM-649: Hack until we figure out what we want to do with Phlebotomy care type
    if (careTypeName === 'primary_care') {
        queryParams = `${queryParams}&careTypeNames[]=phlebotomy`
    }

    const { data, isLoading } = useQuery<AppointmentProfile[]>(
        [`appointment-profiles-by-care-type-name`, careTypeName],
        () => getAppointmentProfiles(queryParams),
        {
            staleTime: 1000 * 60 * 5 // refetch  rendered data after 5min
        }
    )

    return {
        appointmentProfiles: data || [],
        isLoading
    }
}

export const useAppointmentProfilesByApptProfileName = (
    apptProfileName: string,
    automatedSuggestion = false
) => {
    const queryParams = `profileNames[]=${apptProfileName}&automatedSuggestion=${automatedSuggestion}`

    const { data, isLoading } = useQuery<AppointmentProfile[]>(
        [`appointment-profiles-by-appt-profile-name`, apptProfileName],
        () => getAppointmentProfiles(queryParams),
        {
            staleTime: 1000 * 60 * 5 // refetch rendered data after 5min
        }
    )

    return {
        appointmentProfiles: data || [],
        isLoading
    }
}

export const useCareTypeViewData = (careTypeName: string) => {
    // Make both requests in parallel
    const careTypeMeta = useCareTypes(careTypeName)
    const profiles = useAppointmentProfilesByCareTypeName(careTypeName)

    return {
        careType: careTypeMeta.careType,
        appointmentProfiles: profiles.appointmentProfiles,
        isLoading: careTypeMeta.isLoading || profiles.isLoading
    }
}
