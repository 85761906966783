import React from 'react'
import { Heading, Radio } from '@asktia/tia-ui'

export interface ITentativeQuestionProps {
    appointmentLabel: string
}

export const TentativeVerificationQuestion = ({
    appointmentLabel
}: ITentativeQuestionProps) => {
    return (
        <>
            <Heading h4>
                Has a Care Coordinator confirmed that Tia takes your insurance
                for {appointmentLabel.toLowerCase()}?
            </Heading>
            <Radio name="usedInsuranceBefore" value="yes" pretty sx={{ mb: 2 }}>
                Yes
            </Radio>
            <Radio name="usedInsuranceBefore" value="no" pretty sx={{ mb: 4 }}>
                No
            </Radio>
        </>
    )
}
