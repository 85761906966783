import { useModal } from 'react-modal-hook'
import { CallTia } from 'src/components/Blocks'
import {
    Box,
    Button,
    Heading,
    TiaModal,
    Link,
    Flex,
    ThemeStyleObject,
    Paragraph
} from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import { CloseIcon } from 'src/components/Blocks/Icons'

const ErrorIcon = (props: { sx?: ThemeStyleObject; errorType?: string }) => {
    switch (props.errorType) {
        case 'booking':
            // Blue Computer and Phone
            return (
                <Box sx={props.sx}>
                    <svg
                        width="150"
                        height="116"
                        viewBox="0 0 150 116"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M97.5 82H6.75C3.57436 82 1 84.5744 1 87.75V87.75C1 90.9256 3.57436 93.5 6.75 93.5H95.5"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                        <path
                            d="M128.5 34V7.5C128.5 3.91015 125.59 1 122 1H21.5C17.9102 1 15 3.91015 15 7.5V75.5C15 79.0898 17.9101 82 21.5 82H91"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                        <rect
                            x="22.5"
                            y="9.5"
                            width="100"
                            height="66"
                            rx="3.25904"
                            fill="#6462FF"
                        />
                        <path
                            d="M75.5 32.5C74.9477 32.5 74.5 32.9477 74.5 33.5C74.5 34.0523 74.9477 34.5 75.5 34.5V32.5ZM105.578 34.5C106.131 34.5 106.578 34.0523 106.578 33.5C106.578 32.9477 106.131 32.5 105.578 32.5V34.5ZM75.5 34.5H105.578V32.5H75.5V34.5Z"
                            fill="black"
                        />
                        <path
                            d="M75.5 59.5C74.9477 59.5 74.5 59.9477 74.5 60.5C74.5 61.0523 74.9477 61.5 75.5 61.5V59.5ZM99.5 61.5C100.052 61.5 100.5 61.0523 100.5 60.5C100.5 59.9477 100.052 59.5 99.5 59.5V61.5ZM75.5 61.5H99.5V59.5H75.5V61.5Z"
                            fill="black"
                        />
                        <path
                            d="M75.5 50.2244C74.9477 50.2244 74.5 50.6721 74.5 51.2244C74.5 51.7766 74.9477 52.2244 75.5 52.2244V50.2244ZM101.308 52.2244C101.86 52.2244 102.308 51.7766 102.308 51.2244C102.308 50.6721 101.86 50.2244 101.308 50.2244V52.2244ZM75.5 52.2244H101.308V50.2244H75.5V52.2244Z"
                            fill="black"
                        />
                        <path
                            d="M75.5 41.5C74.9477 41.5 74.5 41.9477 74.5 42.5C74.5 43.0523 74.9477 43.5 75.5 43.5V41.5ZM101.5 43.5C102.052 43.5 102.5 43.0523 102.5 42.5C102.5 41.9477 102.052 41.5 101.5 41.5V43.5ZM75.5 43.5H101.5V41.5H75.5V43.5Z"
                            fill="black"
                        />
                        <rect
                            x="32.5002"
                            y="33.5"
                            width="31.9811"
                            height="26.5867"
                            rx="1.67434"
                            fill="white"
                            stroke="black"
                            strokeWidth="2"
                        />
                        <path
                            d="M40.5451 21.6769C41.8084 21.6769 42.8325 20.7418 42.8325 19.5884C42.8325 18.435 41.8084 17.5 40.5451 17.5C39.2819 17.5 38.2578 18.435 38.2578 19.5884C38.2578 20.7418 39.2819 21.6769 40.5451 21.6769Z"
                            fill="white"
                        />
                        <path
                            d="M48.5001 21.6769C49.6535 21.6769 50.5885 20.7418 50.5885 19.5884C50.5885 18.435 49.6535 17.5 48.5001 17.5C47.3466 17.5 46.4116 18.435 46.4116 19.5884C46.4116 20.7418 47.3466 21.6769 48.5001 21.6769Z"
                            fill="white"
                        />
                        <path
                            d="M32.5882 21.6769C33.7416 21.6769 34.6766 20.7418 34.6766 19.5884C34.6766 18.435 33.7416 17.5 32.5882 17.5C31.4348 17.5 30.4998 18.435 30.4998 19.5884C30.4998 20.7418 31.4348 21.6769 32.5882 21.6769Z"
                            fill="white"
                        />
                        <rect
                            x="108.503"
                            y="36.373"
                            width="41.6835"
                            height="74.3122"
                            rx="7.5"
                            transform="rotate(7.83462 108.503 36.373)"
                            fill="white"
                            stroke="black"
                            strokeWidth="2"
                        />
                        <rect
                            x="111.918"
                            y="41.579"
                            width="33.4967"
                            height="64.9285"
                            rx="4"
                            transform="rotate(7.83462 111.918 41.579)"
                            fill="#6462FF"
                        />
                        <path
                            d="M121.549 49.7037C122.239 49.6967 122.793 49.1314 122.786 48.4411C122.779 47.7507 122.214 47.1968 121.524 47.2038C120.833 47.2109 120.279 47.7762 120.286 48.4665C120.293 49.1568 120.859 49.7107 121.549 49.7037Z"
                            fill="white"
                        />
                        <path
                            d="M126.775 50.3911C127.466 50.3841 128.02 49.8188 128.013 49.1284C128.006 48.4381 127.44 47.8842 126.75 47.8912C126.06 47.8982 125.506 48.4635 125.513 49.1539C125.52 49.8442 126.085 50.3981 126.775 50.3911Z"
                            fill="white"
                        />
                        <path
                            d="M116.775 49.0135C117.466 49.0065 118.02 48.4412 118.013 47.7509C118.006 47.0606 117.44 46.5066 116.75 46.5137C116.06 46.5207 115.506 47.086 115.513 47.7763C115.52 48.4666 116.085 49.0205 116.775 49.0135Z"
                            fill="white"
                        />
                        <path
                            d="M112.808 58.9584C112.26 58.8882 111.782 59.2777 111.74 59.8284C111.697 60.3791 112.107 60.8824 112.655 60.9525L112.808 58.9584ZM137.49 64.134C138.038 64.2042 138.517 63.8147 138.559 63.264C138.601 62.7134 138.191 62.2101 137.643 62.1399L137.49 64.134ZM112.655 60.9525L137.49 64.134L137.643 62.1399L112.808 58.9584L112.655 60.9525Z"
                            fill="black"
                        />
                        <path
                            d="M111.003 77.9535C110.455 77.8833 109.976 78.2728 109.934 78.8235C109.892 79.3741 110.302 79.8774 110.85 79.9476L111.003 77.9535ZM130.666 82.4862C131.214 82.5564 131.692 82.1668 131.735 81.6162C131.777 81.0655 131.367 80.5622 130.819 80.492L130.666 82.4862ZM110.85 79.9476L130.666 82.4862L130.819 80.492L111.003 77.9535L110.85 79.9476Z"
                            fill="black"
                        />
                        <path
                            d="M111.447 71.3911C110.899 71.3205 110.398 71.7073 110.327 72.255C110.256 72.8028 110.643 73.3041 111.191 73.3747L111.447 71.3911ZM135.372 76.4918C135.92 76.5624 136.421 76.1756 136.492 75.6278C136.562 75.0801 136.176 74.5788 135.628 74.5082L135.372 76.4918ZM111.191 73.3747L135.372 76.4918L135.628 74.5082L111.447 71.3911L111.191 73.3747Z"
                            fill="black"
                        />
                        <path
                            d="M112.497 65.3031C111.95 65.2304 111.447 65.6153 111.374 66.1628C111.302 66.7102 111.687 67.213 112.234 67.2857L112.497 65.3031ZM136.368 70.4913C136.916 70.564 137.419 70.1791 137.491 69.6317C137.564 69.0842 137.179 68.5814 136.632 68.5087L136.368 70.4913ZM112.234 67.2857L136.368 70.4913L136.632 68.5087L112.497 65.3031L112.234 67.2857Z"
                            fill="black"
                        />
                    </svg>
                </Box>
            )
        default:
            // Pink Lady At Desk
            return (
                <Box sx={props.sx}>
                    <svg
                        width="150"
                        height="150"
                        viewBox="0 0 150 150"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="150" height="150" fill="white" />
                        <path
                            d="M37.191 49.5307C35.9888 50.0783 35.167 51.1973 35.167 52.4909C35.167 52.7681 35.2051 53.0385 35.2773 53.2952C35.6768 54.7325 37.1225 55.8002 38.8459 55.8002C40.8774 55.8002 42.5248 54.3184 42.5248 52.4909C42.5248 50.66 40.8774 49.1782 38.8459 49.1782"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M44.8798 42.9239C47.8539 42.9239 49.1468 42.3981 49.1468 41.632C49.1468 40.8697 46.1765 37.2383 39.414 37.2383C39.414 37.2383 38.0004 37.2571 36.4135 37.9931C36.5153 37.6777 36.5718 37.3434 36.5718 36.9942C36.5718 35.1916 35.1055 33.7271 33.2961 33.7271C31.4867 33.7271 30.0166 35.1916 30.0166 36.9942C30.0166 38.7968 31.4867 40.2576 33.2961 40.2576C32.8902 40.8474 32.0786 42.7795 32.0786 45.7892C32.0786 52.5713 35.701 54.3288 35.701 54.3288L37.8798 50.2317C37.8798 50.2317 36.5718 48.7484 36.5718 47.2688C36.5718 46.0821 37.3521 45.5751 38.2341 45.5751C39.8211 45.5751 40.1792 47.22 40.1792 47.22C40.1792 47.22 41.9056 42.9239 44.8798 42.9239Z"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M48.7792 42.1934C48.7792 42.1934 50.0323 43.5837 50.0323 47.985C50.0323 51.7932 48.4293 55.6461 46.6121 55.6461C45.3859 55.6461 44.7986 55.1202 43.7127 55.1202C42.6748 55.1202 41.8807 56.2617 41.8807 57.5C41.8807 57.5 47.4468 58.5343 51 60.5M26.1943 61.1813L35.502 57.2836L35.3986 52.4795"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M63.5 68C63.5 68 64.9399 61.5035 64.9399 59.3675C64.9399 57.2315 61.6553 54.6913 61.6553 53.3639C61.6553 52.0366 64.0703 55.0117 64.9399 55.0117C65.8094 55.0117 64.4576 47.7494 65.2285 47.7494C65.9994 47.7494 65.6193 48.9963 66.2917 49.2102C67.4197 49.5691 66.1456 46.2351 66.8726 46.2351C67.5997 46.2351 66.6822 49.281 67.7897 49.0081C68.6678 48.7916 68.0235 46.8912 68.7286 46.8912C69.4338 46.8912 69.7224 48.7067 69.4813 50.774C69.2402 52.8414 69.7699 48.8059 70.3983 48.8059C71.0267 48.8059 71.2204 50.1676 71.2204 54.1496C71.2204 58.1317 69.6749 59.7985 69.1927 62.7202C68.7104 65.6457 68.1441 69.0442 68.1441 69.0442"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M123.19 47.0799C123.797 48.131 123.038 49.4449 121.824 49.4449L84.1757 49.4449C82.962 49.4449 82.2034 48.131 82.8103 47.0799L101.635 14.4753C102.241 13.4242 103.759 13.4242 104.365 14.4753L123.19 47.0799Z"
                            fill="#EF308D"
                        />
                        <path
                            d="M101.913 38.2474H104.407V27.3352H101.913V38.2474ZM103.149 43.5319C104.224 43.5319 105.093 42.6397 105.093 41.5874C105.093 40.535 104.224 39.6657 103.149 39.6657C102.074 39.6657 101.227 40.535 101.227 41.5874C101.227 42.6397 102.074 43.5319 103.149 43.5319Z"
                            fill="white"
                        />
                        <path
                            d="M137.929 136H11.0713C8.82338 136 7 133.68 7 130.82V77H142V130.82C142 133.68 140.18 136 137.929 136Z"
                            fill="white"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M24.1646 61.6216C26.0295 59.6983 30.6594 57.9251 33.1489 57.145C33.4117 57.0627 33.6882 57.2131 33.7773 57.4738C34.577 59.8134 36.626 63.8057 39.6005 64.1892C42.5913 64.5747 43.4769 60.1658 43.5745 57.4997C43.5846 57.2237 43.7698 56.9954 44.0441 57.0279C44.6717 57.1022 46.0067 57.3344 48.5633 58.027C54 59.5 59.5177 67.2703 60.5136 68.2973C61.2349 69.0412 61.7385 68.7187 61.9506 68.397C61.9921 68.3339 62.0069 68.2574 62.0091 68.1819C62.0434 67.016 62.5972 66.5064 63.0761 66.326C63.3097 66.238 63.5224 66.4302 63.5877 66.6712C64.2075 68.9571 66.9935 69.1224 68.6417 68.8709C68.8457 68.8398 69.0588 68.924 69.1126 69.1232C69.4988 70.5521 68.0609 73.9083 67.1251 75.7341C67.0406 75.899 66.8708 76 66.6856 76H56.7418C56.6065 76 56.477 75.9452 56.3828 75.8481L53.4057 72.7777C53.093 72.4553 52.5467 72.6766 52.5467 73.1258V74.9865C52.5467 75.2626 52.3229 75.4865 52.0467 75.4865H21.677C21.4009 75.4865 21.1764 75.2628 21.1672 74.9868C21.1085 73.2224 20.8166 69.8587 21.1771 68C21.675 65.4324 21.6749 64.1892 24.1646 61.6216Z"
                            fill="#EF308D"
                        />
                        <path
                            d="M145 76H5V79H145V76Z"
                            fill="white"
                            stroke="black"
                            strokeWidth="1.46119"
                            strokeMiterlimit="10"
                        />
                    </svg>
                </Box>
            )
    }
}

export const ErrorModal = (props: {
    hideModal: Function
    errorType?: string
}) => {
    let errorCopy

    switch (props.errorType) {
        case 'cardDeclined':
            errorCopy = (
                <p>
                    Please check your payment details and/or try another payment
                    method. If that does not work, contact your Care
                    Coordinator.
                </p>
            )
            break
        case 'booking':
            errorCopy = (
                <p>
                    Looks like our Appointment Booking is out of commission at
                    the moment — apologies! Please try again in a few minutes or{' '}
                    <Link
                        as={RouterLink}
                        sx={{ fontSize: 4, color: 'blue' }}
                        // @ts-ignore: TS doesn't understand this is a RouterLink now
                        to="/chat/new"
                    >
                        contact your Care Coordinator
                    </Link>{' '}
                    and they can help get you booked!
                </p>
            )
            break
        default:
            errorCopy = (
                <p>
                    Something went wrong. Please try again or contact your Care
                    Coordinator.
                </p>
            )
    }

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    mb: 5
                }}
            >
                <Heading h2 sx={{ m: 0 }}>
                    Oh no!
                </Heading>
                <Box
                    onClick={() => props.hideModal()}
                    sx={{ cursor: 'pointer' }}
                >
                    <CloseIcon color="text" />
                </Box>
            </Flex>
            <ErrorIcon
                sx={{ textAlign: 'center' }}
                errorType={props.errorType}
            />
            {errorCopy}

            <Button sx={{ mt: 4 }} onClick={() => props.hideModal()} fullWidth>
                Close
            </Button>
            {props.errorType !== 'booking' && (
                <Paragraph sx={{ fontSize: 2, textAlign: 'center', mt: 4 }}>
                    Need help now?
                    <br />
                    <CallTia>Give us a call</CallTia>
                </Paragraph>
            )}
        </TiaModal>
    )
}

export function useErrorModal(
    errorType?: string,
    hideModalCallback?: Function
) {
    const [showErrorModal, hideErrorModal] = useModal(() => (
        <ErrorModal
            hideModal={() => {
                hideModalCallback?.()
                hideErrorModal()
            }}
            errorType={errorType}
        />
    ))

    return showErrorModal
}
