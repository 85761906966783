import { Text, Link } from '@asktia/tia-ui'
import { useQuery } from 'react-query'
import { BASE_API_URL, APPOINTMENT_PROFILE_NAMES } from 'src/globals'
import { AppointmentProfile } from 'src/types'
import { fetcher } from 'src/fetcher'

export async function getAppointmentProfile(
    appointmentProfileUuid: string,
    appointmentSuggestionUuid?: string | null
): Promise<AppointmentProfile> {
    const {
        MASSAGE,
        MENOPAUSE_PERIMENOPAUSE,
        MENOPAUSE_FOLLOWUP,
        FERTILITY_CONCEPTION,
        FERTILITY_ASSESSMENT,
        FERTILITY_FOLLOWUP,
        GROUP_THERAPY
    } = APPOINTMENT_PROFILE_NAMES

    const queryParams = new URLSearchParams({
        apiVersion: 'v2'
    })
    if (appointmentSuggestionUuid) {
        queryParams.set('appointmentSuggestionUuid', appointmentSuggestionUuid)
    }

    const profile = await fetcher<AppointmentProfile>(
        `${BASE_API_URL}/tmd-api/appointments/patient-offerings/${appointmentProfileUuid}?${queryParams.toString()}`
    )

    // TODO TM-5952: we should move this into the DB
    switch (profile.name) {
        case 'therapy':
        case 'talk_therapy_intake':
        case 'talk_therapy_program':
        case 'talk_therapy_returning':
            profile.pricingDetails = {
                text: 'Your insurance may cover this service. Please contact a Care Coordinator before your appointment to check benefits.',
                showServicePriceList: true
            }
            profile.emergencyHelpText = (
                <Text
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 5,
                        mb: 6
                    }}
                >
                    If you're experiencing thoughts of suicide, have been
                    harmed, or are in need of urgent medical attention, please
                    call 911 or reach out to:
                    <br />
                    National Suicide Prevention Hotline:
                    <br />
                    <Link
                        sx={{ color: 'primaryButton' }}
                        href="tel:1-800-273-8255"
                    >
                        1-800-273-8255
                    </Link>
                    <br />
                    National Domestic Violence Hotline:
                    <br />
                    <Link
                        sx={{ color: 'primaryButton' }}
                        href="tel:1-800-799-SAFE"
                    >
                        1-800-799-SAFE (7233)
                    </Link>
                </Text>
            )
            break

        case 'acupuncture':
            profile.pricingDetails = {
                text: 'Your insurance may cover this service. Please contact a Care Coordinator before your appointment to check benefits.',
                showServicePriceList: true
            }
            break

        case 'cupping':
            profile.pricingDetails = {
                text: 'Tia is affordable and accessible with transparent pricing. We do not yet accept insurance for this service.',
                showServicePriceList: true
            }
            break

        case 'tia_whole_health_exam_mental_health':
            profile.pricingDetails = {
                text: 'While your annual exam is covered by insurance, the mental health package costs $70. During check-in, ask us for a receipt for HSA/FSA reimbursement!',
                showServicePriceList: true
            }
            profile.emergencyHelpText = (
                <Text
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 5,
                        mb: 6
                    }}
                >
                    If you're experiencing thoughts of suicide, have been
                    harmed, or are in need of urgent medical attention, please
                    call 911 or reach out to:
                    <br />
                    National Suicide Prevention Hotline:
                    <br />
                    <Link
                        sx={{ color: 'primaryButton' }}
                        href="tel:1-800-273-8255"
                    >
                        1-800-273-8255
                    </Link>
                    <br />
                    National Domestic Violence Hotline:
                    <br />
                    <Link
                        sx={{ color: 'primaryButton' }}
                        href="tel:1-800-799-SAFE"
                    >
                        1-800-799-SAFE (7233)
                    </Link>
                </Text>
            )
            break
        case 'pain':
            profile.emergencyHelpText = (
                <Text
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 5,
                        mb: 6
                    }}
                >
                    If you are experiencing severe or sudden pain or bleeding,
                    please seek emergency care immediately.
                </Text>
            )
            break
        case 'mental_health_medication_management':
            profile.emergencyHelpText = (
                <Text
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 5,
                        mb: 6
                    }}
                >
                    This is not a talk therapy appointment. If you are looking
                    to speak with one of our therapists, please book a talk
                    therapy appointment.
                </Text>
            )
            break
        case 'prescription_refill':
            profile.emergencyHelpText = (
                <Text
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 5,
                        mb: 6
                    }}
                >
                    Don’t forget to contact your Care Coordinator to see if you
                    have refills before booking!
                    <br />
                    If you need psychiatric medicine, please book a Mental
                    health medication management appointment.
                </Text>
            )
            break

        case 'tia_whole_health_exam':
            profile.pricingDetails = {
                text: (
                    <>
                        For most people, these appointments should be covered by
                        insurance, but patients may need to pay some out of
                        pocket costs. The in-person appointment is most often
                        covered 100% by in-network insurance as long as you
                        haven't already had your annual or gynecological exam
                        this year. The first virtual and follow-up visits are
                        covered by in-network insurance but co-pays,
                        co-insurance, or deductibles may apply. Labs and imaging
                        may incur additional costs.
                    </>
                )
            }
            break
        case 'annual_preventative_exam':
            profile.pricingDetails = {
                text: (
                    <>
                        This appointment is most often covered 100% by
                        in-network insurance as long as you haven't already had
                        your annual or gynecological exam this year, but
                        co-pays, co-insurance, or deductibles may apply. Labs
                        and imaging may incur additional costs.
                    </>
                )
            }
            break

        case 'tia_whole_health_in_clinic_care':
            profile.pricingDetails = {
                text: (
                    <>
                        This appointment is covered by in-network insurance as
                        long as you haven’t already had your annual or
                        gynecological exam this year.*
                        <br />
                        <br />
                        *Extras like labs and imaging may incur additional
                        costs.
                    </>
                )
            }
            break

        case 'tia_whole_health_follow_up':
            profile.pricingDetails = {
                text: (
                    <>
                        We accept in-network{' '}
                        <Link
                            href="https://asktia.com/insurance"
                            target="_blank"
                        >
                            insurance
                        </Link>{' '}
                        for this appointment but you may be responsible for a
                        copay or coinsurance depending on your plan. We
                        recommend checking with your health plan before booking.
                    </>
                )
            }
            break
        case MASSAGE:
            profile.pricingDetails = {
                text: 'Tia is affordable and accessible with transparent pricing. We do not yet accept insurance for this service.',
                showServicePriceList: true
            }
            break
        case GROUP_THERAPY:
            profile.pricingDetails = {
                text: 'Your insurance may cover group therapy. Please contact a Care Coordinator before your appointment to check benefits.',
                showServicePriceList: true
            }
            break
        case 'pelvic_floor_physical_therapy_initial':
        case 'pelvic_floor_physical_therapy_returning':
            profile.pricingDetails = {
                text: 'Tia is affordable and accessible with transparent pricing. We do not yet accept insurance for this service.',
                showServicePriceList: true
            }
            break
        case 'acu_community':
            profile.pricingDetails = {
                text: '$65 for Community Acu (different than single session)',
                showServicePriceList: false
            }
            break
        case 'acu_allergies':
        case 'acu_mental_health':
        case 'acu_digestion':
        case 'acu_fatigue':
        case 'acu_headache':
        case 'acu_pain':
        case 'acu_sleep':
        case 'acu_fertility':
        case 'acu_pregnancy':
        case 'acu_postpartum':
        case 'acu_menopause':
        case 'acu_miscarriage':
        case 'acu_menstrual':
        case 'acu_general':
        case 'acu_cupping':
            profile.pricingDetails = {
                text: 'If you live in CA: your insurance may cover this service. Please contact a Care Coordinator before your appointment to check benefits.',
                showServicePriceList: true
            }
            break
        case 'coaching_focus_motivation':
        case 'coaching_boundaries':
        case 'coaching_general':
            profile.pricingDetails = {
                text: (
                    <>
                        Coaching is not covered by insurance. See our{' '}
                        <Link
                            href="https://asktia.com/tia-service-list"
                            target="_blank"
                        >
                            coaching prices
                        </Link>
                        .
                    </>
                ),
                // needs to be false if link in text
                showServicePriceList: false
            }
            break
        case 'mh_group_sexual_wellbeing':
        case 'mh_group_setting_boundaries':
        case 'mh_group_connecting_to_your_power':
        case 'mh_group_developing_body_image':
            profile.pricingDetails = {
                text: (
                    <>
                        Groups are not covered by insurance. See our{' '}
                        <Link
                            href="https://asktia.com/tia-service-list"
                            target="_blank"
                        >
                            groups prices
                        </Link>
                        .
                    </>
                ),
                // needs to be false if link in text
                showServicePriceList: false
            }
            break
        case 'cosderm_neuromodulator_30':
        case 'cosderm_neuromodulator_60':
        case 'cosderm_neuromodulator_adjustment':
        case 'cosderm_VIpeel':
        case 'cosderm_microneedling':
            profile.pricingDetails = {
                text: (
                    <>
                        Cosmetic treatments are not covered by insurance.{' '}
                        <Link
                            href="https://asktia.com/tia-service-list"
                            target="_blank"
                        >
                            See our prices here.
                        </Link>
                    </>
                ),
                // needs to be false if link in text
                showServicePriceList: false
            }
            break
    }
    return profile
}

export const useAppointmentProfile = (
    appointmentProfileUuid?: string | null,
    appointmentSuggestionUuid?: string | null
) => {
    const profileQuery = useQuery<AppointmentProfile>(
        [
            'appointment-profile',
            appointmentProfileUuid,
            appointmentSuggestionUuid
        ],
        () =>
            getAppointmentProfile(
                appointmentProfileUuid!,
                appointmentSuggestionUuid
            ),
        {
            staleTime: Infinity
        }
    )

    return {
        profile: profileQuery.data,
        isLoading: profileQuery.isLoading
    }
}
