import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { ReferralCode } from 'src/types'

const DEFAULT_PROMO_ID = '30' //Id of the default promo for all newly registered users

async function getReferralCodes({
    userId,
    promoId = DEFAULT_PROMO_ID
}: {
    userId?: number
    promoId?: string
}) {
    const queryStr = new URLSearchParams({
        promoId: promoId,
        apiVersion: 'v2'
    }).toString()

    const referralCodes = await fetcher<{ referralCodes: ReferralCode[] }>(
        `${BASE_API_URL}/tmd-api/patients/${userId}/referralCodes?${queryStr}`,
        undefined,
        'GET',
        {
            errorMsg: 'Failed to retrieve referral code',
            options: {
                headers: {
                    'x-user': `${userId}`,
                    'service': 'www'
                }
            }
        }
    )

    return referralCodes.referralCodes
}

type ReferralCodeReturn = {
    isLoading: boolean
    referralCodes: ReferralCode[]
}

export function useReferralCodes(): ReferralCodeReturn {
    const { user } = useUserInfo()

    const query = useQuery<ReferralCode[]>(
        ['getReferralCode', user?.id],
        () =>
            getReferralCodes({
                userId: user?.id
            }),
        {
            staleTime: Infinity,
            enabled: !!user
        }
    )

    return {
        isLoading: query.isLoading,
        referralCodes: query.data || []
    }
}
