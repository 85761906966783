import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { MembershipPayment } from 'src/types'

export function useUserMembership() {
    const { user } = useUserInfo()

    const membershipQuery = useQuery<MembershipPayment>(
        ['current-user-membership', user?.id],
        async () =>
            await fetcher(
                `${BASE_API_URL}/tmd-api/patients/${
                    user!.id
                }/subscription?apiVersion=v2`,
                undefined,
                'GET',
                {
                    errorMsg: 'membership returned not-ok',
                    options: {
                        headers: {
                            'x-user': `${user!.id}`
                        }
                    }
                }
            ),
        { enabled: !!user }
    )

    return {
        membership: membershipQuery.data,
        isLoading: membershipQuery.isLoading
    }
}
