import { Button, Container, Flex, Grid, Image, Text } from '@asktia/tia-ui'
import { FC } from 'react'
import { useAmpli } from 'src/hooks'
import {
    useCarePlan,
    useGetUnseenCarePlanInterventions
} from 'src/hooks/useCarePlan'

import AciIllustration from '../../../assets/aci-intermission-illustration.png'

export const CarePlanBanner: FC = () => {
    const { viewYourCarePlanClickedOnHome } = useAmpli()
    const { data: interventions } = useCarePlan()
    const unseenInterventions = useGetUnseenCarePlanInterventions()

    if (!interventions || interventions.length === 0) {
        return null
    }

    return (
        <Container sx={{ mt: 6 }}>
            <Flex
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'primaryButton',
                    width: '100%',
                    borderRadius: 2,
                    p: 5
                }}
            >
                <Grid gap={6} columns={['1fr', '1fr 2fr']}>
                    <Flex
                        sx={{
                            width: '100%',
                            justifyContent: ['center', 'flex-end']
                        }}
                    >
                        <Image
                            src={AciIllustration}
                            sx={{
                                width: 160,
                                height: 160
                            }}
                        />
                    </Flex>

                    <Flex
                        sx={{
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Text
                            sx={{
                                fontFamily: 'Inferi',
                                fontSize: 20,
                                fontWeight: 350,
                                lineHeight: '120%',
                                color: 'white',
                                maxWidth: [undefined, 400]
                            }}
                        >
                            {!unseenInterventions ||
                            unseenInterventions.length === 0
                                ? `Your Care Plan is your go-to routine to help you reach your optimal version of health, supported by your Care Team.`
                                : `${unseenInterventions.length} new item${
                                      unseenInterventions.length > 1 ? 's' : ''
                                  } added to your Care
                            Plan from your provider`}
                        </Text>
                        <Button
                            as="a"
                            href="/r/your-care/plan"
                            variant="secondaryLight"
                            sx={{ mt: 3 }}
                            onClick={() => viewYourCarePlanClickedOnHome()}
                        >
                            View your Care Plan
                        </Button>
                    </Flex>
                </Grid>
            </Flex>
        </Container>
    )
}
