import { useMutation, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export function useUpdateAccountCard() {
    const queryClient = useQueryClient()

    return useMutation(
        async (args: {
            nonce: string
            idempotencyKey: string
            postalCode?: string
        }) =>
            await fetcher(
                `${BASE_API_URL}/api/v1/account/card`,
                JSON.stringify(args),
                'POST'
            ),
        {
            retry: false,
            onSuccess: () => queryClient.invalidateQueries('current-user')
        }
    )
}
