import { useEffect } from 'react'
import {
    Heading,
    Text,
    Flex,
    Button,
    Whisper,
    Box,
    Card,
    Paragraph
} from '@asktia/tia-ui'
import { HealthRecordCard } from 'src/components/HealthRecordCard'
import { HealthRecordEntry, SaveStepFunction } from 'src/types'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'
import { useHealthRecordInfo, useAmpli } from 'src/hooks'

type UhrInfoProps = {
    /**
     * health record entries and their status
     */
    healthRecord: HealthRecordEntry[]

    /**
     * does the API say intakes are complete
     */
    isComplete: boolean

    saveStep: SaveStepFunction

    isSaving: boolean
}

export const UhrInfo = ({
    healthRecord,
    isComplete,
    saveStep,
    isSaving
}: UhrInfoProps) => {
    function onSubmit() {
        saveStep()
    }

    const { uhrViewed } = useAmpli()
    const { uhrLocation, hRPC } = useHealthRecordInfo(healthRecord)

    useEffect(() => {
        uhrViewed(uhrLocation, hRPC)
    }, [hRPC, uhrLocation, uhrViewed])

    return (
        <View withFooter>
            {isComplete ? (
                <Card variant="info" sx={{ mb: 6 }}>
                    <Paragraph>
                        To provide the best care possible, we regularly ask for
                        an updated health record. Common updates include:
                    </Paragraph>
                    <ul style={{ marginBottom: 0, paddingLeft: 16 }}>
                        <li>Preferred pharmacy (Personal Info)</li>
                        <li>Vaccination status (Medical History)</li>
                        <li>Allergies & Medications (Medical History)</li>
                    </ul>
                </Card>
            ) : null}

            <HealthRecordCard
                sx={{ mb: 6 }}
                title="Your Health Record"
                records={healthRecord}
                variant="uhr"
            />
            <Box sx={{ mb: 3 }} />
            <Footer>
                <Button
                    disabled={!isComplete || isSaving}
                    onClick={onSubmit}
                    loading={isSaving}
                >
                    My health info is correct
                    <Text variant="buttonSubtext" as="div">
                        Continue to Card on File
                    </Text>
                </Button>
            </Footer>
        </View>
    )
}

const HealthRecordSkeleton = (width: string, index: number) => (
    <Flex
        key={index}
        sx={{
            pt: 2,
            pb: 2,
            alignItems: 'center',
            borderBottom: ({ colors }) => `1px solid ${colors?.grey10}`,
            justifyContent: 'space-between'
        }}
    >
        <Whisper sx={{ width, height: 56 }}></Whisper>
    </Flex>
)

export const UhrInfoSkeleton = () => {
    return (
        <View>
            <Heading h2>Your Health Record</Heading>
            {['100%', '100%', '100%', '100%', '100%'].map(HealthRecordSkeleton)}

            <Button disabled>
                My health info is correct
                <Text variant="buttonSubtext" as="div">
                    Continue to Card on File
                </Text>
            </Button>
        </View>
    )
}
