import { Card } from '@asktia/tia-ui'
import { FC, ReactNode } from 'react'
import { ThemeUIStyleObject } from '@theme-ui/css'

export const FollowUpQuestionsCard: FC<{
    hidden?: boolean
    children?: ReactNode
    sx?: ThemeUIStyleObject
}> = ({ children, hidden, sx }) => {
    if (hidden) {
        return <>{children}</>
    }

    return (
        <Card variant="info" sx={{ mt: 4, py: 5, px: 4, ...sx }}>
            {children}
        </Card>
    )
}
