import { Spinner, LayoutGrid, Form, Button } from '@asktia/tia-ui'
import { FC, useState } from 'react'
import { useForm, FieldValues, FormProvider } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { GluedSingleChoiceQuestion } from 'src/flows/questionnaires/components/Input/SingleChoice'
import { useQuestionnaire } from 'src/flows/questionnaires/hooks/useQuestionnaire'
import { useQuestionnaireResponse } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { useNavigation } from 'src/hooks'
import { get as _get, omit as _omit } from 'lodash'
import { getFormDone } from 'src/flows/questionnaires/getFormDone'
import { QuestionnaireDivider } from 'src/flows/questionnaires/components/QuestionnaireDivider'
import { ErrorSavingBanner } from 'src/flows/questionnaires/components/ErrorSavingBanner'
import { FormAlertCard } from '../../../components/FormAlertCard'

export const QOLPage: FC = () => {
    const [searchParams] = useSearchParams()
    const { navigate } = useNavigation()
    const [showSavingError, setShowSavingError] = useState(false)

    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
    })

    const { questionnaire } = useQuestionnaire('qol-5')
    const { answersMap, questionnaireResponse, saveQuestionnaireResponse } =
        useQuestionnaireResponse(
            'qol-5',
            searchParams.get('appointmentProfileUuid') as string
        )
    const { formDone } = getFormDone(formMethods.getValues())

    const onComplete = async () => {
        triggerSave()
        navigate(searchParams.get('onCompleteRelativePath') as string)
    }

    const getValidFormValues = async (triggeredByFieldName?: string) => {
        // validate form against latest form values
        await formMethods.trigger(triggeredByFieldName)

        const formValues = formMethods.getValues()
        const formKeys = Object.keys(formValues)

        // TODO: not sure best way to handle this any
        // at the moment the formValues also have an any type
        const validFormValues: any = {}
        const fieldErrors = formMethods.formState.errors

        // Note - this only works for 1 nested level
        // i.e.
        // formValues = {nicotine-use: {polar: 'yes', quantity: 'asdfasdf'}}
        // errors = {nicotine-use: {quantity: 'must be a number'}}
        // validFormValues = {nicotine-use: {polar: 'yes'}
        formKeys.forEach((key: string) => {
            const fieldValue = _get(formValues, key)
            const nestedFieldErrors = _get(fieldErrors, key)
            validFormValues[key as string] =
                typeof fieldValue === 'object' && !Array.isArray(fieldValue)
                    ? _omit(fieldValue, Object.keys(nestedFieldErrors || {}))
                    : fieldValue
        })

        return validFormValues
    }

    // Save questionnaire and handle errors:
    // - clear triggering field, so user knows what's wrong
    // - show error toast
    async function triggerSave(triggeredByFieldName?: string) {
        setShowSavingError(false)

        try {
            await saveQuestionnaireResponse(
                await getValidFormValues(triggeredByFieldName)
            )
        } catch (e) {
            console.error(e)
            if (triggeredByFieldName) {
                formMethods.setValue(triggeredByFieldName, undefined)
            }
            setShowSavingError(true)
        }
    }

    if (!questionnaire.item) {
        return <Spinner />
    }

    const questionFormGlue = {
        questionnaire,
        questionnaireResponse,
        answersMap,
        triggerSave
    }

    const canProceed = formDone && Object.keys(formMethods.errors).length === 0

    return (
        <>
            {showSavingError ? <ErrorSavingBanner /> : null}
            <LayoutGrid columns="one" variant="condensed">
                <FormAlertCard sx={{ fontWeight: 'bold' }}>
                    Over the last 2 weeks, how often have you been bothered by
                    any of the following problems?
                </FormAlertCard>
                <FormProvider {...formMethods}>
                    <Form
                        useFormMethods={formMethods}
                        onSubmit={() => console.log('on submit')}
                    >
                        <GluedSingleChoiceQuestion
                            linkId="cheerful-in-good-spirits"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="calm-and-relaxed"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="active-and-vigorous"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="fresh-and-rested"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="daily-life-filled-things-interest-me"
                            {...questionFormGlue}
                        />
                        <Button
                            sx={{ mt: 6, mb: 8, py: '20px' }}
                            onClick={onComplete}
                            disabled={!canProceed}
                            fullWidth
                        >
                            Complete Quality of Life
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </>
    )
}
