import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { HealthRecordEntry } from 'src/types'

const includeStatus = (
    record: HealthRecordEntry & { pctComplete: number }
) => ({
    ...record,
    status: record.pctComplete === 1 ? 'done' : 'started',
    url: `/intake/health-record/${record.name}`
})

export async function getNormalizedUserInfo() {
    const userInfo = await getUserInfo()
    return {
        ...userInfo,
        records: userInfo.records.map(includeStatus) || []
    }
}

export async function getUserInfo() {
    return await fetcher(`${BASE_API_URL}/data-api/user/health-record-nav`)
}

export const useHealthRecordNav = () =>
    useQuery(['user-info'], getNormalizedUserInfo, { retry: false })
