import { Select } from '@asktia/tia-ui'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export type GeneticMutation = {
    id: number
    display: string
    createdAt: Date
    updatedAt: Date
}

export const GeneticMutationsTypeahead: FC<{ disabled: boolean }> = ({
    disabled
}) => {
    const query = useQuery<GeneticMutation[]>(['mutations-typeahead'], () =>
        fetcher(`${BASE_API_URL}/uhr-api/conditions/genetic-mutations`)
    )

    return (
        <Select
            placeholder="e.g. BRCA or cystic fibrosis"
            name="geneticMutation"
            options={query.data}
            isDisabled={disabled}
            loadingOptions={query.isLoading}
            getOptionLabel={option => option.display}
            getOptionValue={option => String(option.id)}
        />
    )
}
