import {
    Box,
    Flex,
    getCSS,
    IconProps,
    ThemeStyleObject,
    useTiaUI
} from '@asktia/tia-ui'
import { CSSProperties } from 'react'
import { ReactComponent as IconImage } from 'src/assets/icon-image.svg'
import { ReactComponent as IconPdf } from 'src/assets/icon-pdf.svg'

type IconsProps = {
    color: string
    hoverColor?: string
    style?: CSSProperties
    sx?: ThemeStyleObject

    onClick?: () => void
}

export const ChevronRight = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const color = theme.colors?.[props.color]

    return (
        <Box
            as="svg"
            sx={{
                width: '8px',
                height: '12px',
                viewBox: '0 0 8 12',
                fill: 'none',
                ...props.sx
            }}
        >
            <path
                d="M0.99998 0.704992C1.18681 0.517739 1.44046 0.412506 1.70498 0.412506C1.9695 0.412506 2.22315 0.517739 2.40998 0.704992L6.99998 5.29499C7.38998 5.68499 7.38998 6.31499 6.99998 6.70499L2.40998 11.295C2.01998 11.685 1.38998 11.685 0.99998 11.295C0.609981 10.905 0.609981 10.275 0.99998 9.88499L4.87998 5.99499L0.99998 2.11499C0.609981 1.72499 0.61998 1.08499 0.99998 0.704992Z"
                fill={color as string}
            />
        </Box>
    )
}

export const ChevronLeft = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const color = theme.colors?.[props.color]

    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.00002 0.704992C6.81319 0.517739 6.55954 0.412506 6.29502 0.412506C6.0305 0.412506 5.77685 0.517739 5.59002 0.704992L1.00002 5.29499C0.61002 5.68499 0.61002 6.31499 1.00002 6.70499L5.59002 11.295C5.98002 11.685 6.61002 11.685 7.00002 11.295C7.39002 10.905 7.39002 10.275 7.00002 9.88499L3.12002 5.99499L7.00002 2.11499C7.39002 1.72499 7.38002 1.08499 7.00002 0.704992Z"
                fill={color as string}
            />
        </svg>
    )
}

export const PhotoIcon = () => {
    return (
        <Box
            as="svg"
            sx={{
                minWidth: '16px',
                minHeight: '16px',
                width: '16px',
                height: '16px'
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 3.33333V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.6 2 3.33333 2H12.6667C13.4 2 14 2.6 14 3.33333ZM7.33333 11.0067L5.93333 9.32C5.79333 9.15333 5.54 9.16 5.41333 9.33333L3.75333 11.4667C3.58 11.6867 3.73333 12.0067 4.01333 12.0067H12.0067C12.28 12.0067 12.44 11.6933 12.2733 11.4733L9.93333 8.35333C9.8 8.17333 9.53333 8.17333 9.4 8.34667L7.33333 11.0067Z"
                fill="#282725"
            />
        </Box>
    )
}

export const AttachementIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.1373 10.5L3.68627 10.5C2.5098 10.5 1.33333 9.72222 1.33333 8.16667C1.33333 6.61111 2.36684 5.83333 3.68627 5.83333C4.31372 5.83333 10.2222 5.83333 13.098 5.83333C13.6209 5.83333 14.6667 6.22222 14.6667 7.38889C14.6667 8.16667 13.8823 8.94444 13.098 8.94444C12.4706 8.94444 7.60784 8.94444 5.2549 8.94444C4.99346 8.94444 4.47058 8.78889 4.47058 8.16667C4.47058 7.54444 4.99346 7.38889 5.2549 7.38889L11.1373 7.38889"
                stroke="#8C8C8C"
                strokeWidth="1.16667"
            />
        </svg>
    )
}

export const AttachmentClipIcon = (props: IconsProps) => {
    return (
        <Box
            as="svg"
            sx={{
                'width': '24px',
                'height': '24px',
                '& path': {
                    fill: props.color
                },
                '&:hover path': { fill: props.hoverColor }
            }}
            style={props.style}
        >
            <path d="M16 6.74758V17.3276C16 19.4176 14.47 21.2776 12.39 21.4776C10 21.7076 8 19.8376 8 17.4976V5.13758C8 3.82758 8.94 2.63758 10.24 2.50758C11.74 2.35758 13 3.52758 13 4.99758V15.4976C13 16.0476 12.55 16.4976 12 16.4976C11.45 16.4976 11 16.0476 11 15.4976V6.74758C11 6.33758 10.66 5.99758 10.25 5.99758C9.84 5.99758 9.5 6.33758 9.5 6.74758V15.3576C9.5 16.6676 10.44 17.8576 11.74 17.9876C13.24 18.1376 14.5 16.9676 14.5 15.4976V5.16758C14.5 3.07758 12.97 1.21758 10.89 1.01758C8.51 0.787583 6.5 2.65758 6.5 4.99758V17.2676C6.5 20.1376 8.6 22.7076 11.46 22.9776C14.75 23.2776 17.5 20.7176 17.5 17.4976V6.74758C17.5 6.33758 17.16 5.99758 16.75 5.99758C16.34 5.99758 16 6.33758 16 6.74758Z" />
        </Box>
    )
}

export const AttachmentIcon = (
    props: IconsProps & { fileType: 'image' | 'pdf' }
) => {
    return (
        <Box
            as="svg"
            sx={{
                'width': '24px',
                'height': '24px',
                '& path': {
                    fill: props.color
                },
                '&:hover path': { fill: props.hoverColor }
            }}
            style={props.style}
        >
            {props.fileType === 'image' && <IconImage />}
            {props.fileType === 'pdf' && <IconPdf />}
        </Box>
    )
}

export const CheckmarkIcon = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const color = theme.colors?.[props.color]

    return (
        <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke={color as string}
                d="M2 8.50723L7.46939 14.2914L19.0918 2"
                strokeWidth="2.65873"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const SmallCheckmarkIcon = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const color = theme.colors?.[props.color]

    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.86368 10.6842L3.55034 8.3709C3.42579 8.24606 3.25669 8.17591 3.08034 8.17591C2.904 8.17591 2.7349 8.24606 2.61034 8.3709C2.35034 8.6309 2.35034 9.0509 2.61034 9.3109L5.39701 12.0976C5.65701 12.3576 6.07701 12.3576 6.33701 12.0976L13.3903 5.04423C13.6503 4.78423 13.6503 4.36423 13.3903 4.10423C13.2658 3.9794 13.0967 3.90924 12.9203 3.90924C12.744 3.90924 12.5749 3.9794 12.4503 4.10423L5.86368 10.6842Z"
                fill={color as string}
            />
        </svg>
    )
}

export const CircleInfoIcon = ({ color, hoverColor }: IconsProps) => (
    <Box
        as="svg"
        sx={{
            'width': '24px',
            'height': '24px',
            '& path': {
                fill: color
            },
            '&:hover path': { fill: hoverColor }
        }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.00006C6.48 2.00006 2 6.48006 2 12.0001C2 17.5201 6.48 22.0001 12 22.0001C17.52 22.0001 22 17.5201 22 12.0001C22 6.48006 17.52 2.00006 12 2.00006ZM12 7.00007C11.4477 7.00007 11 7.44779 11 8.00007C11 8.55236 11.4477 9.00007 12 9.00007C12.5523 9.00007 13 8.55236 13 8.00007C13 7.44779 12.5523 7.00007 12 7.00007ZM13 16.0001C13 16.5501 12.55 17.0001 12 17.0001C11.45 17.0001 11 16.5501 11 16.0001V12.0001C11 11.4501 11.45 11.0001 12 11.0001C12.55 11.0001 13 11.4501 13 12.0001V16.0001ZM4 12.0001C4 16.4101 7.59 20.0001 12 20.0001C16.41 20.0001 20 16.4101 20 12.0001C20 7.59006 16.41 4.00006 12 4.00006C7.59 4.00006 4 7.59006 4 12.0001Z"
        />
    </Box>
)

export const ArrowRightIcon = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const color = theme.colors?.[props.color]

    return (
        <svg
            width="17"
            height="12"
            viewBox="0 0 17 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={props.style}
        >
            <path
                fill={color as string}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7574 0.696722L16.5303 5.46969C16.8232 5.76259 16.8232 6.23746 16.5303 6.53035L11.7574 11.3033C11.4645 11.5962 10.9896 11.5962 10.6967 11.3033C10.4038 11.0104 10.4038 10.5356 10.6967 10.2427L14.1893 6.75002L-5.48401e-07 6.75002L-4.17266e-07 5.25002L14.1893 5.25002L10.6967 1.75738C10.4038 1.46449 10.4038 0.989615 10.6967 0.696722C10.9896 0.403828 11.4645 0.403828 11.7574 0.696722Z"
            />
        </svg>
    )
}

export const PlusIcon = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const cssGet = getCSS(theme)
    const color = cssGet('color', props.color)

    return (
        <Box
            as="svg"
            // @ts-ignore
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={props.sx}
        >
            <path
                d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
                fill={color as string}
            />
        </Box>
    )
}

export const CloseIcon = ({
    color,
    hoverColor,
    style,
    sx,
    onClick
}: IconsProps) => (
    <Box
        as="svg"
        style={style}
        sx={{
            'width': '24px',
            'height': '24px',
            '& path': {
                fill: color
            },
            '&:hover path': { fill: hoverColor },
            ...sx
        }}
        onClick={onClick}
    >
        <path d="M18.2997 5.70999C18.1129 5.52273 17.8592 5.4175 17.5947 5.4175C17.3302 5.4175 17.0766 5.52273 16.8897 5.70999L11.9997 10.59L7.10973 5.69999C6.9229 5.51273 6.66924 5.4075 6.40473 5.4075C6.14021 5.4075 5.88656 5.51273 5.69973 5.69999C5.30973 6.08999 5.30973 6.71999 5.69973 7.10999L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10999C18.6797 6.72999 18.6797 6.08999 18.2997 5.70999Z" />
    </Box>
)

export const FilterIcon = ({ color, hoverColor, style, sx }: IconsProps) => (
    <Box
        as="svg"
        style={style}
        sx={{
            'width': '16px',
            'height': '16px',
            '& path': {
                fill: color
            },
            '&:hover path': { fill: hoverColor },
            ...sx
        }}
    >
        <path d="M2 4.66667C2 5.03333 2.3 5.33333 2.66667 5.33333H13.3333C13.7 5.33333 14 5.03333 14 4.66667C14 4.3 13.7 4 13.3333 4H2.66667C2.3 4 2 4.3 2 4.66667ZM7.33333 12H8.66667C9.03333 12 9.33333 11.7 9.33333 11.3333C9.33333 10.9667 9.03333 10.6667 8.66667 10.6667H7.33333C6.96667 10.6667 6.66667 10.9667 6.66667 11.3333C6.66667 11.7 6.96667 12 7.33333 12ZM11.3333 8.66667H4.66667C4.3 8.66667 4 8.36667 4 8C4 7.63333 4.3 7.33333 4.66667 7.33333H11.3333C11.7 7.33333 12 7.63333 12 8C12 8.36667 11.7 8.66667 11.3333 8.66667Z" />
    </Box>
)

export const StarIcon = ({ color, hoverColor, style }: IconsProps) => (
    <Box
        as="svg"
        style={style}
        sx={{
            'width': '10px',
            'height': '10px',
            '& path': {
                fill: color
            },
            '&:hover path': { fill: hoverColor }
        }}
    >
        <path d="M4.99972 7.56675L7.18639 9.15786C7.53305 9.41564 8.00417 9.06897 7.87083 8.66008L7.03528 6.0912L9.19528 4.55342C9.55083 4.30453 9.37306 3.74453 8.9375 3.74453H6.28861L5.42639 1.06008C5.29306 0.651196 4.71528 0.651196 4.58194 1.06008L3.71083 3.74453H1.06194C0.626388 3.74453 0.44861 4.30453 0.804166 4.55342L2.96417 6.0912L2.12861 8.66008C1.99528 9.06897 2.46639 9.40675 2.81306 9.15786L4.99972 7.56675Z" />
    </Box>
)

export const MessagesIcon = (props: IconProps) => {
    const { theme } = useTiaUI()
    const cssGet = getCSS(theme)
    const color = String(
        props.color ? cssGet('color', props.color) : theme.colors?.white
    )

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.8 9C3.2536 9 2 10.1939 2 11.6667V15.6667C2 17.1394 3.2536 18.3333 4.8 18.3333H6.2V21L9 18.3333H11.5C13.0464 18.3333 14.3 17.1394 14.3 15.6667V11.6667C14.3 10.1939 13.0464 9 11.5 9H4.8Z"
                stroke={color}
                strokeWidth="1.3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 7V5.66667C8 4.19391 9.2536 3 10.8 3H19.2C20.7464 3 22 4.19391 22 5.66667V9.66667C22 11.1394 21.5464 12.3333 20 12.3333H19.5V15L17 12.3333H16.5"
                stroke={color}
                strokeWidth="1.3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

type AlerIconProps = {
    sx?: ThemeStyleObject
    variant?: 'primaryButton' | 'communicationBanner'
}

export const AlertIcon = ({ variant, sx }: AlerIconProps) => {
    const { theme } = useTiaUI()
    const circleFill = theme.colors?.[variant || 'primaryButton']

    return (
        <Box sx={{ ...sx }}>
            <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="4" cy="4" r="4" fill={circleFill as string} />
            </svg>
        </Box>
    )
}

export const ProfileIcon = (props: IconsProps) => {
    const { theme } = useTiaUI()
    const cssGet = getCSS(theme)
    const color = String(
        props.color ? cssGet('color', props.color) : theme.colors?.white
    )

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="11.1111"
                cy="7.74074"
                r="4.09074"
                stroke={color}
                strokeWidth="1.3"
            />
            <path
                d="M11.7037 14.8518H8.79613C7.41086 14.8518 6.12421 15.5685 5.39507 16.7464L4 19"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path
                d="M11.7038 14.8518H15"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <rect
                x="18"
                y="14"
                width="1"
                height="7"
                rx="0.15"
                fill={color}
                stroke={color}
            />
            <rect
                x="22"
                y="17"
                width="1"
                height="7"
                rx="0.15"
                transform="rotate(90 22 17)"
                fill={color as string}
                stroke={color as string}
            />
        </svg>
    )
}
