import { useMessageThreads } from 'src/hooks/useMessageThreads'

export function useUnreadMessages() {
    let value = false

    const { threads, isLoading } = useMessageThreads()

    if (threads.length) {
        value = threads[0].hasUnreadMessages
    }

    return {
        isLoading,
        value
    }
}
