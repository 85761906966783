import { Box, Textarea } from '@asktia/tia-ui'
import { FC } from 'react'
import {
    BaseInput,
    IBaseInput
} from 'src/flows/questionnaires/components/Input/BaseInput'
import { QuestionFormGlue } from '../../types'
import { FollowUpQuestionsCard } from '../FollowUpQuestionsCard'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'
import { ThemeUIStyleObject } from '@theme-ui/css'

export interface ILongOpenInput
    extends Omit<IBaseInput, 'render' | 'readDefaultValue'> {
    placeholder?: string
}

export const LongOpenInput: FC<ILongOpenInput> = ({
    questionnaireItem,
    answersMap,
    variant,
    placeholder,
    sx,
    index,
    triggerSave
}) => {
    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            variant={variant}
            sx={sx}
            index={index}
            triggerSave={triggerSave}
            readDefaultValue={defaultValue =>
                defaultValue && defaultValue.length > 0
                    ? defaultValue[0].valueString
                    : undefined
            }
            render={props => (
                // The weird CSS and Boxes enable a custom resize handler
                <Box sx={{ position: 'relative', minWidth: '100%' }}>
                    <Box
                        as="svg"
                        sx={{
                            width: '6px',
                            height: '6px',
                            position: 'absolute',
                            right: 2,
                            bottom: '20px',
                            zIndex: 2
                        }}
                    >
                        <path
                            d="M1.22739 5.88477L4.8902 5.88477C5.44174 5.88477 5.88722 5.43929 5.88722 4.88774L5.88722 1.22493C5.88722 0.333977 4.80535 -0.1115 4.17602 0.517825L0.51321 4.18064C-0.116116 4.80996 0.336433 5.88477 1.22739 5.88477Z"
                            fill="#BE9191"
                        />
                    </Box>
                    <Textarea
                        {...props}
                        placeholder={placeholder}
                        sx={{
                            'mb': 3,
                            'height': 9,
                            'position': 'relative',
                            'zIndex': 1,
                            'minWidth': '100%',
                            'maxWidth': '100%',
                            '::-webkit-resizer': {
                                display: 'none'
                            }
                        }}
                    />
                </Box>
            )}
        />
    )
}

export const GluedLongOpenInputQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    questionnaireResponse,
    sx,
    variant,
    followupCardSx,
    placeholder,
    index,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    variant?: 'info'
    followupCardSx?: ThemeUIStyleObject
    sx?: ThemeUIStyleObject
    placeholder?: string
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    if (!questionConfig?.linkId) {
        return null
    }

    let isEnabled = true

    if (questionConfig && !!questionConfig.enableWhen) {
        isEnabled = questionnaireResponse
            ? isItemEnabled(questionConfig!, questionnaireResponse)
            : false
    }

    const shouldRender = questionConfig?.linkId && isEnabled

    if (!shouldRender) {
        return null
    }

    return (
        <FollowUpQuestionsCard hidden={variant !== 'info'} sx={followupCardSx}>
            <LongOpenInput
                questionnaireItem={questionConfig}
                answersMap={answersMap}
                sx={sx}
                variant={variant}
                placeholder={placeholder}
                index={index}
                triggerSave={triggerSave}
            />
        </FollowUpQuestionsCard>
    )
}
