import {
    Box,
    Button,
    Flex,
    Form,
    Heading,
    Link,
    Paragraph,
    Radio,
    TiaModal
} from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { AppointmentCadenceType } from 'src/types'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { useAmpli } from 'src/hooks'

type FormValues = { cadence: string } | FieldValues

export const CadenceFilterModal = ({
    hideModal,
    onSelected,
    onClearSelections,
    initialValue,
    appointmentProfile
}: FilterModalProps<AppointmentCadenceType[]>) => {
    const { ampli } = useAmpli()
    const formMethods = useForm<FormValues>({
        defaultValues: {
            cadence: initialValue || undefined
        }
    })

    const options = [
        /*
            The figma does not show "Once", but we've added it to Admin
            Leave this here for if/when we do, or remove if definitely not
            { key: 'once', value: 'once', label: 'Once' },
        */
        { key: 'weekly', value: 'weekly', label: 'Weekly' },
        { key: 'bi-weekly', value: 'bi-weekly', label: 'Bi-Weekly' }
    ]

    const onSubmit = (formValues: FormValues) => {
        ampli.appointmentFilteredByCadence({
            appointmentProfile: appointmentProfile.appointmentProfileUuid,
            AppointmentCadence: formValues.cadence
        })

        onSelected(formValues.cadence)
        hideModal()
    }

    const clearSelections = () => {
        onClearSelections()
        hideModal()
    }

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: 4
                }}
            >
                <Heading h2 sx={{ m: 0, p: 0 }}>
                    Frequency
                </Heading>
                <Box onClick={() => hideModal()} sx={{ cursor: 'pointer' }}>
                    <CloseIcon color="inactiveText" hoverColor="text" />
                </Box>
            </Flex>
            <Paragraph sx={{ fontSize: 2, mb: 5 }}>
                Tia offers weekly & bi-weekly therapy appointments. Select a
                time slot that will continually work for your schedule.
            </Paragraph>
            <Form
                useFormMethods={formMethods}
                onSubmit={onSubmit}
                sx={{ alignItems: 'left' }}
            >
                <Box sx={{ mb: 6 }}>
                    {options.map(options => (
                        <Radio
                            name="cadence"
                            key={options.key}
                            value={options.value}
                            pretty
                            sx={{ mb: 4 }}
                        >
                            {options.label}
                        </Radio>
                    ))}
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Button fullWidth type="submit" sx={{ mb: 4 }}>
                        Apply Filter
                    </Button>
                    <Link
                        onClick={clearSelections}
                        href="#"
                        sx={{
                            fontSize: 2,
                            color: 'text'
                        }}
                    >
                        Clear selections
                    </Link>
                </Box>
            </Form>
        </TiaModal>
    )
}
