import { assign, createMachine, EventObject } from 'xstate'
import { useMachine } from '@xstate/react'
import { useEffect } from 'react'
import {
    CanUseInsurance,
    InsuranceEligibilityStatus,
    InsuranceInfoSource,
    InsuranceVerificationStatus
} from 'src/types'
import { Control, useWatch } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

/**
 * which insurance validation state to show
 */
export type InsuranceValidationState = {
    verificationStatus?: InsuranceVerificationStatus
    verificationSource?: InsuranceInfoSource
    updatedAt: Date
    eligibilityStatus?: InsuranceEligibilityStatus
    eligibilitySource?: InsuranceInfoSource
}
import { useLatestInsurance } from 'src/hooks/useLatestInsurance'

const context = {
    insuranceVerified: false,
    hasLatestInsurance: false,
    noLatestInsurance: false,
    showUpdateInsurance: false,
    showAddInsurance: false,
    showInsuranceForm: false,
    showPaymentMethod: false,
    showVerification: false,
    showIsADependent: false,
    insuranceValidationState: {} as InsuranceValidationState,
    showInsuranceCardUpload: false,
    showTentativeQuestion: false,
    insuranceWasUsedBefore: false,
    insuranceIsBlocked: false
}

export type MachineContext = typeof context

interface EventType extends EventObject {
    insuranceValidationState?: InsuranceValidationState
}

// actions used in the state machine
const actions = {
    'set hasLatestInsurance': assign<MachineContext, EventType>({
        hasLatestInsurance: true
    }),
    'set noLatestInsurance': assign<MachineContext, EventType>({
        noLatestInsurance: true
    }),
    'set showUpdateInsurance': assign<MachineContext, EventType>({
        showUpdateInsurance: true
    }),
    'set showAddInsurance': assign<MachineContext, EventType>({
        showAddInsurance: true
    }),
    'unset showAddInsurance': assign<MachineContext, EventType>({
        showAddInsurance: false
    }),
    'set insuranceIsBlocked': assign<MachineContext, EventType>({
        insuranceIsBlocked: true
    }),
    'unset insuranceIsBlocked': assign<MachineContext, EventType>({
        insuranceIsBlocked: false
    }),
    'set showInsuranceForm': assign<MachineContext, EventType>({
        showInsuranceForm: true
    }),
    'unset showInsuranceForm': assign<MachineContext, EventType>({
        showInsuranceForm: false,
        showIsADependent: false
    }),
    'set showPaymentMethod': assign<MachineContext, EventType>({
        showPaymentMethod: true
    }),
    'unset showPaymentMethod': assign<MachineContext, EventType>({
        showPaymentMethod: false
    }),
    'set showVerification': assign<MachineContext, EventType>({
        showVerification: true
    }),
    'unset showVerification': assign<MachineContext, EventType>({
        showVerification: false
    }),
    'set insuranceVerified': assign<MachineContext, EventType>({
        insuranceVerified: true
    }),
    'unset insuranceVerified': assign<MachineContext, EventType>({
        insuranceVerified: false
    }),
    'set insuranceValidationState': assign<MachineContext, EventType>(
        (context, event) => ({
            // @ts-ignore
            insuranceValidationState: event.insuranceValidationState
        })
    ),
    'clear insuranceValidationState': assign<MachineContext, EventType>({
        insuranceValidationState: {} as InsuranceValidationState
    }),
    'set showInsuranceCardUpload': assign<MachineContext, EventType>({
        showInsuranceCardUpload: true
    }),
    'unset showInsuranceCardUpload': assign<MachineContext, EventType>({
        showInsuranceCardUpload: false
    }),
    'set showTentativeQuestion': assign<MachineContext, EventType>({
        showTentativeQuestion: true
    }),
    'unset showTentativeQuestion': assign<MachineContext, EventType>({
        showTentativeQuestion: false
    }),
    'set insuranceWasUsedBefore': assign<MachineContext, EventType>({
        insuranceWasUsedBefore: true
    }),
    'unset insuranceWasUsedBefore': assign<MachineContext, EventType>({
        insuranceWasUsedBefore: false
    })
}

// action guards used in the state machine
const guards = {
    hasLatestInsurance: (ctx: MachineContext) => ctx.hasLatestInsurance,
    noLatestInsurance: (ctx: MachineContext) => ctx.noLatestInsurance,
    showInsuranceForm: (ctx: MachineContext) => ctx.showInsuranceForm
}

/**
 * State machine that drives the insurance step UI
 */
const insuranceUIStateMachine = createMachine<MachineContext, EventType>({
    context: context,
    schema: {
        context: {} as MachineContext,
        events: {} as
            | { type: 'has_latest_insurance' }
            | { type: 'Submit' }
            | { type: 'NO_useExistingInsurance' }
            | { type: 'NO_addInsurance' }
            | { type: 'YES_addInsurance' }
            | { type: 'CHANGE_insuranceInfo' }
            | { type: 'insurance_not_supported' }
            | { type: 'YES_useExistingInsurance' }
            | { type: 'no_latest_insurance' }
            | { type: 'error_submitting' }
            | { type: 'success' }
            | { type: 'set showIsADependentFromFormValue' }
            | { type: 'NO_usedInsuranceBefore' }
            | { type: 'YES_usedInsuranceBefore' }
    },
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: 'insurance UI states',
    initial: 'start',
    states: {
        'start': {
            on: {
                has_latest_insurance: [
                    {
                        target: 'Want to use existing insurance?',
                        cond: 'hasInsuranceCardUploaded',
                        actions: [
                            'set hasLatestInsurance',
                            'unset showInsuranceCardUpload'
                        ]
                    },
                    {
                        target: 'Want to use existing insurance?',
                        cond: 'notHasInsuranceCardUploaded',
                        actions: [
                            'set hasLatestInsurance',
                            'set showInsuranceCardUpload'
                        ]
                    }
                ],
                insurance_not_supported: {
                    target: 'Payment Method',
                    description:
                        "When the appointment itself doesn't take insurance"
                },
                no_latest_insurance: {
                    target: 'Want to add insurance?',
                    actions: 'set noLatestInsurance'
                }
            }
        },
        'Want to use existing insurance?': {
            entry: 'set showUpdateInsurance',
            on: {
                YES_useExistingInsurance: {
                    target: 'Ready to check coverage'
                },
                NO_useExistingInsurance: {
                    target: 'Updating insurance form'
                }
            }
        },
        'Want to add insurance?': {
            entry: 'set showAddInsurance',
            on: {
                NO_addInsurance: {
                    target: 'Payment Method'
                },
                YES_addInsurance: {
                    target: 'Updating insurance form'
                }
            }
        },
        'Updating insurance form': {
            entry: [
                'set showInsuranceForm',
                'unset insuranceVerified',
                'unset showVerification',
                'unset showPaymentMethod',
                'set showInsuranceCardUpload',
                'set showIsADependentFromFormValue',
                'unset showTentativeQuestion',
                'unset insuranceIsBlocked'
            ],
            after: {
                '200': {
                    target: '#insurance UI states.Ready to check coverage',
                    cond: 'form is valid',
                    actions: [],
                    description:
                        'need auto-trigger transition if form is valid without any changes',
                    internal: false
                }
            },
            on: {
                CHANGE_insuranceInfo: {
                    target: 'Updating insurance form',
                    internal: false
                },
                NO_addInsurance: {
                    target: 'Payment Method',
                    cond: 'noLatestInsurance',
                    actions: [
                        'unset showInsuranceForm',
                        'unset showInsuranceCardUpload',
                        'unset insuranceVerified'
                    ]
                },
                YES_useExistingInsurance: {
                    target: 'Check Insurance Card upload',
                    cond: 'hasLatestInsurance',
                    actions: 'unset showInsuranceForm'
                }
            }
        },
        'Checking Coverage': {
            on: {
                success: [
                    {
                        target: 'Tentative insurance question',
                        cond: 'should ask tentative question',
                        actions: 'set insuranceValidationState'
                    },
                    {
                        target: 'Insurance verified',
                        cond: 'should NOT ask tentative question',
                        actions: 'set insuranceValidationState'
                    },
                    {
                        target: 'Insurance Blocked',
                        cond: 'insurance is blocked',
                        actions: 'set insuranceValidationState'
                    }
                ],
                error_submitting: {
                    target: 'Ready to check coverage'
                }
            }
        },
        'Insurance Blocked': {
            entry: [
                'set insuranceVerified',
                'set showVerification',
                'set insuranceIsBlocked',
                'unset showPaymentMethod',
                'unset showAddInsurance'
            ],
            on: {
                success: {
                    target: 'exit'
                },
                NO_useExistingInsurance: {
                    target: 'Updating insurance form'
                }
            }
        },
        'Payment Method': {
            entry: 'set showPaymentMethod',
            on: {
                NO_addInsurance: {
                    cond: 'noLatestInsurance',
                    actions: [
                        'unset showInsuranceCardUpload',
                        'unset showInsuranceForm',
                        'unset showVerification',
                        'unset isADependent',
                        'unset insuranceVerified'
                    ]
                },
                YES_useExistingInsurance: {
                    target: 'Ready to check coverage',
                    cond: 'hasLatestInsurance',
                    actions: 'unset showInsuranceForm'
                },
                Submit: {
                    target: 'Submitting insurance',
                    cond: 'form is valid'
                },
                YES_addInsurance: {
                    target: 'Updating insurance form',
                    cond: 'noLatestInsurance'
                },
                CHANGE_insuranceInfo: {
                    target: 'Updating insurance form',
                    cond: 'showInsuranceForm'
                },
                NO_useExistingInsurance: {
                    target: 'Updating insurance form',
                    cond: 'hasLatestInsurance',
                    actions: [
                        'unset showVerification',
                        'unset showPaymentMethod'
                    ]
                },
                NO_usedInsuranceBefore: {
                    target: 'Tentative insurance question',
                    actions: 'unset insuranceWasUsedBefore'
                },
                YES_usedInsuranceBefore: {
                    target: 'Tentative insurance question',
                    actions: 'set insuranceWasUsedBefore'
                },
                NO_confirmAgreeCashPay: {
                    target: 'Insurance verified',
                    actions: ['unset showPaymentMethod']
                }
            }
        },
        'Submitting insurance': {
            on: {
                success: {
                    target: 'exit'
                },
                error_submitting: {
                    target: 'Payment Method'
                }
            }
        },
        'Insurance verified': {
            entry: ['set showVerification', 'set insuranceVerified'],
            after: {
                '200': { target: 'Payment Method', cond: 'form is valid' }
            },
            on: {
                YES_confirmAgreeCashPay: {
                    target: 'Payment Method'
                },
                YES_useExistingInsurance: {
                    actions: 'unset showInsuranceForm',
                    target: 'Ready to check coverage'
                },
                NO_addInsurance: {
                    target: 'Payment Method',
                    cond: 'noLatestInsurance',
                    actions: [
                        'unset showVerification',
                        'unset showInsuranceForm',
                        'unset showInsuranceCardUpload',
                        'unset insuranceVerified'
                    ]
                },
                NO_useExistingInsurance: {
                    target: 'Updating insurance form'
                },
                YES_addInsurance: {
                    target: 'Updating insurance form',
                    cond: 'noLatestInsurance'
                }
            }
        },
        'Ready to check coverage': {
            entry: [
                'unset insuranceVerified',
                'unset showVerification',
                'unset showPaymentMethod',
                'clear insuranceValidationState'
            ],
            after: {
                '200': {
                    target: '#insurance UI states.Updating insurance form',
                    cond: 'form is not valid and updating insurance',
                    actions: [],
                    description:
                        'need auto-trigger back to form if form is invalid without changes',
                    internal: false
                }
            },
            on: {
                CHANGE_insuranceInfo: {
                    target: 'Updating insurance form',
                    cond: 'showInsuranceForm'
                },
                YES_useExistingInsurance: {
                    actions: 'unset showInsuranceForm'
                },
                NO_addInsurance: {
                    target: 'Payment Method',
                    cond: 'noLatestInsurance',
                    actions: [
                        'unset showInsuranceForm',
                        'unset showInsuranceCardUpload',
                        'unset insuranceVerified'
                    ]
                },
                Submit: {
                    target: 'Checking Coverage'
                },
                NO_useExistingInsurance: {
                    target: 'Updating insurance form',
                    cond: 'hasLatestInsurance'
                }
            }
        },
        'exit': {
            type: 'final'
        },
        'Check Insurance Card upload': {
            always: [
                {
                    target: 'Ready to check coverage',
                    cond: 'hasInsuranceCardUploaded',
                    actions: 'unset showInsuranceCardUpload'
                },
                {
                    target: 'Ready to check coverage',
                    cond: 'notHasInsuranceCardUploaded',
                    actions: 'set showInsuranceCardUpload'
                }
            ]
        },
        'Tentative insurance question': {
            entry: 'set showTentativeQuestion',
            on: {
                NO_useExistingInsurance: {
                    target: 'Updating insurance form'
                },
                NO_usedInsuranceBefore: {
                    target: 'Insurance verified',
                    actions: 'unset insuranceWasUsedBefore'
                },
                YES_usedInsuranceBefore: {
                    target: 'Insurance verified',
                    actions: 'set insuranceWasUsedBefore'
                }
            }
        }
    }
})

// helper hook to pass form values into the state machine as events
// supports yes/no fields
function useFormValueToDriveUI(
    name: string,
    formControl: Control,
    send: Function
) {
    const value: 'yes' | 'no' | boolean | undefined = useWatch({
        control: formControl,
        name
    })

    useEffect(() => {
        if (typeof value !== 'undefined' && value !== null) {
            let formattedValue = value
            if (typeof value == 'boolean') {
                if (value) {
                    formattedValue = 'yes'
                } else {
                    formattedValue = 'no'
                }
            }

            send(`${formattedValue.toString().toUpperCase()}_${name}`)
        }
    }, [value, name, send])
}

// helper hook that ensures changes to insurance info reset the state machine
function useForceRevalidateOnInsuranceChange(
    formControl: Control,
    send: Function
) {
    const values = useWatch({
        control: formControl,
        name: [
            'issuerName',
            'memberId',
            'patientGivenName',
            'patientFamilyName',
            'patientIsADependent',
            'subscriberGivenName',
            'subscriberFamilyName',
            'subscriberDateOfBirth',
            'relationshipToSubscriberCode',
            'address1',
            'address2',
            'city',
            'state',
            'postalCode'
        ]
    })

    useDeepCompareEffect(() => {
        send('CHANGE_insuranceInfo')
    }, [values])
}

export const isEligibleAndInNetwork = (
    insuranceValidationState: InsuranceValidationState
) => {
    return (
        insuranceValidationState.eligibilityStatus === 'eligible' &&
        insuranceValidationState.verificationStatus === 'in_network'
    )
}

export const isDisallowedInsurance = (
    insuranceValidationState: InsuranceValidationState
) => {
    return (
        insuranceValidationState.eligibilityStatus === 'ineligible' ||
        insuranceValidationState.verificationStatus === 'out_of_network' ||
        insuranceValidationState.verificationStatus === 'blocked'
    )
}

const shouldAskTentativeQuestion = (
    canUseInsurance: CanUseInsurance,
    context: MachineContext,
    event: {
        type: string
        insuranceValidationState?: InsuranceValidationState
    }
) => {
    return (
        !context.showInsuranceForm &&
        canUseInsurance === 'tentative' &&
        isEligibleAndInNetwork(event.insuranceValidationState!)
    )
}

const isBlockedInsurance = (
    context: MachineContext,
    event: {
        type: string
        insuranceValidationState?: InsuranceValidationState
    }
): boolean => {
    return (
        !!event.insuranceValidationState &&
        event.insuranceValidationState.verificationStatus === 'blocked'
    )
}

export const useInsuranceUIStateMachine = (
    canUseInsurance: CanUseInsurance,
    hasExistingInsurance: boolean,
    formControl: Control,
    // this lets us use yup validations to guard transitions
    formIsValid: boolean
) => {
    const { latestInsurance, isLoading } = useLatestInsurance()
    const [state, send] = useMachine(insuranceUIStateMachine, {
        actions: {
            ...actions,
            'set showIsADependentFromFormValue': assign<
                MachineContext,
                EventType
            >({
                showIsADependent: () =>
                    formControl.getValues('patientIsADependent') as boolean
            })
        },
        guards: {
            ...guards,
            'hasInsuranceCardUploaded': () =>
                !!latestInsurance?.coverage?.cardPhotosUploaded,
            'notHasInsuranceCardUploaded': () =>
                !latestInsurance?.coverage?.cardPhotosUploaded,
            'should ask tentative question': (
                context,
                event: {
                    type: string
                    insuranceValidationState?: InsuranceValidationState
                }
            ) => shouldAskTentativeQuestion(canUseInsurance, context, event),
            'should NOT ask tentative question': (
                context,
                event: {
                    type: string
                    insuranceValidationState?: InsuranceValidationState
                }
            ) =>
                !shouldAskTentativeQuestion(canUseInsurance, context, event) &&
                !isBlockedInsurance(context, event),
            'insurance is blocked': (
                context,
                event: {
                    type: string
                    insuranceValidationState?: InsuranceValidationState
                }
            ) => isBlockedInsurance(context, event),
            'form is valid': () => formIsValid,
            'form is not valid': () => !formIsValid,
            'form is not valid and updating insurance': (ctx: MachineContext) =>
                !formIsValid && ctx.showInsuranceForm
        }
    })

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (canUseInsurance === 'no') {
            send('insurance_not_supported')
        } else {
            if (hasExistingInsurance) {
                send('has_latest_insurance', { latestInsurance })
            } else {
                send('no_latest_insurance')
            }
        }
    }, [
        canUseInsurance,
        hasExistingInsurance,
        latestInsurance,
        isLoading,
        send
    ])

    useEffect(() => {
        // advance state machine if form becomes valid
        // while editing insurance
        if (
            (typeof state.value === 'string' &&
                state.value === 'Updating insurance form') ||
            (typeof state.value === 'object' &&
                state.value['Updating insurance form'])
        ) {
            send('CHANGE_insuranceInfo')
        }
    }, [formIsValid])

    useFormValueToDriveUI('addInsurance', formControl, send)
    useFormValueToDriveUI('useExistingInsurance', formControl, send)
    useFormValueToDriveUI('usedInsuranceBefore', formControl, send)
    useFormValueToDriveUI('confirmAgreeCashPay', formControl, send)

    useForceRevalidateOnInsuranceChange(formControl, send)

    return {
        context: state.context,
        currentState: state.value,
        send
    }
}
