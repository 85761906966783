import { Link as RouterLink } from 'react-router-dom'
import { Heading, Text, Link, Box, Card } from '@asktia/tia-ui'
import { addMinutes, subMinutes } from 'date-fns'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

import { AppointmentCard } from 'src/components/AppointmentCard'
import { Appointment, AppointmentWithLocationDetails } from 'src/types'
import { ProgressCrumbs } from 'src/components/ProgressCrumbs'
import { getDisplayTimestamp } from 'src/utils'
import { AppointmentZoomLink } from 'src/components/AppointmentZoomLink'
import { useAppointmentStatus } from 'src/hooks/useAppointmentStatus'
import { useShowNPSModalIfApplicable } from 'src/components/NPSModal'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'

type ScheduledWaitingRoomProps = {
    /**
     * appointment being checked into
     */
    appointment: AppointmentWithLocationDetails
}

type Article = {
    image: string
    title: string
    url: string
}

const MEDIA_FEED: Article[] = [
    {
        title: 'Tia’s Model of Care',
        url: 'https://asktia.com/article/a-new-and-distinctly-female-model-of-care-for-the-healthcare-system',
        image: 'https://cdn.sanity.io/images/8qqycr4y/production/0804cee7ccaab5ff999418b7b6478221df9e17c0-5000x1600.png?rect=2785,0,1988,1600&w=800'
    },
    {
        title: 'How and why I turned to therapy',
        url: 'https://asktia.com/article/how-and-why-carolyn-turned-to-therapy',
        image: 'https://cdn.sanity.io/images/8qqycr4y/production/7af0195f31b4bb987204dd173d724274c420857e-2910x1600.png?rect=31,0,2739,1600&w=800'
    },
    {
        title: 'Reinventing Women’s Health Starts With the Well-Woman',
        url: 'https://asktia.com/article/reinventing-women-s-health-starts-with-the-well-woman',
        image: 'https://cdn.sanity.io/images/8qqycr4y/production/77e1a208b1d2531e8c0311aab73b51c4acc4cef6-5000x1600.png?rect=0,0,2483,1600&w=800'
    }
]

const ClinicInstructions = () => (
    <>
        Head on over to the Tia Clinic & our front desk team will make sure
        you’re all set!
    </>
)

const VirtualInstructions = ({
    canStart,
    appointment
}: {
    canStart: boolean
    appointment: Appointment
}) => {
    const lowerTimeBound = getDisplayTimestamp(
        subMinutes(appointment.scheduledTime, 5),
        appointment.clinic.timezone
    )
    const upperTimeBound = getDisplayTimestamp(
        addMinutes(appointment.scheduledTime, 5),
        appointment.clinic.timezone
    )

    if (canStart) {
        return (
            <Card variant="info">
                <p>
                    Tap the button below to enter your appointment! Your
                    appointment link will remain active until{' '}
                    {upperTimeBound.time} {upperTimeBound.timezone} - 5 minutes
                    after your appointment is scheduled to start.
                </p>
                <p>
                    If you are on a mobile device,{' '}
                    <Link href="https://zoom.us/download">
                        download the Zoom app
                    </Link>{' '}
                    prior to your appointment.
                </p>
            </Card>
        )
    } else {
        return (
            <Card variant="info">
                <p>
                    The button below will become active at {lowerTimeBound.time}{' '}
                    {lowerTimeBound.timezone} - 5 minutes before your
                    appointment is scheduled to start.
                </p>
                <p>
                    If you are on a mobile device,{' '}
                    <Link href="https://zoom.us/download">
                        download the Zoom app
                    </Link>{' '}
                    prior to your appointment.
                </p>
            </Card>
        )
    }
}

const MediaStream = (props: { feed: Article[] }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1.05,
        slidesToScroll: 1,
        arrows: false,
        appendDots: (dots: any) => {
            const index = dots.findIndex(
                (d: any) => d.props.className === 'slick-active'
            )
            return (
                <ProgressCrumbs
                    current={index.toString()}
                    steps={dots.map((d: any) => d.key)}
                    sx={{ mb: 5 }}
                />
            )
        }
    }
    return (
        <Box
            sx={{
                '.slick-list': {
                    borderTopLeftRadius: 2,
                    borderBottomLeftRadius: 2
                }
            }}
        >
            <Slider {...settings}>
                {props.feed.map(({ image, title, url }) => (
                    <Box sx={{ px: 2 }}>
                        <Card
                            sx={{
                                mb: 0,
                                textAlign: 'left',
                                p: 0,
                                textDecoration: 'none',
                                height: 281
                            }}
                            key={url}
                        >
                            <Box
                                sx={{
                                    borderTopLeftRadius: 2,
                                    borderTopRightRadius: 2,
                                    height: '67%',
                                    background: `url(${image})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center'
                                }}
                            />
                            <Box sx={{ p: 4 }}>
                                <Heading sx={{ mb: 1 }} variant="cardTitle">
                                    {title}
                                </Heading>
                                <Link
                                    href={url}
                                    target="_blank"
                                    sx={{
                                        color: 'communicationBanner',
                                        textDecoration: 'none',
                                        pl: 3
                                    }}
                                >
                                    Read now →
                                </Link>
                            </Box>
                        </Card>
                    </Box>
                ))}
            </Slider>
        </Box>
    )
}

export const ScheduledWaitingRoom = ({
    appointment
}: ScheduledWaitingRoomProps) => {
    const { isVirtual } = appointment

    // TODO: UNCOMMENT ONCE MIXPANEL PROJECT FOR NPS HAS BEEN MIGRATED
    // useShowNPSModalIfApplicable(
    //     'How satisfied were you with your check-in experience?',
    //     'automated-appointment-checkin',
    //     appointment.id,
    //     {
    //         apptId: appointment.id
    //     }
    // )

    const { canStart } = useAppointmentStatus(appointment)

    return (
        <View sx={{ py: 5, textAlign: 'center' }}>
            <Heading>You're all checked in!</Heading>
            <AppointmentCard
                appointment={appointment}
                sx={{
                    mb: 5,
                    border: '1px solid',
                    borderRadius: 2,
                    borderColor: 'oat'
                }}
                variant="ACI"
            />
            <Text
                sx={{
                    mb: 6,
                    textAlign: 'center'
                }}
            >
                {isVirtual ? (
                    <VirtualInstructions
                        canStart={canStart}
                        appointment={appointment}
                    />
                ) : (
                    <ClinicInstructions />
                )}
            </Text>
            {!canStart ? (
                <>
                    <Heading h4 sx={{ textAlign: 'left' }}>
                        While you wait&hellip;
                    </Heading>
                    <MediaStream feed={MEDIA_FEED} />
                </>
            ) : null}

            <Footer sx={{ bg: 'background' }}>
                {isVirtual ? (
                    <AppointmentZoomLink
                        appointment={appointment}
                        sx={{ mb: 3 }}
                    />
                ) : null}
                <Box sx={{ textAlign: 'center', color: 'text', fontSize: 2 }}>
                    <RouterLink
                        to={`/automated-checkin/${appointment.id}/patient_info`}
                    >
                        Edit Check-in Information
                    </RouterLink>
                </Box>
            </Footer>
        </View>
    )
}
