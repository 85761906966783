import { useRef, useState, FC } from 'react'
import { Box, Button, Text } from '@asktia/tia-ui'
import { ReactComponent as AccordionIcon } from 'src/assets/icon-accordion.svg'
import { Avatar } from 'src/components/Avatar'

export const ProviderAccordion: FC<{
    title: string
    pronoun?: string[]
    description?: string
    avatarUrl?: string
}> = ({ title, pronoun, description, avatarUrl }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <AccordionItem
            isOpen={isOpen}
            onClick={toggleOpen}
            title={title}
            pronoun={pronoun}
            description={description}
            avatarUrl={avatarUrl}
        />
    )
}

const AccordionItem: FC<{
    title: string
    pronoun?: string[]
    description?: string
    isOpen: boolean
    onClick: () => void
    avatarUrl?: string
}> = ({ isOpen, onClick, title, pronoun, description, avatarUrl }) => {
    const nameOnly = title.split(',').shift() ?? ''
    const fullTitle = `${title}${pronoun?.length ? `, ${pronoun[0]}` : ''}`
    const descriptionRef = useRef<HTMLDivElement>()
    const descriptionPadding = isOpen ? 8 : 0
    const descriptionHeight = isOpen
        ? (descriptionRef.current?.scrollHeight || 0) + descriptionPadding
        : 0

    return (
        <Box sx={{ overflow: 'hidden', pt: '10px', fontSize: 0 }}>
            <Button
                variant="unstyled"
                onClick={onClick}
                sx={{
                    cursor: description ? 'pointer' : 'initial',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%'
                }}
            >
                <Avatar url={avatarUrl} userName={nameOnly} />
                <Text
                    sx={{
                        'pl': '8px',
                        'color': 'supportText',
                        'textAlign': 'left',
                        ':hover': {
                            color: description ? 'raspberry' : 'supportText'
                        }
                    }}
                >
                    {fullTitle}
                </Text>
                {description && (
                    <AccordionIcon
                        style={{
                            transition: '.5s ease-in-out',
                            rotate: isOpen ? '0deg' : '180deg',
                            color: 'supportText',
                            marginLeft: 'auto'
                        }}
                    />
                )}
            </Button>
            {description && (
                <Box
                    ref={descriptionRef}
                    sx={{
                        transition: '.7s ease-in-out',
                        visibility: isOpen ? 'visible' : 'hidden',
                        height: `${descriptionHeight}px`,
                        pt: `${descriptionPadding}px`
                    }}
                >
                    <Text sx={{ color: 'supportText' }}>{description}</Text>
                </Box>
            )}
        </Box>
    )
}
