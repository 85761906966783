import { Button } from '@asktia/tia-ui'
import { useEffect } from 'react'
import { useAmpli } from 'src/hooks'

const PreviewButton = () => {
    const { resumeSignupModalCtaRendered, resumeSignupModalCtaClicked } =
        useAmpli()

    useEffect(() => {
        resumeSignupModalCtaRendered()
    })
    return (
        <Button
            fullWidth
            onClick={() => {
                resumeSignupModalCtaClicked()
                window.location.href = `${process.env.REACT_APP_SIGNUP_PUBLIC_URL}/?widgetStep=insurance`
            }}
            sx={{ mb: 6 }}
        >
            Become a Tia Member
        </Button>
    )
}

export default PreviewButton
