import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { AppointmentProfile, ServiceLine } from 'src/types'

export async function getServiceLines(): Promise<ServiceLine[]> {
    return await fetcher<ServiceLine[]>(
        `${BASE_API_URL}/tmd-api/appointments/service-lines?apiVersion=v2`
    )
}

export const useServiceLines = (name?: string) => {
    const { data, isLoading } = useQuery<ServiceLine[]>(
        ['service-lines'],
        getServiceLines,
        {
            staleTime: Infinity // data unlikely to change during a session
        }
    )

    // Filtering here instead of in the query
    // takes advantage of API request caching
    if (name) {
        return {
            serviceLine: (data || []).find(
                serviceLine => serviceLine.name === name
            ),
            isLoading
        }
    } else {
        return {
            serviceLines: data || [],
            isLoading
        }
    }
}
async function getAppointmentProfiles(
    queryParams: string
): Promise<AppointmentProfile[]> {
    const profiles = await fetcher<AppointmentProfile[]>(
        `${BASE_API_URL}/tmd-api/appointments/patient-offerings?apiVersion=v2&${queryParams}`
    )

    return profiles.sort((a: AppointmentProfile, b: AppointmentProfile) => {
        return a.label < b.label ? -1 : 1
    })
}

export const useAppointmentProfilesByServiceLineName = (
    serviceLineName: string
) => {
    const queryParams = `marketingServiceLines[]=${serviceLineName}`

    const { data, isLoading } = useQuery<AppointmentProfile[]>(
        [`appointment-profiles-by-service-line-name`, serviceLineName],
        () => getAppointmentProfiles(queryParams),
        {
            staleTime: 1000 * 60 * 5 // refetch  rendered data after 5min
        }
    )

    return {
        appointmentProfiles: data || [],
        isLoading
    }
}

export const useServiceLineViewData = (careTypeName: string) => {
    // Make both requests in parallel
    const careTypeMeta = useServiceLines(careTypeName)
    const profiles = useAppointmentProfilesByServiceLineName(careTypeName)
    return {
        careType: careTypeMeta.serviceLine,
        appointmentProfiles: profiles.appointmentProfiles,
        isLoading: careTypeMeta.isLoading || profiles.isLoading
    }
}
