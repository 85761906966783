import { FieldValues, useForm } from 'react-hook-form'
import { Box, Form, Radio, Heading } from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { useCancelAppointment } from 'src/hooks/useCancelAppointment'
import { useAmpli } from 'src/hooks/useAmpli'
import { useAppointmentChangeFlow } from './useAppointmentChangeFlow'

type CancelReasonForm =
    | {
          reason:
              | 'Date or time no longer works'
              | 'I no longer need this appointment'
              | 'I am receiving care elsewhere'
              | 'I am sick'
              | 'Other'
      }
    | FieldValues

const options = new Set([
    'Date or time no longer works',
    'I no longer need this appointment',
    'I am receiving care elsewhere',
    'I am sick',
    'Other'
])

export const AppointmentCancelReason = () => {
    const {
        appointmentId,
        appointment,
        isAppointmentLoading,
        navigateToNextStep
    } = useAppointmentChangeFlow()
    const formMethods = useForm<CancelReasonForm>()
    const mutation = useCancelAppointment()
    const { cancelReasonSelected } = useAmpli()
    const cancelReason = formMethods.watch('reason')

    const onSubmit = async (values: CancelReasonForm) => {
        if (!appointmentId) {
            return
        }

        const { reason } = values
        if (appointment && reason) {
            appointment.cancellationReason = reason
            cancelReasonSelected(appointment)
        }

        await mutation.cancelAppointmentAsync({ appointmentId, reason })
        navigateToNextStep()
    }

    return (
        <Form useFormMethods={formMethods} onSubmit={onSubmit}>
            <Heading h3>Why do you want to cancel your appointment?</Heading>

            <Box sx={{ display: 'grid', gridGap: 4, mb: 6 }}>
                {Array.from(options.values()).map(label => (
                    <Radio name="reason" key={label} value={label} pretty>
                        {label}
                    </Radio>
                ))}
            </Box>

            <FooterButton
                type="submit"
                disabled={!cancelReason || isAppointmentLoading}
                loading={mutation.isLoading}
            >
                Cancel appointment
            </FooterButton>
        </Form>
    )
}
