import { Blocks, Box, Container, Flex, Heading, Image } from '@asktia/tia-ui'
import React, { FC } from 'react'
import { NavBarHeading } from './NavBarHeading'

type IllustrationHeaderProps = {
    title: string
    img: string
}

export const IllustrationHeader: FC<IllustrationHeaderProps> = ({
    title,
    img
}) => {
    return (
        <Box
            sx={{
                bg: 'secondaryBackground',
                pt: 2,
                // custom value because this step isn't in our scale
                pb: [46, 96],
                mb: 6,
                position: 'relative'
            }}
        >
            <NavBarHeading sx={{ display: ['none', 'block'] }} />

            <Container>
                <Flex
                    sx={{
                        mt: [2, 8],
                        width: '100%',
                        flexDirection: ['column', 'row'],
                        justifyContent: 'space-between'
                    }}
                >
                    <Heading h1>{title}</Heading>

                    <Image
                        src={img}
                        sx={{
                            width: ['100%', '50%']
                        }}
                    />
                </Flex>
            </Container>

            <Blocks.Wave
                position="bottom"
                fill="mainBackground"
                sx={{ backgroundColor: 'secondaryBackground' }}
            />
        </Box>
    )
}
