import { useCallback, useMemo } from 'react'
import { matchPath } from 'react-router'
import { generatePath, useNavigate, useLocation, Params } from 'react-router'
import { UrlPathParams, UserFlow } from 'src/types'

function useNextPath<T>(
    flow: UserFlow<T>,
    pathParams: UrlPathParams,
    context?: T
): string {
    const location = useLocation()

    const path = useMemo(() => {
        const routeConfig =
            Object.values(flow).find(config =>
                matchPath(config.path, location.pathname)
            ) || flow.init

        const targetStep = context ? routeConfig.nextStep(context) : flow.init

        return generatePath(targetStep.path, pathParams as Params)
    }, [location.pathname, JSON.stringify({ context, flow, pathParams })])

    return path
}

// This is a building block for hooks that handle user flows
// See how it's used in useBookingFlow
// Not meant for direct use by components
export function useUserFlow<T>(
    flow: UserFlow<T>,
    pathParams: UrlPathParams,
    searchParams?: URLSearchParams,
    context?: T
) {
    const navigate = useNavigate()
    let nextPath = useNextPath<T>(flow, pathParams, context)

    if (searchParams) {
        const searchParamList = Array.from(searchParams.entries())
        if (searchParamList.length) {
            const searchString = searchParamList
                .map(
                    ([key, val]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
                )
                .join('&')
            nextPath = `${nextPath}?${searchString}`
        }
    }

    const navigateToNextStep = useCallback(
        (isRedirection = false, state?: unknown) => {
            navigate(nextPath, {
                replace: isRedirection,
                state
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nextPath]
    )

    return {
        nextPath,
        navigateToNextStep
    }
}
