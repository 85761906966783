import React, { FC } from 'react'
import { Box, Image, Text } from '@asktia/tia-ui'
import MessageBubble from '../../assets/avatar-tia@3x.png'
import { keyframes } from '@emotion/react'

export interface IHelperText {
    content: string
}

export const HelperText: FC<IHelperText> = ({ content }) => {
    const chatAnimation = keyframes(`
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        50% {
            opacity: 0.75;
            transform: translateY(5px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    `)

    return (
        <Box
            key={content}
            sx={{
                display: 'flex',
                pr: 6,
                justifyContent: 'space-between',
                animation: `${chatAnimation} 0.4s ease-out`,
                animationDelay: '0.8s',
                animationFillMode: 'forwards',
                opacity: 0
            }}
        >
            <Image src={MessageBubble} sx={{ width: 39, height: 39 }} />
            <Box
                sx={{
                    bg: 'white',
                    width: 'calc(100% - 47px)',
                    py: 3,
                    px: 4,
                    borderRadius: 12,
                    boxShadow: '1px 5px 12px rgb(0 0 0 / 5%)',
                    mb: 7
                }}
            >
                <Text sx={{ fontSize: 3, lineHeight: 1.4 }}>{content}</Text>
            </Box>
        </Box>
    )
}
