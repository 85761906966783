import {
    Box,
    Button,
    Card,
    Flex,
    Form,
    Heading,
    LabeledCheckbox,
    Link,
    Paragraph,
    TiaModal
} from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { useAmpli } from 'src/hooks'
import { useLocation } from 'react-router'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { useAppointmentSuggestion } from 'src/flows/AppointmentBooking/useAppointmentSuggestion'

type FormValues = { clinicUuids: [string] } | FieldValues
type ClinicOption = {
    key: string
    value: string
    label: string
    checked: boolean
    address1: string
    address2?: string
    isVirtual: boolean
}

export const LocationFilterModal = ({
    hideModal,
    onSelected,
    onClearSelections,
    initialValue,
    appointmentProfile
}: FilterModalProps<string[]>) => {
    const getInitialState = () => {
        if (initialValue!.length === 0) {
            return appointmentProfile.clinicsWithOffering.map(c => c.uuid)
        }
        return initialValue
    }

    const formMethods = useForm<FormValues>({
        defaultValues: {
            clinicUuids: getInitialState()
        }
    })

    const options: ClinicOption[] = appointmentProfile.clinicsWithOffering.map(
        c => {
            return {
                key: c.uuid,
                value: c.uuid,
                label: c.isVirtual ? 'Virtual' : c.name,
                checked: initialValue!.includes(c.uuid),
                address1: c.address1,
                address2: c.address2,
                isVirtual: c.isVirtual
            }
        }
    )

    const showLabel = (option: ClinicOption) => {
        if (option.isVirtual) {
            return option.label
        }
        return (
            <Flex sx={{ lineHeight: 1, flexDirection: 'column', pt: 1 }}>
                <Box>{option.label}</Box>

                <Box
                    sx={{
                        fontSize: 0,
                        color: 'supportText',
                        pt: 2,
                        fontWeight: 'body',
                        fontFamily: 'body'
                    }}
                >
                    {option.address1}
                    {option.address2 && `, ${option.address2}`}
                </Box>
            </Flex>
        )
    }

    const { appointmentFilteredByLocation } = useAmpli()

    const onSubmit = (formValues: FormValues) => {
        const locationModality = formValues.clinicUuids
            .map((clinicUuid: string) => {
                const clinic = appointmentProfile.clinicsWithOffering.find(
                    c => c.uuid === clinicUuid
                )
                return clinic?.isVirtual ? 'virtual' : 'clinic'
            })
            .join(',')
        appointmentFilteredByLocation(locationModality, appointmentProfile)
        onSelected(formValues.clinicUuids)
        hideModal()
    }

    const clearSelections = () => {
        onClearSelections()
        hideModal()
    }

    const searchParams = new URLSearchParams(useLocation().search)
    // "context" its the query params used on booking time slot page
    const appointmentSuggestionUuid = searchParams.get('context') || undefined

    const { suggestion } = useAppointmentSuggestion(appointmentSuggestionUuid)

    const suggestionHasLocationFilter = suggestion?.params?.clinicUuids
        ? suggestion?.params?.clinicUuids?.length > 0
        : false

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: 4
                }}
            >
                <Heading h3 sx={{ m: 0, p: 0 }}>
                    Location
                </Heading>
                <Box onClick={() => hideModal()} sx={{ cursor: 'pointer' }}>
                    <CloseIcon color="text" />
                </Box>
            </Flex>
            {suggestionHasLocationFilter ? (
                <Card sx={{ background: 'gold', mb: 5 }}>
                    Heads up! Your care team has specified a certain Tia clinic
                    for your follow-up care. This location is best equipped for
                    the care you need.
                </Card>
            ) : (
                <Paragraph sx={{ fontSize: 2, mb: 5 }}>
                    We let you choose where & how you want your care.
                </Paragraph>
            )}
            <Form
                useFormMethods={formMethods}
                onSubmit={onSubmit}
                sx={{ alignItems: 'left' }}
            >
                <Box sx={{ mb: 6 }}>
                    {options.map(option => (
                        <LabeledCheckbox
                            name="clinicUuids"
                            key={option.key}
                            value={option.value}
                            pretty={true}
                            sx={{ mb: 4 }}
                            defaultChecked={option.checked}
                        >
                            {showLabel(option)}
                        </LabeledCheckbox>
                    ))}
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Button fullWidth type="submit" sx={{ mb: 4 }}>
                        Apply Filter
                    </Button>
                    <Link
                        onClick={clearSelections}
                        href="#"
                        sx={{
                            fontSize: 2,
                            color: 'text'
                        }}
                    >
                        Clear selections
                    </Link>
                </Box>
            </Form>
        </TiaModal>
    )
}
