import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useParams, useNavigate } from 'react-router'

import {
    DataBlob,
    CheckInState,
    SaveStepFunction,
    HealthRecordEntry,
    Appointment
} from 'src/types'
import { useCurrentRouteStep } from './useCurrentRouteStep'
import { BASE_API_URL } from 'src/globals'
import { useErrorModal } from 'src/components/ErrorModal'
import { useAppointment } from 'src/hooks/useAppointment'
import { useAmpli } from 'src/hooks'
import { get, has } from 'lodash'
import { fetcher } from 'src/fetcher'
import Cookies from 'js-cookie'

// maps step name to API
const pathMapping = {
    not_started: 'not-started',
    patient_info: 'patient-info',
    card_on_file: 'card-on-file',
    insurance: 'insurance',
    payment_method: 'payment-method',
    uhr: 'uhr',
    consent: 'consent',
    completed: 'completed',
    pre_appt_info: 'pre-appt-info',
    intermission: 'intermission'
}

async function getStepData(apptId: string, step: CheckInState) {
    return await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/${apptId}/check-in/${pathMapping[step]}`,
        undefined,
        'GET',
        {
            customReturn: async res => {
                if (res.ok && res.status !== 204) {
                    return await res.json()
                } else {
                    return {}
                }
            }
        }
    )
}

async function saveProgressRequest(args: {
    apptId: string
    step: CheckInState
    data?: DataBlob
}) {
    const { apptId, step } = args

    return await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/${apptId}/check-in/${pathMapping[step]}`,
        JSON.stringify(args.data),
        'PUT'
    )
}

async function patchAppointmentCheckInState(args: {
    apptId: string
    checkInState: 'pre_appt_info'
}) {
    const { apptId, checkInState } = args

    return await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/${apptId}?apiVersion=v2`,
        JSON.stringify({
            checkInState
        }),
        'PATCH'
    )
}

export function useCheckinState() {
    const step = useCurrentRouteStep()
    // Assume: won't route here if apptId is undefined
    const { apptId = '' } = useParams<{
        apptId: string
    }>()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {
        checkInPatientInfoCompleted,
        checkInCardOnFileCompleted,
        checkInUhrCompleted,
        checkInConsentCompleted,
        checkInPreAppointmentInfoCompleted
    } = useAmpli()

    const appointmentQuery = useAppointment(apptId)

    const stepDataQuery = useQuery(
        ['stepData', apptId, step],
        // @ts-ignore enabled flag guarantees step is defined
        () => getStepData(apptId, step),
        {
            staleTime: 1000 * 60 * 5, // refetch rendered data after 5min
            enabled: step !== undefined
        }
    )
    const saveProgressMutation = useMutation(saveProgressRequest)
    const changeAppointmentCheckInState = useMutation(
        patchAppointmentCheckInState
    )

    // TODO: use more robust error system, this is a hack to fix bad UX in credit card step
    const showError = useErrorModal(
        step === 'card_on_file' ? 'cardDeclined' : undefined
    )

    const appointment = appointmentQuery.appointment
    const goalIntakes = get(appointment, 'uhr.goals', []).filter(
        (goalIntake: HealthRecordEntry) =>
            goalIntake.name.toLowerCase() !== 'no-goal'
    )

    // moves to next step based on what the backend says
    const saveStep: SaveStepFunction = async (
        data?: DataBlob,
        navigateAfterSave = true,
        // force put to current appointment step, not step in URL
        putToCustomStep: CheckInState | undefined = undefined
    ) => {
        if (!appointment || !step) {
            return [null]
        }

        const persistState = (
            result: any,
            next: any
        ): [boolean | null] | [boolean | null, DataBlob] => {
            trackState(result, data, appointment)

            const nextStep = next.stateStepId as CheckInState

            // use data from backend to pre-set the next query
            if (next.data) {
                queryClient.setQueryData(
                    ['stepData', apptId, nextStep],
                    next.data
                )
            }

            if (navigateAfterSave) {
                navigate(`/automated-checkin/${appointment.id}/${nextStep}`)

                // update cache in case user clicks Back
                queryClient.refetchQueries(['stepData', apptId, step])
            }

            // in each step we update appt status so we need to refetch the appt
            // in the last step we use the appt object to enable/disable the
            // "Enter Appointment" button.
            queryClient.refetchQueries(['appointment', apptId])

            if (next.stateStepId === 'completed') {
                queryClient.refetchQueries(['upcoming-appointments'])
            }

            return [true, result]
        }

        try {
            const { next, result } = await saveProgressMutation.mutateAsync({
                apptId,
                step: putToCustomStep ? putToCustomStep : step,
                data
            })

            return persistState(result, next)
        } catch (err: any) {
            // We do consider errors in insurance step as "unsure"
            // usually this happens when candid is dead 💀
            if (step === 'insurance') {
                // Mocking unsure
                const result = {
                    eligibilityStatus: 'unsure',
                    verificationStatus: 'unsure',
                    verificationSource: 'system',
                    eligibilitySource: 'system',
                    updatedAt: new Date(),
                    insuranceValidationState: {
                        updatedAt: new Date()
                    }
                }

                // Mocking next object
                const next = {
                    stateStepId: 'payment_method'
                }

                await changeAppointmentCheckInState.mutateAsync({
                    apptId,
                    checkInState: 'pre_appt_info'
                })

                Cookies.set('GOT_ERROR_ON_INSURANCE', 'true', {
                    expires: 1 / 24 // 1 Hour
                })

                return persistState(result, next)
            } else {
                showError()

                return [false]
            }
        }
    }

    return {
        // true on hard data loads, stays false for background refetch
        isLoading: [appointmentQuery.status, stepDataQuery.status].some(
            s => s === 'loading'
        ),
        appointment,
        stepProps: stepDataQuery.data,
        latestStepId: (appointment
            ? appointment.checkInState
            : null) as CheckInState | null,
        isSaving: saveProgressMutation.isLoading,
        saveStep
    }

    function trackState(
        result: any,
        data: DataBlob | undefined,
        appointment: Appointment
    ) {
        let additionalTrackingInfo = {}
        let cardOnFile = true
        if (has(data, 'card.nonce')) {
            cardOnFile = false
        }

        switch (step) {
            case 'patient_info':
                checkInPatientInfoCompleted(appointment)
                break
            case 'card_on_file':
                additionalTrackingInfo = {
                    pre_auth_state: result.pre_auth_state
                }
                checkInCardOnFileCompleted(appointment, cardOnFile)
                break
            case 'insurance':
                additionalTrackingInfo = {
                    using_insurance: data!.usingInsurance,
                    eligibility_status: result.eligibilityStatus
                }
                break
            case 'payment_method':
                additionalTrackingInfo = {
                    using_insurance: data!.usingInsurance,
                    patient_wants_receipt: data!.patientWantsReceipt
                }
                break
            case 'uhr':
                checkInUhrCompleted(appointment, !!goalIntakes.length)
                break
            case 'consent':
                checkInConsentCompleted(appointment)
                break
            case 'pre_appt_info':
                checkInPreAppointmentInfoCompleted(appointment)
                break
            case 'intermission':
                //call itly
                break
        }
    }
}
