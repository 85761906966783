import { Route, Routes } from 'react-router'
import { Show404 } from '../../404'
import { Body } from 'src/components/Blocks/Body'
import { QuestionnairePage } from '../components/QuestionnairePage'
import { SOM6Page } from 'src/flows/questionnaires/PhysicalHealth/pages/SOM6'
import { ROS1Page } from 'src/flows/questionnaires/PhysicalHealth/pages/ROS/ros1'
import { ROS2Page } from 'src/flows/questionnaires/PhysicalHealth/pages/ROS/ros2'
import { QuestionnaireHeader } from '../components/QuestionnaireHeader'

export const PhysicalHealth = () => {
    return (
        <Body>
            <QuestionnaireHeader />
            <Routes>
                <Route
                    path={`som-6/`}
                    element={
                        <QuestionnairePage
                            title="Physical Health Check"
                            category="Somatic Health Screening"
                        >
                            <SOM6Page />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`ros-1/`}
                    element={
                        <QuestionnairePage
                            title="Physical Health Check"
                            category="Review of Systems"
                        >
                            <ROS1Page />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`ros-2/`}
                    element={
                        <QuestionnairePage
                            title="Physical Health Check"
                            category="Review of Systems"
                        >
                            <ROS2Page />
                        </QuestionnairePage>
                    }
                />
                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
