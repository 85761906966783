import {
    Blocks,
    Flex,
    Heading,
    LayoutGrid,
    Paragraph,
    Image,
    Container
} from '@asktia/tia-ui'
import { FC } from 'react'
import { useAmpli, useScrollToTopOnPageLoad, useUserInfo } from 'src/hooks'
import { RecommendedActionsButton } from 'src/components/RecommendedActionsButton'
import TiaReceptionIllustration from '../../assets/tia-reception-illustration.png'

const NewPatientActions = () => {
    const {
        clickStartCareOnHomepage,
        clickHealthConcernOnHomepage,
        clickMentalHealthSupportOnHomepage,
        clickSomethingElseOnHomepage
    } = useAmpli()

    const TWHE_APPT_PROFILE_UUID_ON_PROD =
        '27a9fa49-507d-494d-8308-f41b4a1ad9e8' // We do need to hardcode this since it'll not appear on offerings query since it'll be not patient bookable

    return (
        <>
            <RecommendedActionsButton
                selected
                apptLink={`/book-appointment/profile/${TWHE_APPT_PROFILE_UUID_ON_PROD}/time-selection`}
                onClick={() => clickStartCareOnHomepage()}
            >
                I’m looking to start care with Tia
                <Paragraph>
                    Our signature journey starts with a comprehensive health
                    review and may be followed by a preventative annual exam and
                    other visits based on your needs
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments"
                onClick={() => clickHealthConcernOnHomepage()}
            >
                I need help with a health concern
                <Paragraph>
                    Dedicated time to address a specific health concern
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments?serviceLines=mental_health"
                onClick={() => clickMentalHealthSupportOnHomepage()}
            >
                I'd like mental health support
                <Paragraph>
                    Seek support for your mental health and wellness
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments"
                onClick={() => clickSomethingElseOnHomepage()}
            >
                I'm looking for something else
                <Paragraph>
                    Explore all appointments and services at Tia.
                </Paragraph>
            </RecommendedActionsButton>
        </>
    )
}

export const OnboardingRecommendedActions: FC = () => {
    const { user } = useUserInfo()
    useScrollToTopOnPageLoad()

    return (
        <Flex backgroundColor="secondaryBackground" sx={{ pb: 7 }}>
            <Container>
                <Flex sx={{ flexDirection: ['column', 'row'] }}>
                    <Flex
                        sx={{
                            pt: 6,
                            flexDirection: 'column'
                        }}
                    >
                        <Heading
                            h1
                            sx={{
                                textAlign: 'left',
                                width: '100%'
                            }}
                        >
                            Hi {user?.givenName}, what brings you in today?
                        </Heading>
                        <Image
                            src={TiaReceptionIllustration}
                            sx={{
                                width: '80%',
                                minWidth: 'initial'
                            }}
                        />
                    </Flex>

                    <Flex
                        sx={{
                            pt: 6,
                            flexDirection: 'column',
                            alignItems: 'left'
                        }}
                    >
                        <NewPatientActions />
                    </Flex>
                </Flex>
            </Container>
            <Blocks.Wave
                position="bottom"
                fill="mainBackground"
                sx={{ backgroundColor: 'secondaryBackground' }}
            />
        </Flex>
    )
}
