import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export function useLoginByPhone({ onSuccess }: any) {
    return useMutation(
        async (phoneNumber: string) => {
            fetcher(
                `${BASE_API_URL}/api/v1/account/login/phone`,
                JSON.stringify({ phoneNum: phoneNumber }),
                'POST'
            )
        },
        {
            onSuccess
        }
    )
}

export function useLoginByEmail({ onSuccess }: any) {
    const [searchParams] = useSearchParams()
    const { mutate } = useMutation(
        async (email: string) => {
            const redirectUrl = searchParams.get('redirect')
            const loginProperties: { email: string; redirectUrl?: string } = {
                email
            }
            if (redirectUrl && redirectUrl.length > 0) {
                loginProperties.redirectUrl = redirectUrl
            }

            fetcher(
                `${BASE_API_URL}/api/v1/account/login/email`,
                JSON.stringify(loginProperties),
                'POST'
            )
        },
        {
            onSuccess
        }
    )

    return mutate
}
