import { BoxProps, Flex } from '@asktia/tia-ui'
import React, { FC } from 'react'
import { useScrollToTopOnPageLoad } from 'src/hooks'

type ViewProps = {
    withFooter?: boolean
}

/**
 *
    View component that wraps everything beneath the header
    adds className used for transitions
    expands to full height and centers itself
 */
export const View: FC<React.PropsWithChildren<BoxProps & ViewProps>> = ({
    children,
    sx,
    withFooter
}) => {
    useScrollToTopOnPageLoad()
    return (
        <Flex
            sx={{
                minHeight: '100%',
                width: '100%',
                alignSelf: 'center',
                flexDirection: 'column',
                flex: 1,
                pb: withFooter ? [100, 5] : 5,
                mx: 'auto',
                textAlign: 'left',
                ...sx
            }}
            className="view" // used for css transitions
        >
            {children}
        </Flex>
    )
}
