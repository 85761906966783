import {
    Box,
    Button,
    Card,
    Heading,
    TiaModal,
    Link,
    ThemeStyleObject
} from '@asktia/tia-ui'
import { capitalize } from 'lodash'
import React, { FC, useEffect, useRef, useState } from 'react'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { InsuranceCardType } from 'src/hooks/useInsuranceImages'

type UploadInsuranceModalProps = {
    cardType: InsuranceCardType
    hideModal: Function
    onImageChange: Function
    image: File | null
}

export const UploadInsuranceModal: FC<
    React.PropsWithChildren<UploadInsuranceModalProps>
> = ({ cardType, onImageChange, hideModal, image: initialImage }) => {
    const defaultActionButtonState = 'Add image'
    const [loading, setLoading] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)
    const [image, setImage] = useState<File | null>(initialImage)
    const [imageSource, setImageSource] = useState('')

    const [actionButtonState, setActionButtonState] = useState(
        defaultActionButtonState
    )

    const onChangeCard = (e: any) => {
        const file = e.target?.files[0]
        setImage(file || null)
    }

    // updates image source based on image value
    useEffect(() => {
        if (!image) {
            setImageSource('')
            setActionButtonState(defaultActionButtonState)
            return
        }

        const fileReader = new FileReader()
        fileReader.readAsDataURL(image!)
        fileReader.onload = () => {
            setImageSource(
                fileReader.result ? fileReader.result.toString() : ''
            )

            setActionButtonState('Save Image')
        }
    }, [image])

    const handleAddImage = () => {
        inputRef!.current!.click()
    }

    const handleUpdateImage = async () => {
        setLoading(true)

        try {
            onImageChange(image)
            hideModal()
        } catch (e) {
            setLoading(false)
        }
    }

    const handleDeleteImage = () => {
        setImage(null)
        onImageChange(null)
    }

    const imgProps: ThemeStyleObject = imageSource
        ? {
              backgroundImage: `url(${imageSource})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
          }
        : {}

    const sx: ThemeStyleObject = {
        textAlign: 'center',
        height: '169px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 6,
        ...imgProps
    }

    return (
        <TiaModal sx={{ width: '100%' }}>
            <Heading
                h2
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                Card {capitalize(cardType)}
                <Button onClick={() => hideModal()} variant="unstyled">
                    <CloseIcon color="supportText" />
                </Button>
            </Heading>

            {imageSource ? (
                <Box sx={sx} onClick={handleAddImage} />
            ) : (
                <Card sx={sx} onClick={handleAddImage}>
                    No image on file
                </Card>
            )}

            <Button
                fullWidth
                onClick={
                    actionButtonState === defaultActionButtonState
                        ? handleAddImage
                        : handleUpdateImage
                }
                loading={loading}
            >
                {actionButtonState}
            </Button>

            {image && (
                <Link
                    sx={{
                        mt: 4,
                        color: 'supportText',
                        cursor: 'pointer',
                        textAlign: 'center',
                        display: 'block'
                    }}
                    onClick={handleDeleteImage}
                >
                    Delete image
                </Link>
            )}
            <input
                ref={inputRef}
                onChange={onChangeCard}
                name="cardUpload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
            />
        </TiaModal>
    )
}
