import {
    Flex,
    Heading,
    Link,
    Paragraph,
    ThemeStyleObject
} from '@asktia/tia-ui'
import { EventHandler, FC, MouseEventHandler } from 'react'
import { ArrowRightIcon, CheckmarkIcon } from 'src/components/Blocks/Icons'
import { HealthRecordEntry } from 'src/types'

export type UHRProgressCard = 'not_started' | 'started' | 'done'

export interface IUHRProgressCard {
    status: UHRProgressCard
    record: HealthRecordEntry
    isNext?: boolean
    sx?: ThemeStyleObject

    onClick?: MouseEventHandler
}

export const UHRProgressCard: FC<IUHRProgressCard> = ({
    status,
    isNext,
    record,
    sx,
    onClick
}) => {
    return (
        <Link
            href={record.url || '#'}
            onClick={onClick}
            sx={{
                'width': '100%',
                'height': 56,
                'borderRadius': 2,
                'py': 4,
                'px': 5,
                'mb': 2,
                'background':
                    status === 'done'
                        ? 'secondaryBackground'
                        : isNext
                        ? 'primaryButton'
                        : 'white',
                'border': '1px solid',
                'borderColor': 'cardOutline',
                'textDecoration': 'none',
                '&:hover': {
                    borderColor: 'inputOutlineHover'
                },
                '&:active': {
                    background: !isNext && 'secondaryBackground'
                },
                'h4': {
                    color: isNext && 'white'
                },
                'p': {
                    color: isNext && 'white'
                },
                'svg': {
                    path: {
                        fill: isNext && 'white'
                    }
                },
                ...sx
            }}
        >
            <Flex
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Flex sx={{ flexDirection: 'column' }}>
                    <Heading h4 sx={{ mb: 0 }}>
                        {record.title}
                    </Heading>
                </Flex>

                {status === 'done' ? (
                    <CheckmarkIcon color="text" />
                ) : (
                    <Flex
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        {isNext && (
                            <Paragraph sx={{ mb: 0, mr: 2, fontWeight: 700 }}>
                                Start
                            </Paragraph>
                        )}
                        <ArrowRightIcon color="text" />
                    </Flex>
                )}
            </Flex>
        </Link>
    )
}
