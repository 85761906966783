import { Flex, useTiaUI, Box, ThemeStyleObject } from '@asktia/tia-ui'

type ProgressCrumbProps = {
    /**
     * which step are you on
     */
    current: string

    /**
     * what are the steps
     */
    steps: string[]

    sx?: ThemeStyleObject
}

export const Crumb = (props: { color: string }) => (
    <Box sx={{ p: 1 }}>
        <svg
            width="5"
            height="4"
            viewBox="0 0 5 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="2.5" cy="2" r="2" fill={props.color} />
        </svg>
    </Box>
)

export const ProgressCrumbs = ({ current, steps, sx }: ProgressCrumbProps) => {
    const { theme } = useTiaUI()

    return (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center', ...sx }}>
            {steps.map(step => (
                <Crumb
                    color={
                        step === current
                            ? (theme.colors?.text as string)
                            : (theme.colors?.inactiveText as string)
                    }
                    key={step}
                />
            ))}
        </Flex>
    )
}
