import { Container, Grid, Heading, Spinner } from '@asktia/tia-ui'
import { AnimatePresence, motion } from 'framer-motion'
import pluralize from 'pluralize'
import { FC } from 'react'
import { View } from 'src/components/Blocks/View'
import { BookingCard } from 'src/flows/AppointmentBooking/BookingCard'
import { useUserInfo } from 'src/hooks'
import { FilterFunction } from 'src/pages/Appointments/useAppointmentFilters'

import { AppointmentProfile } from 'src/types'

export interface IBookingGrid {
    offerings?: AppointmentProfile[]
    isLoading: boolean
    filterFunction: FilterFunction
}

export const BookingGrid: FC<IBookingGrid> = ({
    offerings,
    isLoading,
    filterFunction
}) => {
    const hasOfferings = offerings && offerings.length > 0
    const { user } = useUserInfo()

    const displayedOfferings = offerings?.filter(filterFunction) || []
    const showingAllAppointments =
        Number(offerings?.length) <= displayedOfferings.length

    return (
        <Container>
            {!hasOfferings && isLoading && (
                <View>
                    <Spinner />
                </View>
            )}
            {!showingAllAppointments && !isLoading && (
                <Heading h2>
                    {`${displayedOfferings.length} ${pluralize(
                        'result',
                        displayedOfferings.length
                    )}`}
                </Heading>
            )}
            <Grid
                pt={showingAllAppointments ? 6 : undefined}
                gap={4}
                sx={{ alignItems: 'stretch' }}
                columns={['1fr', '1fr 1fr 1fr']}
            >
                <AnimatePresence initial={false} mode="wait">
                    {displayedOfferings.map((offering: AppointmentProfile) => {
                        return (
                            <motion.div
                                key={`appointment-profile${offering.appointmentProfileUuid}-${location.href}`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.1 }}
                                exit={{ opacity: 0 }}
                            >
                                <BookingCard
                                    variant="small"
                                    careType={offering.careTypeName}
                                    appointmentProfile={offering}
                                    disabled={
                                        !user?.isActive &&
                                        user?.membershipStatus === 'deactivated'
                                    }
                                    descriptionMaxLines={3}
                                />
                            </motion.div>
                        )
                    })}
                </AnimatePresence>
            </Grid>
        </Container>
    )
}
