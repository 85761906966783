import {
    Box,
    Button,
    Card,
    Flex,
    Form,
    Grid,
    Heading,
    LabeledCheckbox,
    Link,
    Text,
    Whisper
} from '@asktia/tia-ui'
import { yupResolver } from '@hookform/resolvers'
import { memo, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ChevronRight } from 'src/components/Blocks/Icons'
import { SuccessAnimation } from 'src/components/SuccessAnimation'
import { BlueInterstitial } from 'src/flows/AutomatedCheckin/BlueInterstitial'
import { useEnableButtonOnChange, useAmpli, useNavigation } from 'src/hooks'
import { ConsentForm, SaveStepFunction } from 'src/types'
import * as yup from 'yup'
import allSetIllustration from 'src/assets/aci-allset-illustration.png'
import { Appointment } from 'src/types'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'

const ConsentFormLink = (props: ConsentForm & { name: string }) => {
    return (
        <Grid
            sx={{
                'pt': 4,
                'pb': 4,
                'alignItems': 'center',
                'borderBottom': t => `1px solid ${t.colors?.inputOutline}`,
                '&:last-child': {
                    borderBottom: 0,
                    pb: 0
                }
            }}
            columns="1fr 50px"
            gap={0}
        >
            <LabeledCheckbox
                name={props.name}
                errorMessage=""
                defaultChecked={!!props.consented}
            >
                {props.title}
            </LabeledCheckbox>

            <Link href={props.link} target="_blank" sx={{ textAlign: 'right' }}>
                <ChevronRight color="text" />
            </Link>
        </Grid>
    )
}

// the main interactive version of this step
export const InteractiveConsentInfo = (props: {
    appointment: Appointment
    consentForms: ConsentForm[]
    saveStep: SaveStepFunction
    isSaving: boolean
    onSuccess: Function
}) => {
    const namedForms = props.consentForms.map((form, index) => ({
        ...form,
        name: `form-${index}`
    }))

    const schema = yup
        .object(
            Object.fromEntries(
                namedForms.map(form => [form.name, yup.boolean().oneOf([true])])
            )
        )
        .defined()
    type ConsentInfoFields = yup.InferType<typeof schema>

    const formMethods = useForm<ConsentInfoFields>({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    })
    const enableButton = useEnableButtonOnChange(formMethods, schema)

    const onSubmit = async (data: any) => {
        // collect links to consented forms
        const consentedForms = Object.keys(data)
            .map(name => namedForms.find(f => f.name === name))
            .filter(Boolean)
            // @ts-ignore: we filtered out anything undefined
            .map(form => form.link)

        const [success] = await props.saveStep({ consentedForms }, false)

        if (success) {
            props.onSuccess()
        }
    }

    return (
        <View withFooter>
            <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                <Box sx={{ mb: 6 }}>
                    <Heading h2 sx={{ mb: 5 }}>
                        Give Consent for Care
                    </Heading>
                    <p>
                        Please consent to the following to receive care from
                        Tia. With us, your privacy & your data are always
                        protected.
                    </p>
                    {namedForms.map(form => (
                        <ConsentFormLink {...form} key={form.name} />
                    ))}
                </Box>

                <Footer>
                    <Button
                        type="submit"
                        disabled={!enableButton || props.isSaving}
                        loading={props.isSaving}
                    >
                        I'm ready for my appointment
                        <Text variant="buttonSubtext" as="div">
                            Complete Check In
                        </Text>
                    </Button>
                </Footer>
            </Form>
        </View>
    )
}

// ConsentInfo step renders in 3 different variants
// initial – the interactive variant with consent links
// blueInterstitial - the blue screen with Let's Go button
// successAnimation - the delightful squiggly lines
// this could be an xstate machine, but it's simple enough
type ConsentInfoVariant = 'initial' | 'blueInterstitial' | 'successAnimation'

export const ConsentInfo = (props: {
    appointment: Appointment
    consentForms: ConsentForm[]
    saveStep: SaveStepFunction
    isSaving: boolean
}) => {
    const [currentVariant, setCurrentVariant] =
        useState<ConsentInfoVariant>('initial')
    const { navigate } = useNavigation()

    const { checkInPart2Completed } = useAmpli()

    function onCheckinSuccess() {
        setCurrentVariant('blueInterstitial')
    }

    function onLetsGo() {
        setCurrentVariant('successAnimation')

        // navigate to next step after animation complete
        navigate(`/automated-checkin/${props.appointment.id}/completed`, 2300)
    }

    useEffect(() => {
        if (currentVariant === 'blueInterstitial') {
            checkInPart2Completed(props.appointment)
        }
    }, [currentVariant, checkInPart2Completed, props.appointment])

    switch (currentVariant) {
        case 'initial':
            return (
                <InteractiveConsentInfo
                    {...props}
                    onSuccess={onCheckinSuccess}
                />
            )
        case 'blueInterstitial':
            return (
                <BlueInterstitial
                    image={allSetIllustration}
                    title="You’re all set!"
                    btnText="Let's go"
                    // we know saveStep
                    btnOnClick={onLetsGo}
                    info="From booking to billing and everything in between, we're ready to care for the *whole* you."
                    currentStep={2}
                />
            )
        case 'successAnimation':
            return <CheckinAnimation />
    }
}

const ConsentLinkSkeleton = (width: number, index: number) => (
    <Flex
        key={index}
        sx={{
            'pt': 3,
            'pb': 3,
            'alignItems': 'center',
            'borderBottom': t => `1px solid ${t.colors?.grey10}`,
            '&:last-child': {
                borderBottom: 0,
                pb: 0
            }
        }}
    >
        <Whisper sx={{ width: 4, height: 4, mr: 3 }} />
        <Whisper sx={{ width, height: 28 }} />
    </Flex>
)

export const ConsentInfoSkeleton = () => {
    return (
        <View>
            <Box>
                <Card variant="info" sx={{ mb: 4 }}>
                    <p>
                        Please consent to the following to receive care from
                        Tia. With us, your privacy & your data are always
                        protected.
                    </p>
                </Card>

                <Card variant="condensed" sx={{ mb: 6 }}>
                    <Heading variant="cardTitle">Give Consent for Care</Heading>
                    {[8, 200, 9, 8, 200].map(ConsentLinkSkeleton)}
                </Card>
                <Footer>
                    <Button disabled>
                        I'm ready for my appointment
                        <Text variant="buttonSubtext" as="div">
                            Complete Check In
                        </Text>
                    </Button>
                </Footer>
            </Box>
        </View>
    )
}

export const CheckinAnimation = memo(() => <SuccessAnimation variant="aci" />)
