import { FC, useState, useEffect } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import './Stripe.css'
import { Spinner } from '@asktia/tia-ui'

export const CreditCard: FC<{ onChange?: (event: any) => void }> = ({
    onChange
}) => {
    const [ready, setReady] = useState(false)

    /*
     * This is a workaround for a react-stripe-js issue where if we try to mount a CardElement too quickly,
     * if will try to create 2 or more elements and throw an error.
     */
    useEffect(() => {
        setTimeout(() => {
            if (!ready) {
                setReady(true)
            }
        }, Math.ceil(Math.random() * 500 + 700))
    }, [])

    return ready ? (
        <CardElement
            onChange={onChange}
            options={{
                style: {
                    base: {
                        'iconColor': '#831A4A',
                        'backgroundColor': '#fff',
                        'color': '#000',
                        'fontFamily': 'Helvetica, sans-serif',
                        'fontSize': '18px',
                        'lineHeight': '48px',
                        'padding': '20px !important',
                        '::placeholder': {
                            color: '#BE9191'
                        }
                    },
                    invalid: {
                        color: '#FF0000'
                    }
                }
            }}
        ></CardElement>
    ) : (
        <Spinner />
    )
}
