import {
    AppointmentLocationBadge,
    LocationModalityBadge
} from 'src/components/AppointmentLocation'
import {
    LocationModality,
    AppointmentProfile,
    LocationDetails,
    CareType,
    ProviderGender
} from 'src/types'
import {
    Card,
    Flex,
    Heading,
    Image,
    ThemeStyleObject,
    Text
} from '@asktia/tia-ui'
import Markdown from '../Markdown'
import { ProviderTag } from './components/ProviderTag'

type PartialAppointmentProfileForCardRender = {
    careTypes?: Pick<CareType, 'id' | 'name'>[]
} & Pick<
    AppointmentProfile,
    | 'label'
    | 'locations'
    | 'imageUrl'
    | 'description'
    | 'onDemandProfileUuid'
    | 'careTypeName'
    | 'marketingServiceLines'
>

export const AppointmentProfileCard = (props: {
    appointmentProfile: PartialAppointmentProfileForCardRender
    hideDescription?: boolean
    flatBottom?: boolean
    location?: LocationModality
    locationDetails?: LocationDetails
    sx?: ThemeStyleObject
    descriptionMaxLines?: number
    disabled?: boolean
    provider?: { name: string; gender: ProviderGender; pronoun?: string[] }
}) => {
    const { label, locations, imageUrl, description } = props.appointmentProfile

    const flatBottomSx = props.flatBottom
        ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
          }
        : {}

    const marketServiceLines =
        props.appointmentProfile.marketingServiceLines?.map(serviceLine => {
            const isSupportGroup = props.appointmentProfile.careTypes?.some(
                careType => careType.name === 'support_group'
            )

            // TM-4669: Hack until we figure out what we want to do with Support group care type
            if (isSupportGroup) {
                return 'Mental Health Group'
            }

            return serviceLine.label
        }) || []

    const MARKDOWN_LINE_HEIGHT = 21

    return (
        <Card
            sx={{
                border: 'none',
                borderBottom: flatBottomSx ? '1px solid' : 0,
                borderColor: 'oat',
                ...flatBottomSx,
                ...props.sx,
                pb: 0
            }}
        >
            <Flex
                sx={{
                    height: '100%',
                    rowGap: 4,
                    flexDirection: 'column',
                    pb: 4
                }}
            >
                <Flex sx={{ flexWrap: 'wrap' }}>
                    <Flex>
                        {label.startsWith('Tia Whole Health') ||
                        label.startsWith('Annual') ? (
                            <Text
                                sx={{
                                    mb: 1,
                                    fontSize: 0
                                }}
                                variant="eyebrow"
                            >
                                Preventative Care
                            </Text>
                        ) : (
                            <Text
                                sx={{
                                    mb: 1,
                                    fontSize: 0
                                }}
                                variant="eyebrow"
                            >
                                {marketServiceLines.join(' · ')}
                            </Text>
                        )}
                    </Flex>
                    <Flex
                        sx={{ justifyContent: 'space-between', width: '100%' }}
                    >
                        <Flex sx={{ flexDirection: 'column', flex: 1 }}>
                            <Heading
                                h4
                                sx={{
                                    mb: 1,
                                    fontSize: 5,
                                    fontWeight: 'bold'
                                }}
                            >
                                {label}
                            </Heading>
                            {props.locationDetails ? ( //TODO: This appointment profile card should be refactored into HOC depending on use case
                                <AppointmentLocationBadge
                                    sx={{ paddingLeft: 0 }}
                                    locationDetails={props.locationDetails}
                                />
                            ) : (
                                <LocationModalityBadge
                                    sx={{ paddingLeft: 0 }}
                                    modalities={
                                        props.location
                                            ? [props.location]
                                            : locations
                                    }
                                />
                            )}
                        </Flex>

                        <Image src={imageUrl} sx={{ height: 56 }} />
                    </Flex>
                </Flex>

                {!props.hideDescription && description && (
                    <Markdown
                        text={description}
                        sx={{
                            'm': 0,
                            'wordBreak': 'break-word',
                            'color': 'text',
                            'whiteSpace': 'pre-wrap',
                            'display': '-webkit-box',
                            'textOverflow': 'ellipsis',
                            'overflow': 'hidden',
                            'fontSize': 15,
                            'lineHeight': `${MARKDOWN_LINE_HEIGHT}px`,
                            '-webkit-line-clamp':
                                props.descriptionMaxLines?.toString(),
                            '-webkit-box-orient': 'vertical',

                            // Max Height is needed because if the markdown
                            // have a link (or any other component) in it, it broke the line clamping
                            'maxHeight': props.descriptionMaxLines
                                ? MARKDOWN_LINE_HEIGHT *
                                  props.descriptionMaxLines
                                : undefined
                        }}
                    />
                )}
            </Flex>
        </Card>
    )
}
