import { Button, Form, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { FC, useLayoutEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useQuestionnaire } from '../../../hooks/useQuestionnaire'
import { useQuestionnaireResponse } from '../../../hooks/useQuestionnaireResponse'
import { ErrorSavingBanner } from '../../../components/ErrorSavingBanner'
import { getFormDone } from '../../../getFormDone'
import { get as _get, omit as _omit } from 'lodash'
import { QuestionnaireDivider } from '../../../components/QuestionnaireDivider'
import { GluedSingleChoiceQuestion } from '../../../components/Input/SingleChoice'
import { GluedDateQuestion } from '../../../components/Input/DateInput'
import { GluedMultiChoiceQuestion } from '../../../components/Input/MultiChoice'
import { GluedHiddenQuestion } from '../../../components/Input/HiddenInput'
import { QuestionFormGlue } from '../../../types'
import { useNavigation, useScrollToTopOnPageLoad } from 'src/hooks'
import { useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import { Ros2Schema } from 'src/flows/questionnaires/PhysicalHealth/pages/ROS/Ros2.schema'

// Those hidden inputs are added on the page bc we splited the ROS page in 2 and
// to keep the same machinery that we already had, we just add those hidden inputs that will persist data between
// the pages.
//
const QuestionsFromROS1Page: FC<{ questionFormGlue: QuestionFormGlue }> = ({
    questionFormGlue
}) => (
    <>
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-neck"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-circulation"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-circulation-choice"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-blood"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-blood-choice"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-lymph"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="cardiovascular-lymph-choice"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="covid-19" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="covid-19-contact" />
    </>
)

export const ROS2Page: FC = () => {
    useScrollToTopOnPageLoad()
    const queryClient = useQueryClient()
    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all',
        resolver: yupResolver(Ros2Schema)
    })

    const [searchParams] = useSearchParams()
    const { navigate } = useNavigation()
    const [showSavingError, setShowSavingError] = useState(false)
    const { questionnaire } = useQuestionnaire('ros')
    const { answersMap, questionnaireResponse, saveQuestionnaireResponse } =
        useQuestionnaireResponse(
            'ros',
            searchParams.get('appointmentProfileUuid') as string
        )
    const { formDone } = getFormDone(formMethods.getValues())

    const getValidFormValues = async (triggeredByFieldName?: string) => {
        // validate form against latest form values
        await formMethods.trigger(triggeredByFieldName)

        const formValues = formMethods.getValues()
        const formKeys = Object.keys(formValues)

        // TODO: not sure best way to handle this any
        // at the moment the formValues also have an any type
        const validFormValues: any = {}
        const fieldErrors = formMethods.formState.errors

        // Note - this only works for 1 nested level
        // i.e.
        // formValues = {nicotine-use: {polar: 'yes', quantity: 'asdfasdf'}}
        // errors = {nicotine-use: {quantity: 'must be a number'}}
        // validFormValues = {nicotine-use: {polar: 'yes'}
        formKeys.forEach((key: string) => {
            const fieldValue = _get(formValues, key)
            const nestedFieldErrors = _get(fieldErrors, key)
            validFormValues[key as string] =
                typeof fieldValue === 'object' && !Array.isArray(fieldValue)
                    ? _omit(fieldValue, Object.keys(nestedFieldErrors || {}))
                    : fieldValue
        })

        return validFormValues
    }

    // Save questionnaire and handle errors:
    // - clear triggering field, so user knows what's wrong
    // - show error toast
    async function triggerSave(triggeredByFieldName?: string) {
        setShowSavingError(false)
        try {
            await saveQuestionnaireResponse(
                await getValidFormValues(triggeredByFieldName)
            )
        } catch (e) {
            console.error(e)
            if (triggeredByFieldName) {
                formMethods.setValue(triggeredByFieldName, undefined)
            }
            setShowSavingError(true)
        }
    }

    const onComplete = async () => {
        triggerSave()
        navigate(searchParams.get('onCompleteRelativePath') as string)
    }

    // This is needed to ensure that we are on latest state, while navigating to
    // pages ros-1 and ros-2
    useLayoutEffect(() => {
        queryClient.refetchQueries(['uhr', 'questionnaire'])
    }, [])

    if (!questionnaire.item) {
        return <Spinner />
    }

    const questionFormGlue = {
        questionnaire,
        questionnaireResponse,
        answersMap,
        triggerSave
    }

    const canProceed = formDone && Object.keys(formMethods.errors).length === 0

    return (
        <>
            {showSavingError && <ErrorSavingBanner />}
            <LayoutGrid columns="one" variant="condensed">
                <FormProvider {...formMethods}>
                    <Form
                        useFormMethods={formMethods}
                        onSubmit={() => console.log('on submit')}
                    >
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="gastrointestinal"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="gastrointestinal-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="gastrointestinal-kidney"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="gastrointestinal-kidney-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="endocrine-metabolic"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="endocrine-metabolic-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="skin"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="skin-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="eye"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="eye-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="muscular"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="muscular-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="neurological"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="neurological-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="breast-chest"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="breast-chest-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="genital"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="genital-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedDateQuestion
                            {...questionFormGlue}
                            linkId="menstrual"
                            customNilnknownText="I’ve never done this, or this doesn’t apply to me."
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="nose"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="mouth-throat"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="mouth-throat-choice"
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="breathing"
                        />

                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="breathing-choice"
                        />

                        <QuestionsFromROS1Page
                            questionFormGlue={questionFormGlue}
                        />

                        <Button
                            sx={{ mt: 6, mb: 6, py: '20px' }}
                            onClick={onComplete}
                            disabled={!canProceed}
                            fullWidth
                        >
                            Complete Review of Systems
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </>
    )
}
