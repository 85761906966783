import { Questionnaire } from '@asktia/tia-fhir-models'
import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export function useQuestionnaire(questionnaireId: string): {
    isLoading: boolean
    questionnaire: Questionnaire
} {
    const query = useQuery<Questionnaire>(
        ['uhr', 'questionnaire', questionnaireId],
        () =>
            fetcher(
                `${BASE_API_URL}/uhr-api/questionnaire/Questionnaire/${questionnaireId}`
            ),
        {
            cacheTime: Infinity
        }
    )

    return {
        isLoading: query.isLoading,
        questionnaire: query.data || {
            item: [],
            resourceType: 'Questionnaire',
            id: 'personal-wellness',
            title: 'Personal wellness'
        }
    }
}
