import { FC } from 'react'
import { Text, Box, Link } from '@asktia/tia-ui'

export const BillingInfoCopyBlock: FC = () => {
    return (
        <Box as="ul" sx={{ paddingInlineStart: 18, marginBottom: 4 }}>
            <Text variant="supportingInfo" as="li">
                With Tia's Predictable Payments, you’ll pay your bill over time,
                $100 per month.{' '}
                <Link
                    target="_blank"
                    href="https://asktia.com/predictable-payments"
                >
                    Learn more about Predictable Payments.
                </Link>
            </Text>

            <Text variant="supportingInfo" as="li">
                If you prefer to pay your total balance,{' '}
                <Link href="chat/new?label=Billing%20Question&subject=I%20want%20to%20pay%20my%20total%20balance&message=I%20want%20to%20pay%20my%20total%20balance">
                    contact your Care Team
                </Link>{' '}
                and they can help.
            </Text>

            <Text variant="supportingInfo" as="li">
                We offer a payment window of 30 days from the invoice date. If
                we do not receive payment within 30 days, we will automatically
                charge your card on file. If you have questions about your bill,
                please see our{' '}
                <Link
                    target="_blank"
                    href="https://intercom.help/ask-tia/en/collections/3752830-insurance-and-billing"
                >
                    FAQs
                </Link>{' '}
                or{' '}
                <Link href="chat/new?label=Billing%20Question&subject=I%20have%20a%20question%20about%20my%20bill&message=I%20have%20a%20question%20about%20my%20bill">
                    message your Care Team
                </Link>
                .
            </Text>

            <Text variant="supportingInfo" as="li">
                After you pay your invoice, it may take up to 5 business days
                for it to be reflected in your balance
            </Text>
        </Box>
    )
}
