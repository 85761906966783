import {
    ampli,
    PageViewedProperties,
    NpsSubmittedProperties,
    NpsModalViewedProperties,
    ViewAppointmentBillingDetailsProperties,
    SelectPayCurrentInvoiceProperties,
    ViewAppointmentBillingProperties,
    ViewedLastProviderSlotsProperties,
    ClickedOnRecommendedImmunizationProperties,
    ClickedOnOtherImmunizationProperties,
    SearchImunizationProperties,
    AddedImmunizationProperties,
    RemovedImmunizationProperties,
    EditedImmunizationProperties
} from '../ampli'
import {
    AppointmentProfile,
    Appointment,
    AppointmentSuggestion,
    Clinic
} from 'src/types'
import { getDisplayTimestamp } from 'src/utils'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { useCallback } from 'react'
import { omitBy, isNil, isEmpty, get } from 'lodash'

export type AppointmentTrackingProperties = {
    appointmentDayOfWeek?: string
    intake?: boolean
    careType?: string
    bookingPath?: string
    appointmentType?: string
    appointmentProfile?: string
    appointmentId?: string
    booker?: string
    examRoomId?: string
    appointmentGoal?: string
    appointmentLocation?: string
    appointmentDateAndTime?: string
    appointmentStatus?: string
    appointmentSuggestionUuid?: string
}

export const useAmpli = () => {
    const location = useLocation()
    const [isIdentified, setIdentified] = useLocalStorage<boolean>(
        `tia:iteratively:identify:isIdentified`,
        false
    )

    const getAppointmentProfileProperties = useCallback(
        (
            appointmentProfile: AppointmentProfile,
            appointmentSuggestionUuid?: string,
            appointmentGoal?: string
        ): AppointmentTrackingProperties => {
            const properties: AppointmentTrackingProperties = {
                careType: appointmentProfile.careTypeId.toString(),
                bookingPath: location.pathname,
                appointmentProfile: appointmentProfile.appointmentProfileUuid,
                appointmentType: appointmentProfile.appointmentTypeUuid,
                appointmentLocation: appointmentProfile.clinicUuid,
                appointmentSuggestionUuid: appointmentSuggestionUuid,
                appointmentGoal: appointmentGoal
            }

            return omitBy(omitBy(properties, isNil), isEmpty)
        },
        [location]
    )

    const identify = useCallback(
        (user: any) => {
            if (isIdentified) {
                return
            }
            const properties = {}
            const urlParams = new URLSearchParams(window.location.search)
            const utmSource = urlParams.get('utm_source')
            const utmCampaign = urlParams.get('utm_campaign')
            if (utmSource) {
                Object.assign(properties, {
                    utmSource
                })
            }
            if (utmCampaign) {
                Object.assign(properties, {
                    utmCampaign
                })
            }
            setIdentified(true)
            ampli.identify(user.analyticsUuid, properties)
        },
        [isIdentified, setIdentified]
    )

    const pageViewed = useCallback(() => {
        const pageData: PageViewedProperties = {
            page: location.pathname,
            url: window.location.href
        }
        const urlParams = new URLSearchParams(window.location.search)
        const utmSource = urlParams.get('utm_source')
        const utmCampaign = urlParams.get('utm_campaign')
        const utmContent = urlParams.get('utm_content')
        const utmMedium = urlParams.get('utm_medium')
        const utmTerm = urlParams.get('utm_term')
        if (utmSource) {
            Object.assign(pageData, { utmSource })
        }
        if (utmCampaign) {
            Object.assign(pageData, {
                utmCampaign
            })
        }
        if (utmContent) {
            Object.assign(pageData, { utmContent })
        }
        if (utmMedium) {
            Object.assign(pageData, { utmMedium })
        }
        if (utmTerm) {
            Object.assign(pageData, { utmTerm })
        }
        ampli.pageViewed(pageData)
    }, [location])

    const getAppointmentProperties = useCallback(
        (appointment: Appointment) => {
            const properties = {
                bookingPath: location.pathname,
                appointmentLocation: appointment.clinic.uuid,
                appointmentId: appointment.id
            }

            if (appointment.careType) {
                Object.assign(properties, {
                    careType: appointment.careType
                })
            }

            if (appointment.scheduledTime && appointment.clinic.timezone) {
                const appTime = getDisplayTimestamp(
                    appointment.scheduledTime,
                    appointment.clinic.timezone
                )

                Object.assign(properties, {
                    appointmentDayOfWeek: appTime.dayOfWeek
                })
            }

            if (appointment.apptGoal && appointment.apptGoal.id) {
                Object.assign(properties, {
                    appointmentGoal: appointment.apptGoal.id.toString()
                })
            }

            return properties
        },
        [location]
    )

    const addToWaitlistNoTimeSelected = useCallback(
        (appointmentProfile: AppointmentProfile) => {
            const properties =
                getAppointmentProfileProperties(appointmentProfile)
            ampli.addToWaitlistNoTimeSelected(properties)
        },
        [getAppointmentProfileProperties]
    )

    const cancelOrChangeAppointmentClicked = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.cancelOrChangeAppointmentClicked(properties)
        },
        [getAppointmentProperties]
    )

    const cancelOrChangeAppointmentResponseClicked = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.cancelOrChangeAppointmentResponseClicked(properties)
        },
        [getAppointmentProperties]
    )

    const preSlotSearchLocationSelected = useCallback(
        (selectedLocation: string) => {
            ampli.preSlotSearchLocationSelected({ selectedLocation })
        },
        []
    )

    const appointmentCardCtaClicked = useCallback(
        (appointmentProfile: AppointmentProfile) => {
            const properties =
                getAppointmentProfileProperties(appointmentProfile)
            ampli.appointmentCardCtaClicked(properties)
        },
        [getAppointmentProfileProperties]
    )

    const bookingConfirmationCtaClicked = useCallback(
        (
            appointmentProfile: AppointmentProfile,
            appointmentSuggestionUuid?: string,
            appointmentGoal?: string
        ) => {
            const properties = getAppointmentProfileProperties(
                appointmentProfile,
                appointmentSuggestionUuid,
                appointmentGoal
            )
            ampli.bookingConfirmationCtaClicked(properties)
        },
        [getAppointmentProfileProperties]
    )

    const appointmentReviewViewed = useCallback(
        (
            appointmentProfile: AppointmentProfile,
            appointmentSuggestionUuid?: string,
            appointmentGoal?: string,
            preselectedGoalId?: number
        ) => {
            const properties = getAppointmentProfileProperties(
                appointmentProfile,
                appointmentSuggestionUuid,
                appointmentGoal
            )
            ampli.appointmentReviewViewed(
                omitBy(
                    {
                        ...properties,
                        preselectedGoalId
                    },
                    isNil
                )
            )
        },
        [getAppointmentProfileProperties]
    )

    const appointmentUpdateConfirmed = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.appointmentUpdateConfirmed(properties)
        },
        [getAppointmentProperties]
    )

    const updateAppointmentViewed = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.updateAppointmentViewed(properties)
        },
        [getAppointmentProperties]
    )

    const viewNewMemberOnboarding = useCallback(() => {
        ampli.viewNewMemberOnboarding()
    }, [])

    const clickGetStartedInNewMemberOnboarding = useCallback(() => {
        ampli.clickGetStartedInNewMemberOnboarding()
    }, [])

    const clickStartCareOnHomepage = useCallback(() => {
        ampli.clickedStartCareOnHomepage()
    }, [])

    const clickHealthConcernOnHomepage = useCallback(() => {
        ampli.clickedHealthConcernOnHomepage()
    }, [])

    const clickMentalHealthSupportOnHomepage = useCallback(() => {
        ampli.clickedMentalHealthSupportOnHomepage()
    }, [])

    const clickSomethingElseOnHomepage = useCallback(() => {
        ampli.clickedSomethingElseOnHomepage()
    }, [])

    const clickBookNowInNewMemberOnboarding = useCallback(() => {
        ampli.clickBookNowInNewMemberOnboarding()
    }, [])

    const navigatedToAppointmentsPage = useCallback(() => {
        ampli.navigatedToAppointmentsPage()
    }, [])

    const viewYourCarePlanClickedOnHome = useCallback(() => {
        ampli.viewYourCarePlanClickedOnHome()
    }, [])

    const clickedOnRecommendedImmunization = useCallback(
        (properties: ClickedOnRecommendedImmunizationProperties) => {
            ampli.clickedOnRecommendedImmunization(properties)
        },
        []
    )

    const editedImmunization = useCallback(
        (properties: EditedImmunizationProperties) => {
            ampli.editedImmunization(properties)
        },
        []
    )

    const clickedOnOtherImmunization = useCallback(
        (properties: ClickedOnOtherImmunizationProperties) => {
            ampli.clickedOnOtherImmunization(properties)
        },
        []
    )

    const searchImmunization = useCallback(
        (properties: SearchImunizationProperties) => {
            ampli.searchImunization(properties)
        },
        []
    )

    const addedImmunization = useCallback(
        (properties: AddedImmunizationProperties) => {
            ampli.addedImmunization(properties)
        },
        []
    )

    const removedImmunization = useCallback(
        (properties: RemovedImmunizationProperties) => {
            ampli.removedImmunization(properties)
        },
        []
    )

    const filterAppointmentsByKeyword = useCallback(
        ({
            filterType,
            filterKeyword
        }: {
            filterType: 'symptom' | 'appointment'
            filterKeyword: string
        }) => {
            ampli.filterAppointmentsByKeyword({ filterType, filterKeyword })
        },
        []
    )

    const exitNewMemberOnboarding = useCallback(
        (
            exitPage: 'Tia intro' | 'TWHE intro' | 'Recommended actions intro'
        ) => {
            ampli.exitNewMemberOnboarding({
                exitPage
            })
        },
        []
    )

    const navBookAppointmentButtonClicked = useCallback((path: string) => {
        ampli.navBookAppointmentButtonClicked({
            path
        })
    }, [])

    const cancelReasonSelected = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.cancelReasonSelected({
                ...properties,
                cancelReason: appointment.cancellationReason
            })
        },
        [getAppointmentProperties]
    )

    const cancelConfirmed = useCallback(
        (appointment: Appointment) => {
            const properties = getAppointmentProperties(appointment)
            ampli.cancelConfirmed({
                ...properties,
                cancelReason: appointment.cancellationReason
            })
        },
        [getAppointmentProperties]
    )

    const onboardingQuizQuestionAnswered = useCallback(
        ({ name, answer }: { name: string; answer: any }) => {
            ampli.onboardingQuizQuestionAnswered({ name, answer })
        },
        []
    )

    const onboardingSuggestionPageViewed = useCallback(
        ({ appointmentProfileName }: { appointmentProfileName: string }) => {
            ampli.onboardingSuggestionPageViewed({ appointmentProfileName })
        },
        []
    )

    const onboardingSuggestionNoThanksClicked = useCallback(() => {
        ampli.onboardingSuggestionNoThanksClicked()
    }, [])

    const onboardingSuggestionClicked = useCallback(() => {
        ampli.onboardingSuggestionClicked()
    }, [])

    const onboardingExploreAllOtherAppointmentsClicked = useCallback(() => {
        ampli.onboardingExploreAllOtherAppointmentsClicked()
    }, [])

    const viewAppointmentBilling = useCallback(
        (properties: ViewAppointmentBillingProperties) => {
            ampli.viewAppointmentBilling(properties)
        },
        []
    )

    const viewAppointmentBillingDetails = useCallback(
        (properties: ViewAppointmentBillingDetailsProperties) => {
            ampli.viewAppointmentBillingDetails(properties)
        },
        []
    )

    const selectPayCurrentInvoice = useCallback(
        (properties: SelectPayCurrentInvoiceProperties) => {
            ampli.selectPayCurrentInvoice(properties)
        },
        []
    )

    const selectedLastProviderSlots = useCallback(() => {
        ampli.selectedLastProviderSlots()
    }, [])

    const resumeSignupBannerCtaClicked = useCallback(() => {
        ampli.resumeSignupBannerCtaClicked()
    }, [])

    const resumeSignupBannerCtaRendered = useCallback(() => {
        ampli.resumeSignupBannerCtaRendered()
    }, [])

    const resumeSignupModalCtaRendered = useCallback(() => {
        ampli.resumeSignupModalCtaRendered()
    }, [])

    const resumeSignupModalCtaClicked = useCallback(() => {
        ampli.resumeSignupModalCtaClicked()
    }, [])

    const resumeSignupBookAppointmentModalRendered = useCallback(() => {
        ampli.resumeSignupBookAppointmentModalRendered()
    }, [])

    const viewedLastProviderSlots = useCallback(
        (properties: ViewedLastProviderSlotsProperties) => {
            ampli.viewedLastProviderSlots(properties)
        },
        []
    )

    // Warning: There is method to this madness
    // - ensures functions are stable, no infinite loops
    // - you may want to return function: ampli.function
    // this causes an error with this.itly being undefined
    // ¯\_(ツ)_ /¯

    return {
        identify,
        resumeSignupBannerCtaClicked,
        resumeSignupModalCtaClicked,
        resumeSignupModalCtaRendered,
        resumeSignupBannerCtaRendered,
        appointmentCardCtaClicked,
        seeMoreOptionsClicked,
        appointmentReviewViewed,
        bookingConfirmationCtaClicked,
        cancelOrChangeAppointmentClicked,
        cancelOrChangeAppointmentResponseClicked,
        checkInNowClicked,
        checkInNotStarted,
        checkInPatientInfoCompleted,
        checkInInsuranceCompleted,
        checkInCardOnFileCompleted,
        checkInUhrCompleted,
        checkInConsentCompleted,
        checkInCompleted,
        upcomingAppointmentCardClicked,
        appointmentFilteredByProviderGender,
        appointmentFilteredByLocation,
        appointmentFilteredByDate,
        appointmentFilteredByProvider,
        backButtonClicked,
        profileClicked,
        npsSubmitted,
        npsModalViewed,
        pageViewed,
        careTypeBrowsed,
        preQuestionSelected,
        preQuestionSubmitted,
        postQuestionSelected,
        postQuestionSubmitted,
        eventsCtaClicked,
        annualExamMoreInfoCtaClicked,
        selectANewAppointmentTimeClicked,
        addToCalendarClicked,
        prepareForAppointmentIntakeViewed,
        homeViewed,
        ampli,
        insuranceSelected,
        appointmentStarted,
        paymentInformationSaved,
        appointmentHistoryViewed,
        appointmentUpdateConfirmed,
        updateAppointmentViewed,
        cancelReasonSelected,
        cancelConfirmed,
        appointmentSuggestionBannerClicked,
        appointmentSuggestionBookCta,
        appointmentSuggestionDismissCta,
        announcementCta,
        uhrViewed,
        yourCareViewed,
        carePlanViewed,
        cancelOrChangeMembershipViewed,
        cancelMembershipClicked,
        cancelMembershipReasonsViewed,
        changeMembershipClicked,
        cancelMembershipReasonsClicked,
        checkInPreAppointmentInfoCompleted,
        checkInPart1Completed,
        checkInPart2Completed,
        addToWaitlistNoTimeSelected,
        onDemandEnterWaitingRoomCtaClicked,
        onDemandJoinZoomCtaClicked,
        onDemandConfirmLocationClicked,
        bookingGoalSelectionViewed,
        viewNewMemberOnboarding,
        clickGetStartedInNewMemberOnboarding,
        clickBookNowInNewMemberOnboarding,
        exitNewMemberOnboarding,
        navBookAppointmentButtonClicked,
        navigatedToAppointmentsPage,
        filterAppointmentsByKeyword,
        filterAppointmentsByServiceLine,
        filterAppointmentsByLocation,
        clickServiceLineModuleOnAppointmentsPage,
        clickMhGroupsNotificationBanner,
        closeMhGroupsNotificationBanner,
        clickedAnnualExamInRecommendedActions,
        clickedHealthConcernInRecommendedActions,
        clickedMentalHealthSupportInRecommendedActions,
        clickedSomethingElseInRecommendedActions,
        clickedRecommendedAppointmentsInRecommendedActions,
        clickedStartCareInRecommendedActions,
        cashPayConfirmed,
        bookATwheCtaClickedFromAnnualExplanation,
        onboardingQuizQuestionAnswered,
        clickStartCareOnHomepage,
        clickHealthConcernOnHomepage,
        clickMentalHealthSupportOnHomepage,
        clickSomethingElseOnHomepage,
        viewYourCarePlanClickedOnHome,
        onboardingSuggestionPageViewed,
        onboardingSuggestionNoThanksClicked,
        onboardingSuggestionClicked,
        onboardingExploreAllOtherAppointmentsClicked,
        preSlotSearchLocationSelected,
        viewAppointmentBillingDetails,
        selectPayCurrentInvoice,
        viewAppointmentBilling,
        selectedLastProviderSlots,
        viewedLastProviderSlots,
        resumeSignupBookAppointmentModalRendered,
        clickedOnRecommendedImmunization,
        clickedOnOtherImmunization,
        addedImmunization,
        removedImmunization,
        searchImmunization,
        editedImmunization
    }
}

const getAciProperties = (appointment: Appointment) => {
    const properties = {
        appointmentLocation: appointment.clinic.uuid,
        appointmentProfile: appointment.apptProfile,
        appointmentGoal: get(appointment, 'apptGoal.id')
            ? appointment.apptGoal.id.toString()
            : null,
        careType: appointment.careType,
        appointmentId: appointment.id,
        appointmentDateAndTime: appointment.scheduledTime.toISOString()
    }
    return omitBy(properties, isNil)
}

const seeMoreOptionsClicked = async (
    appointmentProfile: AppointmentProfile
) => {
    ampli.seeMoreOptionsClicked({
        appointmentType: appointmentProfile.appointmentTypeUuid,
        appointmentProfile: appointmentProfile.appointmentProfileUuid,
        careType: appointmentProfile.careTypeId.toString()
    })
    // await ampli.flush()
}

const checkInNowClicked = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.checkInNowClicked(properties)
}

const checkInNotStarted = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.checkInNotStarted(properties)
}

const checkInPatientInfoCompleted = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.checkInPatientInfoCompleted(properties)
}

const checkInCardOnFileCompleted = (
    appointment: Appointment,
    cardOnFile?: boolean
) => {
    const properties = getAciProperties(appointment)
    Object.assign(properties, {
        cardOnFile
    })

    ampli.checkInCardOnFileCompleted(omitBy(properties, isNil))
}

const checkInInsuranceCompleted = (
    appointment: Appointment,
    hsaReceipt: boolean,
    insuranceSelected: boolean,
    usingInsuranceOnFile: boolean,
    coverageResult?: string
) => {
    const properties = getAciProperties(appointment)
    Object.assign(properties, {
        hsaReceipt,
        insuranceSelected,
        usingInsuranceOnFile,
        coverageResult
    })

    ampli.checkInInsuranceCompleted(omitBy(properties, isNil))
}

const checkInUhrCompleted = (appointment: Appointment, intake: boolean) => {
    const properties = getAciProperties(appointment)
    ampli.checkInUhrCompleted({
        ...properties,
        intake: intake
    })
}

const checkInConsentCompleted = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.checkInConsentCompleted(properties)
}

const checkInCompleted = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.checkInCompleted(properties)
}

const appointmentFilteredByProviderGender = (
    providerGender: string,
    appointmentProfile: AppointmentProfile
) => {
    const properties = {
        providerGender,
        appointmentType: appointmentProfile.appointmentTypeUuid,
        appointmentProfile: appointmentProfile.appointmentProfileUuid
    }
    ampli.appointmentFilteredByProviderGender(properties)
}

const appointmentFilteredByLocation = (
    appointmentLocation: string,
    appointmentProfile: AppointmentProfile
) => {
    const properties = {
        appointmentLocation,
        appointmentType: appointmentProfile.appointmentTypeUuid,
        appointmentProfile: appointmentProfile.appointmentProfileUuid
    }
    ampli.appointmentFilteredByLocation(properties)
}

const appointmentFilteredByDate = (
    date: Date,
    appointmentProfile: AppointmentProfile
) => {
    const properties = {
        date: date.toUTCString(),
        appointmentType: appointmentProfile.appointmentTypeUuid,
        appointmentProfile: appointmentProfile.appointmentProfileUuid
    }
    ampli.appointmentFilteredByDate(properties)
}

const backButtonClicked = (pageName: string) => {
    ampli.backButtonClicked({ pageName })
}

const profileClicked = () => {
    ampli.profileClicked()
}

const getLocationName = () => {
    const url = window.location.href

    if (
        url.includes('appointment') ||
        (url.includes('automated-checkin') && url.includes('not_started'))
    ) {
        return 'booking-appointment'
    }

    if (url.includes('automated-checkin') && !url.includes('not_started')) {
        return 'automated-checkin'
    }

    if (url.includes('chat')) {
        return 'chat'
    }

    return null
}

const npsSubmitted = (product: string, score: string) => {
    const data: NpsSubmittedProperties = {
        product,
        score
    }
    const location = getLocationName()
    if (location) {
        Object.assign(data, { location })
    }

    ampli.npsSubmitted(data)
}

const npsModalViewed = (product: string) => {
    const data: NpsModalViewedProperties = {
        product
    }

    const location = getLocationName()
    if (location) {
        Object.assign(data, { location })
    }

    ampli.npsModalViewed(data)
}

const careTypeBrowsed = (careType: string) => {
    ampli.careTypeBrowsed({ careType })
}

const preQuestionSelected = (question: string, answerSelected: string) => {
    ampli.preQuestionSelected({ question, answerSelected })
}

const preQuestionSubmitted = (question: string, answerSelected: string) => {
    ampli.preQuestionSubmitted({ question, answerSelected })
}

const postQuestionSelected = (question: string, answerSelected: string) => {
    ampli.postQuestionSelected({ question, answerSelected })
}

const postQuestionSubmitted = (question: string, answerSelected: string) => {
    ampli.postQuestionSubmitted({ question, answerSelected })
}

const homeViewed = () => {
    ampli.homeViewed()
}

const eventsCtaClicked = () => {
    ampli.eventsCtaClicked()
}

const annualExamMoreInfoCtaClicked = () => {
    ampli.annualExamMoreInfoCtaClicked()
}

const selectANewAppointmentTimeClicked = () => {
    ampli.selectANewAppointmentTimeClicked()
}

const addToCalendarClicked = (calendarSelected: string) => {
    ampli.addToCalendarClicked({ calendarSelected })
}

const prepareForAppointmentIntakeViewed = (
    appointment: Appointment,
    intakeCompleted: boolean
) => {
    const properties = getAciProperties(appointment)
    Object.assign(properties, {
        intakeCompleted
    })

    ampli.prepareForAppointmentIntakeViewed(omitBy(properties, isNil))
}

const upcomingAppointmentCardClicked = (
    appointment: Appointment,
    status: string
) => {
    const properties = getAciProperties(appointment)
    properties.appointmentStatus = status
    ampli.upcomingAppointmentCardClicked(properties)
}

const insuranceSelected = (insuranceCompany: string) => {
    ampli.insuranceSelected({ insuranceCompany })
}

const appointmentStarted = (appointment: Appointment) => {
    const properties = getAciProperties(appointment)
    ampli.appointmentStarted(properties)
}

function paymentInformationSaved() {
    ampli.paymentInformationSaved()
}

function appointmentSuggestionBannerClicked(
    appointmentSuggestion: AppointmentSuggestion
) {
    ampli.appointmentSuggestionBannerClicked({
        appointmentSuggestionUuid: appointmentSuggestion.uuid,
        appointmentProfile: appointmentSuggestion.appointmentProfileUuid
    })
}

function appointmentHistoryViewed() {
    ampli.appointmentHistoryViewed()
}

function appointmentSuggestionBookCta(
    appointmentSuggestion: AppointmentSuggestion,
    path: string
) {
    ampli.appointmentSuggestionBookCta({
        appointmentProfile: appointmentSuggestion.appointmentProfileUuid,
        appointmentSuggestionUuid: appointmentSuggestion.uuid,
        sourceType: appointmentSuggestion.sourceType,
        path
    })
}

function appointmentSuggestionDismissCta(
    appointmentSuggestion: AppointmentSuggestion,
    path: string
) {
    ampli.appointmentSuggestionDismissCta({
        appointmentProfile: appointmentSuggestion.appointmentProfileUuid,
        appointmentSuggestionUuid: appointmentSuggestion.uuid,
        sourceType: appointmentSuggestion.sourceType,
        path
    })
}

function announcementCta(announcement_name: string, path: string) {
    ampli.announcementCta({ announcement_name, path })
}

const uhrViewed = (
    location: string,
    healthRecordPercentageCompleted: number
) => {
    ampli.uhrViewed({ location, healthRecordPercentageCompleted })
}

function yourCareViewed() {
    ampli.yourCareViewed()
}

function carePlanViewed() {
    ampli.carePlanViewed()
}

function cancelOrChangeMembershipViewed(userId: number) {
    ampli.cancelOrChangeMembershipViewed({ userID: userId })
}

function cancelMembershipClicked(userId: number) {
    ampli.cancelMembershipClicked({ userID: userId?.toString() })
}

function cancelMembershipReasonsViewed(userId: number) {
    ampli.cancelMembershipReasonsViewed({ userID: userId.toString() })
}

function changeMembershipClicked(userId: number) {
    ampli.changeMembershipClicked({ userID: userId.toString() })
}

function cancelMembershipReasonsClicked(userId: number, reason: string) {
    ampli.cancelMembershipReasonsClicked({
        userID: userId.toString(),
        cancel_membership_reason: reason
    })
}

function appointmentFilteredByProvider(
    providersUuids: string[],
    appointmentProfile: AppointmentProfile
) {
    ampli.appointmentFilteredByProvider({
        providersUuids,
        appointmentType: appointmentProfile.appointmentTypeUuid,
        appointmentProfile: appointmentProfile.appointmentProfileUuid
    })
}

function checkInPreAppointmentInfoCompleted(appointment: Appointment) {
    const properties = getAciProperties(appointment)
    ampli.checkInPreAppointmentInfoCompleted(properties)
}

function checkInPart1Completed(appointment: Appointment) {
    const properties = getAciProperties(appointment)
    ampli.checkInPart1Completed(properties)
}

function checkInPart2Completed(appointment: Appointment) {
    const properties = getAciProperties(appointment)
    ampli.checkInPart2Completed(properties)
}

function onDemandEnterWaitingRoomCtaClicked() {
    ampli.onDemandEnterWaitingRoomCtaClicked()
}

function onDemandJoinZoomCtaClicked() {
    ampli.onDemandJoinZoomCtaClicked()
}

function onDemandConfirmLocationClicked(
    clinic: Clinic,
    userInHomeState: boolean
) {
    ampli.onDemandConfirmLocationClicked({
        homeClinic: clinic.name, //TODO: confirm okay to change the property here
        userInHomeState
    })
}

function bookingGoalSelectionViewed(appointmentProfile: AppointmentProfile) {
    ampli.bookingGoalSelectionViewed({
        appointmentProfile: appointmentProfile.appointmentProfileUuid
    })
}

function filterAppointmentsByServiceLine(serviceLines: string[]) {
    ampli.filterAppointmentsByServiceLine({ serviceLines })
}

function filterAppointmentsByLocation(locations: string[]) {
    ampli.filterAppointmentsByLocation({ locations })
}

function clickServiceLineModuleOnAppointmentsPage(serviceLine: string) {
    ampli.clickServiceLineModuleOnAppointmentsPage({ serviceLine })
}

function clickMhGroupsNotificationBanner() {
    ampli.clickMhGroupsNotificationBanner()
}

function closeMhGroupsNotificationBanner() {
    ampli.closeMhGroupsNotificationBanner()
}

function clickedAnnualExamInRecommendedActions() {
    ampli.clickedAnnualExamInRecommendedActions()
}

function clickedHealthConcernInRecommendedActions() {
    ampli.clickedHealthConcernInRecommendedActions()
}

function clickedMentalHealthSupportInRecommendedActions() {
    ampli.clickedMentalHealthSupportInRecommendedActions()
}

function clickedSomethingElseInRecommendedActions() {
    ampli.clickedSomethingElseInRecommendedActions()
}

function clickedRecommendedAppointmentsInRecommendedActions() {
    ampli.clickedRecommendedAppointmentsInRecommendedActions()
}

function clickedStartCareInRecommendedActions() {
    ampli.clickedStartCareInRecommendedActions()
}

function cashPayConfirmed(appointmentId: string) {
    ampli.cashPayConfirmed({ appointmentId })
}

function bookATwheCtaClickedFromAnnualExplanation() {
    ampli.bookATwheCtaClickedFromAnnualExplanation()
}
