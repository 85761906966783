import { useEffect, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import * as yup from 'yup'

/**
 * This hook allows us to validate the from and enable button onChange
 * But only show validation errors onBlur
 */
export function useEnableButtonOnChange(
    formMethods: UseFormMethods,
    schema: yup.AnySchema
) {
    const [isValid, setIsValid] = useState(false)
    const allFormFields = formMethods.watch()

    useEffect(() => {
        const effect = async () => {
            const isValid = await schema.isValid(formMethods.getValues())

            setIsValid(isValid)
        }
        effect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFormFields])

    return isValid
}
