import { Container, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { Navigate, Route, Routes, useParams } from 'react-router'
import { CloseButton, Header } from 'src/components/Blocks'
import { ErrorModal } from 'src/components/ErrorModal'
import { Show404 } from '../404'
import { CheckingHeader } from './CheckingHeader'
import { stepsSequence } from './stepsSequence'
import { useCheckinState } from './useCheckinState'
import { useCurrentRouteStep } from './useCurrentRouteStep'
import { useAmpli } from 'src/hooks'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Body } from 'src/components/Blocks/Body'
import { PageHeader } from 'src/components/PageHeader'

// helper component for parametrized redirects
const ACIRedirect = (props: { path: string }) => {
    const { apptId } = useParams<{ apptId: string }>()
    return <Navigate to={`/automated-checkin/${apptId}${props.path}`} />
}

const Step = () => {
    // we know from router structure that step is defined
    const step = useCurrentRouteStep() as string
    const { isLoading, appointment, saveStep, stepProps, isSaving } =
        useCheckinState()
    const { checkInNotStarted, checkInCompleted } = useAmpli()

    const { title, subtitle, noNavigation, body, fallback, hideMainHeader } =
        stepsSequence[step]

    // Use deep effect as we're comparing against appointment, an object
    useDeepCompareEffect(() => {
        if (step && appointment) {
            switch (step.toLowerCase()) {
                case 'not_started':
                    checkInNotStarted(appointment)
                    break
                case 'completed':
                    checkInCompleted(appointment)
                    break
                default:
                    break
            }
        }
    }, [step, appointment, checkInNotStarted, checkInCompleted])

    function renderBody() {
        if (isLoading) {
            return fallback()
        } else if (appointment && stepProps) {
            return body({ appointment, stepProps, saveStep, isSaving })
        } else {
            return <ErrorModal hideModal={() => window.location.reload()} />
        }
    }

    return (
        <>
            {!hideMainHeader && (
                <>
                    <PageHeader
                        desktopActions={<CloseButton color="white" />}
                        mobileActions={<CloseButton color="white" />}
                    />
                    <CheckingHeader
                        title={title}
                        subtitle={subtitle}
                        noNavigation={noNavigation}
                    />
                </>
            )}

            <Container>
                <LayoutGrid columns="one">{renderBody()}</LayoutGrid>
            </Container>
        </>
    )
}

// Helper component for redirecting to the latest step
const ContinueCheckinRedirect = () => {
    const { latestStepId, appointment, isLoading } = useCheckinState()

    if (isLoading) {
        return (
            <>
                <Header showClose />
                <Container>
                    <LayoutGrid columns="one">
                        <Spinner />
                    </LayoutGrid>
                </Container>
            </>
        )
    }

    if (latestStepId) {
        return (
            <Navigate
                to={`/automated-checkin/${appointment?.id}/${latestStepId}`}
            />
        )
    } else {
        return (
            <Navigate
                to={`/automated-checkin/${appointment?.id}/not_started`}
            />
        )
    }
}

export const AutomatedCheckin = () => {
    return (
        // rendering Body in the App leads to double flashing
        // because of nested <Routes> components
        // trying to use 1 <Routes> leads to infinite loops
        // result: Transitions between AB and ACI aren't animated
        <Body>
            <Routes>
                <Route
                    path={`continue/:apptId`}
                    element={<ContinueCheckinRedirect />}
                />

                <Route path={`:apptId/:step/*`} element={<Step />} />
                <Route path={`:apptId/:step`} element={<Step />} />

                {/* payment_method step is handled by insurance step */}
                <Route
                    path={`:apptId/payment_method`}
                    element={<ACIRedirect path="/insurance" />}
                />

                <Route
                    path={`:apptId/`}
                    element={<ACIRedirect path="/not_started" />}
                />

                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
