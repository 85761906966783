import { useEffect, useState } from 'react'
import { Button, Donut, Flex, ThemeStyleObject, Text } from '@asktia/tia-ui'
import { CheckmarkIcon } from 'src/components/Blocks/Icons'
import { StateValue } from 'xstate'
import { Footer } from 'src/components/Blocks/Footer'

/**
 * submit button and loading state
 */
export const InsuranceFooter = (props: {
    isFormValid: boolean
    isSubmitting: boolean
    // TODO: can we pull types from state machine definition?
    currentState: StateValue
}) => {
    switch (props.currentState) {
        case 'Want to add insurance?':
            return <Footer></Footer>
        case 'Checking Coverage':
            return (
                <Footer>
                    <CheckingCoverageSpinner />
                </Footer>
            )
        case 'Insurance verified':
        case 'Payment Method':
            return (
                <Footer>
                    <Button
                        type="submit"
                        disabled={!props.isFormValid || props.isSubmitting}
                        loading={props.isSubmitting}
                    >
                        My payment info is correct
                        <Text variant="buttonSubtext" as="div">
                            Complete My Info
                        </Text>
                    </Button>
                </Footer>
            )
        default:
            return (
                <Footer>
                    <Button
                        type="submit"
                        disabled={!props.isFormValid || props.isSubmitting}
                        loading={props.isSubmitting}
                    >
                        My insurance info is correct
                        <Text variant="buttonSubtext" as="div">
                            Check my coverage
                        </Text>
                    </Button>
                </Footer>
            )
    }
}

export const CheckingCoverageSpinner = () => {
    return (
        <Flex
            sx={{
                alignItems: 'center',
                flexDirection: 'column',
                flexWrap: 'wrap'
            }}
        >
            <Flex
                sx={{
                    position: 'relative',
                    width: 50,
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <CircleSpinner
                    duration={8000}
                    size={50}
                    sx={{ position: 'absolute', color: 'primaryButton' }}
                />
                <CheckmarkIcon color="primaryButton" />
            </Flex>
            <Text sx={{ mt: 3 }}>Checking Coverage</Text>
        </Flex>
    )
}

export type CircleSpinnerProps = {
    duration: number
    size: number
    sx?: ThemeStyleObject
}
export const CircleSpinner = (props: CircleSpinnerProps) => {
    const [value, setValue] = useState(0)

    const step = 1 / 200
    const animationInterval = props.duration / 200

    useEffect(() => {
        if (value < 1) {
            const timer = setTimeout(() => {
                setValue(value + step)
            }, animationInterval)

            return () => clearTimeout(timer)
        } else {
            setValue(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <Donut
            sx={{
                ...(props.sx || {}),
                '& > circle': { strokeLinecap: 'round' }
            }}
            value={value}
            size={props.size}
        />
    )
}
