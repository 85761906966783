import { Container, Flex, Heading, LayoutGrid, Paragraph } from '@asktia/tia-ui'
import { FC } from 'react'
import { useAmpli, useScrollToTopOnPageLoad, useUserInfo } from 'src/hooks'
import { motion } from 'framer-motion'
import { RecommendedActionsButton } from 'src/components/RecommendedActionsButton'

const NewPatientActions = () => {
    const {
        clickedStartCareInRecommendedActions,
        clickedHealthConcernInRecommendedActions,
        clickedMentalHealthSupportInRecommendedActions,
        clickedSomethingElseInRecommendedActions
    } = useAmpli()

    const TWHE_APPT_PROFILE_UUID_ON_PROD =
        '27a9fa49-507d-494d-8308-f41b4a1ad9e8' // We do need to hardcode this since it'll not appear on offerings query since it'll be not patient bookable

    return (
        <>
            <RecommendedActionsButton
                selected
                apptLink={`/book-appointment/profile/${TWHE_APPT_PROFILE_UUID_ON_PROD}/time-selection`}
                onClick={() => {
                    clickedStartCareInRecommendedActions()
                }}
            >
                I'd like to start care with Tia
                <Paragraph>
                    Our signature journey starts with a comprehensive health
                    review and may be followed by a preventative annual exam and
                    other visits based on your needs
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments"
                onClick={() => {
                    clickedHealthConcernInRecommendedActions()
                }}
            >
                I need help with a health concern
                <Paragraph>
                    Dedicated time to address a specific health concern
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments?serviceLines=mental_health"
                onClick={() => {
                    clickedMentalHealthSupportInRecommendedActions()
                }}
            >
                I'd like mental health support
                <Paragraph>
                    Seek support for your mental health and wellness
                </Paragraph>
            </RecommendedActionsButton>

            <RecommendedActionsButton
                apptLink="/appointments"
                onClick={() => {
                    clickedSomethingElseInRecommendedActions()
                }}
            >
                I'm looking for something else
                <Paragraph>
                    Explore all appointments and services at Tia.
                </Paragraph>
            </RecommendedActionsButton>
        </>
    )
}

export const OnboardingRecommendedActions: FC = () => {
    const { user } = useUserInfo()
    useScrollToTopOnPageLoad()

    return (
        <motion.div
            animate={{ y: 20 }}
            initial={{ y: '100vh' }}
            exit={{
                y: '-100vh',
                transition: {
                    delay: 0,
                    duration: 0.6
                }
            }}
            transition={{
                type: 'spring',
                stiffness: 16,
                damping: 6,
                delay: 0.1,
                duration: 0.3
            }}
        >
            <Container>
                <LayoutGrid columns="one" variant="condensed">
                    <Heading
                        h1
                        sx={{ mb: 4, textAlign: 'left', width: '100%', pt: 6 }}
                    >
                        Hi {user?.givenName}, what brings you in today?
                    </Heading>
                    <Flex
                        sx={{
                            mt: [4, 0],
                            flexDirection: 'column',
                            minWidth: [200, 500],
                            alignItems: 'left'
                        }}
                    >
                        <NewPatientActions />
                    </Flex>
                </LayoutGrid>
            </Container>
        </motion.div>
    )
}
