import { Blocks, Box, Flex, Spinner, Heading, Container } from '@asktia/tia-ui'
import { ReactChild } from 'react'
import { AppointmentCard } from 'src/components/AppointmentCard'
import { Carousel, CarouselContainer } from 'src/components/Carousel'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { Appointment } from 'src/types'

const compareAppointments = (a1: Appointment, a2: Appointment) => {
    if (a1.scheduledTime < a2.scheduledTime) {
        return -1
    } else if (a1.scheduledTime > a2.scheduledTime) {
        return 1
    } else {
        return 0
    }
}

const LoadingCard = () => (
    <Flex
        sx={{
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'center'
        }}
    >
        <Spinner color="text" />
    </Flex>
)

const Content = ({ cards }: { cards: ReactChild[] }) => {
    return (
        <>
            <Container sx={{ pt: 36 }}>
                <Heading h1>
                    You have {cards.length} upcoming{' '}
                    {cards.length > 1 ? 'appointments' : 'appointment'}
                </Heading>

                <CarouselContainer sx={{ pl: '0 !important' }}>
                    <Carousel paginationProps={{ variant: 'dark' }}>
                        {cards.map((carouselItem, index) => (
                            <Box
                                key={index}
                                sx={{
                                    height: '100%',
                                    outline: 'none'
                                }}
                            >
                                {carouselItem}
                            </Box>
                        ))}
                    </Carousel>
                </CarouselContainer>
            </Container>
        </>
    )
}

export const UpcomingAppointmentsUI = ({
    isLoading,
    carouselItems
}: {
    isLoading: boolean
    carouselItems: ReactChild[]
}) => {
    let cards = carouselItems

    if (isLoading) {
        cards = [<LoadingCard />, ...cards]
    }

    return <Content cards={cards}></Content>
}

export const UpcomingAppointments = () => {
    const { appointments, isLoading } = useUpcomingAppointments()

    const appointmentCards = appointments
        .sort(compareAppointments)
        .map(appointment => (
            <AppointmentCard
                appointment={appointment}
                variant="homepage"
                sx={{
                    height: '100%'
                }}
            />
        ))

    return appointmentCards.length || isLoading ? (
        <UpcomingAppointmentsUI
            carouselItems={appointmentCards}
            isLoading={isLoading}
        />
    ) : (
        <></>
    )
}
