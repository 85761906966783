import { Container } from '@asktia/tia-ui'
import { Box, Form, Heading, LayoutGrid, Radio, Text } from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { useForm, FieldValues } from 'react-hook-form'
import * as yup from 'yup'

import { View } from 'src/components/Blocks/View'
import { useSubmitFluAnswer } from 'src/flows/AutomatedCheckin/views/PreApptInfo/useSubmitFluAnswer'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers'
import { useFluShotStatus } from 'src/flows/AutomatedCheckin/views/PreApptInfo/useFluShotStatus'
import { useUserInfo } from 'src/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

type FormValues = { hasPatientHadFluShot: string } | FieldValues

/**
 * Displays flu question and navigates to parent page on submit
 */
const FluQuestion = () => {
    const { user } = useUserInfo()
    const { isLoading, fluShotResponse } = useFluShotStatus(user?.id)
    const queryClient = useQueryClient()
    const mutation = useSubmitFluAnswer()
    const navigate = useNavigate()

    const formMethods = useForm<FormValues>({
        resolver: yupResolver(
            yup.object({
                hasPatientHadFluShot: yup.string().oneOf(['yes', 'no'])
            })
        ),
        mode: 'all'
    })

    const { isDirty, isValid, isSubmitting } = formMethods.formState

    // we need this to set async defaults
    // because we're not on RHF7
    useEffect(() => {
        if (!isDirty && !isLoading) {
            formMethods.setValue(
                'hasPatientHadFluShot',
                fluShotResponse?.answer ? 'yes' : 'no'
            )
            formMethods.trigger()
        }
    }, [fluShotResponse])

    const onSubmit = async (formValues: FormValues) => {
        if (!user) {
            return
        }

        const answer = formValues.hasPatientHadFluShot === 'yes' ? true : false

        try {
            await mutation.submitFluAnswer({
                patientId: user.id,
                answer
            })

            queryClient.setQueryData(['flu-shot', user.id], {
                patientId: user.id,
                answer
            })

            navigate('../')
        } catch (err) {
            console.error('could not save flu vaccine response')
        }
    }

    return (
        <Container>
            <View withFooter>
                <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                    <LayoutGrid columns="one">
                        <Box>
                            <Heading h4>
                                Have you gotten your flu shot this flu season
                                (October 1, 2023 - March 31, 2024)?
                            </Heading>
                            <Text sx={{ color: 'putty' }}>
                                The flu shot protects you and other from getting
                                & spreading the flu. Your flu shot did not have
                                to be from Tia.
                            </Text>
                            <Box sx={{ my: 5 }}>
                                <Radio
                                    name="hasPatientHadFluShot"
                                    value="yes"
                                    pretty
                                    sx={{ mb: 2 }}
                                >
                                    Yes
                                </Radio>
                                <Radio
                                    name="hasPatientHadFluShot"
                                    value="no"
                                    pretty
                                >
                                    No
                                </Radio>
                            </Box>
                        </Box>
                        <FooterButton
                            type="submit"
                            disabled={!isValid}
                            loading={isSubmitting}
                        >
                            Complete
                        </FooterButton>
                    </LayoutGrid>
                </Form>
            </View>
        </Container>
    )
}

export default FluQuestion
