import { Button, Flex, Heading, Paragraph, Spinner } from '@asktia/tia-ui'
import { ConditionForm, LoadingConditionForm } from './ConditionForm'
import { useUserInfo } from 'src/hooks'
import { useConditionConfirmationMutation } from './useConditionConfirmationMutation'
import { useState } from 'react'
import { Divider } from 'src/components/Blocks'
import {
    useGetInactiveConditions,
    useGetActiveConditions
} from './useGetConditions'
import { GeneticMutationForm } from './GeneticMutationForm'
import { useGeneticMutation } from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/useGeneticMutation'
import { useSearchParams } from 'react-router-dom'
import { useNavigation } from 'src/hooks'

const MedicalConditions = () => {
    const [validForms, setValidForms] = useState({
        activeConditions: false,
        inactiveConditions: false,
        geneticMutations: false
    })

    const [unsavedWork, setUnsavedWork] = useState({
        activeConditions: { hasUnsavedContent: false, reveal: false },
        inactiveConditions: { hasUnsavedContent: false, reveal: false },
        geneticMutations: { hasUnsavedContent: false, reveal: false }
    })

    const { user } = useUserInfo()
    const { mutateAsync: conditionConfirmationMutate } =
        useConditionConfirmationMutation()

    const [searchParams] = useSearchParams()
    const { navigate } = useNavigation()

    const {
        submitConfirmation: geneticMutationConfirmationMutation,
        isQuerying: isGeneticMutationsQuerying
    } = useGeneticMutation()

    const revealUnsavedWorkErrors = () => {
        setUnsavedWork(oldState => ({
            ...oldState,
            activeConditions: {
                hasUnsavedContent: oldState.activeConditions.hasUnsavedContent,
                reveal: oldState.activeConditions.hasUnsavedContent
            },
            inactiveConditions: {
                hasUnsavedContent:
                    oldState.inactiveConditions.hasUnsavedContent,
                reveal: oldState.inactiveConditions.hasUnsavedContent
            },
            geneticMutations: {
                hasUnsavedContent: oldState.geneticMutations.hasUnsavedContent,
                reveal: oldState.geneticMutations.hasUnsavedContent
            }
        }))
    }

    const hasUnsavedWork =
        unsavedWork.activeConditions.hasUnsavedContent ||
        unsavedWork.inactiveConditions.hasUnsavedContent ||
        unsavedWork.geneticMutations.hasUnsavedContent

    const onComplete = async () => {
        revealUnsavedWorkErrors()
        if (hasUnsavedWork) {
            return
        }

        await conditionConfirmationMutate({
            patientId: user!.id
        })
        await geneticMutationConfirmationMutation({
            patientId: user!.id
        })

        navigate(searchParams.get('onCompleteRelativePath') as string)
    }
    const patientInactiveConditions = useGetInactiveConditions()
    const patientActiveConditions = useGetActiveConditions()

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                margin: '0 auto',
                pb: 8,
                maxWidth: 380
            }}
        >
            <Heading h3 sx={{ mb: 1 }}>
                List your current or chronic medical conditions, and when they
                started:
            </Heading>
            <Paragraph
                sx={{
                    variant: 'text.supportingInfo',
                    fontSize: 0,
                    lineHeight: 1.25,
                    mb: 4
                }}
            >
                Please add any and all conditions you're currently experiencing.
                Examples of medical conditions include diabetes, depression,
                PCOS, high blood pressure, irritable bowel syndrome, etc. Your
                provider needs an approximate year, not an exact date.
            </Paragraph>

            {!patientActiveConditions.isLoading ? (
                <ConditionForm
                    snomePlaceholder="Add medical condition"
                    yearPlaceholder="Year started"
                    clinicalStatus="active"
                    conditions={patientActiveConditions.data.conditions}
                    showUnsavedError={
                        unsavedWork.activeConditions.hasUnsavedContent &&
                        unsavedWork.activeConditions.reveal
                    }
                    isNone={
                        patientActiveConditions.data.emptyReason === 'nilknown'
                    }
                    onChange={isValid =>
                        setValidForms(oldState => ({
                            ...oldState,
                            activeConditions: isValid
                        }))
                    }
                    setHasUnsavedWork={(formHasUnsavedWork: boolean) => {
                        setUnsavedWork(oldState => ({
                            ...oldState,
                            activeConditions: {
                                hasUnsavedContent: formHasUnsavedWork,
                                reveal:
                                    oldState.activeConditions.reveal &&
                                    formHasUnsavedWork
                            }
                        }))
                    }}
                />
            ) : (
                <LoadingConditionForm />
            )}

            <Divider variant="blockSpacer" />

            <Heading h3 sx={{ mb: 1 }}>
                List any conditions that have been resolved from your past or
                childhood:
            </Heading>
            <Paragraph
                sx={{
                    variant: 'text.supportingInfo',
                    fontSize: 0,
                    lineHeight: 1.25,
                    mb: 4
                }}
            >
                Please add any and all medical conditions you have previously
                experienced.
            </Paragraph>

            {!patientInactiveConditions.isLoading ? (
                <ConditionForm
                    snomePlaceholder="Add medical condition"
                    yearPlaceholder="Year resolved"
                    clinicalStatus="inactive"
                    conditions={patientInactiveConditions.data.conditions}
                    showUnsavedError={
                        unsavedWork.inactiveConditions.hasUnsavedContent &&
                        unsavedWork.inactiveConditions.reveal
                    }
                    isNone={
                        patientInactiveConditions.data.emptyReason ===
                        'nilknown'
                    }
                    onChange={isValid =>
                        setValidForms(oldState => ({
                            ...oldState,
                            inactiveConditions: isValid
                        }))
                    }
                    setHasUnsavedWork={(formHasUnsavedWork: boolean) => {
                        setUnsavedWork(oldState => ({
                            ...oldState,
                            inactiveConditions: {
                                hasUnsavedContent: formHasUnsavedWork,
                                reveal:
                                    oldState.inactiveConditions.reveal &&
                                    formHasUnsavedWork
                            }
                        }))
                    }}
                />
            ) : (
                <LoadingConditionForm />
            )}

            <Divider variant="blockSpacer" />

            <Heading h3 sx={{ mb: 1 }}>
                List any genetic mutations for which you have tested positive:
            </Heading>
            <Paragraph
                sx={{
                    variant: 'text.supportingInfo',
                    fontSize: 0,
                    lineHeight: 1.25,
                    mb: 4
                }}
            >
                A genetic mutation is a change in the DNA sequence that is
                inherited from one or both of your birth parents. Genetic
                mutations are confirmed by laboratory testing and may or may not
                cause physical symptoms or illness.
            </Paragraph>

            {!isGeneticMutationsQuerying ? (
                <GeneticMutationForm
                    showUnsavedError={
                        unsavedWork.geneticMutations.hasUnsavedContent &&
                        unsavedWork.geneticMutations.reveal
                    }
                    onChange={isValid =>
                        setValidForms(oldState => ({
                            ...oldState,
                            geneticMutations: isValid
                        }))
                    }
                    setHasUnsavedWork={(formHasUnsavedWork: boolean) => {
                        setUnsavedWork(oldState => ({
                            ...oldState,
                            geneticMutations: {
                                hasUnsavedContent: formHasUnsavedWork,
                                reveal:
                                    oldState.geneticMutations.reveal &&
                                    formHasUnsavedWork
                            }
                        }))
                    }}
                />
            ) : (
                <LoadingConditionForm />
            )}

            <Button
                sx={{ mt: 5 }}
                onClick={onComplete}
                disabled={Object.values(validForms).some(v => !v)}
                py="20px"
            >
                Complete Medical History
            </Button>
        </Flex>
    )
}

export default MedicalConditions
