import { useCheckinState } from './useCheckinState'
import { Heading, Grid, Text, Box, useTiaUI, LayoutGrid } from '@asktia/tia-ui'
import { Link } from 'react-router-dom'
import { ProgressCrumbs } from 'src/components/ProgressCrumbs'
import { ChevronLeft, ChevronRight } from 'src/components/Blocks/Icons'
import { useCurrentRouteStep } from './useCurrentRouteStep'
import { stepsSequence } from './stepsSequence'

type CheckingHeaderProps = {
    title: string
    subtitle: string
    noNavigation?: boolean
}

const ChevronLink = (props: {
    enabled: boolean | undefined | null | number
    to: string | undefined
    render: (props: { color: string }) => JSX.Element
}) =>
    props.to && props.enabled ? (
        <Link to={props.to}>{props.render({ color: 'text' })}</Link>
    ) : (
        props.render({ color: 'inactiveText' })
    )

// assumption: stepsSequence never changes at runtime
const stepKeys = Object.keys(stepsSequence)

export const CheckingHeader = ({
    title,
    subtitle,
    noNavigation
}: CheckingHeaderProps) => {
    const { appointment, latestStepId } = useCheckinState()
    // we know from router structure that step is defined
    const currentStep = useCurrentRouteStep() as string
    const { theme } = useTiaUI()

    // don't show crumbs on first and last step
    const showNavigation =
        currentStep !== 'not_started' && currentStep !== 'completed'
    // 16px pad + 8px width for chevrons
    const chevronBoxWidth = (theme.sizes?.[4] as number) + 8

    const currentStepIndex = stepKeys.findIndex(key => key === currentStep)

    const canGoLeft = currentStep !== 'not_started'
    const canGoRight =
        latestStepId &&
        // latest step is after current step in sequence
        stepKeys.findIndex(key => key === latestStepId) > currentStepIndex

    const stepDots = stepKeys.filter(
        k => !['not_started', 'completed'].includes(k)
    )

    return showNavigation ? (
        <Box
            sx={{
                py: 5,
                mb: 6,
                bg: 'secondaryBackground',
                textAlign: 'center'
            }}
        >
            <LayoutGrid columns="one">
                <Heading h1 sx={{ mb: !noNavigation ? 2 : 0 }}>
                    {title}
                </Heading>

                {!noNavigation && (
                    <>
                        <Grid
                            columns={`${chevronBoxWidth}px 1fr ${chevronBoxWidth}px`}
                            gap={0}
                            className="head"
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <ChevronLink
                                    enabled={appointment && canGoLeft}
                                    to={
                                        appointment &&
                                        `/automated-checkin/${appointment.id}/${
                                            stepKeys[currentStepIndex - 1]
                                        }`
                                    }
                                    render={ChevronLeft}
                                />
                            </Box>
                            <Text sx={{ fontSize: 0 }}>{subtitle}</Text>
                            <Box sx={{ textAlign: 'center' }}>
                                <ChevronLink
                                    enabled={appointment && canGoRight}
                                    to={
                                        appointment &&
                                        `/automated-checkin/${appointment.id}/${
                                            stepKeys[currentStepIndex + 1]
                                        }`
                                    }
                                    render={ChevronRight}
                                />
                            </Box>
                        </Grid>
                        <ProgressCrumbs
                            current={currentStep}
                            steps={stepDots}
                        />
                    </>
                )}
            </LayoutGrid>
        </Box>
    ) : null
}
