import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react'
import { CenteredSpinner } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import { LayoutGrid, Container } from '@asktia/tia-ui'
import { useMessageThreads } from 'src/hooks/useMessageThreads'
import { NoMessagesInbox } from './NoMessagesInbox'
import { Inbox as InboxContainer } from './Inbox'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { useUserInfo } from 'src/hooks'
import { PreviewExplanation } from 'src/components/Preview/PreviewExplanation'
import { useModal } from 'react-modal-hook'

const InboxContext = React.createContext<
    [string[], Dispatch<SetStateAction<string[]>>] | undefined
>(undefined)

const InboxProvider = (props: React.PropsWithChildren<{}>) => {
    const state = useState<string[]>([])

    return (
        <InboxContext.Provider value={state}>
            {props.children}
        </InboxContext.Provider>
    )
}

export const useInbox = () => {
    const context = useContext(InboxContext)
    if (context === undefined) {
        throw new Error('useInbox must be within InboxProvider')
    }
    return context
}

export const Inbox = () => {
    const { user } = useUserInfo()

    const [showPreviewExplanationModal] = useModal(
        () => <PreviewExplanation showWhatsMissing={true} />,
        []
    )

    useEffect(() => {
        if (user?.membershipSignupStatus === 'preview') {
            showPreviewExplanationModal()
        }
    }, [user])

    const { isLoading, threads } = useMessageThreads()

    return (
        <Body>
            <PageHeader mobileBack={<BackButton href="/" variant="white" />} />
            <NavBarHeading titleAlign="left" href="/" showBackButton>
                Care Team Messages
            </NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    {isLoading && <CenteredSpinner />}
                    {!isLoading && threads.length === 0 && <NoMessagesInbox />}
                    {!isLoading && threads.length > 0 && (
                        <InboxProvider>
                            <InboxContainer />
                        </InboxProvider>
                    )}
                </LayoutGrid>
            </Container>
        </Body>
    )
}
