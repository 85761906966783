import { Checkbox, ErrorLabel, Flex, Label, Paragraph } from '@asktia/tia-ui'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { ThemeUIStyleObject } from '@theme-ui/css'
import { get as _get } from 'lodash'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'
import { BaseInput, IBaseInput } from './BaseInput'
import { getFieldName } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'
import { FollowUpQuestionsCard } from 'src/flows/questionnaires/components/FollowUpQuestionsCard'

export interface IMultiChoice
    extends Omit<IBaseInput, 'render' | 'readDefaultValue'> {
    fullWidth?: boolean
}

export const MultiChoice: FC<IMultiChoice> = ({
    questionnaireItem,
    answersMap,
    variant,
    index,
    sx,
    triggerSave
}) => {
    const {
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext()
    const { answerOption } = questionnaireItem
    const [noneSelected, setNoneSelected] = useState(false)
    const noneAboveCode = 'none-of-the-above'

    const name = getFieldName(questionnaireItem, index)

    function save() {
        const values: Array<string> = getValues(name)
        if (values.includes(noneAboveCode) && !noneSelected) {
            setValue(name, noneAboveCode)
            setNoneSelected(true)
        } else if (values.includes(noneAboveCode) && noneSelected) {
            setValue(
                name,
                values.filter(v => v !== noneAboveCode)
            )
            setNoneSelected(false)
        }
        triggerSave(name)
    }

    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            variant={variant}
            sx={sx}
            index={index}
            triggerSave={save}
            readDefaultValue={defaultValue =>
                defaultValue?.map(value => value.valueCoding?.code as string)
            }
            render={props => (
                <Flex sx={{ flexDirection: 'column' }}>
                    <Paragraph
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            mt: t => `-${t.sizes?.[3]}px`,
                            mb: 4
                        }}
                    >
                        Select all that apply:
                    </Paragraph>

                    <ErrorLabel show={_get(errors, name)}>
                        Please select a response
                    </ErrorLabel>

                    <Flex sx={{ flexWrap: 'wrap', gap: 4 }}>
                        {answerOption?.map(option => {
                            const code = option.valueCoding?.code as string

                            return (
                                <Label
                                    key={code}
                                    sx={{
                                        alignItems: 'center',
                                        width: '100%',
                                        fontSize: 3,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Checkbox
                                        pretty
                                        sx={{ minWidth: 'auto' }}
                                        name={props.name}
                                        value={code}
                                        // blur doesn't work well for checkboxes
                                        onClick={props.onBlur}
                                    />
                                    {option.valueCoding?.display}
                                </Label>
                            )
                        })}
                    </Flex>
                </Flex>
            )}
        />
    )
}

export const GluedMultiChoiceQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    questionnaireResponse,
    sx,
    variant,
    followupCardSx,
    triggerSave
}: QuestionFormGlue & {
    linkId: string
    variant?: 'info'
    sx?: ThemeUIStyleObject
    followupCardSx?: ThemeUIStyleObject
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    let isEnabled = true

    if (questionConfig && !!questionConfig.enableWhen) {
        isEnabled = questionnaireResponse
            ? isItemEnabled(questionConfig!, questionnaireResponse)
            : false
    }

    const shouldRender = questionConfig?.linkId && isEnabled

    if (!shouldRender) {
        return null
    }

    return (
        <FollowUpQuestionsCard hidden={variant !== 'info'} sx={followupCardSx}>
            <MultiChoice
                questionnaireItem={questionConfig}
                answersMap={answersMap}
                sx={sx}
                variant={variant}
                triggerSave={triggerSave}
            />
        </FollowUpQuestionsCard>
    )
}
