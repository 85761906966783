import { Flex, Input } from '@asktia/tia-ui'
import { props } from 'bluebird'
import { FC, ReactNode } from 'react'
import { SnomedTypeahead } from 'src/flows/questionnaires/Uhr/components/SnomedTypeahead'

export const SnomedYearField: FC<{
    hideYear?: boolean
    snomedPlaceholder: string
    yearPlaceholder: string
    disabled?: boolean
    noOptionsMessage?: (props: { inputValue: string }) => ReactNode
}> = ({
    hideYear,
    snomedPlaceholder,
    yearPlaceholder,
    disabled,
    noOptionsMessage
}) => {
    return (
        <Flex>
            <SnomedTypeahead
                name="snomed"
                semanticTag="disorder"
                sx={{
                    minWidth: hideYear ? '100%' : '65%',
                    mr: 2,
                    mb: 0,
                    div: { mb: 0 }
                }}
                placeholder={snomedPlaceholder}
                errorMessage=""
                noOptionsMessage={noOptionsMessage}
                disabled={disabled}
            />
            {!hideYear && (
                <Input
                    name="year"
                    type="number"
                    defaultValue={undefined}
                    placeholder={yearPlaceholder}
                    sx={{ mb: 0 }}
                    errorMessage=""
                    disabled={disabled}
                />
            )}
        </Flex>
    )
}
