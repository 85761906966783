import { differenceInHours, differenceInMinutes } from 'date-fns'
import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { AppointmentWithLocationDetails, AppointmentUIStatus } from 'src/types'
import { extendAppointment } from 'src/utils'

const MAX_LATE_MINUTES = 10,
    EARLY_START_MINUTES = 5,
    CHECKIN_OPEN_HOURS = 25

async function getAppointment(
    appointmentUuid: string
): Promise<AppointmentWithLocationDetails> {
    const json = await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/${appointmentUuid}?apiVersion=v2`
    )

    return extendAppointment(json)
}

export function useAppointment(
    appointmentUuid?: string,
    options?: Omit<
        UseQueryOptions<
            AppointmentWithLocationDetails,
            unknown,
            AppointmentWithLocationDetails,
            QueryKey
        >,
        'queryKey' | 'queryFn'
    >
) {
    const query = useQuery<AppointmentWithLocationDetails>(
        ['appointment', appointmentUuid],
        () => getAppointment(appointmentUuid!),
        {
            ...options,
            staleTime: 1000 * 60 * 5, // refetch rendered data after 5min
            enabled: Boolean(appointmentUuid)
        }
    )

    return {
        isLoading: query.isLoading,
        appointment: query.data,
        status: query.status,
        refetch: query.refetch
    }
}

// fetch appointments with a faster stale time
export function useAppointmentPolling(appointmentUuid: string) {
    // refetch every 10 seconds
    return useAppointment(appointmentUuid, { refetchInterval: 1000 * 10 })
}

function canCheckin(scheduledTime: Date) {
    return differenceInHours(scheduledTime, new Date()) < CHECKIN_OPEN_HOURS
}

function canStart(
    appointment: Pick<
        AppointmentWithLocationDetails,
        'scheduledTime' | 'careType'
    >
) {
    const diff = differenceInMinutes(appointment.scheduledTime, new Date())
    // 5min before to 10min after
    // negative numbers are how late you are
    return diff >= -MAX_LATE_MINUTES && diff <= EARLY_START_MINUTES
}

// is current time MAX_LATE_MINUTES+ after appointment start
function missed(
    appointment: Pick<
        AppointmentWithLocationDetails,
        'scheduledTime' | 'careType'
    >
) {
    const diff = differenceInMinutes(appointment.scheduledTime, new Date())
    // negative numbers are how late you are
    return diff < -MAX_LATE_MINUTES
}

export const getAppointmentStatus = (
    appointment: Pick<
        AppointmentWithLocationDetails,
        'checkInState' | 'scheduledTime' | 'uhr' | 'status' | 'careType'
    >
): AppointmentUIStatus => {
    if (appointment.status === 'No Show' || missed(appointment)) {
        return 'no_show'
    }

    if (appointment.status === 'Finding Provider') {
        return 'finding_provider'
    }

    if (appointment.checkInState === 'completed') {
        if (canStart(appointment)) {
            return 'appointment_ready'
        }

        return 'check_in_completed'
    }

    if (canCheckin(appointment.scheduledTime)) {
        return 'check_in_ready'
    }

    const uhrComplete = [
        ...appointment.uhr.records,
        ...appointment.uhr.goals
    ].every(record => record.status === 'done')

    if (uhrComplete) {
        return 'uhr_completed'
    }

    return 'check_in_not_ready'
}
