import {
    Box,
    Card,
    Flex,
    Heading,
    Link,
    Stepper,
    Text,
    Blocks,
    Button
} from '@asktia/tia-ui'
import { AppointmentCard } from 'src/components/AppointmentCard'
import { Link as RouterLink } from 'react-router-dom'
import { TitleHeader } from 'src/components/TitleHeader'
import { useAmpli, useLocalStorage, useNavigation } from 'src/hooks'
import { AppointmentWithLocationDetails, SaveStepFunction } from 'src/types'
import { differenceInHours } from 'date-fns'
import { BlueInterstitial } from 'src/flows/AutomatedCheckin/BlueInterstitial'
import intermissionIllustration from 'src/assets/aci-intermission-illustration.png'
import { useCallback, useEffect } from 'react'
import { useLatestInsurance } from 'src/hooks/useLatestInsurance'
import { View } from 'src/components/Blocks/View'
import Cookies from 'js-cookie'

function isCheckinEnabled(appointment: AppointmentWithLocationDetails) {
    const remainingHoursToAppointment = differenceInHours(
        appointment.scheduledTime,
        new Date()
    )

    return remainingHoursToAppointment <= 24
}

type IntermissionProps = {
    /**
     * appointment being checked into
     */
    appointment: AppointmentWithLocationDetails

    saveStep: SaveStepFunction
    isSaving: boolean
}

export const Intermission = (props: IntermissionProps) => {
    const { continueACI, isLoading, currentVariant } = useContinueACI(
        props.appointment,
        props.saveStep
    )

    switch (currentVariant) {
        case 'blueInterstitial':
            if (isCheckinEnabled(props.appointment)) {
                return (
                    <InterstitialSub24h
                        isLoading={props.isSaving || isLoading}
                        continueACI={continueACI}
                    />
                )
            } else {
                return (
                    <InterstitialAbove24h
                        isLoading={props.isSaving || isLoading}
                        continueACI={continueACI}
                    />
                )
            }
        case 'waitingRoom':
            return (
                <WaitingRoom
                    appointment={props.appointment}
                    isLoading={props.isSaving || isLoading}
                    continueACI={continueACI}
                />
            )
    }
}

type IntermissionVariant = 'blueInterstitial' | 'waitingRoom'

// Decides which intermission screen to show
function useCurrentVariant(appointment: AppointmentWithLocationDetails) {
    const [currentVariant, setCurrentVariant] =
        useLocalStorage<IntermissionVariant>(
            `tia:intermission-state:${appointment.id}`,
            'blueInterstitial'
        )

    const { checkInPart1Completed } = useAmpli()
    useEffect(() => {
        // blueInterstitial means this is the first time
        if (currentVariant === 'blueInterstitial') {
            checkInPart1Completed(appointment)
        }
    }, [currentVariant, checkInPart1Completed, appointment])

    return { currentVariant, setCurrentVariant }
}

// controls what happens when CTA is clicked
// - 24h+, waiting room
// - <24h and old coverage, go to insurance
// - <24h and fresh coverage, go to next step as backend says
// - not using insurance, don't check coverage
function useContinueACI(
    appointment: AppointmentWithLocationDetails,
    saveStep: SaveStepFunction
) {
    const { latestInsurance, isLoading } = useLatestInsurance()
    const { navigate } = useNavigation()
    const { currentVariant, setCurrentVariant } = useCurrentVariant(appointment)

    const needsNewEligibilityCheck =
        appointment.usingInsurance &&
        !latestInsurance?.eligibilityCheck?.isRecent

    const continueACI = useCallback(() => {
        if (isLoading) {
            // no-op while data is loading
        } else if (isCheckinEnabled(appointment)) {
            const gotErrorOnInsuranceStep = Cookies.get(
                'GOT_ERROR_ON_INSURANCE'
            )

            // We do use this cookie to avoid a infinity loop when candid is down
            // always moving the user to /insurance
            if (gotErrorOnInsuranceStep === 'true') {
                // advance to next ACI step
                saveStep()
                // hide blue screen if user navigates back
                setCurrentVariant('waitingRoom')
            }

            if (needsNewEligibilityCheck) {
                // we need to force an eligibility check
                navigate(`/automated-checkin/${appointment.id}/insurance`)
            } else {
                // advance to next ACI step
                saveStep()
                // hide blue screen if user navigates back
                setCurrentVariant('waitingRoom')
            }
        } else {
            // close blue interstitial and go to waiting room
            setCurrentVariant('waitingRoom')
        }
    }, [
        isLoading,
        needsNewEligibilityCheck,
        appointment,
        navigate,
        saveStep,
        setCurrentVariant
    ])

    return { continueACI, isLoading, currentVariant }
}

type IntermissionViewProps = {
    appointment: AppointmentWithLocationDetails
    continueACI: () => void
    isLoading: boolean
}

const InterstitialSub24h = (
    props: Omit<IntermissionViewProps, 'appointment'>
) => (
    <BlueInterstitial
        image={intermissionIllustration}
        title="Almost there..."
        btnText="Keep going"
        btnOnClick={() => props.continueACI()}
        info="Our providers use this info to care for the *whole* you. Just a few remaining questions and you’ll be all checked in and ready. Reminder, you can update Your Health Info at any time!"
        currentStep={1}
        loading={props.isLoading}
    />
)

const InterstitialAbove24h = (
    props: Omit<IntermissionViewProps, 'appointment'>
) => (
    <BlueInterstitial
        image={intermissionIllustration}
        title="Almost there..."
        btnText="See you soon"
        btnOnClick={() => props.continueACI()}
        info="We can’t *officially* check you in yet, so we’ll text you 24 hours before your appointment to finish. Don’t worry, all your info has been saved and you can always come back to update it!"
        currentStep={1}
        loading={props.isLoading}
    />
)

const WaitingRoom = (props: IntermissionViewProps) => {
    console.log('props', props)
    return (
        <View withFooter>
            <TitleHeader sx={{ my: 6 }} title="Your appointment" />
            <AppointmentCard
                appointment={props.appointment}
                sx={{
                    mb: 5,
                    border: '1px solid',
                    borderRadius: 2,
                    borderColor: 'oat'
                }}
                variant="ACI"
            />

            <Card variant="info" sx={{ p: 5, mt: 4 }}>
                <Stepper
                    currentStep={1}
                    steps={['Booked', 'Your Info', 'Check In']}
                    boxed
                    blurNextStepLine={isCheckinEnabled(props.appointment)}
                    sx={{ mb: 6 }}
                />

                {isCheckinEnabled(props.appointment) ? (
                    <>
                        <CheckinCopy />
                        <Flex sx={{ justifyContent: 'center', mt: 5 }}>
                            <Button
                                loading={props.isLoading}
                                onClick={() => props.continueACI()}
                            >
                                Complete Check In
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <WaitingCopy appointmentId={props.appointment.id} />
                )}
            </Card>
            <Box>
                <Blocks.CurlyDivider sx={{ my: 6 }} />
                <Text sx={{ color: 'supportText', fontSize: 2 }}>
                    If you have questions about your insurance benefits,{' '}
                    <Link
                        sx={{ color: 'supportText' }}
                        as={RouterLink}
                        // @ts-ignore: TS doesn't understand this is a RouterLink now
                        to="/chat/new"
                    >
                        contact your Care Coordinator
                    </Link>{' '}
                    before your appointment.
                </Text>
            </Box>
        </View>
    )
}

const WaitingCopy = ({ appointmentId }: { appointmentId: string }) => {
    return (
        <>
            <Heading h4>Next steps</Heading>
            <p>
                Hang tight! We'll text you 24 hours before your appointment to
                finish checking in.
            </p>
            <p>
                Need to make edits? You can{' '}
                <Link
                    href={`/r/automated-checkin/${appointmentId}/patient_info`}
                >
                    update Your Info
                </Link>{' '}
                at any time!
            </p>
        </>
    )
}

const CheckinCopy = () => {
    return (
        <>
            <Heading h4>Next steps</Heading>
            <p>
                It's time to check in! You'll need to finish checking in to
                start your appointment.
            </p>
        </>
    )
}

export const IntermissionSkeleton = () => {
    return (
        <View withFooter>
            <InterstitialAbove24h isLoading={true} continueACI={() => null} />
        </View>
    )
}
