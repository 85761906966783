import React, { FC } from 'react'
import { Flex, ThemeStyleObject, Heading } from '@asktia/tia-ui'
import { CloseButton } from 'src/components/Blocks'

type TitleHeaderProps = {
    sx?: ThemeStyleObject
    title?: string
}

export const TitleHeader: FC<React.PropsWithChildren<TitleHeaderProps>> = ({
    sx,
    title
}) => {
    return (
        <Flex
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                ...sx
            }}
        >
            <Heading h1 sx={{ mb: 0 }}>
                {title}
            </Heading>
            <CloseButton />
        </Flex>
    )
}
