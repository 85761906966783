import { FC } from 'react'
import {
    Container,
    Flex,
    Heading,
    LayoutGrid,
    Text,
    Link
} from '@asktia/tia-ui'
import { useParams } from 'react-router'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { StandardPageHeader } from 'src/components/PageHeader'
import { useAppointmentSuggestion } from 'src/flows/AppointmentBooking/useAppointmentSuggestion'
import { THREAD_LABELS } from 'src/globals'
import { DismissActionModal } from './components/DismissActionModal'
import { useModal } from 'react-modal-hook'
import { ProviderCard } from 'src/pages/AppointmentSuggestion/Detail/components/ProviderCard'
import { AppointmentCard } from './components/AppointmentCard'

export const AppointmentSuggestionDetail: FC = () => {
    const { appointmentSuggestionUuid } = useParams<{
        appointmentSuggestionUuid: string
    }>()
    const { suggestion } = useAppointmentSuggestion(
        appointmentSuggestionUuid,
        true
    )
    const [showDismissModal, hideDismissModal] = useModal(
        () => (
            <DismissActionModal
                hideModal={hideDismissModal}
                apptSuggestion={suggestion!}
            />
        ),
        [suggestion]
    )

    return (
        <Body>
            <StandardPageHeader />
            <NavBarHeading>Provider recommendation</NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    {suggestion?.interventionFollowup?.carePlanIntervention
                        ?.patientEducation && (
                        <Flex sx={{ flexDirection: 'column', pt: 6 }}>
                            <Heading h2 sx={{ mb: 2 }}>
                                Notes from your provider:
                            </Heading>

                            <ProviderCard
                                name={
                                    suggestion.interventionFollowup
                                        .carePlanIntervention.originActor.name
                                }
                                patientEducation={
                                    suggestion.interventionFollowup
                                        .carePlanIntervention.patientEducation
                                }
                                avatarUrl={
                                    suggestion.interventionFollowup
                                        .carePlanIntervention.originActor
                                        .avatarUrl
                                }
                            />
                        </Flex>
                    )}

                    <Flex sx={{ flexDirection: 'column', pt: 4 }}>
                        <Heading h2 sx={{ mb: 2 }}>
                            Recommended appointment
                        </Heading>
                        {suggestion && <AppointmentCard {...suggestion} />}
                        <Text
                            sx={{
                                mt: 4,
                                fontSize: 0,
                                color: 'supportText'
                            }}
                        >
                            Have questions about your care plan?{' '}
                            <Link
                                //@ts-ignore
                                href={`/chat/new?label=${THREAD_LABELS.followUp}&subject=Follow up care&message=I have a question about my Care Plan`}
                                sx={{
                                    fontSize: 0
                                }}
                            >
                                Message your Care Team
                            </Link>
                        </Text>

                        <Link
                            href="#"
                            onClick={showDismissModal}
                            sx={{
                                mt: 4,
                                mb: 8,
                                width: '100%',
                                textAlign: 'center',
                                fontSize: 0
                            }}
                        >
                            Dismiss this recommendation
                        </Link>
                    </Flex>
                </LayoutGrid>
            </Container>
        </Body>
    )
}
