import { Box, Flex, Heading, ThemeStyleObject, Text } from '@asktia/tia-ui'
import { format } from 'date-fns'
import { FC } from 'react'
import { AppointmentV1 } from 'src/types'

const cancelledStatuses = ['Cancelled', 'Cancelled Late']

const getLocationText = (appointment: AppointmentV1) => {
    if (cancelledStatuses.includes(appointment.status)) {
        return appointment.status
    }

    const providerName =
        appointment.providerGivenName && appointment.providerFamilyName
            ? `${appointment.providerGivenName} ${appointment.providerFamilyName}`
            : null

    if (!providerName) {
        return appointment.isVirtual ? 'Virtual' : 'In Clinic'
    }

    return appointment.isVirtual
        ? `Virtual with ${providerName}`
        : `In Clinic with ${providerName}`
}

export interface AppointmentHistoryRowProps {
    appointment: AppointmentV1
    sx?: ThemeStyleObject
}

export const AppointmentHistoryRow: FC<AppointmentHistoryRowProps> = ({
    appointment,
    sx
}) => {
    const cancelled = cancelledStatuses.includes(appointment.status)

    return (
        <Box
            sx={{
                'py': 4,
                'fontSize': 2,
                'alignItems': 'center',
                'borderBottom': t => `1px solid ${t.colors?.cardOutline}`,
                '&:first-of-type': {
                    pt: 0
                },
                '&:last-child': {
                    borderBottom: 0,
                    pb: 0
                }
            }}
        >
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Heading
                    variant="h4"
                    sx={{
                        color: cancelled ? 'inactiveText' : 'text'
                    }}
                >
                    {appointment.label}
                </Heading>
                <Text
                    sx={{
                        color: cancelled ? 'inactiveText' : 'text'
                    }}
                >
                    {format(appointment.scheduledTime, 'MMM dd, yyyy')}
                </Text>
            </Flex>
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Text
                    sx={{
                        color: cancelled ? 'inactiveText' : 'text'
                    }}
                >
                    {getLocationText(appointment)}
                </Text>
                <Text
                    sx={{
                        color: cancelled ? 'inactiveText' : 'text'
                    }}
                >
                    {format(appointment.scheduledTime, 'hh:mmaaa')}
                </Text>
            </Flex>
        </Box>
    )
}
