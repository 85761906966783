import { useUserMembership, useNavigation } from 'src/hooks'
import { titleize } from 'src/utils'
import { AccountInfoRow, AccountInfoRowLabel } from './AccountInfoRow'

export const Membership = () => {
    const { membership, isLoading } = useUserMembership()
    const { navigate } = useNavigation()

    const enableEditing = () => {
        navigate('/membership/change-cancel')
    }

    return (
        <AccountInfoRow
            title="Membership"
            isLoading={isLoading}
            onEdit={enableEditing}
        >
            <AccountInfoRowLabel onClick={enableEditing}>
                {membership && titleize(membership.pricingModel.chargeInterval)}
            </AccountInfoRowLabel>
        </AccountInfoRow>
    )
}
