import { Flex, Text } from '@asktia/tia-ui'
import { format } from 'date-fns'
import { FC } from 'react'
import { Avatar } from '../../../../../components/Avatar'
import { AppointmentSuggestion } from 'src/types'

export interface SuggestionTitleProps {
    suggestions: AppointmentSuggestion[]
}

export const SuggestionTitle: FC<SuggestionTitleProps> = ({ suggestions }) => {
    const appointment = suggestions.find(
        suggestion =>
            !!suggestion?.interventionFollowup?.carePlanIntervention
                ?.originAppointment
    )?.interventionFollowup?.carePlanIntervention?.originAppointment
    const actor = suggestions.find(
        suggestion =>
            !!suggestion?.interventionFollowup?.carePlanIntervention
                ?.originActor
    )?.interventionFollowup?.carePlanIntervention?.originActor
    const actorNameOnly = actor?.name?.split(',').shift()

    return (
        <Flex
            sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                minHeight: 36
            }}
        >
            <Avatar
                userName={actorNameOnly ?? 'Your care Team'}
                url={
                    actor
                        ? actor?.avatarUrl
                        : 'https://assets.asktia.com/images/pastel_rebrand/logo-white.svg'
                }
                sx={{ mr: 4 }}
                imageSx={{
                    background: actor ? undefined : 'primaryButton',
                    padding: actor ? undefined : 1
                }}
            />
            <Text sx={{ fontSize: 5 }}>
                <Text as="span" sx={{ fontWeight: 700 }}>
                    {`${suggestions.length} appointment${
                        suggestions.length > 1 ? 's' : ''
                    }`}
                </Text>{' '}
                recommended by{' '}
                <Text as="span" sx={{ fontWeight: 700 }}>
                    {actor ? actorNameOnly : 'Your Care Team'}
                </Text>{' '}
                {appointment?.ehrAppointmentProfile?.label && (
                    <>
                        from your{' '}
                        <Text
                            as="span"
                            sx={{ fontWeight: 700 }}
                        >{`${appointment?.ehrAppointmentProfile?.label}`}</Text>
                    </>
                )}
                {appointment?.scheduled_time && (
                    <>
                        {' '}
                        on{' '}
                        <Text as="span" sx={{ fontWeight: 700 }}>
                            {format(
                                new Date(appointment.scheduled_time),
                                'MMM dd, yyyy'
                            )}
                        </Text>
                    </>
                )}
            </Text>
        </Flex>
    )
}
