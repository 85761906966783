import {
    Box,
    Button,
    Card,
    Flex,
    Heading,
    Link,
    Paragraph,
    Text
} from '@asktia/tia-ui'
import { FC, useState } from 'react'
import { BOOKING_STEPS } from 'src/flows/AppointmentBooking/useBookingFlow'
import { useAmpli, useNavigation } from 'src/hooks'
import { AppointmentSuggestion } from 'src/types'
import { generatePath } from 'react-router'
import { format } from 'date-fns'

export const SuggestionItem: FC<AppointmentSuggestion> = props => {
    const { appointmentSuggestionBookCta } = useAmpli()
    const { navigate } = useNavigation()

    const { appointmentProfileImageUrl, appointmentProfileLabel } = props

    const getBookLink = (appointmentSuggestion: AppointmentSuggestion) => {
        const basePath = BOOKING_STEPS.timeSelection.path

        const path = generatePath(basePath, {
            appointmentProfileUuid: appointmentSuggestion.appointmentProfileUuid
        })

        return `${path}?context=${appointmentSuggestion.uuid}`
    }

    const originAppt =
        props.interventionFollowup?.carePlanIntervention?.originAppointment

    const originApptProfile = originAppt?.ehrAppointmentProfile

    const originActor =
        props.interventionFollowup?.carePlanIntervention?.originActor

    return (
        <Card>
            <Flex
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Flex sx={{ flexDirection: 'column', width: '100%' }}>
                    <Flex
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: '8px',
                            width: '100%'
                        }}
                    >
                        <Box sx={{ width: '70%' }}>
                            <Heading h4>{appointmentProfileLabel}</Heading>
                            <Text variant="supportingInfo">
                                {props.sourceType === 'care-plan' &&
                                    props?.params?.startDate && (
                                        <>
                                            Complete on or around{' '}
                                            <span style={{ fontWeight: 700 }}>
                                                {format(
                                                    new Date(
                                                        props.params.startDate
                                                    ),
                                                    'MMM dd, yyyy'
                                                )}
                                            </span>
                                        </>
                                    )}
                            </Text>
                        </Box>
                        <img
                            src={appointmentProfileImageUrl}
                            width="56px"
                            height="56px"
                        />
                    </Flex>
                    {props.interventionFollowup?.carePlanIntervention
                        ?.patientEducation && (
                        <Card
                            variant="info"
                            sx={{
                                mb: 2,
                                mt: 4,
                                padding: 2,
                                minWidth: '100%',
                                background: 'cream',
                                border: '1px solid',
                                borderColor: 'oat'
                            }}
                        >
                            <Text variant="supportingInfo">
                                Notes from your provider:
                            </Text>
                            <Text
                                variant="supportingInfo"
                                sx={{
                                    'display': '-webkit-box',
                                    'mt': 2,
                                    'overflow': 'hidden',
                                    '-webkit-line-clamp': '2',
                                    '-webkit-box-orient': 'vertical'
                                }}
                            >
                                {
                                    props.interventionFollowup
                                        .carePlanIntervention.patientEducation
                                }
                            </Text>
                        </Card>
                    )}
                </Flex>
            </Flex>
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Button
                    mt="4"
                    as={Link}
                    href={`/r/suggestions/${props.uuid}`}
                    sx={{
                        backgroundColor: 'transparent',
                        color: 'supportText',
                        border: '1px solid',
                        borderColor: 'supportText',
                        py: '12px',
                        mr: [4, undefined],
                        paddingLeft: [undefined, '24px !important'],
                        paddingRight: [undefined, '24px !important']
                    }}
                >
                    Read More
                </Button>
                <Button
                    mt="4"
                    sx={{
                        backgroundColor: 'tiaPink'
                    }}
                    onClick={() => {
                        appointmentSuggestionBookCta(props, location.pathname)
                        navigate(`${getBookLink(props)}&backTo=/`)
                    }}
                >
                    Book Now
                </Button>
            </Flex>
        </Card>
    )
}
