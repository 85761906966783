import { useContext, PropsWithChildren, createContext, useState } from 'react'
import { Button, Grid, Label, Text, Image, Box } from '@asktia/tia-ui'
import iconCardDefault from 'src/assets/icon-card-default.svg'
import { CardOnFile } from 'src/types'
import {
    PaymentForm,
    usePaymentForm
} from 'src/components/StripeWrapper/PaymentForm'
import { CreditCard } from 'src/components/StripeWrapper/CreditCard'
import { PaymentMethod } from '@stripe/stripe-js'

export const CreditCardContext = createContext<{
    showCardFields: boolean
    setShowCardFields: Function
}>({
    showCardFields: false,
    setShowCardFields: (value: boolean) => null
})

export const TiaCreditCardSubmitButton = ({
    loading = false,
    children
}: PropsWithChildren<{ loading?: boolean }>) => {
    const { onSubmitCard } = usePaymentForm()

    return (
        <Button
            loading={loading}
            disabled={loading}
            className="sq-creditcard"
            onClick={onSubmitCard}
        >
            {children}
        </Button>
    )
}

type CardInputProps = {
    cardOnFile?: CardOnFile
    onChange?: (event: any) => void
}

/**
 * helper component to show a prefilled card on file view that turns into stripe
 */
export const CardInput = ({ cardOnFile, onChange }: CardInputProps) => {
    const { showCardFields, setShowCardFields } = useContext(CreditCardContext)

    return (
        <Box
            onClick={() => !showCardFields && setShowCardFields(true)}
            sx={{ minHeight: 83 }}
        >
            <Label>{showCardFields ? 'Card Details' : 'Card on File'}</Label>

            {!showCardFields && cardOnFile ? (
                <Grid
                    sx={{
                        border: t => `1px solid ${t.colors?.inputOutline}`,
                        pr: 4,
                        pl: 4,
                        height: 7,
                        borderRadius: 2,
                        alignItems: 'center',
                        color: 'text',
                        overflow: 'hidden',
                        bg: 'white'
                    }}
                    columns="36px 2fr 65px 0.6fr"
                >
                    <Image
                        src={iconCardDefault}
                        sx={{ width: 36, height: 24 }}
                    />
                    <Text>{cardOnFile.last4}</Text>
                    <Text>
                        {cardOnFile.expMonth} /{' '}
                        {String(cardOnFile.expYear.toString()).slice(-2)}
                    </Text>
                    <Text>{cardOnFile.postalCode}</Text>
                </Grid>
            ) : null}

            <Box sx={{ display: showCardFields ? 'block' : 'none' }}>
                <CreditCard onChange={onChange} />
            </Box>
        </Box>
    )
}

type CreditCardFormProps = {
    onSuccess: (nonce: string, postalCode: string) => unknown
    onError?: (errors: string[]) => unknown
    showCardOnLoad: boolean
}

export const CreditCardForm = ({
    showCardOnLoad,
    onSuccess,
    onError,
    children
}: PropsWithChildren<CreditCardFormProps>) => {
    const [showCardFields, setShowCardFields] = useState(showCardOnLoad)

    const cardTokenizeResponseReceived = (token: any) => {
        const paymentMethod = token as PaymentMethod
        const nonce = paymentMethod.id
        const postalCode = paymentMethod?.billing_details?.address?.postal_code

        onSuccess(nonce, postalCode as string)
    }

    const onCardError = (errors: string[]) => {
        onError?.(errors)
    }

    return (
        <PaymentForm
            cardTokenizeResponseReceived={cardTokenizeResponseReceived}
            onError={onCardError}
        >
            <CreditCardContext.Provider
                value={{ showCardFields, setShowCardFields }}
            >
                {children}
            </CreditCardContext.Provider>
        </PaymentForm>
    )
}
