import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { getExtensionName } from 'src/flows/questionnaires/components/Input/BaseInputExtensions'
import { getFieldName } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'
import { BaseInput, IBaseInput } from './BaseInput'

export const HiddenQuestion: FC<
    Omit<
        IBaseInput,
        'render' | 'readDefaultValue' | 'triggerSave' | 'sx' | 'variant'
    > & { type?: 'date' }
> = ({ questionnaireItem, answersMap, type, index }) => {
    const { register, setValue } = useFormContext()
    const defaultValue = answersMap.get(questionnaireItem.linkId)
    const name = getFieldName(questionnaireItem, index)
    const extensionsName = getExtensionName(questionnaireItem?.linkId)

    useEffect(() => {
        register(name)
        if (type === 'date') {
            register(extensionsName)
        }
    }, [name, extensionsName])

    useEffect(() => {
        //Checks for extensions only for date inputs
        if (!questionnaireItem || !defaultValue || type !== 'date') {
            return
        }

        if (Array.isArray(defaultValue) && defaultValue.length === 0) {
            setValue(questionnaireItem?.linkId, '')
            setValue(extensionsName, true)
        }
    }, [defaultValue, extensionsName])

    return (
        <BaseInput
            questionnaireItem={questionnaireItem}
            answersMap={answersMap}
            index={index}
            noHeaders
            triggerSave={() => {}}
            readDefaultValue={defaultValue => {
                switch (type) {
                    case 'date':
                        return defaultValue && defaultValue.length > 0
                            ? (defaultValue[0].valueDate as string)
                            : undefined
                    default:
                        return defaultValue && defaultValue.length > 0
                            ? (defaultValue[0].valueCoding?.code as string)
                            : undefined
                }
            }}
            render={() => null}
        />
    )
}

export const GluedHiddenQuestion = ({
    linkId,
    questionnaire,
    answersMap,
    index,
    type
}: QuestionFormGlue & {
    linkId: string
    type?: 'date'
}) => {
    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === linkId
    )

    if (!questionConfig) {
        return null
    }

    return (
        <HiddenQuestion
            questionnaireItem={questionConfig}
            answersMap={answersMap}
            index={index}
            type={type}
        />
    )
}
