import { useQuery, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'src/globals'
import { extendAppointment } from 'src/utils'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { AppointmentWithLocationDetails } from 'src/types'
import { fetcher } from 'src/fetcher'

async function getAppointments(patientId: number) {
    const json = await fetcher(
        // TODO: update url
        `${BASE_API_URL}/tmd-api/patients/${patientId}/upcoming-appointments?apiVersion=v2`
    )
    return json.map(extendAppointment) as AppointmentWithLocationDetails[]
}

export function useUpcomingAppointments() {
    const { user, isLoading } = useUserInfo()
    const queryClient = useQueryClient()

    const query = useQuery(
        ['upcoming-appointments', user?.id],
        () => getAppointments(user!.id),
        {
            staleTime: 1000 * 60 * 5, // refetch rendered data after 5min
            enabled: !!user
        }
    )

    if (query.data) {
        for (const appointment of query.data) {
            queryClient.setQueryData(
                ['appointment', appointment.id],
                appointment
            )
        }
    }

    return {
        isLoading: isLoading || query.isLoading,
        appointments: query.data || []
    }
}
