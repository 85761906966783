import { useQuery } from 'react-query'
import { useUserInfo } from 'src/hooks'
import { LocationModality } from 'src/types'

export type OfferingGoal = {
    id: number
    description: string
}

export type OfferingTreatment = {
    id: number
    name: string
}

export type OfferingClinicalStaff = {
    clinicUuid: string
    staffUuid: string
    name: string
    userId: number
    givenName: string
    familyName: string
}

export type OfferingDetails = {
    appointmentProfileUuid: string

    /**
     * @deprecated TM-4669 - "care_type_id" is deprecated since marketing_service_line relationship was added
     */
    careTypeId: number

    name: string
    label: string
    description: string
    imageUrl: string
    allowProviderGenderSelection: boolean
    locations: LocationModality[]
    treatments: OfferingTreatment[]
    goals: OfferingGoal[]
    clinicStaff: OfferingClinicalStaff[]
    quickReplyAdminCopy: string
}

export async function getOfferingDetails(
    userId: string,
    appointmentProfileUuid: string
): Promise<OfferingDetails> {
    const res = await fetch(
        `/tmd-api/appointments/patient-offerings/${appointmentProfileUuid}?apiVersion=v2`,
        {
            method: 'GET',
            headers: {
                'x-user': userId,
                'Content-Type': 'application/json'
            }
        }
    )

    if (!res.ok) {
        throw new Error('Error fetching offerings')
    }

    const resp = await res.json()
    const uniqueProviders: OfferingClinicalStaff[] = Object.values(
        resp.clinicStaff.reduce(
            (
                a: Record<string, OfferingClinicalStaff>,
                cs: OfferingClinicalStaff
            ) => {
                if (!cs || Object.keys(cs).length === 0 || a[cs.staffUuid]) {
                    return a
                }
                return {
                    ...a,
                    [cs.staffUuid]: cs
                }
            },
            {}
        )
    )

    return {
        ...resp,
        clinicStaff: uniqueProviders.sort(
            (a: OfferingClinicalStaff, b: OfferingClinicalStaff) =>
                a.name < b.name ? -1 : 1
        )
    }
}

export function useOfferingDetails(appointmentProfileUuid: string) {
    const { user, isLoading: userLoading } = useUserInfo()
    const query = useQuery<OfferingDetails>(
        ['offering-details', appointmentProfileUuid],
        () => getOfferingDetails(String(user?.id), appointmentProfileUuid),
        {
            enabled: !!user,
            staleTime: Infinity
        }
    )

    return {
        isLoading: query.isLoading,
        offeringDetails: query.data
    }
}
