import { useState, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Form, Radio, Card, Heading, Paragraph } from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { useAmpli } from 'src/hooks/useAmpli'
import { useNavigation } from 'src/hooks/useNavigation'
import { useAppointmentChangeFlow } from './useAppointmentChangeFlow'

type ChangeAppointmentOperationForm =
    | {
          operation: 'cancel' | 'reschedule'
      }
    | FieldValues

export const AppointmentChangeQuestion = () => {
    const { navigate } = useNavigation()
    const { updateAppointmentViewed } = useAmpli()
    const formMethods = useForm<ChangeAppointmentOperationForm>()
    const {
        appointmentId,
        navigateToNextStep,
        appointment,
        isAppointmentLoading
    } = useAppointmentChangeFlow()
    const [lastViewedAppointmentId, setLastViewedAppointmentId] =
        useState<string>()

    const operation = formMethods.watch('operation')

    // Track page views by appointment id
    useEffect(() => {
        if (appointment && lastViewedAppointmentId !== appointment.id) {
            updateAppointmentViewed(appointment)
            setLastViewedAppointmentId(appointment.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment])

    const onSubmit = (values: ChangeAppointmentOperationForm) => {
        const appointmentProfileUuid = appointment?.appointmentProfileUuid
        const { operation } = values
        if (operation === 'reschedule' && appointmentProfileUuid) {
            navigate(
                `/book-appointment/profile/${appointmentProfileUuid}/time-selection?reschedule=${appointmentId}&backTo=/`,
                0,
                false
            )
        } else {
            navigateToNextStep()
        }
    }

    return (
        <Form useFormMethods={formMethods} onSubmit={onSubmit}>
            <Heading h3>
                Do you want to cancel or change your appointment?
            </Heading>
            <Radio name="operation" value="cancel" sx={{ mt: 4, mb: 2 }} pretty>
                Cancel my appointment
            </Radio>
            <Radio name="operation" value="reschedule" sx={{ mb: 6 }} pretty>
                Change my appointment time / date
            </Radio>
            {operation === 'reschedule' && (
                <Card variant="info" sx={{ mb: 6 }}>
                    <Paragraph sx={{ fontSize: 2 }}>
                        Your current appointment will not be canceled until you
                        confirm a new appointment.
                    </Paragraph>
                    <Paragraph sx={{ fontSize: 2 }}>
                        If you do not find a new time or date that works for
                        your appointment, you may keep your current appointment
                        or choose to cancel.
                    </Paragraph>
                </Card>
            )}
            <FooterButton
                type="submit"
                disabled={!operation || isAppointmentLoading}
            >
                {operation === 'reschedule'
                    ? 'Select new appointment time'
                    : 'Next'}
            </FooterButton>
        </Form>
    )
}
