import React, { FC } from 'react'
import { Flex, ThemeStyleObject } from '@asktia/tia-ui'

type FooterProps = {
    sx?: ThemeStyleObject
}

export const Footer: FC<React.PropsWithChildren<FooterProps>> = ({
    sx,
    children
}) => {
    return (
        <Flex
            sx={{
                position: 'relative',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bg: 'background',
                zIndex: 1000,
                left: 0,
                py: [5, 0],
                px: 5,
                ...sx
            }}
        >
            {children}
        </Flex>
    )
}
