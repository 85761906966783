import { Button, Form, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { FC, useLayoutEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useQuestionnaire } from '../../../hooks/useQuestionnaire'
import { useQuestionnaireResponse } from '../../..//hooks/useQuestionnaireResponse'
import { ErrorSavingBanner } from '../../../components/ErrorSavingBanner'
import { getFormDone } from '../../../getFormDone'
import { get as _get, omit as _omit } from 'lodash'
import { QuestionnaireDivider } from '../../../components/QuestionnaireDivider'
import { GluedSingleChoiceQuestion } from '../../../components/Input/SingleChoice'
import { GluedMultiChoiceQuestion } from '../../../components/Input/MultiChoice'
import { GluedHiddenQuestion } from '../../../components/Input/HiddenInput'
import { QuestionFormGlue } from '../../../types'
import { useNavigation, useScrollToTopOnPageLoad } from '../../../../../hooks'
import { useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'

// Those hidden inputs are added on the page bc we did split the ROS page in 2 and
// to keep the same machinery that we already had, we just add those hidden inputs that will persist data between
// the pages.
//
const QuestionsFromROS2Page: FC<{ questionFormGlue: QuestionFormGlue }> = ({
    questionFormGlue
}) => (
    <>
        <GluedHiddenQuestion {...questionFormGlue} linkId="gastrointestinal" />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="gastrointestinal-choice"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="gastrointestinal-kidney"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="gastrointestinal-kidney-choice"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="endocrine-metabolic"
        />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="endocrine-metabolic-choice"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="skin" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="skin-choice" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="eye" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="eye-choice" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="muscular" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="muscular-choice" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="neurological" />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="neurological-choice"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="breast-chest" />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="breast-chest-choice"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="genital" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="genital-choice" />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="menstrual"
            type="date"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="nose" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="mouth-throat" />
        <GluedHiddenQuestion
            {...questionFormGlue}
            linkId="mouth-throat-choice"
        />
        <GluedHiddenQuestion {...questionFormGlue} linkId="breathing" />
        <GluedHiddenQuestion {...questionFormGlue} linkId="breathing-choice" />
    </>
)

export const ROS1Page: FC = () => {
    useScrollToTopOnPageLoad()
    const { navigateOutsideRouter } = useNavigation()
    const queryClient = useQueryClient()
    const formMethods = useForm<FieldValues>({
        defaultValues: {},
        mode: 'all'
    })

    const [searchParams] = useSearchParams()
    const [showSavingError, setShowSavingError] = useState(false)
    const { questionnaire } = useQuestionnaire('ros')
    const { answersMap, questionnaireResponse, saveQuestionnaireResponse } =
        useQuestionnaireResponse(
            'ros',
            searchParams.get('appointmentProfileUuid') as string
        )
    const { formDone } = getFormDone(formMethods.getValues())

    const getValidFormValues = async (triggeredByFieldName?: string) => {
        // validate form against latest form values
        await formMethods.trigger(triggeredByFieldName)

        const formValues = formMethods.getValues()
        const formKeys = Object.keys(formValues)

        // TODO: not sure best way to handle this any
        // at the moment the formValues also have an any type
        const validFormValues: any = {}
        const fieldErrors = formMethods.formState.errors

        // Note - this only works for 1 nested level
        // i.e.
        // formValues = {nicotine-use: {polar: 'yes', quantity: 'asdfasdf'}}
        // errors = {nicotine-use: {quantity: 'must be a number'}}
        // validFormValues = {nicotine-use: {polar: 'yes'}
        formKeys.forEach((key: string) => {
            const fieldValue = _get(formValues, key)
            const nestedFieldErrors = _get(fieldErrors, key)
            validFormValues[key as string] =
                typeof fieldValue === 'object' && !Array.isArray(fieldValue)
                    ? _omit(fieldValue, Object.keys(nestedFieldErrors || {}))
                    : fieldValue
        })

        return validFormValues
    }

    // Save questionnaire and handle errors:
    // - clear triggering field, so user knows what's wrong
    // - show error toast
    async function triggerSave(triggeredByFieldName?: string) {
        setShowSavingError(false)
        try {
            await saveQuestionnaireResponse(
                await getValidFormValues(triggeredByFieldName)
            )
        } catch (e) {
            console.error(e)
            if (triggeredByFieldName) {
                formMethods.setValue(triggeredByFieldName, undefined)
            }
            setShowSavingError(true)
        }
    }

    const onComplete = async () => {
        triggerSave()
        navigateOutsideRouter(
            `/r/physical-health/ros-2/?appointmentProfileUuid=${searchParams.get(
                'appointmentProfileUuid'
            )}&onCompleteRelativePath=${searchParams.get(
                'onCompleteRelativePath'
            )}`
        )
    }

    // This is needed to ensure that we are on latest state, while navigating to
    // pages ros-1 and ros-2
    useLayoutEffect(() => {
        queryClient.refetchQueries(['uhr', 'questionnaire'])
    }, [])

    if (!questionnaire.item) {
        return <Spinner />
    }

    const questionFormGlue = {
        questionnaire,
        questionnaireResponse,
        answersMap,
        triggerSave
    }

    const canProceed = formDone && Object.keys(formMethods.errors).length === 0

    return (
        <>
            {showSavingError && <ErrorSavingBanner />}
            <LayoutGrid columns="one" variant="condensed">
                <FormProvider {...formMethods}>
                    <Form
                        useFormMethods={formMethods}
                        onSubmit={() => console.log('on submit')}
                    >
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="cardiovascular-neck"
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="cardiovascular-circulation"
                        />
                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="cardiovascular-circulation-choice"
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="cardiovascular-blood"
                        />
                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="cardiovascular-blood-choice"
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="cardiovascular-lymph"
                        />
                        <GluedMultiChoiceQuestion
                            {...questionFormGlue}
                            variant="info"
                            linkId="cardiovascular-lymph-choice"
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="covid-19"
                        />
                        <QuestionnaireDivider />
                        <GluedSingleChoiceQuestion
                            {...questionFormGlue}
                            linkId="covid-19-contact"
                        />

                        <QuestionsFromROS2Page
                            questionFormGlue={questionFormGlue}
                        />

                        <Button
                            sx={{ mt: 6, mb: 6, py: '20px' }}
                            onClick={onComplete}
                            disabled={!canProceed}
                            fullWidth
                        >
                            Next
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </>
    )
}
