import { getShortTimezoneAbbr } from 'src/utils'
import { useHomeMarket } from '.'

export const useTzAbbrFromHomeMarket = () => {
    const { homeMarket } = useHomeMarket()
    if (!homeMarket?.timezone) {
        return ''
    }

    const now = new Date()
    return getShortTimezoneAbbr(now, homeMarket.timezone)
}
