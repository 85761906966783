import { FC } from 'react'
import { Card, Flex, Text } from '@asktia/tia-ui'
import { Avatar } from 'src/components/Avatar'

export interface ProviderCardProps {
    name: string
    patientEducation: string
    bio?: string
    avatarUrl?: string
}

export const ProviderCard: FC<ProviderCardProps> = ({
    name,
    patientEducation,
    avatarUrl
}) => {
    const nameWithoutTitle = name.split(',').shift() as string

    return (
        <Card>
            <Flex
                sx={{
                    alignItems: 'center',
                    color: 'supportText',
                    fontSize: 0,
                    mb: 2
                }}
            >
                <Avatar userName={nameWithoutTitle} url={avatarUrl} />
                <Text sx={{ ml: 2 }}>{name}</Text>
            </Flex>
            <Text>{patientEducation}</Text>
        </Card>
    )
}
