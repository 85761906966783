import { QuestionnaireItem } from '@asktia/tia-fhir-models'
import { Checkbox, Label } from '@asktia/tia-ui'
import { FC } from 'react'

export interface IBaseInputExtensions {
    questionnaireItem: QuestionnaireItem
    customNilnknownText?: string
    onExtensionChange: (checked: boolean, code?: string) => void
}

export const getExtensionName = (linkId: string) => `extensions.${linkId}`

export const BaseInputExtensions: FC<IBaseInputExtensions> = ({
    customNilnknownText,
    questionnaireItem,
    onExtensionChange
}) => {
    return (
        <>
            {questionnaireItem?.extension?.map(extension => {
                const name = getExtensionName(questionnaireItem?.linkId)
                const concept = extension?.valueCodeableConcept
                const code =
                    concept &&
                    concept.coding &&
                    (concept.coding[0].code as string)

                // Right now we only support "nilknown" extension
                if (code !== 'nilknown') {
                    return null
                }

                return (
                    <Label
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            cursor: 'pointer'
                        }}
                    >
                        <Checkbox
                            name={name}
                            onClick={event =>
                                onExtensionChange(
                                    (event.target as any).checked,
                                    code
                                )
                            }
                        />

                        {customNilnknownText || concept?.text}
                    </Label>
                )
            })}
        </>
    )
}
