import { Heading, Label, Input, Button, Form, Text } from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

import { Divider } from 'src/components/Blocks'
import {
    phoneValidation,
    formatPhoneNumber,
    yupBirthdateSchema
} from 'src/utils'
import { useEnableButtonOnChange, useAmpli, useUserInfo } from 'src/hooks'
import { Person, EmergencyContact, SaveStepFunction, Address } from 'src/types'
import { format } from 'date-fns'
import pick from 'lodash.pick'
import { AddressForm, yupAddressSchema } from 'src/components/AddressForm'
import { ReferralAddressForm } from 'src/components/ReferralAddressForm'
import { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'
import { NameForm } from 'src/components/Blocks/NameForm'

const schema = yup.object().shape({
    givenName: yup.string().required(),
    familyName: yup.string().required(),
    birthdate: yupBirthdateSchema.required('Date of Birth is required'),
    ...yupAddressSchema(),
    ...yupAddressSchema('referral_'),
    emergency_givenName: yup.string().required(),
    emergency_familyName: yup.string().required(),
    emergency_phoneNumber: yup
        .string()
        .required()
        .test('phone-number', 'Enter a valid phone number', phoneValidation),
    emergency_relationshipToPatient: yup.string().required()
})

// in theory yup can infer this from the schema
// but the type didn't fit react-hook-form requirements
// https://github.com/jquense/yup#typescript-support
type PatientInfoFields =
    | {
          givenName: string
          familyName: string
          birthdate: Date | string
          address1: string
          address2: string
          city: string
          state: string
          postalCode: string

          referral_address1: string
          referral_address2: string
          referral_city: string
          referral_state: string
          referral_postalCode: string

          emergency_givenName: string
          emergency_familyName: string
          emergency_phoneNumber: string
          emergency_relationshipToPatient: string

          sameAsMailing: boolean
      }
    | FieldValues

export const PatientInfo = (props: {
    patient?: Person
    emergencyContact?: EmergencyContact
    saveStep: SaveStepFunction
    isSaving: boolean
}) => {
    const { user } = useUserInfo()
    const { ampli } = useAmpli()
    const emergencyContactValues = props.emergencyContact
        ? Object.fromEntries(
              Object.keys(props.emergencyContact).map(key => [
                  `emergency_${key}`,
                  // @ts-ignore: TS doesn't understand strings are valid keys
                  props.emergencyContact[key]
              ])
          )
        : {}

    const defaultSameAsMailing = useMemo<boolean>(
        () =>
            !!props.patient &&
            !!props.patient.mailingAddress &&
            isEqual(
                props.patient.mailingAddress,
                props.patient.referralAddress
            ),
        [props.patient]
    )
    const formMethods = useForm<PatientInfoFields>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            ...props.patient,
            ...emergencyContactValues,
            sameAsMailing: defaultSameAsMailing
        }
    })
    const enableButton = useEnableButtonOnChange(formMethods, schema)

    const onSubmit = async (data: any) => {
        const [success] = await props.saveStep({
            patient: {
                ...pick(data, ['givenName', 'familyName']),
                birthdate: format(data.birthdate, 'yyyy-MM-dd'),
                mailingAddress: {
                    ...pick(data, [
                        'address1',
                        'address2',
                        'city',
                        'state',
                        'postalCode'
                    ])
                },
                referralAddress: {
                    address1: data.referral_address1,
                    address2: data.referral_address2,
                    city: data.referral_city,
                    state: data.referral_state,
                    postalCode: data.referral_postalCode
                }
            },
            // code seems repetitive, but the clever version looked far worse
            emergencyContact: {
                givenName: data.emergency_givenName,
                familyName: data.emergency_familyName,
                phoneNumber: formatPhoneNumber(data.emergency_phoneNumber),
                relationshipToPatient: data.emergency_relationshipToPatient
            }
        })

        if (success && user) {
            ampli.identify(user.analyticsUuid, {
                mailingAddressLine1: data.address1,
                mailingAddressLine2: data.address2,
                mailingAddressCity: data.city,
                mailingAddressPostalCode: data.postalCode,
                mailingAddressState: data.state
            })
        }
    }

    const mailingAddress: Address = formMethods.watch([
        'address1',
        'address2',
        'city',
        'state',
        'postalCode'
    ])

    return (
        <View withFooter>
            <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                <Heading h2>Personal Info</Heading>

                <NameForm
                    label="Legal Name"
                    fieldNames={['givenName', 'familyName']}
                    defaultValues={['', '']}
                />

                <Label>Date of Birth</Label>
                <Input name="birthdate" type="date" required />

                <Label>Mailing Address</Label>
                <AddressForm
                    defaultValue={props.patient && props.patient.mailingAddress}
                />

                <Divider variant="blockSpacer" />

                <Heading h2>Emergency Contact</Heading>

                <NameForm
                    label="Legal Name"
                    fieldNames={['emergency_givenName', 'emergency_familyName']}
                    defaultValues={['', '']}
                />

                <Label>Phone Number</Label>
                <Input
                    name="emergency_phoneNumber"
                    inputMode="tel"
                    placeholder="(XXX) XXX - XXXX"
                    errorMessage="Please enter a valid phone number"
                    required
                />

                <Label>Relationship to You</Label>
                <Input
                    name="emergency_relationshipToPatient"
                    placeholder="e.g. mother"
                    errorMessage="Relationship is required"
                    required
                />

                <Divider variant="blockSpacer" />

                <ReferralAddressForm
                    mailingAddress={mailingAddress}
                    referralAddress={props.patient?.referralAddress}
                    formMethods={formMethods}
                />

                <Footer>
                    <Button
                        type="submit"
                        disabled={!enableButton || props.isSaving}
                        loading={props.isSaving}
                    >
                        My essential info is correct
                        <Text variant="buttonSubtext" as="div">
                            Continue to Update Health Info
                        </Text>
                    </Button>
                </Footer>
            </Form>
        </View>
    )
}

export const PatientInfoSkeleton = () => {
    const fakeForm = useForm()
    return (
        <View>
            <Form useFormMethods={fakeForm} loading onSubmit={() => null}>
                <Heading h2>Personal Info</Heading>

                <NameForm
                    label="Legal Name"
                    fieldNames={['givenName', 'familyName']}
                    defaultValues={['', '']}
                />

                <Label>Date of Birth</Label>
                <Input name="birthdate" type="date" />

                <Label>Mailing Address</Label>
                <AddressForm />
            </Form>
        </View>
    )
}
