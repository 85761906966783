import { Box, Logo, Text } from '@asktia/tia-ui'
import React, { FC, ReactNode } from 'react'
import { HelperText } from './HelperText'

export interface ILoginLayout {
    stepMessage?: string
    chatMessage?: string
    children: ReactNode
}

export const LoginLayout: FC<ILoginLayout> = ({
    stepMessage,
    chatMessage,
    children
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Logo sx={{ height: [40, 24], my: 5 }} variant="black" />

            {chatMessage && <HelperText content={chatMessage} />}

            {stepMessage && (
                <Text sx={{ fontSize: 2, mb: 4 }}>{stepMessage}</Text>
            )}

            {children}
        </Box>
    )
}
