import {
    Person,
    EmergencyContact,
    SaveStepFunction,
    CardOnFile,
    InsuranceCoverage,
    InsuranceEligibility,
    ConsentForm,
    CheckInUhr,
    AppointmentWithLocationDetails
} from 'src/types'
import { extendHealthRecord } from 'src/utils'

import {
    StartCheckInInfo,
    StartCheckInInfoSkeleton
} from './views/StartCheckInInfo'
import { PatientInfo, PatientInfoSkeleton } from './views/PatientInfo'
import { PaymentInfo, PaymentInfoSkeleton } from './views/PaymentInfo'
import { InsuranceInfo } from './views/InsuranceInfo'
import { UhrInfo, UhrInfoSkeleton } from './views/UhrInfo'
import { ConsentInfo, ConsentInfoSkeleton } from './views/ConsentInfo'

import pick from 'lodash.pick'
import { Intermission, IntermissionSkeleton } from './views/Intermission'
import { PreApptInfo, PreApptInfoSkeleton } from './views/PreApptInfo'
import {
    CheckInComplete,
    CheckInCompleteSkeleton
} from 'src/flows/AutomatedCheckin/views/CheckInComplete'

export type StepProps = {
    /**
     * appointment being checked into
     */
    appointment: AppointmentWithLocationDetails

    /**
     * method to be called when moving to next step
     */
    saveStep: SaveStepFunction

    /**
     * flag for loading state on submit button
     */
    isSaving: boolean

    /**
     * object returned from the tmd check-in API
     */
    stepProps: {
        patient?: Person
        emergencyContact?: EmergencyContact
        card?: CardOnFile
        coverage?: InsuranceCoverage
        eligibility?: InsuranceEligibility
        hasExisting?: boolean
        uhr?: CheckInUhr
        consentForms?: ConsentForm[]
    }
}

type StepSequenceType = {
    [key: string]: {
        title: string
        subtitle: string
        noNavigation?: boolean
        hideMainHeader?: boolean
        body: (props: StepProps) => JSX.Element | undefined
        fallback: () => JSX.Element
    }
}

export const stepsSequence: StepSequenceType = {
    not_started: {
        title: '',
        subtitle: '',
        body: (props: StepProps) => (
            <StartCheckInInfo
                saveStep={props.saveStep}
                isSaving={props.isSaving}
                appointment={props.appointment}
            />
        ),
        fallback: () => <StartCheckInInfoSkeleton />
    },
    patient_info: {
        title: 'Your Info',
        subtitle: 'Update Your Essential Info',
        body: (props: StepProps) => (
            <PatientInfo
                patient={props.stepProps.patient}
                emergencyContact={props.stepProps.emergencyContact}
                saveStep={props.saveStep}
                isSaving={props.isSaving}
            />
        ),
        fallback: () => <PatientInfoSkeleton />
    },
    uhr: {
        title: 'Your Info',
        subtitle: 'Update Your Health Info',
        body: (props: StepProps) => {
            // Current URL so the intake page can redirect here when finish
            const redirectUrl = `/r/automated-checkin/${props.appointment.id}/uhr`
            return (
                props.stepProps.uhr && (
                    <UhrInfo
                        healthRecord={extendHealthRecord(
                            'health-record',
                            props.stepProps.uhr.records,
                            props.appointment.id,
                            redirectUrl,
                            props.appointment.appointmentProfileUuid
                        )}
                        isComplete={props.stepProps.uhr.isComplete}
                        saveStep={props.saveStep}
                        isSaving={props.isSaving}
                    />
                )
            )
        },
        fallback: () => <UhrInfoSkeleton />
    },
    card_on_file: {
        title: 'Your Info',
        subtitle: 'Review Your Card on File',
        body: (props: StepProps) => {
            return (
                <PaymentInfo
                    canUseInsurance={props.appointment.canUseInsurance}
                    cardOnFile={props.stepProps.card}
                    saveStep={props.saveStep}
                    isSaving={props.isSaving}
                />
            )
        },
        fallback: () => <PaymentInfoSkeleton />
    },
    insurance: {
        title: 'Your Info',
        subtitle: 'Verify Insurance & Payment Info',
        body: (props: StepProps) => {
            const { defaultValues, insuranceValidationState } =
                insuranceInfodefaults(props)

            return (
                <InsuranceInfo
                    canUseInsurance={props.appointment.canUseInsurance}
                    defaultFormValues={defaultValues}
                    {...insuranceValidationState}
                    mailingAddress={
                        props.stepProps.patient &&
                        props.stepProps.patient.mailingAddress
                    }
                    hasExistingInsurance={props.stepProps.hasExisting}
                    saveStep={props.saveStep}
                    appointment={props.appointment}
                    isSaving={props.isSaving}
                />
            )
        },
        fallback: () => <></>
    },
    intermission: {
        title: '',
        subtitle: '',
        hideMainHeader: true,
        body: (props: StepProps) => (
            <Intermission
                appointment={props.appointment}
                saveStep={props.saveStep}
                isSaving={props.isSaving}
            />
        ),
        fallback: () => <IntermissionSkeleton />
    },
    pre_appt_info: {
        title: 'Check In',
        subtitle: '',
        noNavigation: true,
        body: (props: StepProps) => {
            const redirectUrl = `/r/automated-checkin/${props.appointment.id}/pre_appt_info`
            return (
                props.stepProps.uhr && (
                    <PreApptInfo
                        ros={extendHealthRecord(
                            'ros',
                            props.stepProps.uhr.ros,
                            props.appointment.id,
                            redirectUrl,
                            props.appointment.appointmentProfileUuid
                        )}
                        feedback={extendHealthRecord(
                            'feedback',
                            props.stepProps.uhr.feedback,
                            props.appointment.id,
                            redirectUrl
                        )}
                        isComplete={props.stepProps.uhr.isComplete}
                        saveStep={props.saveStep}
                        isSaving={props.isSaving}
                    />
                )
            )
        },
        fallback: () => <PreApptInfoSkeleton />
    },
    consent: {
        title: 'Check In',
        subtitle: 'Consent for Care',
        body: (props: StepProps) =>
            props.stepProps.consentForms && (
                <ConsentInfo
                    appointment={props.appointment}
                    consentForms={props.stepProps.consentForms}
                    saveStep={props.saveStep}
                    isSaving={props.isSaving}
                />
            ),
        fallback: () => <ConsentInfoSkeleton />
    },
    completed: {
        title: '',
        subtitle: '',
        body: (props: StepProps) => (
            <CheckInComplete appointment={props.appointment} />
        ),
        fallback: () => <CheckInCompleteSkeleton />
    }
}

function insuranceInfodefaults(props: StepProps) {
    // TODO: this smells like a generalizable pattern
    const fields = [
        'usingInsurance',
        'issuerName',
        'payerUuid',
        'memberId',
        'patientGivenName',
        'patientFamilyName',
        'patientIsADependent',
        'subscriberGivenName',
        'subscriberFamilyName',
        'subscriberDateOfBirth',
        'relationshipToSubscriberCode',
        'paymentMethod',
        'patientWantsReceipt',
        'patientWantsSuperbill',
        'address1',
        'address2',
        'city',
        'state',
        'postalCode'
    ]

    const dateFields = [
        'patientDateOfBirth',
        'subscriberDateOfBirth',
        'beginDate',
        'endDate',
        'updatedAt'
    ]

    function getValue(name: string) {
        let value = null

        if (props.stepProps.coverage) {
            // @ts-ignore: TS hates this useful pattern
            value = props.stepProps.coverage[name]
        }
        if (!value && props.stepProps.eligibility) {
            // @ts-ignore: TS hates this useful pattern
            value = props.stepProps.eligibility[name]
        }
        if (!value && props.stepProps.patient) {
            // @ts-ignore: TS hates this useful pattern
            value = props.stepProps.patient[name]
        }
        if (!value && name === 'patientGivenName') {
            value = props.stepProps.patient?.givenName
        }
        if (!value && name === 'patientFamilyName') {
            value = props.stepProps.patient?.familyName
        }

        // TODO: find a good runtime types library that supports TS
        if (dateFields.includes(name)) {
            value = new Date(value)
        }

        return value
    }

    const defaultValues = Object.fromEntries(
        fields.map(name => [name, getValue(name)])
    )

    // Fix in ticket TM-4358
    defaultValues.usingInsurance = 'yes'
    defaultValues.patientIsADependent = false

    const insuranceValidationState = {
        ...pick(defaultValues, [
            'verificationStatus',
            'verificationSource',
            'eligibilityStatus',
            'eligibilitySource',
            'updatedAt'
        ])
    }

    return { defaultValues, insuranceValidationState }
}
