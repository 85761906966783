import { Box, Button, Icon, Container, Flex } from '@asktia/tia-ui'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { useAmpli, useLocalStorage } from 'src/hooks'
import { AppointmentSuggestion } from 'src/types'
import { Link as RouterLink } from 'react-router-dom'

// Helper component to render banner contents
const BookingSuggestion = (props: {
    appointmentSuggestion: AppointmentSuggestion
    dismissBanner: Function
}) => {
    const { appointmentSuggestionBannerClicked } = useAmpli()

    function dismissBanner() {
        props.dismissBanner(props.appointmentSuggestion.uuid)
    }

    function trackClick() {
        appointmentSuggestionBannerClicked(props.appointmentSuggestion)
    }

    return (
        <>
            <Flex
                sx={{
                    alignItems: ['flex-start', 'center', 'center'],
                    flexDirection: ['column', 'row', 'row'],
                    color: 'white'
                }}
            >
                <p>You have a new appointment to book from your Care Team ✨</p>
                <Button
                    as={RouterLink}
                    sx={{
                        fontSize: 2,
                        py: 2,
                        px: 4,
                        ml: [0, 4, 4]
                    }}
                    variant="secondaryLight"
                    // @ts-ignore: TS doesn't understand this is a RouterLink now
                    to="/your-care/plan"
                    onClick={trackClick}
                >
                    Check it out
                </Button>
            </Flex>
            <Button
                variant="unstyled"
                sx={{ fontSize: 7 }}
                onClick={dismissBanner}
            >
                <Icon.Close
                    color="white"
                    sx={{ '&:hover path': { fill: 'text' } }}
                />
            </Button>
        </>
    )
}

// Renders communicationBanner banner, handles local storage logic
export const SuggestedAppointmentsBanner = () => {
    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })
    const [dismissedSuggestionBanners, setDismissedSuggestionBanners] =
        useLocalStorage<string[]>('tia:dismissed-appt-suggestion-uuids', [])

    // appends new uuid to list of dismissed suggestions
    function dismissBanner(suggestionUuid: string) {
        setDismissedSuggestionBanners(uuids => [suggestionUuid, ...uuids])
    }

    // Filter suggestions for displaying banner
    // * suggestion banners that patient has not previously dismissed
    const filteredSuggestions = appointmentSuggestions.filter(
        suggestion => !dismissedSuggestionBanners.includes(suggestion.uuid)
    )

    if (filteredSuggestions.length === 0) {
        return null
    }

    return (
        <Box
            sx={{
                backgroundColor: 'communicationBanner'
            }}
        >
            <Container
                sx={{
                    p: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <BookingSuggestion
                    appointmentSuggestion={filteredSuggestions[0]}
                    dismissBanner={dismissBanner}
                />
            </Container>
        </Box>
    )
}
