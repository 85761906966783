import { useLocation, useParams } from 'react-router-dom'
import { useUserFlow } from 'src/hooks/useUserFlow'
import { useAppointment } from 'src/hooks/useAppointment'

export type AppointmentChangeContext = {
    appointmentId: string
}

export const APPOINTMENT_CHANGE_STEPS = {
    init: {
        path: '/change-appointment/:appointmentId',
        nextStep: () => {
            // If the operation is a reschedule it should redirect
            // over to the /book-appointment flow, so we don't
            // allow the flow to move anywhere just in case.
            return APPOINTMENT_CHANGE_STEPS.cancel
        }
    },
    cancel: {
        path: '/change-appointment/:appointmentId/cancel',
        nextStep: () => APPOINTMENT_CHANGE_STEPS.cancelConfirmed
    },
    cancelConfirmed: {
        path: '/change-appointment/:appointmentId/cancel/confirm',
        nextStep: () => APPOINTMENT_CHANGE_STEPS.cancelConfirmed
    }
}

export const useAppointmentChangeFlow = () => {
    const { search } = useLocation()
    // Assume: won't route here if appointmentId is undefined
    const { appointmentId = '' } = useParams<{ appointmentId: string }>()
    const {
        isLoading: isAppointmentLoading,
        appointment,
        status: appointmentStatus,
        refetch
    } = useAppointment(appointmentId)
    const searchParams = new URLSearchParams(search)
    const goBack = () => {
        window.location.href =
            searchParams.get('returnUrl') ?? `/r/book-appointment`
    }
    const { navigateToNextStep } = useUserFlow<AppointmentChangeContext>(
        APPOINTMENT_CHANGE_STEPS,
        { appointmentId },
        searchParams,
        { appointmentId }
    )

    return {
        appointmentId,
        navigateToNextStep,
        goBack,
        appointment,
        refetch,
        isAppointmentLoading,
        appointmentStatus
    }
}
