import { ThemeStyleObject } from '@asktia/tia-ui'

const calendarStyles: ThemeStyleObject = {
    'px': 0,
    '.react-calendar': {
        width: '100%'
    },
    '.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after':
        {
            boxSizing: 'border-box',
            overflow: 'hidden'
        },
    '.react-calendar button': {
        margin: 0,
        border: 0,
        outline: 'none'
    },
    '.react-calendar button abbr': {
        height: '24px',
        width: '24px',
        display: 'inline-block',
        borderRadius: '50%',
        lineHeight: '22px',
        fontSize: 3
    },
    '.react-calendar button:enabled:hover': {
        cursor: 'pointer'
    },
    '.react-calendar__navigation': {
        height: '44px',
        marginBottom: '1em'
    },
    '.react-calendar__navigation button': {
        minWidth: '44px',
        background: 'none'
    },
    '.react-calendar__navigation button:enabled:hover abbr, .react-calendar__navigation button:enabled:focus abbr':
        {
            backgroundColor: '#e6e6e6'
        },
    '.react-calendar__navigation button[disabled] abbr': {
        backgroundColor: '#f0f0f0'
    },
    '.react-calendar__month-view__weekdays': {
        textAlign: 'center'
    },
    '.react-calendar__month-view__weekdays__weekday': {
        color: '#c8c8c8',
        fontSize: 3
    },
    '.react-calendar__month-view__weekdays__weekday abbr': {
        textDecoration: 'none'
    },
    '.react-calendar__month-view__weekNumbers .react-calendar__tile': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.75em',
        padding: 'calc(0.75em / 0.75) calc(0.5em / 0.75)'
    },
    '.react-calendar__tile': {
        maxWidth: '100%',
        textAlign: 'center',
        padding: '0.75em 0.5em',
        background: 'none'
    },
    '.react-calendar__tile:enabled:hover abbr, .react-calendar__tile:enabled:focus abbr':
        {
            backgroundColor: '#e6e6e6'
        },
    '.react-calendar__tile--now abbr': {
        border: '1px solid #222222'
    },
    '.react-calendar__tile--hasActive abbr': {
        background: '#76baff'
    },
    '.react-calendar__tile--active abbr': {
        background: '#222222',
        color: 'mainBackground'
    },
    '.react-calendar__tile--hasActive:enabled:hover abbr, .react-calendar__tile--hasActive:enabled:focus abbr, .react-calendar__tile--active:enabled:hover abbr, .react-calendar__tile--active:enabled:focus abbr':
        {
            background: '#3F3F3F'
        },
    '.react-calendar--selectRange .react-calendar__tile--hover': {
        backgroundColor: '#e6e6e6'
    },
    '.react-calendar__navigation__label': {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    '.outsideWindow': {
        color: '#c8c8c8 !important'
    },
    '.outsideWindow:hover, .outsideWindow:hover abbr': {
        background: 'transparent !important',
        cursor: 'default !important'
    }
}

export default calendarStyles
