import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const useScrollToTopOnPageLoad = () => {
    const location = useLocation()
    const hash = location.hash
    useEffect(() => {
        if (window && !hash) {
            // delay scrolling until fadeout transition finishes
            setTimeout(() => window.scrollTo(0, 0), 100)
        }
    }, [location.pathname + location.search + location.hash])
}
