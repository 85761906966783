import { Button, Form, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { useSearchParams } from 'react-router-dom'
import { useNavigation } from 'src/hooks'

import { GluedSingleChoiceQuestion } from 'src/flows/questionnaires/components/Input/SingleChoice'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useQuestionnaire } from 'src/flows/questionnaires/hooks/useQuestionnaire'
import { useQuestionnaireResponse } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'
import { useState } from 'react'
import { GluedOpenInputQuestion } from 'src/flows/questionnaires/components/Input/OpenInput'
import { yupResolver } from '@hookform/resolvers'
import { get as _get, omit as _omit } from 'lodash'
import * as yup from 'yup'
import { GluedMultiChoiceQuestion } from '../../../components/Input/MultiChoice'
import { QuestionnaireDivider } from 'src/flows/questionnaires/components/QuestionnaireDivider'
import { getFormDone } from 'src/flows/questionnaires/getFormDone'
import { ErrorSavingBanner } from '../../../components/ErrorSavingBanner'

const schema = yup.object().shape({
    'exercise-habits': yup.object().shape({
        days: yup
            .string()
            .required('*Required')
            .matches(/^-?\d+$/, 'Please enter a number.')
            .test(
                'minDays',
                'Please enter a value greater than or equal to 0.',
                value => !!value && parseInt(value) >= 0
            )
            .test(
                'maxDays',
                'Please enter a value less than or equal to 7.',
                value => !!value && parseInt(value) <= 7
            )
    }),
    'nicotine-use': yup.object().shape({
        quantity: yup
            .string()
            .required('*Required')
            .matches(/^-?\d+$/, 'Please enter a number.')
    }),
    'drug-use': yup.object().shape({
        'specify-drugs': yup.array().min(1, ' ').nullable().required()
    })
})

const PersonalWellness = () => {
    const [searchParams] = useSearchParams()
    const { navigate } = useNavigation()
    const [showSavingError, setShowSavingError] = useState(false)

    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schema),
        defaultValues: {},
        mode: 'all'
    })

    const { questionnaire } = useQuestionnaire('personal-wellness')
    const { answersMap, questionnaireResponse, saveQuestionnaireResponse } =
        useQuestionnaireResponse(
            'personal-wellness',
            searchParams.get('appointmentProfileUuid') as string
        )
    const { formDone } = getFormDone(formMethods.getValues())

    const onComplete = async () => {
        triggerSave()
        navigate(searchParams.get('onCompleteRelativePath') as string)
    }

    const getValidFormValues = async (triggeredByFieldName?: string) => {
        // validate form against latest form values
        await formMethods.trigger(triggeredByFieldName)

        const formValues = formMethods.getValues()
        const formKeys = Object.keys(formValues)

        // TODO: not sure best way to handle this any
        // at the moment the formValues also have an any type
        const validFormValues: any = {}
        const fieldErrors = formMethods.formState.errors

        // Note - this only works for 1 nested level
        // i.e.
        // formValues = {nicotine-use: {polar: 'yes', quantity: 'asdfasdf'}}
        // errors = {nicotine-use: {quantity: 'must be a number'}}
        // validFormValues = {nicotine-use: {polar: 'yes'}
        formKeys.forEach((key: string) => {
            const fieldValue = _get(formValues, key)
            const nestedFieldErrors = _get(fieldErrors, key)
            validFormValues[key as string] = _omit(
                fieldValue,
                Object.keys(nestedFieldErrors || {})
            )
        })

        return validFormValues
    }

    // Save questionnaire and handle errors:
    // - clear triggering field, so user knows what's wrong
    // - show error toast
    async function triggerSave(triggeredByFieldName?: string) {
        setShowSavingError(false)

        try {
            await saveQuestionnaireResponse(
                await getValidFormValues(triggeredByFieldName)
            )
        } catch (e) {
            console.error(e)
            if (triggeredByFieldName) {
                formMethods.setValue(triggeredByFieldName, undefined)
            }
            setShowSavingError(true)
        }
    }

    if (!questionnaire.item) {
        return <Spinner />
    }

    const questionFormGlue = {
        questionnaire,
        questionnaireResponse,
        answersMap,
        triggerSave
    }

    const canProceed = formDone && Object.keys(formMethods.errors).length === 0

    return (
        <>
            {showSavingError ? <ErrorSavingBanner /> : null}
            <LayoutGrid columns="one" variant="condensed">
                <FormProvider {...formMethods}>
                    <Form
                        useFormMethods={formMethods}
                        onSubmit={() => console.log('on submit')}
                    >
                        <GluedSingleChoiceQuestion
                            linkId="alcohol-use.ofteness"
                            {...questionFormGlue}
                        />

                        <GluedSingleChoiceQuestion
                            linkId="alcohol-use.drink-spread"
                            variant="info"
                            {...questionFormGlue}
                        />

                        <GluedSingleChoiceQuestion
                            linkId="alcohol-use.concerns-polar"
                            variant="info"
                            followupCardSx={{ mt: -5, px: 4, py: 5 }}
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="nutrition.concerns-polar"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedOpenInputQuestion
                            linkId="exercise-habits.days"
                            placeholder="number"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="exercise-habits.concerns-polar"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="nicotine-use.use-polar"
                            {...questionFormGlue}
                        />

                        <GluedOpenInputQuestion
                            linkId="nicotine-use.quantity"
                            placeholder="number"
                            variant="info"
                            sx={{ mb: -3 }}
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="drug-use.use-polar"
                            {...questionFormGlue}
                        />

                        <GluedSingleChoiceQuestion
                            linkId="drug-use.oftneness"
                            variant="info"
                            {...questionFormGlue}
                        />

                        <GluedMultiChoiceQuestion
                            linkId="drug-use.specify-drugs"
                            variant="info"
                            followupCardSx={{ mt: -5, px: 4, py: 5 }}
                            {...questionFormGlue}
                        />

                        <GluedSingleChoiceQuestion
                            linkId="drug-use.concerns-polar"
                            variant="info"
                            followupCardSx={{ mt: -5, px: 4, py: 5 }}
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="experience-stress.ofteness"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="loneliness.ofteness"
                            {...questionFormGlue}
                        />

                        <QuestionnaireDivider />

                        <GluedSingleChoiceQuestion
                            linkId="abuse-experience.polar"
                            {...questionFormGlue}
                        />

                        <Button
                            sx={{ my: 8, py: '20px' }}
                            onClick={onComplete}
                            disabled={!canProceed}
                            fullWidth
                        >
                            Complete Personal Wellness
                        </Button>
                    </Form>
                </FormProvider>
            </LayoutGrid>
        </>
    )
}

export default PersonalWellness
