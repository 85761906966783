import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'
import { fetcher } from 'src/fetcher'
import { useQuery } from 'react-query'

export function useGetGynecologyCompletion(): {
    isLoading: boolean
    gynecologyCompletion: number
} {
    const { user } = useUserInfo()

    const { data, isLoading } = useQuery<{ percentage: number }>(
        ['gynecology', 'completion', user?.id],
        () =>
            fetcher(
                `${BASE_API_URL}/tmd-api/patients/gynecology-completion/${user?.id}?apiVersion=v2`,
                undefined,
                'GET'
            ),
        { enabled: !!user }
    )

    return {
        isLoading,
        gynecologyCompletion: data?.percentage || 0
    }
}
