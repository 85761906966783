import { Link } from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { CheckboxFilterModal } from './CheckboxFilterModal'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { useAmpli } from 'src/hooks'

export const GenderFilterModal = (props: FilterModalProps<string[]>) => {
    const options = [
        { key: 'any', label: 'Any gender' },
        { key: 'female', label: 'Female' },
        { key: 'male', label: 'Male' }
    ]

    const { appointmentFilteredByProviderGender } = useAmpli()

    function onSelected(genders: string[]) {
        appointmentFilteredByProviderGender(
            genders.sort().join(', '),
            props.appointmentProfile
        )
        props.onSelected(genders)
    }

    return (
        <CheckboxFilterModal
            {...props}
            onSelected={onSelected}
            title="Gender Identity"
            description="Tia hires the highest quality providers, regardless of gender, while giving you choices to maximize your comfort. They are trained to deliver non-judgemental gender affirming care."
            label="Select gender preferences (you can choose more than one):"
            options={options}
            footer={
                <>
                    Tia recognizes that gender is not binary, but a spectrum. We
                    are proudly cultivating a healthcare community that is
                    welcome to all people — including trans and gender
                    non-conforming patients and providers. Have feedback on how
                    we can improve our offerings for gender non-conforming
                    patients?{' '}
                    <Link
                        as={RouterLink}
                        // @ts-ignore: TS doesn't understand this is a RouterLink now
                        to="/chat/new"
                    >
                        Message your Care Coordinator
                    </Link>
                </>
            }
            anyKey="any"
        />
    )
}
