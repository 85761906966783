import { Flex, Heading, Paragraph, Text } from '@asktia/tia-ui'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
    QuestionnaireItem,
    QuestionnaireResponseAnswer
} from '@asktia/tia-fhir-models'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { getFieldName } from 'src/flows/questionnaires/hooks/useQuestionnaireResponse'

export interface IBaseInput {
    questionnaireItem: QuestionnaireItem
    answersMap: Map<string, QuestionnaireResponseAnswer[]>
    variant?: 'info'
    sx?: ThemeUIStyleObject
    index?: number
    noHeaders?: boolean
    triggerSave: (triggerByFieldName: string) => void
    render: (p: { name: string; onBlur: () => void }) => ReactNode
    readDefaultValue: (
        defaultValue?: QuestionnaireResponseAnswer[]
    ) => string | string[] | number | undefined
}

/**
 * Enables comparing array form values
 * that may have unstable references
 */
function getStableValue(value: string | string[] | number | undefined) {
    return Array.isArray(value) ? value.join(',') : value
}

/**
 * This is a headless lego to build UHR inputs
 */
export const BaseInput: FC<IBaseInput> = ({
    questionnaireItem,
    answersMap,
    variant,
    sx,
    index,
    noHeaders,
    triggerSave,
    readDefaultValue,
    render
}) => {
    const { text } = questionnaireItem
    const { setValue } = useFormContext()

    const name = getFieldName(questionnaireItem, index)
    const defaultValue = readDefaultValue(answersMap.get(name))

    const [prevDefaultValue, setPrevDefaultValue] = useState(defaultValue)

    // populate field when defaults load
    if (getStableValue(prevDefaultValue) !== getStableValue(defaultValue)) {
        setPrevDefaultValue(defaultValue)
        setValue(name, defaultValue)
    }

    // populate field on first render,
    // if we already know default
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue)
        }
    }, [])

    const save = () => {
        triggerSave(name)
    }

    return (
        <Flex sx={{ flexDirection: 'column', ...sx }}>
            {!noHeaders &&
                (variant === 'info' ? (
                    <Text
                        sx={{
                            fontSize: 3,
                            lineHeight: '20px',
                            fontWeight: 'bold',
                            mb: 4
                        }}
                    >
                        {text}
                    </Text>
                ) : (
                    <Heading h3>{text}</Heading>
                ))}

            {questionnaireItem.item
                ?.filter(item => item.type === 'display')
                .map(item => (
                    <Paragraph
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            mt: t => `-${t.sizes?.[3]}px`,
                            mb: 4
                        }}
                    >
                        {item.text}
                    </Paragraph>
                ))}

            <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
                {render({ name, onBlur: save })}
            </Flex>
        </Flex>
    )
}
