import {
    Box,
    Button,
    Card,
    Checkbox,
    Flex,
    Form,
    Heading,
    Label,
    Link,
    Spinner,
    TiaModal
} from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { useOfferingDetails } from 'src/flows/AppointmentBooking/useOfferingDetails'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import { Appointment, AppointmentProfile } from 'src/types'
import { useAmpli } from 'src/hooks'
import { useAppointmentSuggestion } from 'src/flows/AppointmentBooking/useAppointmentSuggestion'
import { useLocation } from 'react-router'

type FormValues = { providers: string[] } | FieldValues

const ProvidersList = (props: {
    appointmentProfile: AppointmentProfile
    rescheduledAppointment?: Appointment
}) => {
    const { offeringDetails, isLoading } = useOfferingDetails(
        props.appointmentProfile.appointmentProfileUuid
    )

    if (isLoading) {
        return (
            <Box sx={{ mb: 6 }}>
                <Spinner />
            </Box>
        )
    }

    return (
        <Box sx={{ mb: 6, maxHeight: 300, overflow: 'auto' }}>
            {offeringDetails?.clinicStaff.map(staff => (
                <Label sx={{ alignItems: 'center', mb: 4 }}>
                    <Checkbox
                        name="providers"
                        key={staff.staffUuid}
                        value={staff.staffUuid}
                        disabled={
                            props?.rescheduledAppointment
                                ?.requirePatientReschedulingWithSameProvider
                        }
                        pretty
                    />
                    <Heading h4 sx={{ m: 0 }}>
                        {staff.name}
                    </Heading>
                </Label>
            ))}
        </Box>
    )
}

export const ProviderFilterModal = ({
    hideModal,
    onSelected,
    onClearSelections,
    initialValue,
    appointmentProfile,
    rescheduledAppointment
}: FilterModalProps<string[]>) => {
    const searchParams = new URLSearchParams(useLocation().search)
    // "context" its the query params used on booking time slot page
    const appointmentSuggestionUuid = searchParams.get('context') || undefined

    const { appointmentFilteredByProvider } = useAmpli()
    const { suggestion } = useAppointmentSuggestion(appointmentSuggestionUuid)
    const formMethods = useForm<FormValues>({
        defaultValues: {
            providers: initialValue || undefined
        }
    })

    const onSubmit = (formValues: FormValues) => {
        appointmentFilteredByProvider(formValues.providers, appointmentProfile)

        onSelected(formValues.providers)
        hideModal()
    }

    const clearSelections = () => {
        onClearSelections()
        hideModal()
    }

    const suggestionHasProviderFilter = suggestion?.params?.providerUuids
        ? suggestion?.params?.providerUuids?.length > 0
        : false

    return (
        <TiaModal sx={{ width: 456 }}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: 4
                }}
            >
                <Heading h2 sx={{ m: 0, p: 0 }}>
                    Provider
                </Heading>
                <Box onClick={() => hideModal()} sx={{ cursor: 'pointer' }}>
                    <CloseIcon color="inactiveText" hoverColor="text" />
                </Box>
            </Flex>

            {suggestionHasProviderFilter && (
                <Card sx={{ background: 'gold', mb: 5 }}>
                    Heads up! Your care team has specified a certain Tia
                    provider for your follow-up care. This provider is best
                    equipped for the care you need.
                </Card>
            )}

            <Form
                useFormMethods={formMethods}
                onSubmit={onSubmit}
                sx={{ alignItems: 'left' }}
            >
                <ProvidersList
                    appointmentProfile={appointmentProfile}
                    rescheduledAppointment={rescheduledAppointment}
                />

                <Box sx={{ textAlign: 'center' }}>
                    <Button fullWidth type="submit" sx={{ mb: 4 }}>
                        Apply Filter
                    </Button>
                    <Link
                        onClick={clearSelections}
                        href="#"
                        sx={{
                            fontSize: 2,
                            color: 'text'
                        }}
                    >
                        Clear selections
                    </Link>
                </Box>
            </Form>
        </TiaModal>
    )
}
