import { Heading, Card, Paragraph } from '@asktia/tia-ui'
/**
 * shows how payment works copy
 */
export const HowPaymentWorks = () => {
    return (
        <Card variant="info" sx={{ mb: 6 }}>
            <Heading h2 sx={{ fontSize: 5 }}>
                How payment works:
            </Heading>
            <Paragraph>
                Your insurer will first send you an Explanation of Benefits.
                Then we send one convenient bill for all fees, even copays,
                before charging your card. With insurance, we charge you 4-8
                weeks after an appointment, depending on your insurer. With
                self-pay, we charge you in 2-3 weeks.
            </Paragraph>
        </Card>
    )
}
