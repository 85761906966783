import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'

async function postImmunizationSeen(patientId: number) {
    await fetcher(
        `${BASE_API_URL}/tmd-api/patients/${patientId}/immunization-seen?apiVersion=v2`,
        JSON.stringify({}),
        'POST'
    )
}

export const useSubmitImmunizationSeen = () => {
    const { user, isLoading } = useUserInfo()

    //@ts-ignore (this will be immediately aviailable by time you call here)
    const mutation = useMutation(() => postImmunizationSeen(user?.id))

    return {
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        submitImmunizationSeen: mutation.mutateAsync
    }
}
