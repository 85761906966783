import {
    Box,
    Form,
    Heading,
    LayoutGrid,
    Paragraph,
    Radio,
    Spinner
} from '@asktia/tia-ui'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { FooterButton } from 'src/components/Blocks'
import { Show404 } from 'src/flows/404'
import { AppointmentGoal } from 'src/types'
import { BookingCardDetails } from '../BookingCard'
import { useBookingFlow } from '../useBookingFlow'
import { useAmpli } from 'src/hooks'
import { isNil } from 'lodash'
import { View } from 'src/components/Blocks/View'
import { useLocation } from 'react-router'

const question = 'Please select your main discussion topic'
const compareGoals = (a: AppointmentGoal, b: AppointmentGoal): number => {
    // order is important here: latest in the list, goes latest in the result
    const lastItems = ['nothing right now', 'no topic', 'just curious to try']
    const getCompareStr = (e: AppointmentGoal) => e.description.toLowerCase()
    const isLastItem = (e: AppointmentGoal) =>
        lastItems.includes(getCompareStr(e))

    if (isLastItem(a) && isLastItem(b)) {
        return (
            lastItems.findIndex(e => e === getCompareStr(a)) -
            lastItems.findIndex(e => e === getCompareStr(b))
        )
    }

    if (isLastItem(a)) {
        return 1
    }
    if (isLastItem(b)) {
        return -1
    }
    return getCompareStr(a) < getCompareStr(b) ? -1 : 1
}

function useBookingGoal() {
    const {
        appointmentProfile,
        isLoading,
        // currently selected goal
        selectedGoal,
        // goal interpreted from URL params or similar
        preselectedGoal,
        saveGoal,
        navigateToNextStep
    } = useBookingFlow()
    const { postQuestionSelected, postQuestionSubmitted } = useAmpli()

    const formMethods = useForm()
    const selectedGoalId =
        formMethods.watch('selectedGoalId') || selectedGoal?.id
    // Stringify the goal id due to a bug in our form watcher where the goal ID is first a string, then a number,
    // then a string again. https://asktia.atlassian.net/browse/TM-1369
    const stringifiedSelectedGoalId = isNil(selectedGoalId)
        ? selectedGoalId
        : `${selectedGoalId}`
    const goals = appointmentProfile?.goals

    useEffect(() => {
        // On first render the selected goal may be undefined
        if (stringifiedSelectedGoalId) {
            postQuestionSelected(question, stringifiedSelectedGoalId)
        }
    }, [stringifiedSelectedGoalId, postQuestionSelected])

    const { bookingGoalSelectionViewed } = useAmpli()
    useEffect(() => {
        if (appointmentProfile) {
            bookingGoalSelectionViewed(appointmentProfile)
        }
    }, [appointmentProfile])

    const onSubmit = ({
        selectedGoalId
    }: { selectedGoalId: string } | FieldValues) => {
        const goal = goals?.find(x => x.id.toString() === selectedGoalId)

        postQuestionSubmitted(question, selectedGoalId.toString())
        saveGoal(goal)
        navigateToNextStep()
    }

    if (!isLoading && !goals) {
        navigateToNextStep()
        // Keep showing spinner while navigation happens
        return {
            onSubmit,
            formMethods,
            isLoading: true
        }
    }

    // If we somehow end up here with only 1 goal to pick from, pick it and move on
    if (goals?.length === 1) {
        onSubmit({ selectedGoalId: goals[0].id.toString() })

        // keep showing spinner while navigating
        return {
            onSubmit,
            formMethods,
            isLoading: true
        }
    }

    return {
        isLoading,
        appointmentProfile,
        goals,
        onSubmit,
        formMethods,
        disableSubmit: !selectedGoalId,
        selectedGoalId
    }
}

export const BookingGoalSelection = () => {
    const { search } = useLocation()
    const {
        isLoading,
        appointmentProfile,
        onSubmit,
        goals,
        formMethods,
        disableSubmit,
        selectedGoalId
    } = useBookingGoal()

    const searchParams = new URLSearchParams(search)
    const goalId = searchParams.get('goalId')

    useEffect(() => {
        if (goalId) {
            onSubmit({ selectedGoalId: goalId })
        }
    }, [goalId])

    if (isLoading) {
        return <Spinner />
    }

    if (!appointmentProfile || !goals) {
        return <Show404 redirectToCareCoordinator />
    }

    return (
        <View withFooter>
            <LayoutGrid columns="two">
                <Box>
                    <BookingCardDetails
                        appointmentProfile={appointmentProfile}
                        selectedTreatments={[]}
                    />
                </Box>
                <Form
                    id="goalForm"
                    onSubmit={onSubmit}
                    useFormMethods={formMethods}
                >
                    <Heading h3>{question}:</Heading>

                    <Paragraph
                        sx={{
                            variant: 'text.supportingInfo',
                            mb: 4,
                            color: 'text'
                        }}
                    >
                        Discussion topics help to tailor your appointment to
                        your needs. We customize your appointments so you get
                        the care you need & deserve.
                    </Paragraph>

                    {goals.sort(compareGoals).map(goal => (
                        <Radio
                            name="selectedGoalId"
                            key={goal.id}
                            value={goal.id}
                            sx={{ mb: 4 }}
                            defaultChecked={goal.id === selectedGoalId}
                            pretty
                        >
                            {goal.description}
                        </Radio>
                    ))}

                    <FooterButton type="submit" disabled={disableSubmit}>
                        Next
                    </FooterButton>
                </Form>
            </LayoutGrid>
        </View>
    )
}
