import { LayoutGrid, Spinner, Card, Button, Text } from '@asktia/tia-ui'
import React, { useEffect, useMemo } from 'react'
import { Divider } from 'src/components/Blocks'
import { View } from 'src/components/Blocks/View'
import Markdown from 'src/components/Markdown'
import {
    SuccessAnimation,
    AnimationVariant
} from 'src/components/SuccessAnimation'
import { Show404 } from 'src/flows/404'
import { BookingCardDetails } from 'src/flows/AppointmentBooking/BookingCard'
import { useBookingFlow } from 'src/flows/AppointmentBooking/useBookingFlow'
import { useAmpli, useHomeMarket, useUserInfo } from 'src/hooks'
import { useAppointmentCancellationPolicy } from 'src/hooks/useAppointmentCancellationPolicy'

// we need the  prop because there is currently
// no good way to find out whether an appointmentProfile is
// on-demand from our API
export const BookingReview = () => {
    const {
        appointmentProfile,
        slot,
        // currently selected goal
        selectedGoal,
        // goal from URL params or similar
        preselectedGoal,
        selectedTreatments,
        isLoading,
        isBooking,
        isBookingSuccess,
        rescheduleAppointmentUuid,
        bookSlot,
        appointmentSuggestionUuid
    } = useBookingFlow()

    const { user } = useUserInfo()

    const { appointmentCancellationPolicy } = useAppointmentCancellationPolicy(
        appointmentProfile?.appointmentProfileUuid
    )
    const { appointmentReviewViewed, bookingConfirmationCtaClicked } =
        useAmpli()

    const appointmentGoal =
        selectedGoal && selectedGoal.id ? `${selectedGoal.id}` : undefined

    const { homeMarket } = useHomeMarket()

    const locationName = useMemo(() => {
        switch (homeMarket?.name) {
            case 'New York City':
                return 'New York State'
            case 'Los Angeles':
            case 'San Francisco':
                return 'California'
            case 'Phoenix':
                return 'Arizona'
            default:
                return 'your home state'
        }
    }, [homeMarket?.name])

    const locationCopy = `Heads up! For compliance reasons, you'll need to be physically present in ${locationName} at the time of this virtual appointment.`

    useEffect(() => {
        if (appointmentProfile) {
            appointmentReviewViewed(
                appointmentProfile,
                appointmentSuggestionUuid,
                appointmentGoal,
                preselectedGoal?.id
            )
        }
    }, [
        appointmentReviewViewed,
        appointmentProfile,
        appointmentSuggestionUuid,
        appointmentGoal,
        preselectedGoal
    ])

    if (isLoading) {
        return <Spinner />
    }

    if (!appointmentProfile || !slot) {
        return <Show404 />
    }

    if (isBookingSuccess) {
        // Transition to next page will happen on hook
        return <BookingAnimation variant={'booking'} />
    }

    function onCtaClick() {
        if (!appointmentProfile) {
            // no-op when no appointment profile
            // this shouldn't be possible
            return
        }

        if (!rescheduleAppointmentUuid) {
            bookingConfirmationCtaClicked(
                appointmentProfile,
                appointmentSuggestionUuid,
                appointmentGoal
            )
        }

        if (slot) {
            bookSlot(slot)
        }
    }

    const bookingCta = `Confirm & ${
        rescheduleAppointmentUuid ? 'Reschedule' : 'Book'
    }`

    return (
        <View withFooter>
            <LayoutGrid columns="one" variant="condensed">
                <BookingCardDetails
                    appointmentProfile={appointmentProfile!}
                    slot={slot}
                    selectedTreatments={selectedTreatments}
                    hideDescription
                />

                {appointmentProfile.emergencyHelpText && (
                    <>
                        {appointmentProfile.emergencyHelpText}
                        <Divider
                            sx={{ mt: 0, mb: 0, bg: 'secondaryBackground' }}
                        />
                    </>
                )}

                {slot?.location === 'virtual' && (
                    <Card variant="info" sx={{ mt: 4 }}>
                        <Text color="putty">{locationCopy}</Text>
                    </Card>
                )}

                <Markdown
                    text={appointmentCancellationPolicy?.cancellationCopy || ''}
                    sx={{
                        variant: 'text.supportingInfo',
                        mt: 4
                    }}
                />

                <Button
                    fullWidth
                    mt={5}
                    onClick={onCtaClick}
                    loading={isBooking}
                >
                    {user?.membershipSignupStatus === 'preview'
                        ? 'Resume signup'
                        : bookingCta}
                </Button>
            </LayoutGrid>
        </View>
    )
}

export const BookingAnimation = React.memo(
    ({ variant }: { variant: AnimationVariant }) => (
        <SuccessAnimation variant={variant} />
    )
)
