import { Spinner } from '@asktia/tia-ui'
import { useEffect, useState } from 'react'
import { Show404 } from 'src/flows/404'
import { useBookingFlow } from 'src/flows/AppointmentBooking/useBookingFlow'

export const DeeplinkRedirect = () => {
    const { appointmentProfile, isLoading, navigateToNextStep } =
        useBookingFlow()
    // Redirect guard because we render during page transition
    const [redirected, setRedirected] = useState(false)
    useEffect(() => {
        if (appointmentProfile && !redirected) {
            setRedirected(true)
            navigateToNextStep(true)
        }
    }, [appointmentProfile, navigateToNextStep, redirected])

    if (isLoading) {
        return <Spinner />
    } else if (appointmentProfile) {
        return <Spinner />
    } else {
        return <Show404 redirectToCareCoordinator />
    }
}
