import { Box, Link, Text, ThemeStyleObject } from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { useAmpli } from 'src/hooks'

type BackButtonProps = {
    sx?: ThemeStyleObject
    href?: string
    variant?: 'default' | 'white'
}

export const BackButton: FC<React.PropsWithChildren<BackButtonProps>> = ({
    href,
    sx,
    variant,
    children = 'Back'
}) => {
    const searchParams = new URLSearchParams(window.location.search)
    const location = useLocation()
    const { backButtonClicked } = useAmpli()
    const color = variant === 'white' ? 'white' : 'text'
    const backTo = searchParams.get('backTo')

    return (
        <Link
            as={RouterLink}
            onClick={() => {
                backButtonClicked(location.pathname)
            }}
            // @ts-ignore TS gets confused with as=RouterLink
            to={backTo ? backTo : href || -1} // -1 goes back in history :D
            sx={{
                cursor: 'pointer',
                alignItems: 'center',
                textDecoration: 'none',
                display: 'flex',
                ...sx
            }}
        >
            <Box
                as="svg"
                sx={{
                    'width': '32px',
                    'height': '32px',
                    '& path': {
                        fill: color
                    }
                }}
            >
                <path d="M7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L12.2426 10.6967C12.5355 10.4038 13.0104 10.4038 13.3033 10.6967C13.5962 10.9896 13.5962 11.4645 13.3033 11.7574L9.06066 16L13.3033 20.2426C13.5962 20.5355 13.5962 21.0104 13.3033 21.3033C13.0104 21.5962 12.5355 21.5962 12.2426 21.3033L7.46967 16.5303ZM24 16.75L8 16.75L8 15.25L24 15.25L24 16.75Z" />
            </Box>

            <Text sx={{ ml: 1, color, fontSize: 0 }}>{children}</Text>
        </Link>
    )
}

export const NoLinkBackButton: FC<{
    variant?: 'default' | 'white'
    children?: string
    onClick: () => void
}> = ({ variant, children = 'Back', onClick }) => {
    const color = variant === 'white' ? 'white' : 'text'

    return (
        <Box
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                alignItems: 'center',
                textDecoration: 'none',
                display: 'flex'
            }}
        >
            <Box
                as="svg"
                sx={{
                    'width': '32px',
                    'height': '32px',
                    '& path': {
                        fill: color
                    }
                }}
            >
                <path d="M7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L12.2426 10.6967C12.5355 10.4038 13.0104 10.4038 13.3033 10.6967C13.5962 10.9896 13.5962 11.4645 13.3033 11.7574L9.06066 16L13.3033 20.2426C13.5962 20.5355 13.5962 21.0104 13.3033 21.3033C13.0104 21.5962 12.5355 21.5962 12.2426 21.3033L7.46967 16.5303ZM24 16.75L8 16.75L8 15.25L24 15.25L24 16.75Z" />
            </Box>

            <Text sx={{ ml: 1, color, fontSize: 0 }}>{children}</Text>
        </Box>
    )
}
