import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export type ModalityType = 'clinic' | 'virtual' | 'clinic or virtual'
type JoinWaitList = {
    patientId: number
    date: Date
    appointmentProfileUuid: string
    modality: ModalityType
}

async function joinWaitList(body: JoinWaitList) {
    await fetcher(
        `${BASE_API_URL}/tmd-api/waitlist?apiVersion=v2`,
        JSON.stringify(body),
        'POST'
    )
}

export const useJoinWaitList = () => {
    const mutation = useMutation(joinWaitList)

    return {
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        joinWaitList: mutation.mutateAsync
    }
}
