import { useState, PropsWithChildren } from 'react'
import * as React from 'react'
import {
    Heading,
    Card,
    Button,
    Text,
    Label,
    Input,
    Form,
    TiaModal,
    Box,
    Flex
} from '@asktia/tia-ui'
import { useModal } from 'react-modal-hook'
import { useForm } from 'react-hook-form'
import {
    CreditCardForm,
    TiaCreditCardSubmitButton,
    CardInput,
    CreditCardContext
} from 'src/components/CreditCardForm'
import { CircleInfoIcon } from 'src/components/Blocks/Icons'
import { CanUseInsurance, CardOnFile, SaveStepFunction } from 'src/types'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'
import { ModalTitle } from 'src/components/Blocks'
import logger from 'src/logger'

// Helper component for the submit button
// Needs to render as special stripe button when stripe form showing
const SubmitButton = ({
    children,
    onClick,
    loading = false
}: PropsWithChildren<{
    onClick: React.MouseEventHandler
    loading?: boolean
}>) => {
    const { showCardFields } = React.useContext(CreditCardContext)

    if (showCardFields) {
        return (
            <TiaCreditCardSubmitButton loading={loading}>
                {children}
            </TiaCreditCardSubmitButton>
        )
    }

    return (
        <Button disabled={loading} loading={loading} onClick={onClick}>
            {children}
        </Button>
    )
}

const CardModal = (props: { hideModal: () => void }) => {
    return (
        <TiaModal>
            <ModalTitle title="Card on File" hideModal={props.hideModal} />
            <p>
                Currently, we can only accept credit or debit cards to be used
                as your card on file.
            </p>
            <p>
                On the next page, you can select to receive a copy of your
                receipt to submit for FSA or HSA reimbursement.
            </p>
            <Button onClick={props.hideModal}>Got it!</Button>
        </TiaModal>
    )
}

// helper component for this step's header
const Header = (props: { onClick?: () => void }) => {
    return (
        <Heading
            h2
            sx={{
                mb: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            Credit or Debit Card{' '}
            <Button variant="unstyled" onClick={props.onClick} sx={{ mt: 2 }}>
                <CircleInfoIcon color="inactiveText" hoverColor="text" />
            </Button>
        </Heading>
    )
}

type PaymentInfoProps = {
    canUseInsurance?: CanUseInsurance
    cardOnFile?: CardOnFile
    saveStep: SaveStepFunction
    isSaving: boolean
}

export const PaymentInfo = ({
    canUseInsurance,
    cardOnFile,
    saveStep,
    isSaving
}: PaymentInfoProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isUpdating, setUpdating] = useState(false)
    const [showModal, hideModal] = useModal(() => (
        <CardModal hideModal={hideModal} />
    ))

    async function cardSuccess(nonce: string, postalCode: string) {
        setUpdating(true)
        await saveStep({
            card: {
                nonce,
                postalCode
            }
        })
        setUpdating(false)
        setErrorMessage('')
    }

    function cardError(errors: string[]) {
        logger.error(errors.join(', '))
        setErrorMessage(errors.join(', '))
    }

    function onSubmit(event: React.MouseEvent) {
        event.preventDefault()
        saveStep()
    }

    return (
        <View withFooter>
            <Header onClick={showModal} />
            <p>
                In order to confirm your appointment, we need to pre-authorize
                your card on file. This card will also be used to pay your Tia
                membership dues and any charges related to this appointment.
            </p>
            <p>
                On the next page, you can choose to receive a copy of your
                receipt to submit for FSA or HSA reimbursement.
            </p>

            <CreditCardForm
                onSuccess={cardSuccess}
                onError={cardError}
                showCardOnLoad={!cardOnFile}
            >
                <Box sx={{ mb: 4, pb: 4 }}>
                    <CardInput
                        cardOnFile={cardOnFile}
                        onChange={() => setErrorMessage('')}
                    />

                    {errorMessage && (
                        <Flex
                            sx={{
                                width: '100%',
                                justifyContent: 'center'
                            }}
                        >
                            <Text
                                sx={{
                                    fontSize: 0,
                                    textAlign: 'center',
                                    color: 'inputOutlineError'
                                }}
                            >
                                {errorMessage}
                            </Text>
                        </Flex>
                    )}
                </Box>

                <Card
                    variant="info"
                    sx={{
                        mb: 6,
                        backgroundColor: 'secondaryBackground'
                    }}
                >
                    <p>
                        In order to confirm your appointment, we will place a $1
                        hold on your card that will be released after your
                        appointment. By continuing, you are agreeing to this
                        hold.
                    </p>
                </Card>

                <Footer>
                    <SubmitButton
                        onClick={onSubmit}
                        loading={isUpdating || isSaving}
                    >
                        My card information is correct
                        <Text variant="buttonSubtext" as="div">
                            {canUseInsurance === 'yes'
                                ? 'Continue to Insurance & Payment'
                                : 'Continue to Payment'}
                        </Text>
                    </SubmitButton>
                </Footer>
            </CreditCardForm>
        </View>
    )
}

export const PaymentInfoSkeleton = () => {
    const fakeForm = useForm()
    const [showModal, hideModal] = useModal(() => (
        <CardModal hideModal={hideModal} />
    ))
    return (
        <View>
            <Header onClick={showModal} />
            <p>
                In order to confirm your appointment, we need to pre-authorize
                your card on file. This card will also be used to pay your Tia
                membership dues and any charges related to this appointment.
            </p>
            <p>
                On the next page, you can choose to receive a copy of your
                receipt to submit for FSA or HSA reimbursement.
            </p>

            <Form useFormMethods={fakeForm} loading onSubmit={() => null}>
                <Box sx={{ mb: 4, pb: 2 }}>
                    <Label>Card Details</Label>
                    <Input name="card_details" />
                </Box>

                <Card variant="info" sx={{ mb: 6 }}>
                    <p>
                        In order to confirm your appointment, we will place a $1
                        hold on your card that will be released after your
                        appointment. By continuing, you are agreeing to this
                        hold.
                    </p>
                </Card>
            </Form>
        </View>
    )
}
