import { useMutation, UseMutationOptions } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export interface IPhoneCodeVerification {
    phone: string
    code: string
}

export function useCodeVerification(
    options: Omit<UseMutationOptions<void, unknown, any, unknown>, 'mutationFn'>
) {
    return useMutation(
        async ({ phone, code }: IPhoneCodeVerification) =>
            await fetcher(
                `${BASE_API_URL}/api/v1/account/code`,
                JSON.stringify({
                    phoneNum: phone,
                    code: code,
                    clientTimezone: 'America/Los_Angeles'
                }),
                'POST'
            ),
        options
    )
}
