import { Box, Checkbox, Flex, Label, Link, Select } from '@asktia/tia-ui'
import { useServiceLines } from 'src/flows/AppointmentBooking/useServiceLines'
import {
    ClinicDropdownItem,
    ServiceDropdownItem
} from './useAppointmentFilters'
import { useTrackingStuff } from './useTrackingStuff'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

const RecommendedAppointmentsFilter = () => {
    const showPlainSymptomsSearch =
        useAmpliFeatureFlag('plain-symptoms-field-in-appointment-search') ===
        'treatment'

    if (showPlainSymptomsSearch) {
        return null
    }

    return (
        <Label sx={{ 'justifyContent': 'right', '& svg': { mr: 0 } }}>
            My Recommended Appointments <Checkbox name="showRecommended" />
        </Label>
    )
}

export const HidableFilters = (props: {
    showFilters: boolean
    resetFilters: () => void
    numActiveFilters: number
}) => {
    const { showFilters, resetFilters, numActiveFilters } = props
    const { serviceLines } = useServiceLines()
    const { trackServiceLineChange, trackLocationChange } = useTrackingStuff()

    return (
        <Box
            sx={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                mt: showFilters ? [0, 4] : 0,
                mb: showFilters ? [4, 0] : 0,
                display: showFilters ? 'block' : 'none'
            }}
        >
            <Flex
                sx={{
                    width: '100%',
                    flexDirection: ['column', 'row'],
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        width: ['100%', '50%']
                    }}
                >
                    <Label>Service category</Label>
                    <Select
                        name="serviceLines"
                        options={serviceLines?.map(sl => ({
                            label: sl.label,
                            value: sl.name
                        }))}
                        onChange={opt =>
                            trackServiceLineChange(opt as ServiceDropdownItem[])
                        }
                        placeholder="Any"
                        isMulti={true}
                        isSearchable={false}
                        sx={{ mb: -24 }}
                    />
                </Box>

                <Box
                    sx={{
                        width: ['100%', '50%'],
                        ml: [0, 4],
                        mt: [4, 0]
                    }}
                >
                    <Box>
                        <Flex
                            sx={{
                                width: '100%',
                                justifyContent: 'center',
                                textAlign: ['center', 'right'],
                                mt: 4,

                                display: 'inline-flex',
                                flexDirection: 'column'
                            }}
                        >
                            {numActiveFilters > 0 && (
                                <Link href="#" onClick={resetFilters}>
                                    Reset filters
                                </Link>
                            )}
                        </Flex>
                        <Flex
                            sx={{
                                width: '100%',
                                justifyContent: 'center',
                                textAlign: ['center', 'right'],
                                mt: 4,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <RecommendedAppointmentsFilter />
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}
