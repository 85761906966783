import { Box, Button, Text, Paragraph, Flex } from '@asktia/tia-ui'
import { useState } from 'react'
import { ReactComponent as IconCopy } from 'src/assets/icon-copy.svg'

export const ReferralCode = ({ code }: { code: string }) => {
    const [isCopied, setIsCopied] = useState(false)

    return (
        <Box>
            <Paragraph sx={{ textAlign: 'center' }}>
                Love Tia? <br /> Share your code with friends and they’ll get
                $50 off their annual membership!
            </Paragraph>
            <Button
                onClick={() => {
                    navigator.clipboard.writeText(code)
                    setIsCopied(true)
                }}
                sx={{
                    'bg': 'cardBackground',
                    'border': t => `1px solid ${t.colors?.inputOutline}`,
                    '&:hover': {
                        border: t => `1px solid ${t.colors?.inputOutlineHover}`
                    }
                }}
                fullWidth
            >
                <Flex sx={{ justifyContent: 'space-between', px: [4, 0] }}>
                    <IconCopy />
                    <Text sx={{ color: 'text' }}>{code}</Text>
                    <Text sx={{ color: 'primaryButton' }}>
                        {isCopied ? 'Copied!' : 'Copy'}
                    </Text>
                </Flex>
            </Button>
        </Box>
    )
}
