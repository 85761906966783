import { Card, Heading, Link, Paragraph } from '@asktia/tia-ui'
import { Bill } from './useBillingSummary'

export const NotCollectableMessage = () => {
    return (
        <Card variant="info" sx={{ p: 5, my: 4 }}>
            <Heading h4>We are finalizing your bill</Heading>
            <Paragraph sx={{ mb: 0 }}>
                We are currently finalizing the details of your bill. No action
                is required from you at this moment. Please check back in 2-5
                days.
            </Paragraph>
        </Card>
    )
}

export const NotRecentEnoughMessage = () => {
    return (
        <Card sx={{ p: 5, my: 4, color: 'secondary' }}>
            <Paragraph sx={{ mb: 0 }}>
                Only bills for services on <b>November 2, 2023 and later</b> are
                available in your portal. For assistance with older bills,
                please contact your{' '}
                <Link href="/r/chat/new" sx={{ fontSize: 3 }}>
                    Care Team
                </Link>
                .
            </Paragraph>
        </Card>
    )
}
