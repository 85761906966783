import {
    Button,
    Flex,
    Text,
    Form,
    Checkbox,
    Label,
    ErrorLabel,
    Box
} from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import DeclaredValuePill from '../../components/DeclaredValuePill'
import * as yup from 'yup'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
    Condition,
    Condition as TCondition,
    PatientOwnedClinicalStatus,
    useSaveCondition
} from './useSaveCondition'
import { SnomedYearField } from 'src/flows/questionnaires/Uhr/components/SnomedYearField'
import { useDeleteCondition } from './useDeleteCondition'

const ConditionSchema = yup
    .object({
        snomed: yup
            .object({
                text: yup.string().required('You must pick a condition'),
                value: yup.number().required('You must pick a condition')
            })
            .nullable(),
        year: yup.number().when('snomed', {
            is: (snomed: { text: string; value: number }) => snomed !== null,
            then: yup
                .number()
                .typeError('Year is a required field.')
                .required('Year is a required field.')
                .min(1900, 'The year field should be a valid year (ex: 2023)')
                .max(
                    new Date().getFullYear(),
                    'The year cannot be in the future'
                ),
            otherwise: yup.string()
        })
    })
    .required()

const getErrorMessage = (errors: any) => {
    return (
        errors?.snomed?.text?.message ||
        errors?.snomed?.value?.message ||
        errors?.year?.message
    )
}

type FormType =
    | {
          snomed: {
              text: string
              value: number
          } | null
          year: number
          none: boolean
      }
    | FieldValues

const ConditionsList: FC<{
    values: Condition[]
    onRemove: (condition: Condition) => void
}> = ({ values, onRemove }) => {
    return (
        <Flex
            sx={{
                mt: values.length > 0 ? 4 : undefined,
                width: '100%',
                flexWrap: 'wrap'
            }}
        >
            {values.map(condition => (
                <DeclaredValuePill
                    key={condition.uuid}
                    label={`${condition.code.text}, ${
                        condition.clinicalStatus === 'active'
                            ? condition.onset?.approximateYear
                            : condition.abatement?.approximateYear
                    }`}
                    onRemove={() => onRemove(condition)}
                />
            ))}
        </Flex>
    )
}

export const LoadingConditionForm = () => {
    const methods = useForm()
    return (
        <Form useFormMethods={methods} onSubmit={() => null} loading>
            <Label sx={{ color: 'secondary', mb: 4 }}>
                <Checkbox name="none" />
                None
            </Label>
            <SnomedYearField snomedPlaceholder="" yearPlaceholder="" />
            <Box
                sx={{
                    mt: 1,
                    mb: 0,
                    div: {
                        mt: 0,
                        mb: 0
                    }
                }}
            >
                &nbsp;
            </Box>
            <Button type="submit" disabled sx={{ maxWidth: 120, mt: 4 }}>
                <Flex
                    sx={{
                        justifyContent: 'space',
                        alignItems: 'center',
                        px: [6, 0]
                    }}
                >
                    <PlusIcon color={'inactiveText'} />
                    <Text sx={{ ml: 3 }}>Add</Text>
                </Flex>
            </Button>
        </Form>
    )
}

export const ConditionForm: FC<{
    hideYear?: boolean
    clinicalStatus: PatientOwnedClinicalStatus
    snomePlaceholder: string
    yearPlaceholder: string
    conditions: TCondition[]
    isNone?: boolean
    showUnsavedError: boolean
    setHasUnsavedWork: (unsavedWork: boolean) => void
    onChange: (isValid: boolean) => void
}> = props => {
    const [conditions, setConditions] = useState<TCondition[]>(
        props.conditions || []
    )
    const [showNoneError, setShowNoneError] = useState<boolean>(false)

    const formMethods = useForm<FormType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(ConditionSchema),
        defaultValues: {
            snomed: null,
            year: undefined,
            // this works because parent ensures we render once data is fetched
            none: !!props.isNone
        }
    })
    const saveCondition = useSaveCondition()
    const deleteCondition = useDeleteCondition()

    const removeCondition = (conditionToDelete: Condition) => {
        try {
            deleteCondition(conditionToDelete)
            setConditions(conditions =>
                conditions.filter(
                    condition => condition.uuid !== conditionToDelete.uuid
                )
            )
        } catch (e) {
            console.error(e)
        }
    }

    function showSaveError() {
        formMethods.setError('snomed.text', {
            message: "Couldn't save, try again"
        })
        formMethods.setError('year', {
            message: "Couldn't save, try again"
        })
    }

    async function onSubmit(values: FieldValues) {
        try {
            const condition = await saveCondition({
                label: values.snomed.text,
                snomedCode: values.snomed.value,
                year: values.year,
                clinicalStatus: props.clinicalStatus
            })

            if (condition) {
                setConditions(conditions => [...conditions, condition])
                formMethods.reset({
                    snomed: null,
                    year: null
                })
            } else {
                showSaveError()
            }
        } catch (e) {
            showSaveError()
        }
    }

    const { isValid, errors, isSubmitting, isDirty } = formMethods.formState

    const noneSelected = formMethods.watch('none')
    const conditionWatch = formMethods.watch('snomed')
    const addButtonDisabled = !isValid || noneSelected || !isDirty
    function onClickNone() {
        if (conditions.length > 0) {
            setShowNoneError(true)
        } else {
            // can't use formMethods.reset, have to keep none
            formMethods.setValue('snomed', null)
            formMethods.setValue('year', null)
            formMethods.clearErrors()
            setShowNoneError(false)
        }
    }

    useEffect(() => {
        props.onChange(noneSelected || conditions.length > 0)
    }, [conditions, noneSelected])

    useEffect(() => {
        props.setHasUnsavedWork(conditionWatch?.text)
    }, [conditionWatch])

    const shouldShowUnsavedError =
        props.showUnsavedError && conditionWatch?.text

    return (
        <>
            <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                <Label sx={{ color: 'secondary', mb: 4 }} onClick={onClickNone}>
                    <Checkbox name="none" disabled={conditions.length > 0} />I
                    don't have any medical conditions
                </Label>

                <ErrorLabel show={showNoneError}>
                    Please delete existing selections before checking none
                </ErrorLabel>

                <SnomedYearField
                    snomedPlaceholder={props.snomePlaceholder}
                    yearPlaceholder={props.yearPlaceholder}
                    disabled={noneSelected}
                    noOptionsMessage={() => <>Start typing to see conditions</>}
                />

                <Box
                    sx={{
                        mt: 1,
                        mb: 0,
                        div: {
                            mt: 0,
                            mb: 0
                        }
                    }}
                >
                    <ErrorLabel show={errors.snomed || errors.year}>
                        {getErrorMessage(errors)}
                    </ErrorLabel>
                    <ErrorLabel show={shouldShowUnsavedError}>
                        You have an unsaved condition, delete or save to
                        proceed.
                    </ErrorLabel>
                </Box>

                <Button
                    type="submit"
                    disabled={addButtonDisabled}
                    loading={isSubmitting}
                    sx={{ maxWidth: 120, mt: 4 }}
                >
                    <Flex
                        sx={{
                            justifyContent: 'space',
                            alignItems: 'center',
                            px: [6, 0]
                        }}
                    >
                        <PlusIcon
                            color={addButtonDisabled ? 'inactiveText' : 'white'}
                        />
                        <Text sx={{ ml: 3 }}>Save</Text>
                    </Flex>
                </Button>
            </Form>

            <ConditionsList values={conditions} onRemove={removeCondition} />
        </>
    )
}
