import {
    Button,
    Checkbox,
    Flex,
    Form,
    Grid,
    Heading,
    Label,
    Link,
    Paragraph,
    Text,
    TiaModal
} from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { ModalTitle } from 'src/components/Blocks'
import { ReactNode, useEffect } from 'react'
import { usePrevious } from 'src/hooks/usePrevious'
import { FilterModalProps } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'

type Option = {
    key: string
    label: string
    prompt?: string
}

type FormValues =
    | {
          selected_options: string[]
      }
    | FieldValues

type CheckboxFilterModalProps = {
    title: string
    description: string
    label: string
    options: Option[]
    footer?: ReactNode
    anyKey?: string
} & FilterModalProps<string[]>

const getDefaultSelectedOptions = (props: CheckboxFilterModalProps) => {
    if (!props.initialValue || props.initialValue.length === 0) {
        return props.anyKey ? [props.anyKey] : []
    }

    return props.initialValue
}

export const CheckboxFilterModal = (props: CheckboxFilterModalProps) => {
    const formMethods = useForm<FormValues>({
        defaultValues: {
            selected_options: getDefaultSelectedOptions(props)
        }
    })
    const prevSelectedOptions = usePrevious(
        formMethods.watch('selected_options')
    )

    const onSubmit = (formValues: FormValues) => {
        const selected: string[] = formValues.selected_options

        if (props.anyKey && selected.includes(props.anyKey)) {
            props.onSelected([])
        } else {
            props.onSelected(selected)
        }

        props.hideModal()
    }

    const clearSelections = () => {
        props.onClearSelections()
        props.hideModal()
    }

    useEffect(() => {
        const selectedOptions = formMethods.watch(
            'selected_options'
        ) as string[]
        if (!props.anyKey || !prevSelectedOptions) {
            return
        }

        if (
            !prevSelectedOptions.includes(props.anyKey) &&
            selectedOptions.includes(props.anyKey)
        ) {
            formMethods.reset({
                selected_options: [props.anyKey]
            })
        } else if (
            prevSelectedOptions.includes(props.anyKey) &&
            selectedOptions.length > 1
        ) {
            formMethods.reset({
                selected_options: selectedOptions.filter(
                    key => key !== props.anyKey
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.watch('selected_options')])

    return (
        <TiaModal>
            <ModalTitle
                sx={{ mb: 4 }}
                title={
                    <Heading h2 sx={{ m: 0, p: 0 }}>
                        {props.title}
                    </Heading>
                }
                hideModal={props.hideModal}
            />

            <Paragraph sx={{ mb: 4 }}>{props.description}</Paragraph>

            <Paragraph sx={{ fontSize: 2, mb: 5 }}>{props.label}</Paragraph>

            <Form
                useFormMethods={formMethods}
                onSubmit={onSubmit}
                sx={{ alignItems: 'left' }}
            >
                <Flex sx={{ mb: 6, flexDirection: 'column', gap: 3 }}>
                    {props.options.map(option => (
                        <Grid
                            key={option.key}
                            sx={{
                                gridRowGap: 0,
                                gridColumnsGap: 3,
                                gridTemplateColumns: '40px auto',
                                grid: 'none'
                            }}
                        >
                            <Label
                                sx={
                                    option.prompt
                                        ? { alignItems: 'center', mb: 0 }
                                        : { alignItems: 'center' }
                                }
                            >
                                <Checkbox
                                    name="selected_options"
                                    value={option.key}
                                    pretty
                                />
                                <Heading h4 sx={{ m: 0 }}>
                                    {option.label}
                                </Heading>
                            </Label>
                            {option.prompt && (
                                <Label sx={{ pl: '56px' }}>
                                    {option.prompt}
                                </Label>
                            )}
                        </Grid>
                    ))}
                </Flex>
                <Grid
                    sx={{
                        alignItems: 'center',
                        gap: 5,
                        justifyItems: 'center'
                    }}
                >
                    <Button fullWidth type="submit">
                        Apply Filter
                    </Button>
                    <Link
                        onClick={clearSelections}
                        href="#"
                        sx={{ fontSize: 2, color: 'text' }}
                    >
                        Clear selections
                    </Link>
                    {props.footer && (
                        <Text sx={{ fontSize: 2, color: 'supportText' }}>
                            {props.footer}
                        </Text>
                    )}
                </Grid>
            </Form>
        </TiaModal>
    )
}
