import { FC } from 'react'
import { Card, Flex, Heading, Text, Image, Button, Link } from '@asktia/tia-ui'
import { format } from 'date-fns'
import { AppointmentSuggestion } from 'src/types'
import { BOOKING_STEPS } from 'src/flows/AppointmentBooking/useBookingFlow'
import { generatePath } from 'react-router-dom'

export const AppointmentCard: FC<AppointmentSuggestion> = ({
    uuid,
    appointmentProfileLabel,
    appointmentProfileDescription,
    appointmentProfileImageUrl,
    appointmentProfileUuid,
    params
}) => {
    const getBookLink = () => {
        const basePath = `/r/${BOOKING_STEPS.timeSelection.path}`

        const path = generatePath(basePath, {
            appointmentProfileUuid: appointmentProfileUuid
        })

        return `${path}?context=${uuid}`
    }

    return (
        <Card>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    mb: 4
                }}
            >
                <Flex
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Heading h4 sx={{ mb: 0 }}>
                        {appointmentProfileLabel}
                    </Heading>
                    {params?.startDate && (
                        <Text
                            sx={{
                                fontSize: 0,
                                color: 'supportText'
                            }}
                        >
                            Complete on or around{' '}
                            <Text as="span" sx={{ fontWeight: 700 }}>
                                {format(params.startDate, 'eee, MMM. d')}
                            </Text>
                        </Text>
                    )}
                </Flex>
                <Image
                    src={appointmentProfileImageUrl}
                    sx={{ width: 56, height: 56 }}
                />
            </Flex>

            <Text>{appointmentProfileDescription}</Text>

            <Button as={Link} fullWidth sx={{ mt: 4 }} href={getBookLink()}>
                Book now
            </Button>
        </Card>
    )
}
