import { useLocation } from 'react-router'
import { HealthRecordEntry } from 'src/types'

export function useHealthRecordInfo(records: HealthRecordEntry[]) {
    const location = useLocation()

    const uhrLocation = location.pathname.includes('health-record')
        ? 'Profile'
        : location.pathname.includes('prepare')
        ? 'Post Booking'
        : 'ACI'

    let completed = 0
    records.forEach(r => {
        if (r.status === 'done') {
            completed += 1
        }
    })
    const hRPC =
        completed > 0
            ? Number(((completed / records.length) * 100).toFixed(2))
            : 0

    return {
        uhrLocation,
        hRPC
    }
}
