import {
    Button,
    Flex,
    Text,
    Form,
    Checkbox,
    Label,
    ErrorLabel,
    Box
} from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import * as yup from 'yup'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
    GeneticMutation,
    GeneticMutationsTypeahead
} from './GeneticMutationsTypeahead'
import DeclaredValuePill from 'src/flows/questionnaires/Uhr/components/DeclaredValuePill'
import {
    PatientGeneticMutation,
    useGeneticMutation
} from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/useGeneticMutation'

const GeneticMutationSchema = yup
    .object({
        geneticMutation: yup
            .object({
                display: yup.string(),
                id: yup.number()
            })
            .nullable()
    })
    .required()

type FormType =
    | {
          geneticMutation: GeneticMutation | null
          none: boolean
      }
    | FieldValues

const GeneticMutationsList: FC<{
    values: PatientGeneticMutation[]
    onRemove: (geneticMutation: PatientGeneticMutation) => void
}> = ({ values, onRemove }) => {
    return (
        <Flex
            sx={{
                mt: values.length > 0 ? 4 : undefined,
                width: '100%',
                flexWrap: 'wrap'
            }}
        >
            {values.map(geneticMutation => (
                <DeclaredValuePill
                    key={geneticMutation.uuid}
                    label={geneticMutation.display}
                    onRemove={() => onRemove(geneticMutation)}
                />
            ))}
        </Flex>
    )
}

export const GeneticMutationForm: FC<{
    showUnsavedError: boolean
    onChange: (isValid: boolean) => void
    setHasUnsavedWork: (unsavedWork: boolean) => void
}> = props => {
    const {
        data,
        create: createGeneticMutation,
        update: patchGeneticMutation
    } = useGeneticMutation()

    const { geneticMutations, emptyReason } = data

    const [showNoneError, setShowNoneError] = useState<boolean>(false)

    const formMethods = useForm<FormType>({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(GeneticMutationSchema),
        defaultValues: {
            geneticMutation: null,
            // this works because parent ensures we render once data is fetched
            none: emptyReason === 'nilknown'
        }
    })

    const removeGeneticMutation = async ({ uuid }: PatientGeneticMutation) =>
        await patchGeneticMutation({
            uuid,
            verificationStatus: 'entered-in-error'
        })

    function showSaveError() {
        formMethods.setError('geneticMutation', {
            message: "Couldn't save, try again"
        })
    }

    async function onSubmit(values: FieldValues) {
        try {
            const geneticMutation = await createGeneticMutation(
                values.geneticMutation
            )

            if (geneticMutation) {
                formMethods.reset({ geneticMutation: null })
            } else {
                showSaveError()
            }
        } catch (e) {
            showSaveError()
        }
    }

    const { isValid, errors, isSubmitting, isDirty } = formMethods.formState
    const noneSelected = formMethods.watch('none')
    const watchGeneticMutation = formMethods.watch('geneticMutation')
    const addButtonDisabled = !isValid || noneSelected || !isDirty

    function onClickNone() {
        if (geneticMutations.length > 0) {
            setShowNoneError(true)
        } else {
            // can't use formMethods.reset, have to keep none
            formMethods.setValue('geneticMutation', null)
            formMethods.clearErrors()
            setShowNoneError(false)
        }
    }

    useEffect(() => {
        props.onChange(noneSelected || geneticMutations.length > 0)
    }, [geneticMutations, noneSelected])

    useEffect(() => {
        props.setHasUnsavedWork(watchGeneticMutation)
    }, [watchGeneticMutation])

    const shouldShowUnsavedError =
        props.showUnsavedError && watchGeneticMutation

    return (
        <>
            <Form useFormMethods={formMethods} onSubmit={onSubmit}>
                <Label sx={{ color: 'secondary', mb: 4 }} onClick={onClickNone}>
                    <Checkbox
                        name="none"
                        disabled={geneticMutations.length > 0}
                    />
                    None
                </Label>

                <Box
                    sx={{
                        mt: 0,
                        mb: 4,

                        div: {
                            mt: 0,
                            mb: 0
                        }
                    }}
                >
                    <ErrorLabel show={showNoneError}>
                        Please delete existing selections before checking none
                    </ErrorLabel>
                </Box>

                <GeneticMutationsTypeahead disabled={noneSelected} />

                <ErrorLabel show={shouldShowUnsavedError}>
                    You have an unsaved genetic mutation, delete or save to
                    proceed.
                </ErrorLabel>

                <Button
                    type="submit"
                    disabled={addButtonDisabled}
                    loading={isSubmitting}
                    sx={{ maxWidth: 120, mt: -1 }}
                >
                    <Flex
                        sx={{
                            justifyContent: 'space',
                            alignItems: 'center',
                            px: [6, 0]
                        }}
                    >
                        <PlusIcon
                            color={addButtonDisabled ? 'inactiveText' : 'white'}
                        />
                        <Text sx={{ ml: 3 }}>Save</Text>
                    </Flex>
                </Button>
            </Form>

            <GeneticMutationsList
                values={geneticMutations}
                onRemove={removeGeneticMutation}
            />
        </>
    )
}
