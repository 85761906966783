import {
    Flex,
    Heading,
    Text,
    Box,
    TiaModal,
    Button,
    Link
} from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import { useDismissApptSuggestion } from 'src/hooks/useDismissApptSuggestion'
import { AppointmentSuggestion } from 'src/types'
import { useAmpli } from 'src/hooks/useAmpli'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { useNavigation, useUserInfo } from 'src/hooks'
import PreviewButton from 'src/components/Preview/PreviewButton'

import { Paragraph } from '@asktia/tia-ui'
import { ReactNode, useEffect } from 'react'
import { CheckmarkIcon } from 'src/components/Blocks/Icons'
import { redirect } from 'react-router'

export const TiaBenefits = ({ children }: { children: ReactNode }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                textAlign: 'left',
                mt: 2
            }}
        >
            <Box
                sx={{
                    minWidth: 5,
                    height: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: 3
                }}
            >
                <CheckmarkIcon color="supportText" />
            </Box>
            <Text
                sx={{
                    pl: 2,
                    lineHeight: '20px',
                    fontFamily: 'body',
                    color: 'supportText',
                    fontSize: 3
                }}
            >
                {children}
            </Text>
        </Box>
    )
}

export const PreviewExplanation = (props: {
    hideModal?: Function
    dontShowAppointmentsLink?: boolean
    showWhatsMissing?: boolean
    redirectUrl?: string
}) => {
    const mutate = useDismissApptSuggestion()

    const { resumeSignupBookAppointmentModalRendered } = useAmpli()
    const { user } = useUserInfo()
    const { navigate } = useNavigation()

    useEffect(() => {
        resumeSignupBookAppointmentModalRendered()
    }, [])

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4
                }}
            >
                {props.redirectUrl && (
                    <Heading h2 sx={{ m: 0 }}>
                        Ready to book this appointment?
                    </Heading>
                )}
                {!props.redirectUrl && (
                    <Heading h2 sx={{ m: 0 }}>
                        Become a Member for Full Portal Access
                    </Heading>
                )}
                {props.hideModal && (
                    <Button
                        variant="unstyled"
                        //@ts-ignore
                        onClick={() => props.hideModal()}
                    >
                        <CloseIcon color="text" />
                    </Button>
                )}
            </Flex>

            <Box sx={{ mb: 6 }}>
                <Box sx={{ mb: 4 }}>
                    <Text
                        sx={{
                            fontFamily: 'body',
                            fontSize: 4
                        }}
                    >
                        {props.redirectUrl
                            ? 'Join Tia’s membership to finish booking this appointment and get the care you deserve.'
                            : 'Join Tia’s membership to get full access to your member portal'}
                    </Text>
                </Box>

                <Text>Tia members get access to:</Text>
                <TiaBenefits>
                    Expert providers specializing in women’s care
                </TiaBenefits>
                <TiaBenefits>
                    A personalized care plan tailored to your treatment needs
                </TiaBenefits>
                <TiaBenefits>
                    Unlimited messaging with your Care Team
                </TiaBenefits>
                <TiaBenefits>
                    Access to appointment history and lab results
                </TiaBenefits>
            </Box>

            <PreviewButton />
            {!props.dontShowAppointmentsLink && (
                <>
                    <Box sx={{ mb: 6 }}>
                        <Text
                            sx={{
                                mb: 1,
                                fontFamily: 'body',
                                fontSize: 4
                            }}
                        >
                            Are you ready to get care from Tia?
                        </Text>
                        <br></br>
                        <Link
                            sx={{
                                fontFamily: 'body',
                                fontSize: 4
                            }}
                            as={RouterLink}
                            // @ts-ignore: TS doesn't understand this is a RouterLink now
                            to={`/appointments`}
                            // sx={{ fontWeight: 1000, fontSize: 5 }}
                        >
                            Browse our appointments
                        </Link>{' '}
                        <Text
                            sx={{
                                mb: 1,
                                fontFamily: 'body',
                                fontSize: 4
                            }}
                        >
                            to get the care you deserve.
                        </Text>
                    </Box>
                </>
            )}
        </TiaModal>
    )
}
