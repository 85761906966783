import { LayoutGrid, Container, Paragraph, Link, Heading } from '@asktia/tia-ui'
import { FC } from 'react'
import { PageHeader } from 'src/components/PageHeader'
import { BackButton } from 'src/components/Blocks/BackButton'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { Body } from 'src/components/Blocks/Body'
import { RecommendedActionsButton } from 'src/components/RecommendedActionsButton'
import { useOfferings } from 'src/hooks/useOfferings'
import { PREVENTATIVE_CARE_APPOINTMENTS } from 'src/globals'
import { BookingCard } from 'src/flows/AppointmentBooking/BookingCard'
import { Link as RouterLink } from 'react-router-dom'
import { useAmpli } from 'src/hooks'

export const BookPreventativeCare: FC = () => {
    const { bookATwheCtaClickedFromAnnualExplanation } = useAmpli()
    const { offerings, isLoading: offeringsLoading } = useOfferings(
        PREVENTATIVE_CARE_APPOINTMENTS
    )

    const twhe = offerings?.find(appt => appt.name === 'tia_whole_health_exam')
    const annualExam = offerings?.find(
        appt => appt.name === 'annual_preventative_exam'
    )

    function bookTwhe() {
        bookATwheCtaClickedFromAnnualExplanation()
    }

    return (
        <Body>
            <PageHeader mobileBack={<BackButton href="/" variant="white" />} />
            <NavBarHeading titleAlign="left" href="/" showBackButton>
                Ok, you need an annual exam. We've got you, and you have
                options.
            </NavBarHeading>

            <Container>
                <LayoutGrid columns="one" variant="condensed">
                    {annualExam && (
                        <BookingCard
                            appointmentProfile={annualExam}
                            sx={{ borderBottomColor: 'transparent' }}
                        />
                    )}
                    <Paragraph
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            // need this because BookingCard doesn't let us control container margins
                            mt: t => `-${t.sizes?.[1]}px`
                        }}
                    >
                        Please note: this is an in-clinic only visit and
                        therefore availability may be limited. This appointment
                        is most often covered 100% by in-network insurance as
                        long as you haven't already had your annual or
                        gynecological exam this year, but co-pays, co-insurance,
                        or deductibles may apply. Labs and imaging may incur
                        additional costs.
                    </Paragraph>
                    <Paragraph
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            mb: 6
                        }}
                    >
                        If you need urgent assistance, or to address a specific
                        health concern, please choose the{' '}
                        <Link
                            as={RouterLink}
                            // @ts-ignore: TS doesn't understand this is a RouterLink now
                            to="/appointments"
                            sx={{ fontSize: 0, color: 'supportText' }}
                        >
                            "I need help with a health concern"
                        </Link>{' '}
                        option instead.
                    </Paragraph>

                    <Heading h3>
                        Or, if you feel like you need a deep dive on your
                        health:
                    </Heading>
                    <RecommendedActionsButton
                        selected
                        apptLink={`/book-appointment/profile/${twhe?.appointmentProfileUuid}/time-selection`}
                        loading={offeringsLoading}
                        onClick={bookTwhe}
                    >
                        Book a Tia Whole Health Exam
                    </RecommendedActionsButton>
                    <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
                        The Tia Whole Health Exam is designed to help new Tia
                        members best establish their care at Tia. To get another
                        comprehensive look at your health, you're welcome to
                        book this appointment again.
                    </Paragraph>
                </LayoutGrid>
            </Container>
        </Body>
    )
}
