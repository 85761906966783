import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { Condition, PatientOwnedClinicalStatus } from './useSaveCondition'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'
import { ConditionConfirmation } from './useConditionConfirmationMutation'

const getConditions = async ({
    patientId,
    clinicalStatus
}: {
    patientId: number
    clinicalStatus: PatientOwnedClinicalStatus
}) => {
    return fetcher(
        `${BASE_API_URL}/uhr-api/conditions/patient-owned-conditions?patientId=${patientId}&clinicalStatus=${clinicalStatus}`,
        undefined,
        'GET'
    )
}

type ConditionsResponse = {
    conditions: Condition[]
    emptyReason?: 'nilknown' | 'notasked'
    latestPatientConditionConfirmation?: ConditionConfirmation
}

export const useInactiveConditionsQuery = (patientId?: number) => {
    const result = useQuery<ConditionsResponse>(
        ['inactive-conditions', patientId],
        () =>
            getConditions({
                patientId: patientId!,
                clinicalStatus: 'inactive'
            }),
        {
            enabled: !!patientId
        }
    )

    if (result?.data?.latestPatientConditionConfirmation) {
        result.data.latestPatientConditionConfirmation.createdAt = new Date(
            result.data.latestPatientConditionConfirmation.createdAt
        )
        result.data.latestPatientConditionConfirmation.lastConfirmedAt =
            new Date(
                result.data.latestPatientConditionConfirmation.lastConfirmedAt
            )
    }

    return result
}

export const useGetInactiveConditions = () => {
    const { user, isLoading: userIsLoading } = useUserInfo()
    const query = useInactiveConditionsQuery(user?.id)

    return {
        isLoading: query.isLoading || userIsLoading,
        data: query.data || { conditions: [] }
    }
}

export const useActiveConditionsQuery = (patientId?: number) => {
    const result = useQuery<ConditionsResponse>(
        ['active-conditions', patientId],
        () =>
            getConditions({
                patientId: patientId!,
                clinicalStatus: 'active'
            }),
        {
            enabled: !!patientId
        }
    )

    if (result?.data?.latestPatientConditionConfirmation) {
        result.data.latestPatientConditionConfirmation.createdAt = new Date(
            result.data.latestPatientConditionConfirmation.createdAt
        )
        result.data.latestPatientConditionConfirmation.lastConfirmedAt =
            new Date(
                result.data.latestPatientConditionConfirmation.lastConfirmedAt
            )
    }
    return result
}

export const useGetActiveConditions = () => {
    const { user } = useUserInfo()
    const query = useActiveConditionsQuery(user?.id)

    return {
        isLoading: query.isLoading,
        data: query.data || { conditions: [] }
    }
}
