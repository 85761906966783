import {
    Box,
    Flex,
    Heading,
    ThemeStyleObject,
    Text,
    Card
} from '@asktia/tia-ui'
import { format } from 'date-fns'
import { FC } from 'react'
import { AppointmentV1 } from 'src/types'

const cancelledStatuses = ['Cancelled', 'Cancelled Late']

const getLocationText = (appointment: AppointmentV1) => {
    if (cancelledStatuses.includes(appointment.status)) {
        return appointment.status
    }

    const providerName =
        appointment.providerGivenName && appointment.providerFamilyName
            ? `${appointment.providerGivenName} ${appointment.providerFamilyName}`
            : null

    if (!providerName) {
        return appointment.isVirtual ? 'Virtual' : 'In Clinic'
    }

    return appointment.isVirtual
        ? `Virtual with ${providerName}`
        : `In Clinic with ${providerName}`
}

export interface AppointmentHistoryRowProps {
    appointment: AppointmentV1
    sx?: ThemeStyleObject
}

export const AppointmentHistoryRow: FC<AppointmentHistoryRowProps> = ({
    appointment,
    sx
}) => {
    const cancelled = cancelledStatuses.includes(appointment.status)

    return (
        <Card
            sx={{
                px: 5,
                py: 4,
                mb: 4,
                fontSize: 2,
                alignItems: 'center',
                borderBottom: t => `1px solid ${t.colors?.cardOutline}`
            }}
        >
            <Text
                sx={{
                    fontSize: 0,
                    color: cancelled ? 'inactiveText' : 'supportText',
                    mb: 1
                }}
            >
                {format(appointment.scheduledTime, 'MMM dd, yyyy')},{' '}
                {format(appointment.scheduledTime, 'hh:mmaaa')}
            </Text>
            <Heading
                variant="h4"
                sx={{
                    color: cancelled ? 'inactiveText' : 'text',
                    mb: 1
                }}
            >
                {appointment.label}
            </Heading>
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Text
                    sx={{
                        fontSize: 0,
                        color: cancelled ? 'inactiveText' : 'supportText'
                    }}
                >
                    {getLocationText(appointment)}
                </Text>
            </Flex>
        </Card>
    )
}
