import { Spinner } from '@asktia/tia-ui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigation } from 'src/hooks'
import { useAppointment } from 'src/hooks/useAppointment'

export const AppointmentRedirect = () => {
    const { appointmentUuid } = useParams<{
        appointmentUuid: string
    }>()
    const { appointment } = useAppointment(appointmentUuid)
    const { navigate } = useNavigation()

    // Redirect guard because we render during page transition
    const [redirected, setRedirected] = useState(false)

    useEffect(() => {
        if (!redirected) {
            if (appointment?.status === 'Finding Provider') {
                navigate(
                    `/automated-checkin/${appointmentUuid}/completed`,
                    0,
                    true
                )
            } else {
                navigate(
                    `/automated-checkin/continue/${appointmentUuid}`,
                    0,
                    true
                )
            }

            setRedirected(true)
        }
    }, [redirected, appointmentUuid, navigate])

    return <Spinner />
}
