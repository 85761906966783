import { useEffect, useState } from 'react'
import {
    Container,
    Text,
    Box,
    Spinner,
    Grid,
    Heading,
    Blocks,
    LayoutGrid
} from '@asktia/tia-ui'
import { useCarePlan, useLastSeenCarePlan } from 'src/hooks/useCarePlan'
import { Intervention } from 'src/types'
import { useAmpli } from 'src/hooks'
import { Body } from 'src/components/Blocks/Body'
import { PageHeader } from 'src/components/PageHeader'
import { BackButton } from 'src/components/Blocks/BackButton'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { InterventionCard } from './InterventionCard'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { SuggestionItem } from '../Home/ActionsToComplete/SuggestionItem'
import { groupBy } from 'lodash'

// sets the latest care plan as seen on load
function useMarkCarePlanSeen(interventions: Intervention[] | undefined) {
    const [lastSeenCarePlan, setLastSeenCarePlan] = useLastSeenCarePlan()

    // The LAST seen date, we need it to have the previous date since we are going to update it right away
    const [lastSeenBeforeUpdate] = useState(new Date(lastSeenCarePlan))

    let timestamp: string | null = null
    const sortedInterventions = interventions?.sort(
        (a: Intervention, b: Intervention) =>
            new Date(b.originTimestamp).getTime() -
            new Date(a.originTimestamp).getTime()
    )

    if (sortedInterventions && sortedInterventions.length) {
        const timestamps = sortedInterventions.map(
            intervention => intervention.originTimestamp
        )

        timestamp = timestamps[0]
    }

    useEffect(() => {
        if (timestamp) {
            setLastSeenCarePlan(timestamp)
        }
    }, [timestamp, setLastSeenCarePlan, lastSeenCarePlan])

    return [lastSeenBeforeUpdate]
}

export const Plan = () => {
    const { carePlanViewed } = useAmpli()

    useEffect(() => carePlanViewed(), [carePlanViewed])

    let { isLoading, data: interventions = [] } = useCarePlan()
    const [lastSeen] = useMarkCarePlanSeen(interventions)

    // Removing tiaAppointments since they are already appearing on suggestions
    interventions = interventions.filter(
        x => x.carePlanIntervention.type !== 'tiaAppointment'
    )

    const groupedInterventions = groupBy(
        interventions,
        (x: Intervention) => x.carePlanIntervention?.patientCommunication?.label
    )

    const labels = [
        ...new Set(
            interventions
                .map(x => x.carePlanIntervention.patientCommunication)
                .sort((a, b) => a.priority - b.priority)
                .map(x => x.label)
        )
    ]

    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })

    return (
        <Body>
            <PageHeader
                mobileBack={<BackButton href="/your-care" variant="white" />}
            />
            <NavBarHeading href="/your-care">Your Care Plan</NavBarHeading>

            {appointmentSuggestions.length > 0 && (
                <Box
                    sx={{
                        bg: 'secondaryBackground',
                        pt: [6, 8],
                        // custom value because this step isn't in our scale
                        pb: [46, 96],
                        mt: 4,
                        mb: 6,
                        position: 'relative'
                    }}
                >
                    <Blocks.Wave
                        position="top"
                        fill="secondaryBackground"
                        sx={{
                            backgroundColor: 'mainBackground'
                        }}
                    />
                    <Container>
                        <Heading h2>Recommended appointments</Heading>
                        <Grid gap={4} columns={['1fr', '1fr 1fr 1fr']} pt="4">
                            {appointmentSuggestions.map(suggestion => (
                                <SuggestionItem {...suggestion} />
                            ))}
                        </Grid>
                    </Container>
                    <Blocks.Wave
                        position="bottom"
                        fill="mainBackground"
                        sx={{
                            backgroundColor: 'secondaryBackground',
                            transform: 'scaleX(-1)'
                        }}
                    />
                </Box>
            )}

            <Container sx={{ my: 6 }}>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <LayoutGrid columns="one">
                        {interventions.length > 0 &&
                            labels.map((label, index) => {
                                const currentGroup = groupedInterventions[
                                    label
                                ].sort(
                                    (a: Intervention, b: Intervention) =>
                                        new Date(b.originTimestamp).getTime() -
                                        new Date(a.originTimestamp).getTime()
                                )

                                return (
                                    <>
                                        <Heading
                                            h2
                                            sx={{
                                                mt: index > 0 ? 2 : undefined
                                            }}
                                        >
                                            {label}
                                        </Heading>

                                        {currentGroup?.map(
                                            (intervention: Intervention) => (
                                                <InterventionCard
                                                    intervention={intervention}
                                                    lastSeen={lastSeen}
                                                />
                                            )
                                        )}
                                    </>
                                )
                            })}
                    </LayoutGrid>
                )}
            </Container>
        </Body>
    )
}
