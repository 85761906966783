import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Paragraph,
    TiaModal,
    Label,
    ErrorLabel
} from '@asktia/tia-ui'
import { addMonths, isToday } from 'date-fns'
import { uniq } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import {
    ModalityType,
    useJoinWaitList
} from 'src/components/hooks/JoinWaitListHooks'
import { useAvailableSlotsFilter } from 'src/flows/AppointmentBooking/views/BookingTimeSelection/AvailableSlotsFilter'
import calendarStyles from 'src/flows/AppointmentBooking/views/BookingTimeSelection/FilterModals/styles'
import { useUserInfo } from 'src/hooks'
import { AppointmentProfile, Clinic } from 'src/types'
import { CloseIcon, ChevronLeft, ChevronRight } from './Blocks/Icons'

const JoinWaitlistModal = ({
    appointmentProfile,
    hideModal
}: {
    appointmentProfile: AppointmentProfile
    hideModal: () => void
}) => {
    const { user } = useUserInfo()
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const { joinWaitList, isError, isSuccess } = useJoinWaitList()
    const { filterValues } = useAvailableSlotsFilter()

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [])

    const [date, setDate] = useState<Date>(new Date())
    const [dateSelected, setDateSelected] = useState<Date>(new Date())
    const today = new Date()
    const maxDate = addMonths(today, 3)

    // This should never be an array of Dates, but technically
    // the Calendar component allows it, and we can handle the
    // case simply by recursing with the first date
    const onChange = (incoming: Date | Date[]) => {
        if (Array.isArray(incoming)) {
            onChange(incoming[0])
        } else {
            if (incoming > today && incoming < maxDate) {
                setDate(incoming)
            } else {
                setDate(new Date())
            }
        }
    }

    const getModality = (): ModalityType => {
        const selectedClinicsUuids = filterValues.clinicUuids as string[]
        let selectedClinics: Clinic[] = appointmentProfile.clinicsWithOffering

        if (selectedClinicsUuids.length > 0) {
            selectedClinics = appointmentProfile.clinicsWithOffering.filter(
                clinic =>
                    selectedClinicsUuids.some(
                        clinicUuid => clinicUuid === clinic.uuid
                    )
            )
        }

        const clinics = uniq(
            selectedClinics.map(offering => offering.isVirtual)
        )

        let modality: ModalityType = 'clinic or virtual'
        if (clinics.length === 1) {
            const isVirtual = clinics[0]
            if (isVirtual) {
                modality = 'virtual'
            } else {
                modality = 'clinic'
            }
        }
        return modality
    }

    const selectDate = async () => {
        const modality = getModality()

        await joinWaitList({
            patientId: user!.id,
            date,
            appointmentProfileUuid: appointmentProfile.appointmentProfileUuid,
            modality
        })

        setDateSelected(date)
    }

    // We disallow selection of days before today and after three
    // months from today.  This function is used for that filter.
    const isOutsideWindow = () => {
        return (date < today && !isToday(date)) || date > maxDate
    }

    // Sets special classname on calendar days.  This is used to
    // visually "disable" days in the past, and put the ring around
    // today, via CSS.
    const tileClassName = ({ date }: { date: Date }): string => {
        if (isToday(date)) {
            return 'today'
        } else if (date < today || date > maxDate) {
            return 'outsideWindow'
        }

        return ''
    }

    return (
        <TiaModal>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 5
                }}
            >
                <Heading h2 sx={{ m: 0 }}>
                    Join the waitlist!
                </Heading>
                <Box onClick={() => hideModal()} sx={{ cursor: 'pointer' }}>
                    <CloseIcon color="inactiveText" hoverColor="text" />
                </Box>
            </Flex>
            <Paragraph sx={{ mb: 5 }}>
                Select the date you're interested in booking. We will send you a
                message if more times become available.
            </Paragraph>
            <Container sx={calendarStyles}>
                <Calendar
                    onChange={onChange}
                    value={date}
                    prevLabel={<ChevronLeft color="text" />}
                    nextLabel={<ChevronRight color="text" />}
                    prev2Label={null}
                    next2Label={null}
                    calendarType={'US'}
                    showNeighboringMonth={false}
                    view={'month'}
                    tileClassName={tileClassName}
                    minDate={new Date()}
                    maxDate={maxDate}
                ></Calendar>
            </Container>
            <Flex
                sx={{
                    my: 5,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 0
                }}
            >
                <Button
                    fullWidth
                    onClick={selectDate}
                    disabled={isOutsideWindow()}
                >
                    Add me to the list!
                </Button>
                {isSuccess && (
                    <Label
                        sx={{
                            mt: 4,
                            mb: 0,
                            justifyContent: 'center',
                            fontSize: 0
                        }}
                    >
                        {`You've been added to the waitlist for ${dateSelected.toLocaleDateString()}.`}
                    </Label>
                )}
                {isError && (
                    <Box
                        sx={{
                            mt: 4,
                            mb: 0,

                            div: {
                                mt: 0,
                                mb: 0
                            }
                        }}
                    >
                        <ErrorLabel show={isError}>
                            Something went wrong. Please try again.
                        </ErrorLabel>
                    </Box>
                )}
            </Flex>
        </TiaModal>
    )
}

export default JoinWaitlistModal
