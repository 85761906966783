import { FC } from 'react'
import { NameInitialsAvatar } from 'react-name-initials-avatar'
import { Image, Box, ThemeStyleObject } from '@asktia/tia-ui'

export const Avatar: FC<{
    url?: string
    userName: string
    size?: string
    sx?: ThemeStyleObject
    imageSx?: ThemeStyleObject
    nameSx?: { bgColor?: string; textColor?: string }
}> = ({ url, userName, size = '32px', sx, imageSx, nameSx }) => {
    return (
        <Box sx={{ minWidth: size, ...sx }}>
            {url ? (
                <Image
                    src={url}
                    alt="avatar image"
                    sx={{
                        width: size,
                        height: size,
                        borderRadius: 99999,
                        ...imageSx
                    }}
                />
            ) : (
                <NameInitialsAvatar
                    name={userName}
                    bgColor={nameSx?.bgColor || '#831A4A'}
                    textColor={nameSx?.textColor || '#FFF'}
                    borderStyle="hidden"
                    size={size}
                />
            )}
        </Box>
    )
}
