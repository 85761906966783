import { useCallback, useEffect } from 'react'

/**
 * This is useful when you are facing issues with cache (specially on safari),
 * usually this does happen when you hit the back button and your page does not update, ending up in a bad state
 *
 * More info: https://stackoverflow.com/questions/33860241/safari-back-button-doesnt-reload-page-when-used
 */
export const useAvoidCaching = () => {
    const reloadOnCache = useCallback((evt: PageTransitionEvent) => {
        if (evt.persisted) {
            setTimeout(function () {
                window.location.reload()
            }, 10)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('pageshow', reloadOnCache, false)

        return () =>
            window.removeEventListener('pageshow', reloadOnCache, false)
    }, [])
}
