import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'
import { fetcher } from 'src/fetcher'
import { useQuery } from 'react-query'

export function useGetPersonalWellnessCompletion(): {
    isLoading: boolean
    personalWellnessCompletion: number
} {
    const { user } = useUserInfo()

    const { data, isLoading } = useQuery<{ percentage: number }>(
        ['personal-wellness', 'completion', user?.id],
        () =>
            fetcher(
                `${BASE_API_URL}/tmd-api/patients/personal-wellness-completion/${user?.id}?apiVersion=v2`,
                undefined,
                'GET'
            ),
        { enabled: !!user }
    )

    return {
        isLoading,
        personalWellnessCompletion: data?.percentage || 0
    }
}
