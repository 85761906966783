import { FC } from 'react'
import OtpInput from 'react18-input-otp'
import { useThemeUI } from 'theme-ui'

export interface IControlledOtpInput {
    value: string

    hasError?: boolean

    onChange: (value: string) => void
}

export const ControlledOtpInput: FC<IControlledOtpInput> = ({
    value,
    hasError,
    onChange
}) => {
    const {
        theme: { fonts, colors, radii, fontSizes }
    } = useThemeUI()

    return (
        <OtpInput
            value={value}
            onChange={onChange}
            hasErrored={hasError}
            isInputNum={true}
            shouldAutoFocus={true}
            numInputs={6}
            inputStyle={{
                // @ts-ignore Typescript just didn't understand our fonts :/
                fontFamily: fonts && fonts.body,
                fontSize: fontSizes && fontSizes[7],
                width: '100%',
                minWidth: 52,
                maxWidth: 62,
                height: 68,
                borderRadius: radii && radii[2],
                border: '1px solid',
                borderColor: colors?.inputOutline,
                color: colors?.text
            }}
            focusStyle={{
                outline: 'none',
                borderColor: colors?.inputOutlineSelected
            }}
            errorStyle={{
                borderColor: colors?.inputOutlineError
            }}
            separator={<span>&nbsp;&nbsp;</span>}
        ></OtpInput>
    )
}
