import { useEffect } from 'react'
import { useParams } from 'react-router'
import { AppointmentWithLocationDetails, SaveStepFunction } from 'src/types'
import bookedIllustration from 'src/assets/aci-booked-illustration.png'
import { BlueInterstitial } from 'src/flows/AutomatedCheckin/BlueInterstitial'
import { useAmpli } from 'src/hooks'
import { useShowNPSModalIfApplicable } from 'src/components/NPSModal'

type StartCheckInInfoProps = {
    /**
     * callback to move to next step
     */
    appointment: AppointmentWithLocationDetails
    saveStep?: SaveStepFunction
    isSaving: boolean
}

export const StartCheckInInfo = ({
    appointment,
    saveStep,
    isSaving
}: StartCheckInInfoProps) => {
    const { checkInNotStarted } = useAmpli()

    // Assume: won't route here if apptId undefined
    const { apptId = '' } = useParams<{
        apptId: string
    }>()

    // TODO: UNCOMMENT ONCE MIXPANEL PROJECT FOR NPS HAS BEEN MIGRATED
    // useShowNPSModalIfApplicable(
    //     'How satisfied were you with your booking experience?',
    //     'appointment-booking',
    //     apptId,
    //     { apptId }
    // )

    useEffect(() => {
        checkInNotStarted(appointment)
    }, [checkInNotStarted])

    return (
        <BlueInterstitial
            image={bookedIllustration}
            title="You're booked! Now let's get to know each other."
            btnText="Let's go"
            loading={isSaving}
            // we know saveStep
            btnOnClick={() => saveStep!()}
            info="We need some info so we can deliver care specifically tailored to *you.* The more thorough your answers, the better care we can give. Grab your insurance and credit or debit card, and let's get started!"
            currentStep={0}
        />
    )
}

export const StartCheckInInfoSkeleton = () => {
    return (
        <BlueInterstitial
            image={bookedIllustration}
            title="You're booked! Now let's get to know each other."
            btnText="Let's go"
            loading={true}
            info="We need some info so we can deliver care specifically tailored to *you.* The more thorough your answers, the better care we can give. Grab your insurance and credit or debit card, and let's get started!"
            currentStep={0}
        />
    )
}
