import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import logger from 'src/logger'
import { AppointmentSuggestion } from 'src/types'

async function getAppointmentSuggestion(
    appointmentSuggestionId: string,
    withInterventions?: boolean
): Promise<AppointmentSuggestion> {
    const data = await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/suggestions/${appointmentSuggestionId}?apiVersion=v2${
            withInterventions ? `&withInterventions=true` : ''
        }`
    )

    return {
        ...data,
        params: {
            ...data.params,
            startDate: new Date(data.params.startDate)
        }
    }
}

export const useAppointmentSuggestion = (
    appointmentSuggestionId?: string,
    withInterventions?: boolean
) => {
    // We are doing this to try to understand what is causing users to try to fetch this specific suggestionId
    if (appointmentSuggestionId === '0013cfa3-1ce9-4821-bd2a-ce876c2195ef') {
        logger.info(
            `Tried to fetch poisoned appointmentSuggestionId on:  href:${window.location.href}, pathname: ${window.location.pathname}`
        )
    }

    const profileQuery = useQuery<AppointmentSuggestion>(
        ['appointment-suggestion', appointmentSuggestionId],
        () =>
            getAppointmentSuggestion(
                appointmentSuggestionId!,
                withInterventions
            ),
        {
            staleTime: Infinity,
            enabled: Boolean(appointmentSuggestionId)
        }
    )

    return {
        suggestion: profileQuery.data,
        isLoading: profileQuery.isLoading
    }
}
