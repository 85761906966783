import { Box, Flex, Grid, Heading, Spinner, Text } from '@asktia/tia-ui'
import { MouseEventHandler, SyntheticEvent, useMemo } from 'react'
import { AppointmentLocation } from 'src/components/AppointmentLocation'
import { useBookingFlow } from 'src/flows/AppointmentBooking/useBookingFlow'
import { AppointmentProfile, AvailableSlot } from 'src/types'
import { getDisplayTimestamp } from 'src/utils'
import capitalize from 'lodash/capitalize'
import { format } from 'date-fns'
import { useUserInfo } from 'src/hooks'
import { PreviewExplanation } from 'src/components/Preview/PreviewExplanation'
import { useModal } from 'react-modal-hook'

type BookingSlotProps = {
    slot: AvailableSlot
    withDate?: boolean
    isLoading?: boolean
    hideCadence?: boolean
    onClick?: () => void
}
type BookingSlotRenderer = (props: BookingSlotProps) => JSX.Element

export const BookingSlot = ({
    slot,
    withDate,
    isLoading,
    hideCadence
}: BookingSlotProps) => {
    const startTimeDisplay = getDisplayTimestamp(
        slot.startTime,
        slot.locationDetails.timezone
    )

    const showCadence = slot.cadence && !hideCadence

    // isLoading doesn't mean the slot is loading
    // it's about the parent component loading data
    // for slot selection to be possible
    return (
        <Flex
            sx={{
                bg: 'white',
                py: 2,
                px: 4,
                flex: 1,
                flexDirection: 'column',
                borderRadius: 2
            }}
        >
            {withDate && (
                <Text sx={{ fontSize: 4 }}>
                    {format(slot.startTime, 'iii, MMM dd')}
                </Text>
            )}

            <Flex
                sx={{
                    bg: 'white',
                    flex: 1,
                    flexDirection: 'column'
                }}
            >
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <Flex
                            sx={{
                                justifyContent: 'left',
                                alignItems: 'flex-end'
                            }}
                        >
                            <Heading h4 sx={{ mb: 0 }}>
                                {startTimeDisplay.time}
                            </Heading>
                            <Text
                                sx={{
                                    fontSize: 0,
                                    ml: 1,
                                    color: 'secondary'
                                }}
                            >
                                {startTimeDisplay.timezone}
                                {showCadence
                                    ? ` · ${capitalize(slot.cadence)}`
                                    : null}
                            </Text>
                        </Flex>
                        <AppointmentLocation
                            locationDetails={slot.locationDetails}
                            sx={{ mt: 1, mb: 0 }}
                        />
                        {slot.supportGroupUuid && (
                            <Flex
                                sx={{
                                    flex: 1,
                                    flexGrow: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Text
                                    as="p"
                                    sx={{
                                        fontSize: 0,
                                        color: 'secondary',
                                        mt: 1
                                    }}
                                >
                                    Session {slot.sessionNumber} of{' '}
                                    {slot.totalOfSessions}
                                </Text>
                                <Text
                                    as="p"
                                    sx={{
                                        fontSize: 0,
                                        color: 'secondary'
                                    }}
                                >
                                    Led by {slot.providerName}
                                </Text>
                            </Flex>
                        )}
                    </>
                )}
            </Flex>
        </Flex>
    )
}

/**
 * For testing purposes, we decide that this component will receive a Widget to render the Slot.
 * In Storybook we will pass the presentational BookingSlot widget, while on the app code will receive
 * the BookingSlotWrapper that is connected to the booking flow hooks.
 */
export const BookingSlotGroup = (props: {
    day: string
    slots: AvailableSlot[]
    BookingSlotComponent: BookingSlotRenderer
    sideScroll?: boolean
    isLoading?: boolean
    // hack to spelunk click event for closing modals
    onClick?: MouseEventHandler
    hideCadence?: boolean
    appointmentProfile?: AppointmentProfile
}) => {
    const {
        slots,
        day,
        BookingSlotComponent,
        sideScroll,
        isLoading = false,
        hideCadence = false,
        appointmentProfile
    } = props

    const minCardWidth = '180px'

    return (
        <>
            <Heading
                h4
                sx={{
                    color: 'white',
                    display: 'block',
                    mb: 2
                }}
            >
                {day}
            </Heading>
            <Box
                sx={{
                    overflowX: 'auto',
                    pb: sideScroll ? [2, 0] : 0,
                    mx: sideScroll ? ['calc(50% - 50vw)', 0] : 0
                }}
            >
                <Grid
                    sx={{
                        display: sideScroll ? ['inline-grid', 'grid'] : 'grid',
                        gap: 2,
                        gridTemplateColumns: `repeat(auto-fill, minmax(${minCardWidth}, 1fr))`,
                        gridAutoFlow: sideScroll
                            ? ['column', 'initial', 'initial']
                            : 'initial',
                        gridAutoColumns: sideScroll
                            ? [
                                  `minmax(${minCardWidth}, 1fr)`,
                                  'initial',
                                  'initial'
                              ]
                            : 'initial',
                        mx: sideScroll ? [5, 0] : 0,
                        mb: 5
                    }}
                    onClick={props.onClick}
                >
                    {slots.map((slot: AvailableSlot, i) => (
                        <Flex key={`slot-${i}`}>
                            <BookingSlotComponent
                                isLoading={isLoading}
                                slot={slot}
                                hideCadence={hideCadence}
                            />
                        </Flex>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

/** Binds the presentational BookingSlot with the booking flow state and events */
export const BookingSlotWrapper = ({
    slot,
    hideCadence,
    withDate,
    onClick
}: BookingSlotProps) => {
    const { navigateToNextStep, isLoading } = useBookingFlow(slot)
    const { user } = useUserInfo()

    const [showPreviewExplanationModal, hideWaitlistModal] = useModal(
        () => (
            <PreviewExplanation
                hideModal={() => {
                    hideWaitlistModal()
                }}
                dontShowAppointmentsLink={true}
                showWhatsMissing={true}
                redirectUrl={window.location.href}
            />
        ),
        []
    )

    return (
        <Flex
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={(event: SyntheticEvent) => {
                // Disregard click while loading
                if (!isLoading) {
                    if (user?.membershipSignupStatus !== 'preview') {
                        navigateToNextStep()
                        onClick && onClick()
                    } else {
                        showPreviewExplanationModal()
                    }
                }
            }}
        >
            <BookingSlot
                slot={slot}
                isLoading={isLoading}
                hideCadence={hideCadence}
                withDate={withDate}
            />
        </Flex>
    )
}
// TODO: Add UT
const groupSlotsByDay = (slots: AvailableSlot[]) => {
    const daysMap: {
        [k: string]: AvailableSlot[]
    } = {}
    for (const slot of slots) {
        const { day } = getDisplayTimestamp(
            slot.startTime,
            slot.locationDetails.timezone
        )
        daysMap[day] = [...(daysMap[day] || []), slot]
    }
    return daysMap
}

export const GroupedBookingSlots = ({
    slots,
    sideScroll,
    onClick,
    hideCadence,
    appointmentProfile
}: {
    slots: AvailableSlot[]
    sideScroll?: boolean
    // meant for closing modals on user selection
    onClick?: MouseEventHandler

    // TODO: candidate for context
    hideCadence?: boolean
    appointmentProfile?: AppointmentProfile
}) => {
    const groupedSlots: Array<[string, AvailableSlot[]]> = useMemo(
        () => Object.entries(groupSlotsByDay(slots)),
        [slots]
    )

    return (
        <>
            {groupedSlots.map(([day, slots]) => (
                <BookingSlotGroup
                    day={day}
                    slots={slots}
                    key={day}
                    BookingSlotComponent={BookingSlotWrapper}
                    sideScroll={sideScroll}
                    onClick={onClick}
                    hideCadence={hideCadence}
                    appointmentProfile={appointmentProfile}
                />
            ))}
        </>
    )
}
