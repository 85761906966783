import { Text, Image, Heading, Box, LayoutGrid } from '@asktia/tia-ui'
import { FC } from 'react'
import WelcomeIllustration from '../../assets/welcome-illustration.png'
import { ActionPage } from './components/ActionPage'
import { useNavigation } from 'src/hooks/useNavigation'
import { useEffect } from 'react'
import { useAmpli, useScrollToTopOnPageLoad } from 'src/hooks'
import { SmallCheckmarkIcon } from 'src/components/Blocks/Icons'

export const MemberBenefits = ({ children }: { children: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                textAlign: 'left',
                mt: 4
            }}
        >
            <Box
                sx={{
                    minWidth: 5,
                    height: 5,
                    backgroundColor: 'secondaryBackground',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: 3
                }}
            >
                <SmallCheckmarkIcon color="text" />
            </Box>
            <Text sx={{ pl: 2, lineHeight: '20px' }}>{children}</Text>
        </Box>
    )
}

export const Welcome: FC = () => {
    const { navigate } = useNavigation()
    const { viewNewMemberOnboarding, clickGetStartedInNewMemberOnboarding } =
        useAmpli()
    useScrollToTopOnPageLoad()

    useEffect(() => {
        viewNewMemberOnboarding()
    }, [])

    return (
        <ActionPage
            cta={{
                text: "I'm ready",
                onClick: () => {
                    clickGetStartedInNewMemberOnboarding()
                    navigate('/landing/twhe')
                }
            }}
            sx={{ maxWidth: 480, ml: 'auto', mr: 'auto' }}
        >
            <Image src={WelcomeIllustration} sx={{ maxWidth: '100%' }} />
            <Heading h1 sx={{ mb: 2, width: '100%', textAlign: 'left' }}>
                Welcome to Tia
            </Heading>
            <Text sx={{ lineHeight: '20px' }}>
                Finally, a new standard of healthcare that makes you feel seen,
                heard, and cared for. As a Tia member, you now have access to
                reproductive health.
            </Text>
            <MemberBenefits>
                Comprehensive care to care for all of your physical, mental and
                reproductive health needs.
            </MemberBenefits>

            <MemberBenefits>
                Instead of one doctor, you get an integrated Care Team, working
                together to care for the whole you.
            </MemberBenefits>

            <MemberBenefits>
                Convenient virtual and/or in-person appointments from providers
                you trust to meet you wherever you are on your healthcare
                journey.
            </MemberBenefits>

            <MemberBenefits>
                Unlimited messaging with your Care Team and Care Coordinators to
                help you navigate all your healthcare needs.
            </MemberBenefits>
        </ActionPage>
    )
}
