import { useState, useEffect } from 'react'

// Borrowed from https://github.com/SMAKSS/react-scroll-direction
// And converted to TS

type Props = {
    thr?: number
    axis?: string
    scrollUp?: string
    scrollDown?: string
    still?: string
}

function useDetectScroll(props: Props) {
    const {
        thr = 0,
        axis = 'y',
        scrollUp = axis === 'y' ? 'up' : 'left',
        scrollDown = axis === 'y' ? 'down' : 'right',
        still = 'still'
    } = props
    const [scrollDir, setScrollDir] = useState(still)
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        const threshold = thr > 0 ? thr : 0
        let ticking = false
        let lastScroll = axis === 'y' ? window.pageYOffset : window.pageXOffset

        const updateScrollDir = () => {
            let scroll = undefined

            axis === 'y'
                ? (scroll = window.pageYOffset)
                : (scroll = window.pageXOffset)

            if (Math.abs(scroll - lastScroll) < threshold) {
                ticking = false
                return
            }
            setScrollDir(scroll > lastScroll ? scrollDown : scrollUp)
            setOffset(scroll)
            lastScroll = scroll > 0 ? scrollY : 0
            ticking = false
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir)
                ticking = true
            }
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollDir])

    return [scrollDir, offset]
}

export default useDetectScroll
