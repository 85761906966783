import { MedplumClient } from '@medplum/core'
import { BASE_API_URL } from 'src/globals'
import Cookies from 'js-cookie'
import Logger from 'src/logger'

export const medplum = new MedplumClient({
    clientId: process.env.REACT_APP_MEDPLUM_CLIENT_ID,
    baseUrl: `${BASE_API_URL}/fhir-api`,
    fetch: _fetch
})

async function _fetch(url: RequestInfo, init?: RequestInit): Promise<unknown> {
    const startTimestamp = Date.now()

    const context: {
        url: RequestInfo
        method?: string
        duration?: number
        statusCode?: number
        statusText?: string
        errorReason?: unknown
    } = {
        url
    }

    context.method = init?.method ? init.method : '[UNK]'

    const config = {
        ...init,
        headers: {
            ...init?.headers,
            'X-Xsrf-Token': Cookies.get('xsrf-token') || ''
        }
    }

    return fetch(url, config)
        .then(originalResponse => {
            context.duration = Date.now() - startTimestamp
            context.statusCode = originalResponse?.status
            context.statusText = originalResponse?.statusText
            if (originalResponse?.status >= 400) {
                Logger.warn(
                    `Medplum request failed and it took ${context.duration}ms`,
                    context
                )
            } else {
                Logger.info(
                    `Medplum call was successfull and it took ${context.duration}ms`,
                    context
                )
            }
            return originalResponse
        })
        .catch(originalReason => {
            context.duration = Date.now() - startTimestamp
            context.errorReason = originalReason
            Logger.warn(
                `Medplum request failed catastrophically and it took ${context.duration}ms`,
                context
            )
            return Promise.reject(originalReason)
        })
}
