import { useCarePlan } from './useCarePlan'

/**
 * Right now we have all the data we need in the useCarePlan hook
 * so we reuse the cached data from the previous page.
 * We should reimplement this fn once we add more data to the GET intervention
 * endpoint
 */
export const useCarePlanIntervention = (carePlanInterventionUuid: string) => {
    const { data, isLoading } = useCarePlan()
    const intervention = data?.find(
        e => e.carePlanIntervention.uuid === carePlanInterventionUuid
    )

    return { isLoading, data: intervention }
}
