import { Box, Button, ThemeStyleObject } from '@asktia/tia-ui'
import { AlertIcon } from 'src/components/Blocks/Icons'

const FilterIcon = (props: { sx?: ThemeStyleObject }) => (
    <Box
        as="svg"
        sx={{
            width: 24,
            height: 24,
            ...props.sx
        }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z"
            fill="#282725"
        />
    </Box>
)

export const FilterHamburger = (props: {
    onClick: () => void
    numActiveFilters: number
}) => {
    const isFiltering = props.numActiveFilters
        ? props.numActiveFilters > 0
        : false

    return (
        <>
            <Button
                variant="unstyled"
                onClick={props.onClick}
                sx={{
                    display: 'flex',
                    py: 3
                }}
            >
                <FilterIcon sx={{ ml: 2 }} />
                {isFiltering && (
                    <AlertIcon
                        variant="communicationBanner"
                        sx={{
                            position: 'relative',
                            bottom: '12px',
                            right: '4px'
                        }}
                    />
                )}
            </Button>
        </>
    )
}
