import {
    Button,
    Container,
    Flex,
    Grid,
    Heading,
    Image,
    Paragraph,
    Text
} from '@asktia/tia-ui'
import { FC } from 'react'

import NoActionItemsIllustration from '../../../assets/no-action-items-illustration.png'

export const NoActionItemsBanner: FC = () => {
    return (
        <Container sx={{ mt: 5 }}>
            <Heading h1 sx={{ mb: 4 }}>
                Next steps from your Care Team
            </Heading>
            <Flex
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'mainBackground',
                    width: '100%',
                    borderRadius: 2,
                    p: 5
                }}
            >
                <Grid gap={6} columns={['1fr', '1fr 2fr']}>
                    <Flex
                        sx={{
                            width: '100%',
                            justifyContent: ['center', 'flex-end']
                        }}
                    >
                        <Image
                            src={NoActionItemsIllustration}
                            sx={{
                                width: 160,
                                height: 160
                            }}
                        />
                    </Flex>

                    <Flex
                        sx={{
                            width: '100%',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Text
                            sx={{
                                fontFamily: 'Inferi',
                                fontSize: 20,
                                fontWeight: 350,
                                lineHeight: '120%',
                                maxWidth: 400,
                                mb: 4
                            }}
                        >
                            You're all caught up.
                        </Text>
                        <Paragraph sx={{ color: 'secondary', maxWidth: 400 }}>
                            Prioritizing your health and well-being is worth
                            celebrating! Check back later for more action items
                            or whenever you need care.
                        </Paragraph>
                        <Button
                            as="a"
                            href="/r/appointments"
                            variant="secondaryDark"
                            sx={{ mt: 2 }}
                        >
                            Explore Appointments
                        </Button>
                    </Flex>
                </Grid>
            </Flex>
        </Container>
    )
}
