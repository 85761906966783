import { Box, Button, Spinner } from '@asktia/tia-ui'
import { ArrowRightIcon } from 'src/components/Blocks/Icons'
import { Link as RouterLink } from 'react-router-dom'
import { ReactNode } from 'react'

export const RecommendedActionsButton = ({
    children,
    apptLink,
    selected,
    loading,
    onClick
}: {
    children: ReactNode
    apptLink: string
    selected?: boolean
    loading?: boolean
    onClick?: () => void
}) => {
    return (
        <Button
            as={RouterLink}
            // @ts-ignore: TS doesn't understand this is a RouterLink now
            to={apptLink}
            variant="unstyled"
            sx={{
                'bg': selected ? 'primaryButton' : 'lightSecondaryButton',
                'py': 4,
                'px': 5,
                'borderRadius': 2,
                'border': t => `1px solid ${t.colors?.inputOutline}`,
                'mb': 4,
                'display': 'flex',
                'alignItems': 'center',
                'textAlign': 'left',
                'justifyContent': 'space-between',
                'fontSize': 5,
                'fontWeight': 'bold',
                'color': selected ? 'white' : 'text',
                '&:hover': {
                    borderColor: 'inputOutlineHover'
                },
                'p': {
                    fontSize: 0,
                    mb: 0,
                    color: selected ? 'white' : 'supportText'
                }
            }}
            onClick={() => (loading ? null : onClick?.())}
        >
            {loading ? (
                <Spinner color="white" />
            ) : (
                <Box sx={{ width: '100%' }}>{children}</Box>
            )}
            <ArrowRightIcon color={selected ? 'white' : 'text'} />
        </Button>
    )
}
