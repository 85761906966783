import React, {
    FC,
    useState,
    useEffect,
    MouseEventHandler,
    Dispatch,
    SetStateAction
} from 'react'
import {
    Box,
    Text,
    Heading,
    ThemeStyleObject,
    Button,
    Flex,
    Card,
    LabeledCheckbox,
    Paragraph
} from '@asktia/tia-ui'
import { CheckmarkIcon, PlusIcon } from 'src/components/Blocks/Icons'
import { useModal } from 'react-modal-hook'
import capitalize from 'lodash/capitalize'
import {
    InsuranceCardType,
    useUpdateInsuranceImages
} from 'src/hooks/useInsuranceImages'
import { UploadInsuranceModal } from 'src/flows/AutomatedCheckin/views/InsuranceInfo/UploadInsuranceModal'
import { Appointment } from 'src/types'
import { ampli } from 'src/ampli'

type InsuranceCardFormProps = {
    sx?: ThemeStyleObject
    formMethods: any
    appointment: Appointment
}

type InsuranceImagesUpload = {
    disabled?: boolean

    onImagesChange?: (images: ImageState) => void
    onImagesEdited?: (edited: boolean) => void
}

type ImageState = {
    front: File | null
    back: File | null
}

export const InsuranceImagesUpload: FC<InsuranceImagesUpload> = ({
    disabled,
    onImagesChange,
    onImagesEdited
}) => {
    const { mutateAsync, isLoading } = useUpdateInsuranceImages()
    const [imagesToUpload, setImagesToUpload] = useState<ImageState>({
        front: null,
        back: null
    })

    const [isEditing, setIsEditing] = useState<boolean>(true)

    const onUploadClick = async () => {
        if (!imagesToUpload.front || !imagesToUpload.back) {
            return
        }

        await mutateAsync({
            cards: [
                {
                    name: 'front',
                    image: imagesToUpload.front!
                },
                {
                    name: 'back',
                    image: imagesToUpload.back!
                }
            ],
            flow: 'member-info'
        })

        setIsEditing(false)
        onImagesEdited && onImagesEdited(false)
        onImagesChange && onImagesChange(imagesToUpload)
        ampli.insuranceImageSent()
    }

    return (
        <>
            <Heading h4>Add photos of your insurance card</Heading>

            <InsurancePhotoRow
                cardType="front"
                disabled={disabled}
                imagesToUpload={imagesToUpload}
                setImagesToUpload={event => {
                    setIsEditing(true)
                    onImagesEdited && onImagesEdited(true)
                    setImagesToUpload(event)
                }}
            />

            <InsurancePhotoRow
                cardType="back"
                sx={{ borderBottom: 'none', mb: 4 }}
                disabled={disabled}
                imagesToUpload={imagesToUpload}
                setImagesToUpload={event => {
                    setIsEditing(true)
                    onImagesEdited && onImagesEdited(true)
                    setImagesToUpload(event)
                }}
            />

            {isEditing && (
                <Button
                    type="button"
                    sx={{ minWidth: '100%', marginBottom: 5 }}
                    loading={isLoading}
                    disabled={!imagesToUpload.front || !imagesToUpload.back}
                    onClick={onUploadClick}
                >
                    Upload
                </Button>
            )}
        </>
    )
}

export const InsuranceCardForm: FC<
    React.PropsWithChildren<InsuranceCardFormProps>
> = ({ sx, formMethods, appointment }) => {
    const [uploadedImages, setUploadedImages] = useState<ImageState>({
        front: null,
        back: null
    })

    const [cardSuccessfullyUploaded, setCardSuccessfullyUploaded] =
        useState<boolean>(false)

    const [editingImages, setEditingImages] = useState<boolean>(true)

    useEffect(() => {
        formMethods.setValue(
            'hasInsuranceImages',
            uploadedImages.front && uploadedImages.back ? 'on' : 'off'
        )

        setCardSuccessfullyUploaded(
            uploadedImages.front && uploadedImages.back ? true : false
        )
        // don't want formMethods, it's unstable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedImages])

    const displayOptOut = !uploadedImages.back || !uploadedImages.front

    return (
        <Box sx={sx}>
            <Heading h2 sx={{ mb: 0 }}>
                Your Insurance Card
            </Heading>

            <Paragraph
                sx={{
                    mt: 2,
                    mb: 6
                }}
            >
                Having a photo of your insurance card on file helps your Care
                Team better answer questions about your coverage!
            </Paragraph>

            <InsuranceImagesUpload
                disabled={formMethods.watch('photoOptOut')}
                onImagesChange={setUploadedImages}
                onImagesEdited={setEditingImages}
            />

            {cardSuccessfullyUploaded && !editingImages && (
                <Card sx={{ mb: 4 }} variant="info">
                    Cards sent with success! 🎉
                </Card>
            )}

            <Card
                variant="info"
                sx={{ mb: 6 }}
                style={{ display: displayOptOut ? 'block' : 'none' }}
            >
                <Heading h4>Can't upload now?</Heading>
                <Paragraph sx={{ mb: 4 }}>
                    By not adding a card right now, I acknowledge that if there
                    is an error with my insurance, I may be charged the cash
                    price.
                </Paragraph>
                <LabeledCheckbox name="photoOptOut">I agree</LabeledCheckbox>
            </Card>
            <input
                style={{ display: 'none' }}
                type="text"
                name="hasInsuranceImages"
                ref={formMethods.register()}
            />
        </Box>
    )
}

type AddInsuranceButtonProps = {
    hasUploaded: boolean
    onClick: MouseEventHandler
    disabled?: boolean
}

const AddInsuranceButton: FC<
    React.PropsWithChildren<AddInsuranceButtonProps>
> = ({ hasUploaded, onClick, disabled }) => {
    let bg = 'primaryButton'
    let Icon = PlusIcon

    if (hasUploaded) {
        bg = '#282725' // softblack
        Icon = CheckmarkIcon
    }

    return (
        <Button
            type="button"
            sx={{
                width: [7, 7],
                height: 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: [0, 0],
                bg
            }}
            onClick={onClick}
            disabled={disabled}
        >
            <Icon color="mainBackground" />
        </Button>
    )
}

type InsurancePhotoRowProps = {
    cardType: InsuranceCardType
    disabled?: boolean
    imagesToUpload: ImageState
    setImagesToUpload: Dispatch<SetStateAction<ImageState>>
    sx?: ThemeStyleObject
}

const InsurancePhotoRow: FC<
    React.PropsWithChildren<InsurancePhotoRowProps>
> = ({ cardType, disabled, imagesToUpload, setImagesToUpload, sx }) => {
    function onImageChange(image: File) {
        setImagesToUpload(images => ({
            ...images,
            [cardType]: image
        }))
    }
    const subtitleText = disabled ? 'Opted Out' : imagesToUpload[cardType]?.name
    const [showCardUploadModal, hideCardUploadModal] = useModal(
        () => (
            <UploadInsuranceModal
                image={imagesToUpload[cardType]}
                onImageChange={onImageChange}
                cardType={cardType}
                hideModal={() => {
                    hideCardUploadModal()
                }}
            />
        ),
        [imagesToUpload]
    )

    return (
        <Flex
            sx={{
                py: 4,
                alignItems: 'center',
                borderBottom: ({ colors }) => `1px solid ${colors?.grey10}`,
                justifyContent: 'space-between',
                ...sx
            }}
        >
            <Box>
                <Heading h3 sx={{ mb: 2 }}>
                    Card {capitalize(cardType)}
                </Heading>
                <Text sx={{ fontSize: 3, color: 'supportText' }}>
                    {subtitleText || 'Incomplete'}
                </Text>
            </Box>

            <AddInsuranceButton
                hasUploaded={!!imagesToUpload[cardType]}
                onClick={showCardUploadModal}
                disabled={disabled}
            />
        </Flex>
    )
}
