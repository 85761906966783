import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import {
    Form,
    Heading,
    Radio,
    Container,
    LayoutGrid,
    Box,
    Card,
    Text
} from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import { useAmpli, useNavigation, useUserInfo } from 'src/hooks'
import { THREAD_LABELS } from 'src/globals'
import { BackButton } from 'src/components/Blocks/BackButton'
import { View } from 'src/components/Blocks/View'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

type FormValues =
    | {
          cancelChange: string
      }
    | FieldValues

export const ChangeCancelMembership = () => {
    const formMethods = useForm<FormValues>()
    const { navigate } = useNavigation()
    const { user } = useUserInfo()
    const {
        cancelOrChangeMembershipViewed,
        cancelMembershipClicked,
        changeMembershipClicked
    } = useAmpli()

    const predictablePaymentsReleased =
        useAmpliFeatureFlag('predictable-payments-released') === 'treatment'

    const {
        formState: { isDirty }
    } = formMethods

    useEffect(() => {
        if (user) {
            cancelOrChangeMembershipViewed(user.id)
        }
    }, [cancelOrChangeMembershipViewed, user])

    const onSubmit = ({ cancelChange }: FormValues) => {
        if (!user) {
            return
        }

        if (cancelChange === 'cancel') {
            cancelMembershipClicked(user.id)
            navigate('/membership/cancel')
            return
        }

        changeMembershipClicked(user.id)
        navigate(
            `/chat/new?label=${THREAD_LABELS.membership}&subject=Membership change&message=I want to change my membership plan.`
        )
    }

    return (
        <View withFooter>
            <PageHeader mobileBack={<BackButton href="/account-info" />} />
            <NavBarHeading href="/account-info">
                Cancel or change membership
            </NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    <Form
                        id="cancel-change-form"
                        onSubmit={onSubmit}
                        useFormMethods={formMethods}
                    >
                        <Box sx={{ mb: 6 }}>
                            <Heading h3>
                                Do you want to cancel or change your membership?
                            </Heading>

                            <Radio
                                name="cancelChange"
                                value="change"
                                sx={{ mb: 4 }}
                                pretty
                            >
                                Change my membership
                            </Radio>
                            <Radio
                                name="cancelChange"
                                value="cancel"
                                sx={{ mb: 4 }}
                                pretty
                            >
                                Cancel my membership
                            </Radio>
                        </Box>

                        {predictablePaymentsReleased && (
                            <Card
                                sx={{ px: 5, py: 4, mb: 6, background: 'gold' }}
                            >
                                <Text
                                    sx={{
                                        fontSize: 0,
                                        lineHeight: '16px',
                                        display: 'block'
                                    }}
                                >
                                    Heads up! You may have remaining balance
                                    from past appointments. If so, you'll be
                                    asked to pay your balance when your
                                    membership is cancelled.
                                </Text>
                            </Card>
                        )}

                        <FooterButton type="submit" disabled={!isDirty}>
                            Next
                        </FooterButton>
                    </Form>
                </LayoutGrid>
            </Container>
        </View>
    )
}
