import ReactMarkdown from 'react-markdown'
import { Link, Paragraph } from '@asktia/tia-ui'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import { ThemeUIStyleObject } from '@theme-ui/css'
import { Fragment } from 'react'

export interface IMarkdown extends Omit<ReactMarkdownOptions, 'children'> {
    text: string
    sx?: ThemeUIStyleObject
}
/**
 * This component shouldn't be used for multi-paragraph tags, since it will render to a single one only
 */
const Markdown = ({ text, sx, ...props }: IMarkdown) => {
    return (
        <Paragraph {...props} sx={sx}>
            <ReactMarkdown
                components={{
                    p: ({ children }) => <>{children}</>,
                    a: ({ node, ...nodeProps }) => (
                        <Link target="_blank" {...nodeProps} {...props}>
                            {nodeProps.children}
                        </Link>
                    )
                }}
                {...props}
            >
                {text}
            </ReactMarkdown>
        </Paragraph>
    )
}

export default Markdown
