import {
    Box,
    Button,
    Flex,
    Heading,
    Label,
    Link,
    Paragraph,
    Slider,
    TiaModal
} from '@asktia/tia-ui'
import {
    ChangeEvent,
    ChangeEventHandler,
    createContext,
    PropsWithChildren,
    ReactNode,
    useContext,
    useState,
    useEffect
} from 'react'
import { useModal } from 'react-modal-hook'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { CloseIcon } from 'src/components/Blocks/Icons'
import { useAmpli } from 'src/hooks'

const emojiArray = [
    '😡',
    '😠',
    '😩',
    '😣',
    '😞',
    '🙁',
    '😕',
    '🙂',
    '😊',
    '🤗',
    '😍'
]

/**
 * TODO:
 * this is a stubbed version of ye olde useAnalytics
 * should be replaced with useAmpli when NPS modal revived
 */
function useAnalytics(_: string) {
    return (_?: Record<string, unknown>) =>
        console.warn('Replace this with useAmpli')
}

const ThanksCopy = () => {
    return (
        <>
            <Heading>Thanks for your feedback!</Heading>
            <Paragraph sx={{ mb: 5 }}>
                Your #feels help create a better experience
                <br /> for all Tia users.
            </Paragraph>
        </>
    )
}

const EmojiCopy = (props: {
    text: ReactNode
    value: number
    changed: boolean
    onChange: ChangeEventHandler
}) => {
    return (
        <>
            <Heading h3 sx={{ textAlign: 'center', mb: 6 }}>
                {props.text}
            </Heading>

            <Box
                sx={{ fontSize: 68, textAlign: 'center' }}
                role="img"
                aria-label={`score ${props.value}/10`}
            >
                {props.changed ? emojiArray[props.value] : '😶'}
            </Box>
            <Slider
                name="howSatisfied"
                step={1}
                min={0}
                max={10}
                value={props.value}
                onChange={props.onChange}
            />

            <Flex sx={{ justifyContent: 'space-between', mb: 6 }}>
                <Label
                    sx={{
                        display: 'inline-block',
                        textAlign: 'left',
                        fontSize: 0
                    }}
                >
                    Not very
                </Label>
                <Label
                    sx={{
                        display: 'inline-block',
                        textAlign: 'right',
                        fontSize: 0
                    }}
                >
                    Extremely
                </Label>
            </Flex>
        </>
    )
}

export const NPSModal = (props: {
    text: ReactNode
    hideModal: Function
    onSubmit: Function
}) => {
    const [value, setValue] = useState(5)
    const [changed, setChanged] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { npsSubmitted } = useAmpli()

    function changeValue(event: ChangeEvent<HTMLInputElement>) {
        setValue(Number(event.target.value))
        setChanged(true)
    }

    function submit() {
        props.onSubmit(value)
        setSubmitted(true)
        npsSubmitted('web', `${value}`)
    }

    return (
        <TiaModal sx={{ width: '100%' }}>
            <Box sx={{ textAlign: 'right', mt: '-4px', mr: '-4px' }}>
                <Link
                    onClick={() => props.hideModal()}
                    sx={{ cursor: 'pointer' }}
                >
                    <CloseIcon color="text" />
                </Link>
            </Box>

            {submitted ? (
                <ThanksCopy />
            ) : (
                <EmojiCopy
                    text={props.text}
                    value={value}
                    onChange={changeValue}
                    changed={changed}
                />
            )}

            <Flex sx={{ justifyContent: 'center' }}>
                {submitted ? (
                    <Button onClick={() => props.hideModal()}>Close</Button>
                ) : (
                    <Button disabled={!changed} onClick={submit}>
                        Submit
                    </Button>
                )}
            </Flex>
        </TiaModal>
    )
}

type NPSCallbackFunction = (value: number) => void
export const NPSContext = createContext<{
    showNPS: (text: ReactNode, onNPSSubmit: NPSCallbackFunction) => void
}>({
    showNPS: () => {}
})

export function NPSContextProvider(props: PropsWithChildren<{}>) {
    // state tracks values to feed into the modal
    const [contextState, setContextState] = useState<{
        text: ReactNode
        onNPSSubmit: NPSCallbackFunction
    }>({ text: '', onNPSSubmit: () => {} })

    const [showNPSModal, hideNPSModal] = useModal(
        () => (
            <NPSModal
                text={contextState.text}
                hideModal={() => hideNPSModal()}
                onSubmit={(value: number) => contextState.onNPSSubmit(value)}
            />
        ),
        [contextState]
    )
    const { npsModalViewed } = useAmpli()

    // function that sets state and shows modal
    // passed around through context so hooks can access
    const showNPS = (text: ReactNode, onNPSSubmit: NPSCallbackFunction) => {
        setContextState({
            text,
            onNPSSubmit
        })
        showNPSModal()
        npsModalViewed('web')
    }

    return (
        <NPSContext.Provider value={{ showNPS }}>
            {props.children}
        </NPSContext.Provider>
    )
}

/**
 * manages showing the NPS modal based on a type,
 * shown 1x per identifier
 * if enough modals have been skipped
 *
 * @param {string} NPStype what feature is this for
 * @param {string} identifier ID a specific instance
 * @param {Object} properties name:value mapping of properties to save with the event
 */
export function useShowNPSModalIfApplicable(
    text: ReactNode,
    NPStype: string,
    identifier: string,
    properties?: { [key: string]: string | number | null }
) {
    const { showNPS } = useContext(NPSContext)
    const MAX_SKIPPED = 1

    // have we attempted to show NPS modal for this specific ID?
    const [attemptedToShowForID, setAttemptedToShowForID] =
        useLocalStorage<boolean>(
            `tia:nps-modal:attempted-to-show:${NPStype}:${identifier}`,
            false
        )

    // have we shown NPS modal for this specific ID?
    const [shownForID] = useLocalStorage<boolean>(
        `tia:nps-modal:shown:${NPStype}:${identifier}`,
        false
    )

    const [NPSAttemptCount, setNPSAttemptCount] = useLocalStorage<number>(
        `tia:nps-modal:attempt-count:${NPStype}`,
        0
    )

    const skippedEnoughToDisplay = NPSAttemptCount % (MAX_SKIPPED + 1) === 0

    // We still need to account for shownForID for legacy purposes
    // attemptedToShowForID is newer but we dont want to
    // incorrectly show bookings to clients who filled out modal
    // prior to new local storage key
    const canShowToId = !(shownForID || attemptedToShowForID)

    const shouldShow = canShowToId && skippedEnoughToDisplay

    const trackViewNPS = useAnalytics('view-nps-modal')
    const trackSubmitNPS = useAnalytics('NPS')

    const onNPSSubmit: NPSCallbackFunction = (value: number) => {
        trackSubmitNPS({
            ...properties,
            value: value.toString(),
            section: NPStype
        })
    }

    // Every time component is mounted or the NPStype changes
    // we potentially increment the attempt count
    // setting state in useEffect to prevent infinite render
    useEffect(() => {
        if (canShowToId) {
            setNPSAttemptCount(NPSAttemptCount + 1)
        }
    }, [identifier, NPStype])

    useEffect(() => {
        setAttemptedToShowForID(true)
        if (shouldShow) {
            trackViewNPS({ section: NPStype })
            showNPS(text, onNPSSubmit)
        }
    }, [identifier, NPStype])
}
