import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { AppointmentProfile } from 'src/types'

export function fetchOfferings(profileNames?: string[]) {
    return async () => {
        const params = new URLSearchParams(
            profileNames
                ? profileNames.map(profileName => ['profileNames', profileName])
                : undefined
        )
        params.append('apiVersion', 'v2')

        const offeringsData: AppointmentProfile[] = await fetcher(
            `${BASE_API_URL}/tmd-api/appointments/patient-offerings?${params.toString()}`,
            undefined,
            'GET',
            {
                errorMsg: 'Error fetching profiles'
            }
        )

        return profileNames
            ? offeringsData.sort(
                  (a, b) =>
                      profileNames.indexOf(a.name) -
                      profileNames.indexOf(b.name)
              )
            : offeringsData.sort((a, b) => (a.label > b.label ? 1 : -1))
    }
}

export function useOfferings(profileNames?: string[]) {
    const offerings = useQuery<AppointmentProfile[]>(
        ['offerings', profileNames],
        fetchOfferings(profileNames),
        {
            staleTime: Infinity
        }
    )

    return {
        offerings: offerings.data,
        isLoading: offerings.isLoading
    }
}
