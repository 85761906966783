import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { ClinicalStatus } from './useSaveCondition'
import { BASE_API_URL } from 'src/globals'

export interface IConditionConfirmation {
    patientId: number
}

export type ConditionConfirmation = {
    clinicalStatus: ClinicalStatus
    patientId: number
    createdAt: Date
    lastConfirmedAt: Date
}

const submitConfirmation = async ({
    patientId,
    clinicalStatus
}: {
    patientId: number
    clinicalStatus: ClinicalStatus
}) => {
    return (
        fetcher(
            `${BASE_API_URL}/uhr-api/conditions/latest-patient-condition-confirmations`,
            JSON.stringify({ patientId, clinicalStatus }),
            'POST'
        ),
        {
            retry: false
        }
    )
}

export const useConditionConfirmationMutation = () => {
    const statuses: ClinicalStatus[] = ['active', 'inactive']
    return useMutation(
        async (confirmation: IConditionConfirmation) =>
            await Promise.all(
                statuses.map(status =>
                    submitConfirmation({
                        patientId: confirmation.patientId,
                        clinicalStatus: status
                    })
                )
            )
    )
}
