import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { Clinic, UserInfo, Market } from 'src/types'

export async function getCurrentUser() {
    return await fetcher<UserInfo>(
        `${BASE_API_URL}/api/v1/user/whoami`,
        undefined,
        'GET',
        {
            errorMsg: 'whoami returned not-ok'
        }
    )
}

export function useUserInfo() {
    const userQuery = useQuery(['current-user'], getCurrentUser, {
        // refetch every hour
        // we do this to detect if your session expired
        // being up to 1 hour late is okay –
        // unlikely people will leave the app running 24h+
        refetchInterval: 60 * 60 * 1000,
        staleTime: 60 * 60 * 1000,
        retry: false,
        onError: (err: Error) => {
            // /whoami is considered a foundational query
            // doesn't matter why it failed, app won't work
            // most common reason is you're logged out
            if (!window.location.href.includes('login')) {
                window.location.href = '/'
            }
        }
    })

    return {
        user: userQuery.data,
        isLoading: userQuery.isLoading
    }
}

export function useHomeClinic() {
    const { user, isLoading } = useUserInfo()
    const homeClinicQuery = useQuery<Clinic>(
        'home-clinic',
        async () =>
            fetcher(
                `${BASE_API_URL}/tmd-api/patients/${user?.id}/home-clinic?apiVersion=v1`
            ),
        {
            enabled: !!user,
            staleTime: Infinity
        }
    )

    return {
        clinic: homeClinicQuery.data,
        isLoading: isLoading || homeClinicQuery.isLoading
    }
}

export function useHomeMarket() {
    const { user, isLoading } = useUserInfo()
    const homeMarketQuery = useQuery<Market>(
        'homeMarket',
        async () =>
            fetcher(
                `${BASE_API_URL}/tmd-api/member/${user?.id}/home-market?apiVersion=v2`
            ),
        {
            enabled: !!user,
            staleTime: Infinity
        }
    )

    return {
        homeMarket: homeMarketQuery.data,
        isLoading: isLoading || homeMarketQuery.isLoading
    }
}

export function useUpdateUserInfo() {
    const queryClient = useQueryClient()
    return useMutation(
        async (email: string) => {
            const body = JSON.stringify({
                email
            })
            return await fetcher(
                `${BASE_API_URL}/api/v1/user/whoami`,
                body,
                'PUT'
            )
        },
        {
            onSuccess: () => queryClient.invalidateQueries('current-user')
        }
    )
}
