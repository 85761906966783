import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, Form, Input, Link, Spinner } from '@asktia/tia-ui'
import { useForm, FieldValues } from 'react-hook-form'
import { useLoginByPhone } from '../hooks/useLogin'
import { useCodeVerification } from '../hooks/usePhoneVerification'
import { ResendModal } from '../components/ResendModal'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { ControlledOtpInput } from '../components/OtpInput'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

type FormValues = { code: string } | FieldValues

const PhoneLoginForm = ({
    successfulLogin,
    setStepMessage,
    setChatBubbleCopy,
    setSuccessfulLogin
}: {
    successfulLogin: boolean
    setStepMessage: (message: string) => void
    setChatBubbleCopy: (copy: string) => void
    setSuccessfulLogin: (success: boolean) => void
}) => {
    const [isResendModalOpen, setIsResendModalOpen] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [step, setStep] = useState(1)
    const queryClient = useQueryClient()

    useEffect(() => {
        setStepMessage(`Step ${step} of ${chatBubbleCopy.length}`)
        setChatBubbleCopy(chatBubbleCopy[step - 1])
    }, [step])

    const formMethods = useForm<FormValues>({
        shouldUnregister: false,
        mode: 'onChange',
        resolver: yupResolver(
            yup.object({
                phone: yup.string().min(10, '').required('')
            })
        ),
        defaultValues: {
            code: '',
            phone: ''
        }
    })

    const code: string = formMethods.watch('code')
    const phone: string = formMethods.watch('phone')

    const { mutate: sendPostLoginMutator, isLoading: isLoginByPhoneLoading } =
        useLoginByPhone({
            onSuccess: () => {
                setStep(2)
                setSuccessfulLogin(true)
            }
        })

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const {
        mutate: verificationCodeMutator,
        isLoading: isCodeVerificationLoading
    } = useCodeVerification({
        onSuccess: (data: any) => {
            if (data?.isRegistrationComplete) {
                const redirectParam = searchParams.get('redirect')
                const redirectParamDecoded = decodeURIComponent(
                    redirectParam ?? ''
                )
                const redirectUrl = redirectParamDecoded?.replace('/r', '')
                navigate(redirectUrl ? redirectUrl : '/')
            } else {
                setHasError(true)
                window.location.href = `${process.env.REACT_APP_WWW_PUBLIC_URL}/join/join-tia/?widgetStep=phone-login`
            }
        },
        onError: () => {
            setChatBubbleCopy(chatBubbleFailureMessage)
            setHasError(true)
            formMethods.setValue('code', '')
        }
    })

    const onSubmit = ({ code, phone }: any) => {
        if (!code) {
            sendPostLoginMutator(phone)
        } else {
            verificationCodeMutator({ phone, code })

            // Calling this we trigger ampli identify on <AmpliIdentify />
            queryClient.invalidateQueries('current-user')
        }
    }

    const handleOtpInput = (otp: string) => {
        // note the otp input restricts to only numeric
        formMethods.setValue('code', otp)
        if (otp.length === 6) {
            formMethods.handleSubmit(onSubmit)()
        }
    }

    const chatBubbleCopy = [
        'To sign in to your Tia account, enter your' +
            ' phone number and we will send you a text to' +
            ' confirm your identity. This is the safest way to' +
            ' protect your personal data!',

        'If you have a Tia account, we just texted you' +
            ' a code to sign in (it may take a few minutes).' +
            ' We use codes to help protect your personal data.' +
            ' Make sure you have cell service!'
    ]

    const chatBubbleFailureMessage =
        "🤔...looks like something didn't work. Mind giving that another shot?"

    if (isLoginByPhoneLoading || isCodeVerificationLoading) {
        return (
            <Spinner
                color="clay"
                sx={{ '> div > div': { width: 12, height: 12 } }}
            />
        )
    }

    return (
        <>
            <ResendModal
                isOpen={isResendModalOpen}
                phone={phone}
                title="Is this number correct? Make sure you have service!"
                ctaCopy="Send another text"
                backLinkCopy="Update my number"
                onCTAClick={() => sendPostLoginMutator(phone)}
                onClose={() => setIsResendModalOpen(false)}
            />
            <Form
                id="phoneLoginForm"
                onSubmit={onSubmit}
                useFormMethods={formMethods}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center'
                }}
            >
                <Input
                    type="number"
                    placeholder="Phone (include area code)"
                    name="phone"
                    sx={{
                        'display': successfulLogin ? 'none' : 'block',
                        'mb': 6,
                        'appearance': 'none',
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button ':
                            {
                                appearance: 'none'
                            }
                    }}
                />

                <Input
                    name="code"
                    sx={{
                        display: 'none'
                    }}
                />

                {/* use otp input component for styling */}
                {/* but rely on hook form registered input for functionality */}
                {successfulLogin && (
                    <Box sx={{ mb: 4 }}>
                        <ControlledOtpInput
                            value={code}
                            onChange={handleOtpInput}
                            hasError={hasError}
                        />
                    </Box>
                )}

                {step < 2 ? (
                    <Button
                        sx={{ minWidth: 175, mb: 7 }}
                        disabled={!formMethods.formState.isValid}
                    >
                        Next
                    </Button>
                ) : (
                    <Link
                        href="#"
                        sx={{ mt: 7, fontSize: 0, color: 'text' }}
                        onClick={() => setIsResendModalOpen(true)}
                    >
                        Didn't receive a text?
                    </Link>
                )}
            </Form>
        </>
    )
}

export default PhoneLoginForm
