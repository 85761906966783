import { useSearchParams } from 'react-router-dom'
import { CloseButton } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'

export function QuestionnaireHeader() {
    const [searchParams] = useSearchParams()

    return (
        <PageHeader
            mobileActions={
                <CloseButton
                    color="white"
                    // users should never end up here without an onCompleteRelativePath
                    // however, in development and testing of these pages we often go
                    // to the url without coming from a path that sets this param
                    url={
                        (searchParams.get(
                            'onCompleteRelativePath'
                        ) as string) || '/'
                    }
                />
            }
            desktopActions={
                <CloseButton
                    color="white"
                    url={
                        (searchParams.get(
                            'onCompleteRelativePath'
                        ) as string) || '/'
                    }
                />
            }
        />
    )
}
