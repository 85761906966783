import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

type FluAnswer = {
    patientId: number
    answer: boolean
}
async function postFluAnswer({ patientId, answer }: FluAnswer) {
    await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/flu-shot?apiVersion=v2`,
        JSON.stringify({ patientId, answer }),
        'POST'
    )
}

export const useSubmitFluAnswer = () => {
    const mutation = useMutation(postFluAnswer)

    return {
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        submitFluAnswer: mutation.mutateAsync
    }
}
