import { useMutation, useQueryClient } from 'react-query'
import { BASE_API_URL } from 'src/globals'
import { fetcher } from 'src/fetcher'

export function useDismissApptSuggestion() {
    const queryClient = useQueryClient()
    const mutation = useMutation(
        async (appointmentSuggestionUuid: string) =>
            await fetcher(
                `${BASE_API_URL}/tmd-api/appointments/suggestions/${appointmentSuggestionUuid}?apiVersion=v2`,
                JSON.stringify({ actionDismissed: true }),
                'PATCH'
            ),
        {
            retry: false,
            onSuccess: () => {
                queryClient.invalidateQueries('appointments-suggestions')
            }
        }
    )
    return mutation
}
