import React, { useMemo, PropsWithChildren, ReactNode, useEffect } from 'react'
import {
    Container,
    Flex,
    Heading,
    Text,
    Box,
    Spinner,
    TiaModal,
    Button,
    Image
} from '@asktia/tia-ui'
import { Divider, Pill } from 'src/components/Blocks'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import {
    ActionItemCard,
    AcupunctureCard,
    PassiveActionItemCard,
    TalkTherapyCard
} from 'src/components/AnnouncementCard'
import { StandardPageHeader } from 'src/components/PageHeader'
import { Link } from 'react-router-dom'

import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { useDismissApptSuggestion } from 'src/hooks/useDismissApptSuggestion'
import {
    useHasCarePlan,
    useGetUnseenCarePlanInterventions
} from 'src/hooks/useCarePlan'

import { generatePath } from 'react-router'
import { BOOKING_STEPS } from 'src/flows/AppointmentBooking/useBookingFlow'
import {
    AppointmentSuggestion,
    AppointmentWithLocationDetails
} from 'src/types'
import { useAmpli } from 'src/hooks/useAmpli'
import { Body } from 'src/components/Blocks/Body'
import { ChevronRight, CloseIcon } from 'src/components/Blocks/Icons'
import iconAcctDetails from 'src/assets/icon-acct-details.png'
import iconApptHistory from 'src/assets/icon-appt-history.png'
import iconHealthRecord from 'src/assets/icon-health-record.png'
import iconCarePlan from 'src/assets/icon-care-plan.png'
import iconBillingWoman from 'src/assets/icon-billing-woman.png'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { useUserInfo } from 'src/hooks'
import { useModal } from 'react-modal-hook'
import { PreviewExplanation } from 'src/components/Preview/PreviewExplanation'

const YourCareItem = (
    props: PropsWithChildren<{ title: ReactNode; url: string }>
) => {
    const { title, url } = props
    return (
        <Box
            sx={{
                'mb': 4,
                'border': 0,
                '& a': { textDecoration: 'none', color: 'text' }
            }}
        >
            <Link to={url}>
                <Flex sx={{ alignItems: 'center' }}>
                    {props.children}
                    <Box
                        sx={{
                            px: 4,
                            flex: 1
                        }}
                    >
                        <Heading
                            variant="h4"
                            sx={{
                                mb: 0,
                                minWidth: 245
                            }}
                        >
                            {title}
                        </Heading>
                    </Box>
                    <ChevronRight color="text" />
                </Flex>
            </Link>
        </Box>
    )
}

const LoadingCard = () => (
    <Flex
        sx={{
            height: '250px',
            flexDirection: 'column',
            justifyContent: 'center',
            backgrounColor: 'red'
        }}
    >
        <Spinner color="inactiveText" />
    </Flex>
)

const getBookLink = (appointmentSuggestion: AppointmentSuggestion) => {
    const basePath = BOOKING_STEPS.timeSelection.path

    const path = generatePath(basePath, {
        appointmentProfileUuid: appointmentSuggestion.appointmentProfileUuid
    })

    return `${path}?context=${appointmentSuggestion.uuid}`
}

const apptProfileAnnouncementCardMap: Record<string, () => JSX.Element> = {
    talk_therapy_intake: TalkTherapyCard,
    acupuncture: AcupunctureCard
}

/**
 * This hooks returns all the react components to be rendered in the carousel.
 * We have three types of elements:
 * - AnnouncementCard: Visible only if there's no upcoming appointment of the
 *      appt profiles in the map
 * - ActionItemCard: One card for each pending appt suggestion
 * - LoadingCard: If we're still fetching appts suggestions or upcoming appts
 */

export type ActionItem = {
    card: ReactNode
    item: string
}
export const getActionItems = ({
    isLoading,
    isUpcomingApptsLoading,
    appointmentSuggestions,
    appointments,
    onDismiss
}: {
    isLoading: boolean
    isUpcomingApptsLoading: boolean
    appointments: AppointmentWithLocationDetails[]
    appointmentSuggestions: AppointmentSuggestion[]
    onDismiss: (_as: AppointmentSuggestion) => void
}): ActionItem[] => {
    if (isLoading || isUpcomingApptsLoading) {
        return [
            {
                card: <LoadingCard key="action-item-card-loading" />,
                item: 'suggestion'
            }
        ]
    }

    const cards = []

    appointmentSuggestions.forEach((as, i) => {
        if (
            as.sourceType === 'scheduled' &&
            as.appointmentProfileName === 'tia_whole_health_exam'
        ) {
            cards.push({
                item: 'suggestion',
                card: (
                    // children and title props should be received from the BE
                    <PassiveActionItemCard
                        key={`action-item-card-${i}`}
                        imageUrl={as.appointmentProfileImageUrl}
                        bookHref={getBookLink(as)}
                        onDismiss={async () => onDismiss(as)}
                        appointmentSuggestion={as}
                        title={<Text>You’re due for your annual</Text>}
                    >
                        Book your <b>Annual Preventative Exam</b>, part of our
                        signature Whole Health Journey.
                    </PassiveActionItemCard>
                )
            })
        }

        cards.push({
            item: 'suggestion',
            card: (
                <ActionItemCard
                    key={`action-item-card-${i}`}
                    bookHref={getBookLink(as)}
                    locations={as.params.locationType || []}
                    imageUrl={as.appointmentProfileImageUrl}
                    label={as.appointmentProfileLabel}
                    onDismiss={async () => onDismiss(as)}
                    appointmentSuggestion={as}
                />
            )
        })
    })

    for (const profileName in apptProfileAnnouncementCardMap) {
        const AnnouncementCard = apptProfileAnnouncementCardMap[profileName]
        const booked = appointments.find(
            a => a.appointmentProfileName === profileName
        )
        const pending = appointmentSuggestions.find(
            a => a.appointmentProfileName === profileName
        )
        if (!booked && !pending) {
            cards.push({
                item: 'announcement',
                card: (
                    <AnnouncementCard
                        key={`action-item-card-${cards.length}`}
                    />
                )
            })
        }
    }

    return cards
}

export const useActionItemsToRender = (
    onDismiss: (as: AppointmentSuggestion) => void
) => {
    const { isLoading, appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })
    const { appointments, isLoading: isUpcomingApptsLoading } =
        useUpcomingAppointments()
    const loading = isLoading || isUpcomingApptsLoading

    // TODO: retype with changes what memoized value returned from function is
    const objectsToRender = useMemo<ActionItem[]>(() => {
        return getActionItems({
            isLoading,
            isUpcomingApptsLoading,
            appointmentSuggestions,
            appointments,
            onDismiss
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, appointmentSuggestions, appointments])

    return objectsToRender
}

export const YourCare = () => {
    const { user } = useUserInfo()

    const [showPreviewExplanationModal] = useModal(
        () => <PreviewExplanation showWhatsMissing={true} />,
        []
    )

    useEffect(() => {
        if (user?.membershipSignupStatus === 'preview') {
            showPreviewExplanationModal()
        }
    }, [user])

    const hasCarePlan = useHasCarePlan()
    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })
    const interventions = useGetUnseenCarePlanInterventions()
    const hasUnseenCarePlan = interventions.length > 0
    const { yourCareViewed } = useAmpli()
    const showBillingHistory =
        useAmpliFeatureFlag('show-billing-history') === 'treatment'

    const predictablePaymentsReleased =
        useAmpliFeatureFlag('predictable-payments-released') === 'treatment'

    useEffect(() => {
        yourCareViewed()
    }, [yourCareViewed])

    return (
        <Body>
            <StandardPageHeader />

            <NavBarHeading href="/">Your Care</NavBarHeading>
            <Container>
                <Divider
                    sx={{
                        bg: 'inactiveText',
                        display: ['block', 'none'],
                        mt: 0
                    }}
                />
                {(hasCarePlan ||
                    (appointmentSuggestions &&
                        appointmentSuggestions.length > 0)) && (
                    <>
                        <YourCareItem
                            title={
                                <Flex
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Flex>Your Care Plan</Flex>
                                    {hasUnseenCarePlan && <Pill>NEW</Pill>}
                                </Flex>
                            }
                            url={`/your-care/plan`}
                        >
                            <Image src={iconCarePlan} />
                        </YourCareItem>
                        <Divider sx={{ bg: 'cardOutline' }} />
                    </>
                )}
                <YourCareItem
                    title="Your Health Record"
                    url="/your-care/health-record"
                >
                    <Image src={iconHealthRecord} />
                </YourCareItem>
                <Divider sx={{ bg: 'cardOutline' }} />
                {!predictablePaymentsReleased && (
                    <YourCareItem
                        title={
                            showBillingHistory
                                ? 'Past Appointments & Bills'
                                : 'Appointment History'
                        }
                        url="/appointment-history"
                    >
                        <Image src={iconApptHistory} />
                    </YourCareItem>
                )}
                {predictablePaymentsReleased && (
                    <>
                        <YourCareItem
                            title={'Appointment History'}
                            url="/appointment-history-list"
                        >
                            <Image src={iconApptHistory} />
                        </YourCareItem>
                        <Divider sx={{ bg: 'cardOutline' }} />

                        <YourCareItem
                            title={'Appointment Billing'}
                            url="/appointment-billing-list"
                        >
                            {/* TODO: use correct icon later */}
                            <Image
                                src={iconBillingWoman}
                                sx={{ width: 56, height: 56 }}
                            />
                        </YourCareItem>
                    </>
                )}
                <Divider sx={{ bg: 'cardOutline' }} />
                <YourCareItem title="Account Details" url="/account-info">
                    <Image src={iconAcctDetails} />
                </YourCareItem>
                <Divider sx={{ bg: 'cardOutline' }} />
            </Container>
        </Body>
    )
}
