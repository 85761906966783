import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { Condition } from './useSaveCondition'

export interface IConditionConfirmation {
    patientId: number
}

const deleteCondition = async (condition: Condition) => {
    return (
        fetcher(
            `${BASE_API_URL}/uhr-api/conditions/patient-owned-conditions/${condition.uuid}`,
            JSON.stringify({
                asserterCategory: 'patient',
                clinicalStatus: condition.clinicalStatus,
                verificationStatus: 'entered-in-error'
            }),
            'PATCH'
        ),
        {
            retry: false
        }
    )
}

export const useDeleteCondition = () => {
    const { mutateAsync } = useMutation(
        async (condition: Condition) => await deleteCondition(condition)
    )
    return mutateAsync
}
