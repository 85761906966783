import { useQuery } from 'react-query'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { subMinutes } from 'date-fns'
import { AppointmentV1 } from 'src/types'
import { fetcher } from 'src/fetcher'

async function fetchAppointmentHistory(patientId: number) {
    const tenMinAgo = subMinutes(new Date(), 10).toISOString()
    const { appointments = [] } = await fetcher(
        `${BASE_API_URL}/tmd-api/patients/${patientId}/appointments?apiVersion=v1&beforeDate=${tenMinAgo}&includeArchived=true`,
        undefined,
        'GET',
        {
            errorMsg: 'Error fetching profiles'
        }
    )

    return appointments.map((a: AppointmentV1) => ({
        ...(a as AppointmentV1),
        scheduledTime: new Date(a.scheduledTime as unknown as string),
        startTime: new Date(a.startTime as unknown as string)
    }))
}

export function useAppointmentHistory() {
    const { user, isLoading } = useUserInfo()
    const query = useQuery<AppointmentV1[]>(
        ['appointmentHistory', user?.id],
        async () => {
            return await fetchAppointmentHistory(user!.id)
        },
        {
            enabled: !!user,
            staleTime: 1000 * 60 * 5 // refetch rendered data after 5min
        }
    )

    return {
        appointments: query.data || [],
        isLoading: isLoading || query.isLoading
    }
}
