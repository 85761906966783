import { useQuery } from 'react-query'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { Invoice, InvoiceList } from 'src/types'
import { fetcher } from 'src/fetcher'

async function fetchInvoices(patientId: number, referenceId?: string) {
    const { results = [] } = await fetcher<InvoiceList>(
        `${BASE_API_URL}/billing-api/v1/invoice?userId=${patientId}${
            referenceId ? `&referenceId=${referenceId}` : ''
        }`,
        undefined,
        'GET',
        {
            errorMsg: 'Error fetching invoices'
        }
    )

    return results
}

export function useInvoices(referenceId?: string) {
    const { user, isLoading } = useUserInfo()
    const query = useQuery<Invoice[]>(
        ['fetch-invoices', user?.id],
        async () => await fetchInvoices(user!.id, referenceId),
        {
            enabled: !!user,
            staleTime: 1000 * 60 * 15 // refetch rendered data after 15min
        }
    )

    return {
        invoices: query.data || [],
        isLoading: isLoading || query.isLoading
    }
}
