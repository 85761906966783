import { Card, Flex, Text } from '@asktia/tia-ui'
import { capitalize } from 'lodash'
import { Bill } from './useBillingSummary'

const SelfPayBillSummary = ({ bill }: { bill: FormattedBill }) => {
    return (
        <Card sx={{ py: 4 }}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Processed as</Text>
                <Text>{capitalize(bill.processedAs)}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Total charges</Text>
                <Text>{bill.patientResponsibility}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Insurance paid</Text>
                <Text>N/A</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Patient responsibility</Text>
                <Text>{bill.patientResponsibility}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Patient paid</Text>
                <Text>{bill.patientPaid}</Text>
            </Flex>
            {bill.patientAdjustments && (
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        pb: 2
                    }}
                >
                    <Text>Patient adjustment</Text>
                    <Text>{bill.patientAdjustments}</Text>
                </Flex>
            )}
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    fontWeight: 'bold'
                }}
            >
                <Text>Remaining balance</Text>
                <Text>{bill.remainingBalance}</Text>
            </Flex>
        </Card>
    )
}

const InsurancePayBillSummary = ({ bill }: { bill: FormattedBill }) => {
    return (
        <Card sx={{ py: 4 }}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Processed as</Text>
                <Text>{capitalize(bill.processedAs)}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Insurance discounted rate</Text>
                <Text>{bill.totalCharges}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Insurance paid</Text>
                <Text>{bill.insuranceCovered}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Patient responsibility</Text>
                <Text>{bill.patientResponsibility}</Text>
            </Flex>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    pb: 2
                }}
            >
                <Text>Patient paid</Text>
                <Text>{bill.patientPaid}</Text>
            </Flex>
            {bill.patientAdjustments && (
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        pb: 2
                    }}
                >
                    <Text>Patient adjustment</Text>
                    <Text>{bill.patientAdjustments}</Text>
                </Flex>
            )}
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    fontWeight: 'bold'
                }}
            >
                <Text>Remaining balance</Text>
                <Text>{bill.remainingBalance}</Text>
            </Flex>
        </Card>
    )
}

export type FormattedBill = {
    collectable: true
    processedAs: 'Insurance-pay' | 'Self-pay'
    insuranceCovered: string
    patientResponsibility: string
    patientPaid: string
    remainingBalance: string
    patientAdjustments: string
    totalCharges: string
}

/**
 * Formats money values in a bill
 */
function formatBill(bill: Bill): FormattedBill | { collectable: false } {
    if (!bill.collectable) {
        return { collectable: false }
    }

    const formattedBill: FormattedBill = {
        collectable: bill.collectable,
        processedAs: bill.processedAs,
        insuranceCovered: `$${(bill.insuranceCoveredInCents / 100).toFixed(2)}`,
        patientResponsibility: `$${(
            bill.patientResponsibilityInCents / 100
        ).toFixed(2)}`,
        patientPaid: `-$${(bill.patientPaidInCents / 100).toFixed(2)}`,
        remainingBalance: `$${(bill.remainingBalanceInCents / 100).toFixed(2)}`,
        patientAdjustments: bill?.patientAdjustmentsInCents
            ? `$${(bill.patientAdjustmentsInCents / 100).toFixed(2)}`
            : '$0.00',
        totalCharges: bill?.totalChargesInCents
            ? `$${(bill.totalChargesInCents / 100).toFixed(2)}`
            : '$0.00'
    }
    return formattedBill
}

const BillingSummary = ({ bill }: { bill: Bill }) => {
    const usingInsurance = bill.processedAs === 'Insurance-pay'
    const formattedBill = formatBill(bill)

    if (!formattedBill.collectable) {
        return null
    }

    return usingInsurance ? (
        <InsurancePayBillSummary bill={formattedBill} />
    ) : (
        <SelfPayBillSummary bill={formattedBill} />
    )
}

export default BillingSummary
