import React, { FC } from 'react'
import { Button, Flex, Form, Text, useTiaUI } from '@asktia/tia-ui'
import { FieldValues, useForm } from 'react-hook-form'
import { ArrowRightIcon } from 'src/components/Blocks/Icons'
import {
    useCreateMessageForThread,
    FILE_UPLOAD_NAME,
    CreateMessagePayload
} from './useThread'
import { TextareaWithAttachment } from './TextareaWithAttachment'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

export interface ISendMessageForm {
    threadId: number
}

const schema = yup.object().shape({
    [FILE_UPLOAD_NAME]: yup.mixed(),
    body: yup
        .string()
        .required()
        .when(FILE_UPLOAD_NAME, {
            is: (val: FileList) => val.length > 0,
            then: schema => schema.notRequired()
        })
})

export const SendMessageForm: FC<ISendMessageForm> = ({ threadId }) => {
    const formMethods = useForm<CreateMessagePayload | FieldValues>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    })
    const { mutateAsync } = useCreateMessageForThread(threadId)
    const { theme } = useTiaUI()

    const { isValid, isSubmitting } = formMethods.formState

    const onSubmit = async (values: CreateMessagePayload | FieldValues) => {
        await mutateAsync(values as CreateMessagePayload)
        formMethods.reset()
    }

    return (
        <Form
            id="chatForm"
            onSubmit={onSubmit}
            useFormMethods={formMethods}
            sx={{
                bg: 'mainBackground',
                width: ['calc(100% - 48px)', '100%'],
                position: 'absolute',
                bottom: 0
            }}
        >
            <Flex sx={{ alignItems: 'center', mb: 6 }}>
                <Flex
                    sx={{
                        width: '90%',
                        flexDirection: 'row'
                    }}
                >
                    <TextareaWithAttachment
                        textarea={{
                            name: 'body',
                            placeholder: 'Type text...'
                        }}
                        attachment={{
                            name: FILE_UPLOAD_NAME
                        }}
                        formMethods={formMethods}
                    />
                </Flex>

                <Button
                    variant="icon"
                    type="submit"
                    sx={{
                        'ml': 2,
                        ':disabled': {
                            ...theme?.buttons?.icon,
                            'bg': 'secondaryBackground',
                            '&:hover': {
                                bg: 'secondaryBackground',
                                cursor: 'not-allowed'
                            },
                            '&:active': {
                                bg: 'secondaryBackground'
                            }
                        }
                    }}
                    disabled={!isValid}
                    loading={isSubmitting}
                >
                    <ArrowRightIcon
                        color={isValid ? 'white' : 'inactiveText'}
                        style={{ transform: 'rotate(-90deg)' }}
                    />
                </Button>
            </Flex>
        </Form>
    )
}
