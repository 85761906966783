import { FC } from 'react'
import { Button, Flex, Icon, Text } from '@asktia/tia-ui'

const DeclaredValuePill: FC<{
    label: string
    onRemove: () => void
}> = ({ label, onRemove }) => {
    return (
        <Flex
            sx={{
                border: (t: any) => `1px solid ${t.colors?.inputOutline}`,
                py: '3px',
                px: 2,
                borderRadius: 2,
                alignItems: 'center',
                color: 'white',
                overflow: 'hidden',
                bg: 'darkSecondaryButton',
                justifyContent: 'space-between',
                mr: 2,
                mb: 2
            }}
        >
            <Text
                sx={{
                    whiteSpace: 'normal',
                    width: 'fit-content',
                    lineHeight: '24px'
                }}
            >
                {label}
            </Text>
            <Button
                variant="unstyled"
                sx={{
                    ml: 2,
                    fontSize: 7,
                    height: 5,
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={onRemove}
            >
                <Icon.Close
                    color="white"
                    sx={{
                        fontSize: 2
                    }}
                />
            </Button>
        </Flex>
    )
}

export default DeclaredValuePill
