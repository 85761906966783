import {
    Button,
    Flex,
    Grid,
    Image,
    Box,
    Paragraph,
    Text,
    Card,
    Container,
    Blocks,
    Link
} from '@asktia/tia-ui'
import InstagramIcon from 'src/assets/instagram.png'
import { FC } from 'react'

import paynentsIllustration from '../../assets/illustration-all-others.png'
import dollarSign from '../../assets/dollar-sign.svg'
import { CallTia } from 'src/components/Blocks'

export const PredictablePaymentsFooter: FC = () => {
    return (
        <Box
            sx={{
                mt: 5,
                width: '100%',
                background: 'raspberry',
                position: 'relative'
            }}
        >
            <Blocks.Wave
                position="top"
                fill="raspberry"
                sx={{ backgroundColor: 'mainBackground' }}
            />
            <Container
                sx={{
                    py: 8
                }}
            >
                <Card
                    sx={{
                        width: '100%',
                        mb: 6,
                        backgroundColor: 'mainBackground'
                    }}
                >
                    <Grid gap={6} columns={['1fr', '1fr 2fr']}>
                        <Flex
                            sx={{
                                width: '100%',
                                justifyContent: ['center', 'flex-end']
                            }}
                        >
                            <Image
                                src={paynentsIllustration}
                                sx={{
                                    width: 160,
                                    height: 160
                                }}
                            />
                        </Flex>

                        <Flex
                            sx={{
                                width: '100%',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Flex sx={{ alignItems: 'flex-start' }}>
                                <Image src={dollarSign} />

                                <Text
                                    sx={{
                                        fontFamily: 'Inferi',
                                        fontSize: 20,
                                        fontWeight: 350,
                                        lineHeight: '120%',
                                        maxWidth: 400,
                                        mb: 4
                                    }}
                                >
                                    Predictable Payments
                                </Text>
                            </Flex>
                            <Paragraph sx={{ maxWidth: 400 }}>
                                With Tia's Predictable Payments, you’ll pay your
                                bill over time, $100 per month. We’re taking the
                                surprise out of surprise medical bills.
                            </Paragraph>
                            <Button
                                as="a"
                                href="https://asktia.com/predictable-payments"
                                sx={{ mt: 2 }}
                                variant="secondaryDark"
                            >
                                Learn More
                            </Button>
                        </Flex>
                    </Grid>
                </Card>

                <Flex
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                    }}
                >
                    <Flex
                        sx={{
                            justifyContent: 'space-between',
                            fontSize: 0
                        }}
                    >
                        <Link
                            href="https://intercom.help/ask-tia/en/"
                            sx={{
                                fontSize: 0,
                                color: 'white',
                                mr: 4,
                                textDecoration: 'none'
                            }}
                        >
                            FAQs
                        </Link>
                        <Link
                            href="https://asktia.com/membership-terms-of-use"
                            sx={{
                                fontSize: 0,
                                color: 'white',
                                mr: 4,
                                textDecoration: 'none'
                            }}
                        >
                            Membership Terms
                        </Link>
                        <Link
                            href="https://assets.asktia.com/pdfs/tia-privacy-policy.pdf"
                            sx={{
                                fontSize: 0,
                                color: 'white',
                                mr: 4,
                                textDecoration: 'none'
                            }}
                        >
                            NPP
                        </Link>
                        <Link
                            href="https://asktia.com/privacy-policy"
                            sx={{
                                fontSize: 0,
                                color: 'white',
                                mr: 0,
                                textDecoration: 'none'
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </Flex>

                    <Flex
                        sx={{
                            mt: '32px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            fontSize: 0
                        }}
                    >
                        <Text sx={{ color: 'white' }}>Got questions?</Text>
                        <CallTia
                            sx={{
                                fontSize: 0,
                                mt: '4px',
                                color: 'white',
                                textDecoration: 'none'
                            }}
                        ></CallTia>
                    </Flex>
                    <Flex sx={{ mt: '32px' }}>
                        <Link href="https://www.instagram.com/asktia/?hl=en ">
                            <Image src={InstagramIcon} />
                        </Link>
                    </Flex>
                    <Flex>
                        <Text
                            variant="eyebrow"
                            sx={{
                                fontSize: 0,
                                textTransform: 'uppercase',
                                color: 'white',
                                mt: '32px'
                            }}
                        >
                            © Tia 2024
                        </Text>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    )
}
