import React, { ErrorInfo, ReactNode } from 'react'
import logger from '.'
import { ErrorComponent } from './ErrorComponent'

interface IErrorBoundaryProps {
    fallback?: ReactNode
    children: ReactNode
}

interface IErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends React.Component<
    IErrorBoundaryProps,
    IErrorBoundaryState
> {
    constructor(props: IErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidMount() {
        this.startErrorLog()
    }

    startErrorLog() {
        window.onerror = (
            message: Event | string,
            file?: string,
            line?: number,
            column?: number,
            errorObject?: Error
        ) => {
            column =
                column || (window.event && (window.event as any).errorCharacter)
            let stack: undefined | string | any[] = errorObject
                ? errorObject.stack
                : undefined

            //trying to get stack from IE
            if (!stack) {
                stack = []
                //@ts-ignore:next-line
                let f = arguments.callee.caller
                while (f) {
                    stack.push(f.name)
                    f = f.caller
                }
                //@ts-ignore:next-line
                errorObject['stack'] = stack
            }

            const data = {
                message: message,
                file: file,
                line: line,
                column: column,
                errorStack: stack
            }

            logger.error(`Caught window onerror: ${message}`, data)
            return false
        }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        logger.error(`Caught render error: ${error}`, info.componentStack)
        this.setState({ hasError: true })
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback) {
                return this.props.fallback
            }
            return <ErrorComponent />
        }

        return this.props.children
    }
}

export default ErrorBoundary
