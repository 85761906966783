import { useState } from 'react'
import { LoginLayout } from '../LoginLayout'
import { Link } from '@asktia/tia-ui'
import PhoneLoginForm from './PhoneLoginForm'
import EmailLoginForm from './EmailLoginForm'
import { useSearchParams } from 'react-router-dom'

const WWW_PUBLIC_URL = process.env.REACT_APP_WWW_PUBLIC_URL

const Login = () => {
    const [successfulLogin, setSuccessfulLogin] = useState(false)
    const [usingPhone, setUsingPhone] = useState(true)
    const [chatBubbleCopy, setChatBubbleCopy] = useState('')
    const [stepMessage, setStepMessage] = useState('')

    const [searchParams] = useSearchParams()

    const shouldReload = searchParams.get('reload')
    if (shouldReload === 'true') {
        window.location.href = `/r/login?nocache=${new Date().getTime()}`
    }

    return (
        <>
            <LoginLayout stepMessage={stepMessage} chatMessage={chatBubbleCopy}>
                {usingPhone && (
                    <PhoneLoginForm
                        setStepMessage={setStepMessage}
                        setChatBubbleCopy={setChatBubbleCopy}
                        successfulLogin={successfulLogin}
                        setSuccessfulLogin={setSuccessfulLogin}
                    ></PhoneLoginForm>
                )}
                {!usingPhone && (
                    <EmailLoginForm
                        setStepMessage={setStepMessage}
                        setChatBubbleCopy={setChatBubbleCopy}
                        successfulLogin={successfulLogin}
                        setSuccessfulLogin={setSuccessfulLogin}
                    ></EmailLoginForm>
                )}
                {!successfulLogin && (
                    <>
                        <Link
                            href={`${WWW_PUBLIC_URL}/join/join-tia`}
                            sx={{ fontSize: 0, mb: 5, color: 'text' }}
                        >
                            Not a Tia member yet?
                        </Link>
                        <Link
                            onClick={e => {
                                e.stopPropagation()
                                setUsingPhone(!usingPhone)
                            }}
                            sx={{
                                fontSize: 0,
                                color: 'text',
                                cursor: 'pointer'
                            }}
                        >
                            {usingPhone
                                ? 'Sign in with email'
                                : 'Sign in with SMS'}
                        </Link>
                    </>
                )}
            </LoginLayout>
        </>
    )
}

export default Login
