import { Box, Flex, Heading, Text } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { Intervention } from 'src/types'
import { format } from 'date-fns'
import { ArrowRightIcon } from 'src/components/Blocks/Icons'
import { Link } from 'react-router-dom'
import { useAmpli, useUserInfo } from 'src/hooks'

interface InterventionCardProps {
    intervention: Intervention
    lastSeen?: Date
}

export const InterventionCard: FC<InterventionCardProps> = ({
    intervention,
    lastSeen
}) => {
    const {
        originAppointment,
        originActor,
        carePlanIntervention,
        originTimestamp
    } = intervention

    const isClickable =
        !!carePlanIntervention.patientEducation ||
        !!carePlanIntervention.prescriptionDirections

    const { user, isLoading: userIsLoading } = useUserInfo()
    const { ampli } = useAmpli()
    useEffect(() => {
        if (user && !userIsLoading) {
            ampli.carePlanDetailsViewed({
                intervention: carePlanIntervention.uuid,
                userID: user.id.toString()
            })
        }
    }, [userIsLoading])

    const isNew =
        !!lastSeen && new Date(intervention.originTimestamp) > lastSeen

    return (
        <Box
            as={isClickable ? Link : undefined}
            // TS got confused with the "as" prop
            // @ts-ignore
            to={`/your-care/plan/${carePlanIntervention.uuid}`}
            sx={{
                'fontSize': 2,
                'alignItems': 'center',
                'background': 'cardBackground',
                'textDecoration': 'none',
                'border': t => `1px solid ${t.colors?.cardOutline}`,
                'fontWeight': 'unset !important',
                'userSelect': 'none',
                'borderRadius': 2,
                'py': 4,
                'px': 5,
                'mb': 2,
                ':hover': {
                    borderColor: 'inputOutlineHover'
                },
                ':active': {
                    background: 'inputBackgroundSelected',
                    borderColor: 'inputOutlineHover'
                }
            }}
        >
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Flex
                    sx={{
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                    }}
                >
                    <Text variant="eyebrow" color="supportText">
                        {isNew && (
                            <>
                                <Box as="span" sx={{ color: 'tiaPink' }}>
                                    NEW
                                </Box>
                                {' · '}
                            </>
                        )}
                        {format(new Date(originTimestamp), 'MMM dd, yyyy')}
                    </Text>

                    <Heading
                        variant="h4"
                        sx={{
                            my: 0,
                            color: 'text'
                        }}
                    >
                        {carePlanIntervention.name}
                    </Heading>

                    {originActor && originAppointment && (
                        <Text
                            sx={{
                                fontSize: 0,
                                color: 'supportText'
                            }}
                        >
                            Added by {originActor.displayName} from{' '}
                            {originAppointment?.appointmentProfile.label}
                        </Text>
                    )}
                </Flex>

                {isClickable && (
                    <Flex
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <ArrowRightIcon color="text" />
                    </Flex>
                )}
            </Flex>
        </Box>
    )
}
