import { Heading, Flex, Button, Whisper, Paragraph } from '@asktia/tia-ui'
import { Footer } from 'src/components/Blocks/Footer'
import { View } from 'src/components/Blocks/View'
import { HealthRecordEntry, SaveStepFunction } from 'src/types'
import { UHRProgressCard } from 'src/components/UHRProgressCard'
import { useUserInfo } from 'src/hooks'
import { Dispatch, SetStateAction, useState } from 'react'
import { useFluShotStatus } from 'src/flows/AutomatedCheckin/views/PreApptInfo/useFluShotStatus'
import FluQuestion from 'src/flows/AutomatedCheckin/views/PreApptInfo/FluQuestion'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { Route, Routes, useNavigate } from 'react-router'

type PreApptInfoProps = {
    ros: HealthRecordEntry[]
    feedback: HealthRecordEntry[]
    isComplete: boolean
    saveStep: SaveStepFunction
    isSaving: boolean
}

const PreApptInfoQuestions = (props: {
    onSubmit: () => void
    isSaving: boolean
    steps: HealthRecordEntry[]
    isComplete: boolean
    setAnsweringSeasonalUpdate: Dispatch<SetStateAction<boolean>>
}) => {
    let hasFirstNotDoneStep = false

    const { user } = useUserInfo()
    const fluShotStatus = useFluShotStatus(user?.id)
    const showFluShot =
        useAmpliFeatureFlag('seasonal-flu-shot-question') === 'on'
    const navigate = useNavigate()

    const isAllStepsDone = props.steps.every(step => step.status === 'done')

    const disableSubmit =
        !props.isComplete ||
        props.isSaving ||
        (showFluShot &&
            (fluShotStatus?.isLoading || !fluShotStatus?.fluShotResponse))

    return (
        <>
            <Heading h2>Your Current Health</Heading>
            <Paragraph sx={{ mb: 5 }}>
                We regularly check in to update your health record, so we can
                provide you the best care possible. At Tia, your Care Team
                considers both your current health and medical history when
                making decisions.
            </Paragraph>

            {props.steps.map(record => {
                let isFirstNotDone = false
                if (!hasFirstNotDoneStep && record.status !== 'done') {
                    hasFirstNotDoneStep = true
                    isFirstNotDone = true
                }

                return (
                    <UHRProgressCard
                        record={record}
                        isNext={isFirstNotDone}
                        status={record.status}
                    />
                )
            })}

            {!fluShotStatus.isLoading && showFluShot && (
                <UHRProgressCard
                    record={
                        {
                            title: 'Seasonal Update'
                        } as any
                    }
                    isNext={isAllStepsDone && !fluShotStatus?.fluShotResponse}
                    status={
                        fluShotStatus?.fluShotResponse ? 'done' : 'not_started'
                    }
                    /**
                     * boo onClick navigation :(
                     * but it fixes jank because UHR cards are
                     * regular link and cause a page reload
                     * they have to be regular links because
                     * they must support navigating to Pug
                     */
                    onClick={e => {
                        e.preventDefault()
                        navigate('./seasonal_update')
                    }}
                />
            )}

            <Footer sx={{ mt: 5, px: 0 }}>
                <Button
                    disabled={disableSubmit}
                    onClick={() => props.onSubmit()}
                    loading={props.isSaving}
                    fullWidth
                >
                    Complete Check In
                </Button>
            </Footer>
        </>
    )
}

export const PreApptInfo = ({
    ros,
    isComplete,
    feedback,
    saveStep,
    isSaving
}: PreApptInfoProps) => {
    return (
        <View withFooter>
            <Routes>
                <Route path="/seasonal_update" element={<FluQuestion />} />
                <Route
                    path="/"
                    element={
                        <PreApptInfoQuestions
                            onSubmit={saveStep}
                            isSaving={isSaving}
                            steps={[...ros, ...feedback]}
                            isComplete={isComplete}
                            setAnsweringSeasonalUpdate={() => null}
                        />
                    }
                />
            </Routes>
        </View>
    )
}

const HealthRecordSkeleton = (width: string, index: number) => (
    <Flex
        key={index}
        sx={{
            pt: 2,
            pb: 2,
            alignItems: 'center',
            borderBottom: ({ colors }) => `1px solid ${colors?.grey10}`,
            justifyContent: 'space-between'
        }}
    >
        <Whisper sx={{ width, height: 56 }}></Whisper>
    </Flex>
)

export const PreApptInfoSkeleton = () => (
    <View>
        <Heading h2>Your Current Health</Heading>
        <Paragraph sx={{ mb: 5 }}>
            We regularly check in to update your health record, so we can
            provide you the best care possible. At Tia, your Care Team considers
            both your current health and medical history when making decisions.
        </Paragraph>
        {['100%', '100%', '100%', '100%'].map(HealthRecordSkeleton)}

        <Button disabled sx={{ mt: 6 }}>
            Complete Check In
        </Button>
    </View>
)
