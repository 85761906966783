import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks/useUserInfo'
import { AppointmentSuggestion, QueryParams } from 'src/types'

type AppointmentSuggestionStatus = 'pending' | undefined

async function getAppointmentSuggestions(
    queryParams: QueryParams
): Promise<AppointmentSuggestion[]> {
    const params = Object.entries(queryParams)
        .map(e => `${e[0]}=${e[1]}`)
        .join('&')

    const appointmentSuggestions = await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/suggestions?apiVersion=v2&${params}`
    )

    return appointmentSuggestions.sort(
        (a: AppointmentSuggestion, b: AppointmentSuggestion) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
}

export function useAppointmentSuggestions(
    props: {
        status?: AppointmentSuggestionStatus
        actionDismissed?: boolean
    } = {}
) {
    const { user, isLoading } = useUserInfo()

    const query = useQuery<AppointmentSuggestion[]>(
        ['appointments-suggestions', user?.id],
        () => getAppointmentSuggestions(props),
        {
            enabled: !!user
        }
    )

    return {
        refetch: query.refetch,
        isLoading: isLoading || query.isLoading,
        appointmentSuggestions: query.data || []
    }
}
