import { AsyncSelect, Box, Label, Text } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { FieldValues, useFormContext, UseFormMethods } from 'react-hook-form'
import { ImmunizationField } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/components/ImmunizationField'
import { uniqBy } from 'lodash'
import { useAmpli } from 'src/hooks'
import { useMedplumContext } from '@medplum/react'
import { ValueSetExpansionContains } from '@medplum/fhirtypes'
import { Bundle, Immunization } from '@medplum/fhirtypes'

interface ImmunizationOption {
    label: string
    value: string
    system: string
}

type ImmunizationWithoutPatient = Omit<Immunization, 'patient'>

export const convertToApiPayload = (
    values: any
): ImmunizationWithoutPatient[] => {
    const result: ImmunizationWithoutPatient[] = []
    const othersImmunizations = values.others || []

    if (othersImmunizations.length === 0) {
        return []
    }

    for (const immunization of othersImmunizations) {
        const checkBox = values[`other-${immunization.value}`]

        if (!checkBox) {
            continue
        }

        const dates = values[`other-${immunization.value}-dates`]?.map(
            (x: any) => x?.value
        )

        for (const date of dates) {
            result.push({
                resourceType: 'Immunization',
                status: 'completed',
                vaccineCode: {
                    coding: [
                        {
                            code: immunization.value,
                            display: immunization.label,
                            system: immunization.system
                        }
                    ],
                    text: immunization.label
                },
                occurrenceDateTime: new Date(date).toISOString()
            })
        }
    }
    return result
}

export const convertToFormPayload = (
    values: Immunization[],
    recommendedImmunizations: {
        system: string
        code: string
        display: string
        form: {
            label: string
            name: string
            immunizationCode: string
            supportText: string
        }
    }[],
    formMethods: UseFormMethods<FieldValues>
) => {
    const recommendedImmunizationCodes = recommendedImmunizations.map(
        x => x.code
    )
    formMethods.setValue('otherSelect', '')

    formMethods.setValue(
        'others',
        uniqBy(
            values
                .filter(
                    x =>
                        !recommendedImmunizationCodes.includes(
                            x.vaccineCode?.coding?.[0]?.code ?? ''
                        )
                )
                .filter(immunization => immunization.status === 'completed')
                .map(current => ({
                    label: current.vaccineCode?.coding?.[0]?.display,
                    value: current.vaccineCode?.coding?.[0]?.code,
                    system: current.vaccineCode?.coding?.[0]?.system
                })),
            'value'
        )
    )
}

const relevanceSort = (
    immunizations: ValueSetExpansionContains[],
    term: string
) => {
    return immunizations.sort(
        (left: ValueSetExpansionContains, right: ValueSetExpansionContains) => {
            const delta = 0
            // left.display?.localeCompare(right.display ?? '', 'en', {
            //     sensitivity: 'base'
            // }) ?? 0

            return delta === 0
                ? Math.sign(
                      computeRelevance(term, left) -
                          computeRelevance(term, right)
                  )
                : delta
        }
    )
}

const computeRelevance = (
    term: string,
    value: ValueSetExpansionContains
): number => {
    const searchTerm = term.toLowerCase()
    const terms = searchTerm.split(' ')
    const display = value.display?.toLowerCase() ?? ''

    if (display.startsWith(searchTerm)) {
        return 0
    }

    if (display.includes(searchTerm)) {
        const criteria = new RegExp(terms.join('|'), 'gi')
        const occurences = display.match(criteria)?.length ?? 0
        return 10 * occurences
    }

    return 100
}

export const OtherImmunizations: FC<{
    immunizations: Immunization[]
    isLoading: boolean
    onCreateSuccess: (data: Bundle) => void
    onEditSuccess: (data: Immunization) => void
}> = ({ immunizations, isLoading, onCreateSuccess, onEditSuccess }) => {
    const { clickedOnOtherImmunization, searchImmunization } = useAmpli()
    const { register, getValues, watch, setValue } = useFormContext()
    const otherSelect = watch('otherSelect')
    const otherImmunizations = watch('others')
    const { medplum } = useMedplumContext()

    useEffect(() => {
        register('others')
    }, [])

    async function fetchVaccinesValueSetTypeahead(
        searchTerm: string
    ): Promise<ImmunizationOption[]> {
        const response = await medplum.valueSetExpand({
            filter: searchTerm,
            url: 'http://hl7.org/fhir/ValueSet/vaccine-code',
            count: 50
        })

        const valueSetElements = response.expansion
            ?.contains as ValueSetExpansionContains[]

        const newData: ValueSetExpansionContains[] = []

        for (const valueSetElement of valueSetElements) {
            if (
                valueSetElement.code &&
                valueSetElement?.system?.includes('cvx') && // gurarantees user can only pick cvx even if values set contains others
                !newData.some(item => item.code === valueSetElement.code)
            ) {
                newData.push(valueSetElement)
            }
        }

        const sortedOptions = relevanceSort(newData, searchTerm)

        if (sortedOptions) {
            const data = sortedOptions.map(valueSet => ({
                label: valueSet.display!,
                value: valueSet.code!,
                system: valueSet.system!
            }))

            searchImmunization({ AmountOfImmunizationsFound: data.length })
            return data
        } else {
            return []
        }
    }

    useEffect(() => {
        if (otherSelect) {
            const values = getValues()
            const otherImmunizations = values['others'] || []

            setValue('others', [...otherImmunizations, otherSelect])
            setValue('otherSelect', '')
        }
    }, [otherSelect])

    return (
        <Box sx={{ mt: 5 }}>
            <Label sx={{ fontSize: 7, mb: 0 }}>Other Immunizations</Label>

            <Text
                sx={{
                    fontSize: 0,
                    color: 'supportText',
                    mt: 3,
                    mb: 4,
                    display: 'block'
                }}
            >
                If you've had other immunizations than the recommended list
                above, please add them.
            </Text>

            <Label sx={{ fontSize: 0, color: 'supportText' }}>
                Search Immunization
            </Label>
            <AsyncSelect
                name="otherSelect"
                loadOptions={async (searchTerm: string) =>
                    fetchVaccinesValueSetTypeahead(searchTerm)
                }
                getOptionValue={opt => opt?.value}
                hideSelectedOptions
                placeholder="e.g. Varicella shot"
            />

            {otherImmunizations?.map((immunization: ImmunizationOption) => (
                <ImmunizationField
                    key={`immunization-field-${immunization.value}`}
                    label={immunization.label}
                    name={`other-${immunization.value}`}
                    immunizationCode={immunization.value}
                    immunizationDisplay={immunization.label}
                    immunizationSystem={immunization.system}
                    defaultSelected
                    onCheckboxClick={evt =>
                        clickedOnOtherImmunization({
                            Checked: (evt.target as any).checked
                        })
                    }
                    immunizations={immunizations}
                    isLoading={isLoading}
                    onCreateSuccess={onCreateSuccess}
                    onEditSuccess={onEditSuccess}
                />
            ))}
        </Box>
    )
}
