import styled from '@emotion/styled'
import { Flex, Heading, ThemeStyleObject, useTiaUI } from '@asktia/tia-ui'
import { CSSProperties } from 'react'
import { keyframes } from '@emotion/react'

export type AnimationVariant = 'aci' | 'booking' | 'on-demand'

export const SuccessAnimation = ({
    variant
}: {
    variant: AnimationVariant
}) => {
    const zoomIn = keyframes`
        0% {
            opacity: 0;
            transform: scale3d(.3,.3,.3);
        }
        100% {
            opacity: 1;
        }
    `
    const fadeUp = keyframes`
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translate3d(0,-300%,0);
        }
    `

    const sharedStyles: ThemeStyleObject = {
        position: 'absolute',
        top: '50vh',
        p: 0,
        opacity: 0,
        animation: `${zoomIn} 300ms 50ms ease-in, ${fadeUp} 300ms 2s ease-out`,
        animationFillMode: 'forwards'
    }

    const fadeUpTime = variant === 'aci' ? 300 : 500
    const title = {
        'aci': { firstLine: 'Connecting', secondLine: 'your care...' },
        'booking': { firstLine: 'Booking', secondLine: 'your care...' },
        'on-demand': { firstLine: 'Before we', secondLine: 'see you...' }
    }[variant]

    return (
        <Flex
            sx={{
                height: '100vh',
                width: '100vw',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: 'raspberry',
                zIndex: 1100,
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Flex
                sx={{
                    ...sharedStyles,
                    animation: `${zoomIn} 300ms 50ms ease-in, ${fadeUp} ${fadeUpTime}ms 3s ease-out`,
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '250px',
                    mt: '-80px'
                }}
            >
                <Heading
                    sx={{
                        color: 'white',
                        fontSize: [8, 9],
                        textAlign: 'center'
                    }}
                >
                    {title.firstLine} <br /> {title.secondLine}
                </Heading>
            </Flex>
            <AnimatedLinesSvg
                svgStyles={{
                    marginTop: 50,
                    marginLeft: -30
                }}
            />
        </Flex>
    )
}

export const AnimatedLinesSvg = ({
    svgStyles
}: {
    svgStyles?: CSSProperties
}) => {
    const { theme } = useTiaUI()

    const drawLine = keyframes`
        50% {
            stroke-dashoffset: 2200;
        }
        100% {
            stroke-dashoffset: 1100;
        }
    `

    const drawLineReversed = keyframes`
        50% {
            stroke-dashoffset: 4400;
        }
        100% {
            stroke-dashoffset: 5500;
        }
    `

    const StyledPath = styled.path`
        fill: none;
        stroke: ${theme.rawColors?.tiaPink};
        stroke-width: 5;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 1100;
        stroke-dashoffset: 3300;
        animation: ${drawLine} 1s 50ms linear forwards;
    `

    const ReversedStyledPath = styled.path`
        fill: none;
        stroke: ${theme.rawColors?.tiaPink};
        stroke-width: 5;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 1100;
        stroke-dashoffset: 3300;
        animation: ${drawLineReversed} 1s 50ms linear forwards;
    `

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="840"
            height="840"
            viewBox="0 0 1080 1080"
            xmlSpace="preserve"
            style={{
                minWidth: 840,
                ...svgStyles
            }}
        >
            <StyledPath
                d="M102.83,236c0,0,166.1-37.11,243.85,116.63s26.51,169.64-22.97,49.48s65.38-107.79,171.4-26.51"
                style={{ animationDelay: '50ms' }}
            />
            <ReversedStyledPath
                d="M549.89,578.81c0,0-15.9,17.91-15.9,53.01c0,41.35,45.94,93.65,113.09,93.65s57.8-53.36,6.07-53.36
                S524.9,722.77,524.9,778.81c0,56.04,80.83,150.88,242.49,150.88c112.08,0,186.45-35.57,186.45-35.57"
                style={{ animationDelay: '500ms' }}
            />
            <StyledPath
                d="M615.27,368.53c0,0,125.46-144.9,349.88-72.45"
                style={{ animationDelay: '1000ms' }}
            />
        </svg>
    )
}
