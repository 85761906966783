import { Box, Flex, Input, Select, Text } from '@asktia/tia-ui'
import { uniqBy } from 'lodash'
import { FC, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { AppointmentProfile } from 'src/types'
import { useOutsideClick } from 'src/hooks/useOutsideClick'
import { useAmpli } from 'src/hooks'
import { Marquee } from './Marquee'
import { AnimatePresence, motion } from 'framer-motion'

export type DropdownSearchItem = {
    label: string
    value: string
    type: FilterType
}

type FilterType = 'symptom' | 'appointment'

type GroupedOption = {
    label: string
    options: DropdownSearchItem[]
}

// Creates grouped dropdown options from a list of offerings
export function getDropdownOptions(offerings: AppointmentProfile[]) {
    const symptoms = uniqBy(
        offerings
            .map(o => o.appointmentProfileTags || [])
            .flat()
            .map(t => ({
                value: t.name,
                label: t.label,
                type: 'symptom'
            })),
        t => t.value
    )

    const appointments = offerings.map(o => ({
        label: o.label,
        value: o.name,
        type: 'appointment'
    }))

    return [
        { label: 'Symptoms, Feelings & Needs', options: symptoms },
        { label: 'Appointments', options: appointments }
    ] as GroupedOption[]
}

export const formatOptionLabel = ({ label }: DropdownSearchItem) => (
    <div
        style={{
            paddingTop: 6,
            paddingBottom: 6
        }}
    >
        {label}
    </div>
)

// @ts-ignore
export const formatGroupLabel = group => {
    return (
        <div
            style={{
                paddingTop: 12,
                paddingBottom: 4,
                textTransform: 'none',
                color: '#765454',
                fontSize: 12
            }}
        >
            {group.label}
        </div>
    )
}

function getLabel(
    options: GroupedOption[],
    symptom?: string,
    appointment?: string
) {
    const flatOptions = options.map(g => g.options).flat()

    return (
        flatOptions.find(o => o.value === symptom || o.value === appointment)
            ?.label || ''
    )
}

export const FeelingsFilter: FC<{
    offerings: AppointmentProfile[] | undefined
    formMethods: UseFormMethods
}> = ({ offerings, formMethods }) => {
    const [isSearching, setIsSearching] = useState(false)
    const ref = useOutsideClick(() => setIsSearching(false))
    const { filterAppointmentsByKeyword } = useAmpli()

    function onDropdownChange(option: DropdownSearchItem) {
        // TODO: TM-4729 update for appointment filter type possibility
        filterAppointmentsByKeyword({
            filterType: option.type,
            filterKeyword: option.label
        })

        // Clear values, then set the one we care about
        formMethods.setValue('symptom', '')
        formMethods.setValue('appointment', '')
        formMethods.setValue(option.type, option.value)

        setIsSearching(searching => !searching)
    }

    const options = offerings ? getDropdownOptions(offerings) : []

    const marqueeLabel = getLabel(
        options,
        formMethods.getValues('symptom'),
        formMethods.getValues('appointment')
    )

    const handleMarqueeClear = () => {
        onDropdownChange({ label: '', value: '', type: 'symptom' })
        setIsSearching(false)
    }

    return (
        <Flex
            sx={{
                alignItems: 'center',
                flexDirection: ['column', 'row']
            }}
        >
            <Flex
                sx={{
                    alignItems: 'center',
                    minWidth: ['100%', 'max-content'],
                    maxWidth: '100%',
                    height: 56,
                    mb: [4, 0]
                }}
            >
                <Text
                    sx={{
                        mr: [0, 2],
                        fontFamily: 'inferi',
                        fontWeight: 350,
                        fontSize: 32,
                        lineHeight: '36px'
                    }}
                >
                    I'm looking for support with
                </Text>
            </Flex>

            {/* holds current value for the form */}
            <Input type="hidden" name="symptom" />
            <Input type="hidden" name="appointment" />

            <AnimatePresence mode="popLayout" initial={false}>
                {isSearching ? (
                    <motion.div
                        key="select-input"
                        initial={{ opacity: 0, height: 56 }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{ opacity: 0 }}
                        whileInView={{ zIndex: 9999 }}
                    >
                        <Box ref={ref}>
                            <Select
                                name="ignore_me"
                                options={options}
                                menuIsOpen
                                isSearchable
                                placeholder={
                                    <div id="select-placeholder">
                                        Search symptoms, feelings & needs
                                    </div>
                                }
                                onChange={option => {
                                    onDropdownChange(
                                        option as DropdownSearchItem
                                    )
                                }}
                                sx={{
                                    'width': 350,
                                    '#select-placeholder': {
                                        margin: 0,
                                        pr: 1,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '95%'
                                    }
                                }}
                                formatOptionLabel={formatOptionLabel}
                                formatGroupLabel={formatGroupLabel}
                            />
                        </Box>
                    </motion.div>
                ) : (
                    <motion.div
                        key="marquee"
                        initial={{ opacity: 0, height: 56 }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{ opacity: 0 }}
                        style={{ width: 'fit-content' }}
                    >
                        <Marquee
                            options={[
                                'Anxiety',
                                'Fatigue',
                                'Congestion',
                                'Bloating'
                            ]}
                            value={marqueeLabel}
                            handleMarqueeClear={handleMarqueeClear}
                            onClick={() =>
                                setIsSearching(searching => !searching)
                            }
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </Flex>
    )
}
