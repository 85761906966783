import { useNavigate } from 'react-router'

export const useNavigation = () => {
    const navigate = useNavigate()

    /**
     * Programatically change the current React Router path
     *
     * @param path The relative URL path to go to
     * @param delay Delay in milliseconds to perform the navigation
     * @param isRedirection Flag used to skip a redirecting path when using a Back button.
     */
    const navigateWrapper = (
        path: string,
        delay = 0,
        isRedirection = false
    ) => {
        setTimeout(() => {
            if (isRedirection) {
                navigate(path, { replace: true })
            } else {
                navigate(path)
            }
        }, delay)
    }

    /**
     * Change browser URL (not related with React Router)
     *
     * @param newUrl The URL you want to open on the current tab. If starts with '/' works relative to web domain.
     */
    const navigateOutsideRouter = (newUrl: string) => {
        window.location.href = newUrl
    }

    return { navigate: navigateWrapper, navigateOutsideRouter }
}
