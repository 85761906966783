import React, { useEffect } from 'react'
import { Container, Flex, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { HealthRecordCard } from 'src/components/HealthRecordCard'
import { HealthRecordEntry } from 'src/types'
import { useHealthRecordInfo, useHealthRecordNav, useAmpli } from 'src/hooks'
import { Body } from 'src/components/Blocks/Body'
import PageIllustration from 'src/assets/your-health-illustration.png'
import { IllustrationHeader } from 'src/components/Blocks/IllustrationHeader'
import { PageHeader, StandardPageHeader } from 'src/components/PageHeader'
import { BackButton } from 'src/components/Blocks/BackButton'
import { useUhrFeatureFlags } from 'src/flows/questionnaires/Uhr/hooks/useUhrFeatureFlags'
import { useGetMedicalConditionsStatus } from 'src/flows/questionnaires/Uhr/pages/MedicalConditions/useConfirmations'
import { useGetPersonalWellnessCompletion } from 'src/flows/questionnaires/Uhr/hooks/useGetPersonalWellnessCompletion'
import { useGetGynecologyCompletion } from 'src/flows/questionnaires/Uhr/hooks/useGetGynecologyCompletion'
import { extendHealthRecord } from 'src/utils'

export const CenteredSpinner = () => {
    const sx = { height: 9, justifyContent: 'center', alignItems: 'center' }
    return (
        <Flex sx={sx}>
            <Spinner />
        </Flex>
    )
}
// TODO: probably should be centralizing this health record card somewhere
// and sharing between aci and here
// TODO 2: all this logic about what sections to show should be on the backend somewhere
const UHRHealthRecordCard = ({ records }: { records: HealthRecordEntry[] }) => {
    const medicalConditionsStatus = useGetMedicalConditionsStatus()
    const personalWellnessStatus = useGetPersonalWellnessCompletion()
    const gynecologyStatus = useGetGynecologyCompletion()
    const medicalConditionsRecord: HealthRecordEntry = {
        title: 'Medical Conditions',
        name: 'Medical Conditions',
        status: medicalConditionsStatus.data || 'not_started',
        url: '/r/uhr/medical-conditions?onCompleteRelativePath=/your-care/health-record'
    }

    const personalWellnessRecord: HealthRecordEntry = {
        title: 'Personal Wellness',
        name: 'Personal Wellness',
        status:
            personalWellnessStatus.personalWellnessCompletion === 100
                ? 'done'
                : 'not_started',
        url: '/r/uhr/personal-wellness?onCompleteRelativePath=/your-care/health-record'
    }

    const gynecologyRecord: HealthRecordEntry = {
        title: 'Gynecology',
        name: 'Gynecology',
        status:
            gynecologyStatus.gynecologyCompletion === 100
                ? 'done'
                : 'not_started',
        url: '/r/uhr/gynecology?onCompleteRelativePath=/your-care/health-record'
    }

    const { showMedicalConditions, showPersonalWellness, showGynecology } =
        useUhrFeatureFlags()

    let recordsWithNewUhrSections = showGynecology
        ? [gynecologyRecord, ...records]
        : records

    if (showPersonalWellness) {
        // if personal-wellness enabled, hide personal-history
        recordsWithNewUhrSections = recordsWithNewUhrSections.filter(
            record => record.name !== 'personal-history'
        )
        recordsWithNewUhrSections = [
            personalWellnessRecord,
            ...recordsWithNewUhrSections
        ]
    }

    if (showMedicalConditions) {
        recordsWithNewUhrSections = [
            medicalConditionsRecord,
            ...recordsWithNewUhrSections
        ]
    }

    return (
        <HealthRecordCard
            variant="uhr"
            records={recordsWithNewUhrSections}
            sx={{ mb: 6, border: 0, px: 0 }}
        />
    )
}

export const HealthRecord = () => {
    const { isLoading, data } = useHealthRecordNav()

    const { uhrViewed } = useAmpli()
    const { uhrLocation, hRPC } = useHealthRecordInfo(data?.records || [])

    useEffect(() => {
        if (data?.records) {
            uhrViewed(uhrLocation, hRPC)
        }
    }, [hRPC, uhrLocation, uhrViewed])

    const records = extendHealthRecord(
        'health-record',
        data?.records || [],
        '',
        '/r/your-care/health-record'
    )

    return (
        <Body sx={{ paddingBottom: 8 }}>
            <StandardPageHeader />
            <IllustrationHeader
                title="Your Health Record"
                img={PageIllustration}
            />
            <Container>
                <LayoutGrid columns="one">
                    {isLoading && <CenteredSpinner />}
                    {!isLoading && <UHRHealthRecordCard records={records} />}
                </LayoutGrid>
            </Container>
        </Body>
    )
}
