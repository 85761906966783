import { Box, Flex, Input, Label } from '@asktia/tia-ui'

type NameFormProps = {
    label: string
    fieldNames: [string, string]
    defaultValues: [string, string]
}

export const NameForm = ({
    label,
    fieldNames,
    defaultValues
}: NameFormProps) => (
    <Box>
        <Label>{label}</Label>
        <Flex>
            <Flex sx={{ flexDirection: 'column', mr: 2, flex: 1 }}>
                <Input
                    name={fieldNames[0]}
                    placeholder="First Name"
                    defaultValue={defaultValues[0]}
                    errorMessage="First Name is required"
                    required
                />
            </Flex>
            <Flex sx={{ flexDirection: 'column', mr: 2, flex: 1 }}>
                <Input
                    name={fieldNames[1]}
                    placeholder="Last Name"
                    defaultValue={defaultValues[0]}
                    errorMessage="Last Name is required"
                    required
                />
            </Flex>
        </Flex>
    </Box>
)
