import { Box } from '@asktia/tia-ui'
import React, { useContext } from 'react'
import { FC, ReactNode, useState } from 'react'
import { AlertIcon, CloseIcon } from 'src/components/Blocks/Icons'
import { DevSidebarMenuItem } from './DevSidebarMenuItem'

export interface IDevSideBarContext {
    open: boolean
    addAction: (action: IDevAction) => void
    deleteAction: (id: string) => void
}

export const DevSideBarContext = React.createContext<IDevSideBarContext>({
    open: false,
    addAction: () => {},
    deleteAction: () => {}
})

export interface IDevAction {
    id: string
    children: ReactNode
    onClick: () => void
}

export const useDevSidebar = () => {
    const context = useContext(DevSideBarContext)

    return context
}

export const DevSidebarProvider: FC<{ children?: any }> = ({ children }) => {
    const [open, setOpen] = useState(true)
    const [actions, setActions] = useState<IDevAction[]>([
        {
            id: 'close',
            children: <CloseIcon color="text" />,
            onClick: () => setOpen(false)
        }
    ])

    const addAction = (action: IDevAction) =>
        setActions(actions => [...actions, action])
    const deleteAction = (id: string) =>
        setActions(actions => actions.filter(action => action.id !== id))

    const isProduction = window.location.hostname === 'member.asktia.com'
    if (!open || isProduction) {
        return children
    }

    return (
        <DevSideBarContext.Provider value={{ open, addAction, deleteAction }}>
            <Box
                sx={{
                    position: 'fixed',
                    right: '0px',
                    top: '40vh',
                    pb: 2,
                    width: 40,
                    background: '#FFF',
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 99999
                }}
            >
                {actions.map(action => (
                    <DevSidebarMenuItem onClick={action.onClick}>
                        {action.children}
                    </DevSidebarMenuItem>
                ))}
            </Box>
            {children}
        </DevSideBarContext.Provider>
    )
}
