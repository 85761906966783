import { Box, Container, Heading, Paragraph } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAmpli, useScrollToTopOnPageLoad } from 'src/hooks'

// TODO: might want to centralize this in the useappointmentprofile hook
import { useAppointmentProfilesByApptProfileName } from 'src/flows/AppointmentBooking/useCareTypes'
import { MemberBenefits } from '../welcome'
import { OnboardingSuggestionItem } from './OnboardingSuggestionItem'
import { RecommendedActionsButton } from 'src/components/RecommendedActionsButton'
import Markdown from 'src/components/Markdown'

export const OnboardingSuggestionPage: FC = () => {
    const [searchParams] = useSearchParams()
    useScrollToTopOnPageLoad()

    const {
        onboardingSuggestionPageViewed,
        onboardingExploreAllOtherAppointmentsClicked
    } = useAmpli()

    const { appointmentProfiles } = useAppointmentProfilesByApptProfileName(
        searchParams.get('appointmentProfileName') || 'tia_whole_health_exam',
        true
    )

    useEffect(() => {
        const appointmentProfileName = searchParams.get(
            'appointmentProfileName'
        )
        if (appointmentProfileName) {
            onboardingSuggestionPageViewed({ appointmentProfileName })
        }
    }, [searchParams])

    return (
        <Container sx={{ height: '120vh' }}>
            <Box sx={{ mt: 5, mb: 4 }}>
                {searchParams.get('title') && (
                    <Heading
                        h2
                        sx={{ mb: 2, width: '100%', textAlign: 'left' }}
                    >
                        {searchParams.get('title')}
                    </Heading>
                )}
                {searchParams.get('details') &&
                    searchParams
                        .get('details')
                        ?.split(';')
                        .map(detail => {
                            return <MemberBenefits>{detail}</MemberBenefits>
                        })}
            </Box>
            {searchParams.get('appointmentProfileName') &&
                appointmentProfiles &&
                appointmentProfiles[0] && (
                    <OnboardingSuggestionItem {...appointmentProfiles[0]} />
                )}
            {/* TODO: Add amplitude event for click explore appointments */}
            {!searchParams.get('appointmentProfileName') && (
                <RecommendedActionsButton
                    apptLink="/appointments"
                    onClick={onboardingExploreAllOtherAppointmentsClicked}
                >
                    Explore all appointments
                </RecommendedActionsButton>
            )}
            {searchParams.get('footer') && (
                <Markdown
                    text={decodeURI(searchParams.get('footer') as string)}
                    sx={{ fontSize: 2, color: 'supportText' }}
                />
            )}
        </Container>
    )
}
