import { useCallback, useState, useEffect } from 'react'
import { Box, Heading, Paragraph } from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { useAmpli } from 'src/hooks/useAmpli'
import { useAppointmentChangeFlow } from './useAppointmentChangeFlow'

export const EVENT_TRACKING_WAIT = 2000

export const AppointmentCancelConfirmation = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { goBack, appointment, isAppointmentLoading, refetch } =
        useAppointmentChangeFlow()
    const { cancelConfirmed } = useAmpli()

    useEffect(() => {
        // Make sure we pull the appointment again here because
        // we want the cancellation reason to be set on the
        // appointment object.
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = useCallback(() => {
        if (appointment) {
            // Because 'goBack' will trigger a redirect there is a good chance
            // the browser will cancel the tracking request if we don't wait
            // a bit of time. Unfortunately we have no way of knowing when the
            // request is truly successful, so we just wait a "prudent" amount of time.
            setIsLoading(true)
            cancelConfirmed(appointment)
            setTimeout(() => {
                goBack()
            }, EVENT_TRACKING_WAIT)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment, goBack])

    return (
        <Box>
            <Heading h3>Your appointment has been canceled! </Heading>
            <Paragraph sx={{ mb: 6 }}>
                We hope to see you at the Tia clinic soon.
            </Paragraph>

            <FooterButton
                type="submit"
                onClick={onSubmit}
                disabled={isAppointmentLoading}
                loading={isLoading}
            >
                All set!
            </FooterButton>
        </Box>
    )
}
