import {
    Id,
    Questionnaire,
    QuestionnaireResponse,
    QuestionnaireResponseAnswer,
    QuestionnaireResponseItem,
    QuestionnaireResponseStatusEnum,
    QuestionnaireResponses,
    QuestionnaireItem
} from '@asktia/tia-fhir-models'
import { isItemEnabled } from '@asktia/tia-fhir-questionnaire'
import _ from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { useQuestionnaire } from './useQuestionnaire'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import { getExtensionName } from 'src/flows/questionnaires/components/Input/BaseInputExtensions'

/**
 * If index provided, use namespace.N.prop naming
 *  to create a field array
 */
export function getFieldName(
    questionnaireItem: QuestionnaireItem,
    index?: number
): string {
    const name = Number.isInteger(index)
        ? questionnaireItem.linkId.replace('.', `.${index}.`)
        : questionnaireItem.linkId

    return name
}

// Returns a mapping of linkId to answers
function mapAnswers(
    questionnaireResponses: QuestionnaireResponse[]
): Map<string, QuestionnaireResponseAnswer[]> {
    const answersMap = new Map<string, QuestionnaireResponseAnswer[]>()

    // we're translating a flat data structure into non-flat
    // which means we have to keep track of indexes
    // and recursion can't do it for us
    const groupIndexes = new Map<string, number>()

    for (const answer of questionnaireResponses) {
        for (const item of answer.item || []) {
            if (item.answer) {
                answersMap.set(item.linkId, item.answer)
            } else if (item.item) {
                const index = groupIndexes.get(item.linkId) || 0
                groupIndexes.set(item.linkId, index + 1)

                // We support 1 level of "recursion" for group
                for (const subItem of item.item) {
                    if (subItem.answer) {
                        answersMap.set(
                            getFieldName(subItem, index),
                            subItem.answer
                        )
                    }
                }
            }
        }
    }

    return answersMap
}

function collectResponseItems(
    formValues: any,
    questionnaire: Questionnaire
): QuestionnaireResponseItem[] {
    const getAnswers = (question: QuestionnaireItem, index?: number) => {
        const name = getFieldName(question, index)
        const formValue = _.get(formValues, name)

        // We can't handle formValue === undefined together
        // because different inputs expect different behavior
        // cough date input cough
        switch (question.type) {
            case 'date':
                return !formValue ? [] : [{ valueDate: formValue }]
            case 'choice':
                return formValue
                    ? question.answerOption?.filter(option =>
                          Array.isArray(formValue)
                              ? formValue.indexOf(option.valueCoding?.code) > -1
                              : option.valueCoding?.code === formValue
                      )
                    : undefined
            case 'string':
                return formValue ? [{ valueString: formValue }] : undefined
            case 'integer':
                return formValue
                    ? [{ valueInteger: parseInt(formValue) }]
                    : undefined
        }
    }

    // TODO: this looks like recursion,
    // but not sure what to do with isItemEnabled
    // which doesn't apply here
    const getGroupResponse = (item: QuestionnaireItem) => {
        // questionnaire config prefixes groups with group.
        // but members don't have that prefix
        // for example:
        //    the "What year" question in pregnancies
        //    lives inside group.pregnancies
        //    and is named pregnancies.0.when
        //    which stores values in pregnancies: [...]
        const formValue = _.get(formValues, item.linkId.replace(/^group\./, ''))

        // Collect responses for each available group
        const response = formValue
            ?.map((_: any, index: number) => ({
                text: item.text,
                item: item.item
                    // groups have multiple answers
                    ?.map(item => ({
                        text: item.text,
                        answer: getAnswers(item, index),
                        linkId: String(item.linkId)
                    }))
                    .filter(item => item.answer !== undefined),
                linkId: String(item.linkId)
            }))
            // Remove empty groups
            ?.filter(
                (response: QuestionnaireResponseItem) => response.item?.length
            )

        return response
    }

    const items: QuestionnaireResponseItem[] = (questionnaire.item ?? [])
        .map(item =>
            item.type === 'group'
                ? getGroupResponse(item)
                : {
                      text: item.text,
                      answer: getAnswers(item),
                      linkId: String(item.linkId)
                  }
        )
        .flat()
        .filter(
            item => !(item?.answer === undefined && item?.item === undefined)
        )

    return items.filter(item => {
        const question = questionnaire.item?.find(q => q.linkId === item.linkId)
        return (
            question &&
            isItemEnabled(question, {
                // typescript says we need this id,
                // but the value is irrelevant
                id: '123',
                // typescript says we need a canonical url,
                // but the value is irrelevant
                questionnaire: 'http://123',
                resourceType: 'QuestionnaireResponse',
                item: items
            }) &&
            // This will prevent us to send empty answer for dynamic questions
            // when other question does change but the item still enabled and none items were selected
            (Array.isArray(item.answer) &&
            !_.get(formValues, getExtensionName(question.linkId))
                ? item.answer.length > 0
                : true)
        )
    })
}

function useSaveQuestionnaireResponse(
    questionnaireId: string,
    responseId?: Id
) {
    const queryClient = useQueryClient()
    const { user } = useUserInfo()
    const { questionnaire } = useQuestionnaire(questionnaireId)

    const { mutateAsync: saveResponse } = useMutation<void, Error, any>(
        async (formValues: any) => {
            if (user) {
                const newQuestionnaireResponse: QuestionnaireResponse = {
                    id: responseId || uuidv4(),
                    resourceType: 'QuestionnaireResponse',
                    questionnaire: questionnaireId,
                    subject: {
                        id: String(user.id)
                    },
                    item: collectResponseItems(formValues, questionnaire),
                    // TODO: when's it not in progress/
                    status: QuestionnaireResponseStatusEnum.InProgress
                }

                await fetcher(
                    `${BASE_API_URL}/uhr-api/questionnaire/QuestionnaireResponse/${newQuestionnaireResponse.id}`,
                    JSON.stringify(newQuestionnaireResponse),
                    'PUT'
                )
            }
        },
        {
            onSuccess: () => {
                queryClient.refetchQueries([
                    'uhr',
                    'questionnaire',
                    questionnaireId,
                    user?.id
                ])
            }
        }
    )

    return saveResponse
}

export function useQuestionnaireResponse(
    questionnaireId: string,
    appointmentProfileUuid: string
): {
    isLoading: boolean
    questionnaireResponse: QuestionnaireResponse | null
    answersMap: Map<string, QuestionnaireResponseAnswer[]>
    saveQuestionnaireResponse: (formValues: any) => Promise<void>
} {
    const { user } = useUserInfo()

    const query = useQuery<QuestionnaireResponses>(
        ['uhr', 'questionnaire', questionnaireId, user?.id],
        () => {
            if (!user?.id || !questionnaireId) {
                // We do need this because react-query is not respecting the "enable" and were
                // making calls with patient=undefined
                return { questionnaireResponses: [] }
            }
            if (
                questionnaireId === 'personal-wellness' ||
                questionnaireId === 'gynecology'
            ) {
                return fetcher(
                    `${BASE_API_URL}/uhr-api/questionnaire/QuestionnaireResponse?patient=${user?.id}&questionnaire=${questionnaireId}`
                )
            }

            return fetcher(
                `${BASE_API_URL}/uhr-api/questionnaire/context/QuestionnaireResponse?patient=${user?.id}&questionnaire=${questionnaireId}&appointmentProfile=${appointmentProfileUuid}`
            )
        }
    )

    const questionnaireResponse =
        query.data?.questionnaireResponses?.[0] || null

    const saveQuestionnaireResponse = useSaveQuestionnaireResponse(
        questionnaireId,
        questionnaireResponse?.id
    )

    return {
        isLoading: query.isLoading,
        questionnaireResponse,
        answersMap: mapAnswers(query.data?.questionnaireResponses || []),
        saveQuestionnaireResponse
    }
}
