import { PropsWithChildren, ReactNode } from 'react'
import { Container, Flex, Logo, ThemeStyleObject, Box } from '@asktia/tia-ui'
import { Link } from 'react-router-dom'
import { BackButton } from 'src/components/Blocks/BackButton'
import {
    HeaderBookAppointmentButton,
    HeaderChatButton,
    HeaderMenuContainer,
    HeaderYourCareButton
} from './Blocks'

export const LogoLink = (props: {
    sx?: ThemeStyleObject
    variant?: 'mainBackground' | 'mainBackground'
}) => (
    <Box sx={props.sx}>
        <Link to="/book-appointment">
            <Logo sx={{ height: [24, 32] }} />
        </Link>
    </Box>
)

/**
 * PageHeader: Header for most pages on the website
 */
export const PageHeader = (
    props: PropsWithChildren<{
        sx?: ThemeStyleObject
        /**
         * placeholder for rendering actions on the right side of the header on desktop (not visible on mobile)
         */
        desktopActions?: ReactNode
        /**
         * placeholder for rendering an element on the left side of the header on mobile (not visible on desktop)
         */
        mobileBack?: ReactNode
        /**
         * placeholder for rendering an element on the right side of the header on mobile (not visible on desktop)
         */
        mobileActions?: ReactNode
    }>
) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'sticky',
                top: 0,
                zIndex: 3,
                bg: 'navBackground',
                color: 'white',
                py: [3, 5],
                ...props.sx
            }}
        >
            <Container>
                <Flex
                    sx={{
                        position: 'relative',
                        flexDirection: 'row',
                        height: [40, 'auto']
                    }}
                >
                    <Flex
                        sx={{
                            flex: ['0 0 30%', '1'],
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ display: ['block', 'none'] }}>
                            {props.mobileBack}
                        </Box>

                        {/* desktop only logo */}
                        <LogoLink sx={{ display: ['none', 'block'] }} />
                    </Flex>
                    {/* mobile only logo */}
                    <Flex
                        sx={{
                            flex: '1 1 auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: ['flex', 'none']
                        }}
                    >
                        <LogoLink />
                    </Flex>
                    <Flex
                        sx={{
                            flex: ['0 0 30%', '1'],
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: ['none', 'flex'],
                                alignItems: 'center'
                            }}
                        >
                            {props.desktopActions}
                        </Box>
                        <Box
                            sx={{
                                display: ['flex', 'none'],
                                alignItems: 'center'
                            }}
                        >
                            {props.mobileActions}
                        </Box>
                    </Flex>
                </Flex>
            </Container>
            {props.children}
        </Box>
    )
}

export const StandardPageHeader = () => {
    return (
        <PageHeader
            mobileBack={<BackButton variant="white" />}
            desktopActions={
                <>
                    <HeaderYourCareButton />
                    <HeaderChatButton />
                    <HeaderBookAppointmentButton />
                </>
            }
            mobileActions={<HeaderMenuContainer />}
        />
    )
}
