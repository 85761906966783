import { useMessageThreads, Thread } from 'src/hooks/useMessageThreads'
import { useAppointmentSuggestions } from 'src/hooks/useAppointmentSuggestions'
import { useGetUnseenCarePlanInterventions } from 'src/hooks/useCarePlan'
import { AppointmentSuggestion } from 'src/types'

const hasUnreadSuggestions = (
    appointmentSuggestions: AppointmentSuggestion[],
    threads: Thread[]
) => {
    // looks if any appt suggestion has an associated thread with unread
    // messages
    return appointmentSuggestions.some(suggestion => {
        if (!suggestion.threadId) {
            return false
        }
        const thread = threads.find(t => t.id === suggestion.threadId)

        return thread?.hasUnreadMessages
    })
}

export function useYourCareDot() {
    const { appointmentSuggestions } = useAppointmentSuggestions({
        status: 'pending',
        actionDismissed: false
    })

    const interventions = useGetUnseenCarePlanInterventions()

    const { threads } = useMessageThreads()
    const unreadSuggestions = hasUnreadSuggestions(
        appointmentSuggestions,
        threads
    )
    const hasUnseenCarePlan = interventions.length > 0

    return unreadSuggestions || hasUnseenCarePlan
}
