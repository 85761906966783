import { LayoutGrid, Button, Container } from '@asktia/tia-ui'
import { StandardPageHeader } from '../../components/PageHeader'
import { EditEmail } from './EditEmail'
import { Insurance } from './Insurance'
import { CreditCard } from './CreditCard'
import { Membership } from './Membership'
import { ReferralCode } from './ReferralCode'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { useReferralCodes } from 'src/hooks'
import { Divider } from 'src/components/Blocks'

export const AccountInfo = () => {
    const { referralCodes } = useReferralCodes()

    return (
        <>
            <StandardPageHeader />
            <NavBarHeading href="/your-care">Account Info</NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    <EditEmail />
                    <CreditCard />
                    <Insurance />
                    <Membership />
                    {referralCodes.length > 0 ? (
                        <>
                            <Divider sx={{ bg: 'inputOutline' }} />
                            <ReferralCode code={referralCodes[0].code} />
                        </>
                    ) : null}
                    <Button
                        as="a"
                        variant="unstyled"
                        href="/logout"
                        sx={{
                            my: 6,
                            textDecoration: 'underline',
                            fontSize: 2
                        }}
                    >
                        Sign out
                    </Button>
                </LayoutGrid>
            </Container>
        </>
    )
}
