import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { useUserInfo } from 'src/hooks'

const getUhrFeatureFlag = async ({ patientId }: { patientId: number }) => {
    return fetcher(
        `${BASE_API_URL}/tmd-api/patients/${patientId}/uhr-v2-feature-flags?apiVersion=v2`,
        undefined,
        'GET'
    )
}

export const useUhrFeatureFlags = (apptId?: string) => {
    const { user } = useUserInfo()
    const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useQuery(
        ['get-uhr-feature-flags', user?.id],
        () =>
            getUhrFeatureFlag({
                patientId: user!.id
            }),
        {
            enabled: !!user
        }
    )

    return {
        showMedicalConditions: featureFlags?.medicalConditions,
        showPersonalWellness: featureFlags?.personalWellness,
        showGynecology: featureFlags?.gynecology
    }
}
