import { Box, Button, Card, Flex, Text } from '@asktia/tia-ui'
import { format } from 'date-fns'
import { FC } from 'react'
import { isNil } from 'lodash'

export interface BillingSummaryProps {
    currentInvoiceInCents?: number
    totalBalanceInCents?: number
    dueDate: Date | string
    onCTAClick: () => void
}

export const BillingSummary: FC<BillingSummaryProps> = ({
    currentInvoiceInCents,
    totalBalanceInCents,
    dueDate,
    onCTAClick
}) => {
    if (isNil(currentInvoiceInCents) || isNil(totalBalanceInCents)) {
        console.log('NIL!')
        return null
    }

    const currentInvoiceValue = currentInvoiceInCents / 100
    const totalBalanceValue = Math.max(0, totalBalanceInCents / 100)
    const hasPendingBalance = totalBalanceValue > 0
    const fullPaymentInvoice = currentInvoiceValue === totalBalanceValue
    const zeroValue = currentInvoiceValue == 0 && totalBalanceValue == 0
    const sanitizedDueDate =
        'string' === typeof dueDate ? Date.parse(dueDate) : dueDate.getTime()
    const isDueDateInPast = Date.now() >= sanitizedDueDate

    return (
        <Card sx={{ py: 4, mb: 4 }}>
            <Flex sx={{ width: '100%' }}>
                {hasPendingBalance && (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            flex: '1 0 0'
                        }}
                    >
                        <Text variant="eyebrow" color="supportText">
                            Current Invoice
                        </Text>
                        <Text
                            sx={{ fontSize: 7 }}
                            color={
                                currentInvoiceValue === 0
                                    ? 'inactiveText'
                                    : 'tiaPink'
                            }
                        >
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).format(currentInvoiceValue)}
                        </Text>
                        {currentInvoiceValue === 0 ? (
                            <Text sx={{ fontSize: 0, color: 'supportText' }}>
                                Invoice coming soon
                            </Text>
                        ) : isDueDateInPast ? (
                            <>
                                <Text
                                    sx={{ fontSize: 0 }}
                                    color="inputOutlineError"
                                >
                                    Outstanding Balance:
                                </Text>
                                <Text
                                    sx={{ fontSize: 0 }}
                                    color="inputOutlineError"
                                >
                                    Due:{' '}
                                    {format(sanitizedDueDate, 'LLLL dd, yyyy')}
                                </Text>
                            </>
                        ) : (
                            <Text sx={{ fontSize: 0 }} color="supportText">
                                Due: {format(sanitizedDueDate, 'LLLL dd, yyyy')}
                            </Text>
                        )}
                    </Flex>
                )}

                {hasPendingBalance && !fullPaymentInvoice && (
                    <Box
                        sx={{
                            ml: 2,
                            mr: 2,
                            borderRight: '1px solid',
                            borderColor: 'secondaryBackground'
                        }}
                    />
                )}

                {(hasPendingBalance && !fullPaymentInvoice) || zeroValue ? (
                    <Flex sx={{ flexDirection: 'column', flex: '1 0 0' }}>
                        <Text variant="eyebrow" color="supportText">
                            Total Balance
                        </Text>
                        <Text sx={{ fontSize: 7 }} color="text">
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            }).format(totalBalanceValue)}
                        </Text>

                        {totalBalanceValue === 0 && (
                            <Text sx={{ fontSize: 0, color: 'supportText' }}>
                                You have no balance due at the moment
                            </Text>
                        )}
                    </Flex>
                ) : null}
            </Flex>

            {hasPendingBalance && (
                <Button
                    fullWidth
                    onClick={onCTAClick}
                    disabled={currentInvoiceValue === 0}
                    sx={{ mt: 2 }}
                >
                    Pay Current Invoice
                </Button>
            )}
        </Card>
    )
}
