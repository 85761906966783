import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethod, Stripe, StripeElements } from '@stripe/stripe-js'
import { createContext, useState, useContext, PropsWithChildren } from 'react'
import logger from 'src/logger'

export type Tokenize = {
    status: string
    token: string
    details: {
        billing: { postalCode: string }
        card: {
            brand: string
            expMonth: number
            expYear: number
            last4: string
        }
        method: string
    }
    errors: any
}

const createStripePaymentRecord = async (
    stripe: Stripe | null,
    elements: StripeElements | null
): Promise<PaymentMethod | undefined> => {
    if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        logger.error(`Stripe.js hasn't yet loaded`)
        return undefined
    }
    const card = elements.getElement('card')
    if (!card) {
        logger.log('No card provided')
        throw new Error('No card provided')
    }

    const result = await stripe.createPaymentMethod({
        type: 'card',
        card
    })

    if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message)
        throw new Error(
            'Sorry, there was an issue with your payment. Please try again later.'
        )
    } else {
        return result.paymentMethod
    }
}

export type FormContextType = {
    onSubmitCard: () => void
    card: any
}

export const FormContext = createContext<FormContextType>({
    onSubmitCard: () => {},
    card: null as unknown
})

type PaymentFormProps = {
    cardTokenizeResponseReceived: (token: any) => void
    onError: (errors: string[]) => void
}

/**
 * this is a wrapper from the square JS file
 * the square react sdk library didn't work for us at the time this is going to be comitted
 * please use the sdk if in the future if we move to a newer react-script version
 */
const PaymentForm = ({
    cardTokenizeResponseReceived,
    onError,
    children
}: PropsWithChildren<PaymentFormProps>) => {
    const [card] = useState(null)
    const stripe = useStripe()
    const elements = useElements()

    const onSubmitCard = async () => {
        try {
            const token = await createStripePaymentRecord(stripe, elements)

            cardTokenizeResponseReceived(token)
        } catch (e) {
            const errorMsg = (e as Error).message
            onError([errorMsg])
        }
    }

    const context: FormContextType = {
        onSubmitCard,
        card
    }

    return (
        <FormContext.Provider value={context}>{children}</FormContext.Provider>
    )
}

const usePaymentForm = (): FormContextType => {
    const context = useContext(FormContext)

    if (context === undefined) {
        throw new Error('useForm must be used within a FormProvider')
    }

    return context
}

export { PaymentForm, usePaymentForm }
