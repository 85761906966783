import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

export function useAtAppointment(appointmentId: string) {
    const { mutate } = useMutation(async () => {
        fetcher(
            `${BASE_API_URL}/tmd-api/appointments/${appointmentId}/at-appointment`,
            undefined,
            'POST'
        )
    })

    return mutate
}
