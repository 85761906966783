import { Route, Routes } from 'react-router'
import { Show404 } from '../../404'
import { Body } from 'src/components/Blocks/Body'
import { QuestionnairePage } from '../components/QuestionnairePage'
import { QuestionnaireHeader } from '../components/QuestionnaireHeader'
import { Immunizations } from './pages/Immunizations'

export const MedicalHistory = () => {
    return (
        <Body>
            <QuestionnaireHeader />
            <Routes>
                <Route
                    path={`immunizations/`}
                    element={
                        <QuestionnairePage
                            title="Medical History"
                            category="Immunizations"
                        >
                            <Immunizations />
                        </QuestionnairePage>
                    }
                />
                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
