import { Card } from '@asktia/tia-ui'
import { FC, PropsWithChildren } from 'react'
import { ThemeUIStyleObject } from '@theme-ui/css'

export const FormAlertCard: FC<
    PropsWithChildren<{ sx?: ThemeUIStyleObject }>
> = ({ children, sx }) => {
    return (
        <Card variant="info" sx={{ mb: 6, ...sx }}>
            {children}
        </Card>
    )
}
