import { FC } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { LayoutGrid } from '@asktia/tia-ui'
import { Welcome } from './welcome'
import { PageHeader } from 'src/components/PageHeader'
import { TwheLandingPage } from './twhe'
import { CloseButton } from 'src/components/Blocks'
import { useAmpli } from 'src/hooks'
import { AnimatePresence } from 'framer-motion'
import { OnboardingRecommendedActions } from './OnboardingRecommendedActions'
import { OnboardingQuizPage } from 'src/pages/Landing/OnboardingQuizPage'
import { OnboardingSuggestionPage } from './OnboardingSuggestionPage'

export const Landing: FC = () => {
    const { exitNewMemberOnboarding } = useAmpli()
    const location = useLocation()
    return (
        <>
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route
                        path="/welcome"
                        element={
                            <>
                                <PageHeader
                                    mobileActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'Tia intro'
                                                )
                                            }
                                        />
                                    }
                                    desktopActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'Tia intro'
                                                )
                                            }
                                        />
                                    }
                                />

                                <Welcome />
                            </>
                        }
                    />
                    <Route
                        path="/twhe"
                        element={
                            <>
                                <PageHeader
                                    mobileActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'TWHE intro'
                                                )
                                            }
                                        />
                                    }
                                    desktopActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'TWHE intro'
                                                )
                                            }
                                        />
                                    }
                                />

                                <LayoutGrid columns="one">
                                    <TwheLandingPage />
                                </LayoutGrid>
                            </>
                        }
                    />
                    <Route
                        path="/recommended-actions"
                        element={
                            <>
                                <PageHeader
                                    mobileActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'Recommended actions intro'
                                                )
                                            }
                                        />
                                    }
                                    desktopActions={
                                        <CloseButton
                                            color="white"
                                            onClick={() =>
                                                exitNewMemberOnboarding(
                                                    'Recommended actions intro'
                                                )
                                            }
                                        />
                                    }
                                />

                                <LayoutGrid columns="one">
                                    <OnboardingRecommendedActions />
                                </LayoutGrid>
                            </>
                        }
                    />
                    <Route path="/quiz" element={<OnboardingQuizPage />} />
                    <Route
                        path="/onboarding-suggestion"
                        element={
                            <>
                                <PageHeader
                                    mobileActions={
                                        <CloseButton color="white" />
                                    }
                                    desktopActions={
                                        <CloseButton color="white" />
                                    }
                                />

                                <LayoutGrid columns="one">
                                    <OnboardingSuggestionPage />
                                </LayoutGrid>
                            </>
                        }
                    />
                </Routes>
            </AnimatePresence>
        </>
    )
}
