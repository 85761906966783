import { Box, Button, Container, Link } from '@asktia/tia-ui'
import { Link as RouterLink } from 'react-router-dom'
import { useAmpli, useUserInfo } from 'src/hooks'
import { THREAD_LABELS } from 'src/globals'
import logger from 'src/logger'
import { useEffect } from 'react'

export const PaymentWarningBanner = () => {
    const { resumeSignupBannerCtaRendered } = useAmpli()
    const { user } = useUserInfo()

    useEffect(() => {
        if (user?.membershipSignupStatus === 'preview') {
            // this is a very quick way for us to identify that someone is viewing the pre member portal
            resumeSignupBannerCtaRendered()
        }
    }, [user])

    if (!user) {
        return null
    }

    let copy = <></>

    if (user.membershipSignupStatus === 'preview') {
        // we decided that we don't want to see the banner at the moment.
        // but it might come into play later. So just commenting out for now
        copy = <></>
        // copy = <PreviewLink></PreviewLink>
    } else if (user.membershipStatus === 'deactivated') {
        copy = (
            <p>
                Your membership is no longer active! To purchase another
                membership,{' '}
                <Link
                    as={RouterLink}
                    // @ts-ignore: TS doesn't understand this is a RouterLink now
                    to={`/chat/new?label=${THREAD_LABELS.billing}&subject=My deactivated membership&message=I would like to re-purchase my membership`}
                    sx={{ fontSize: 'inherit' }}
                >
                    please message your Care Coordinator
                </Link>
            </p>
        )
    } else if (user.membershipStatus === 'frozen' || !user.rawCard) {
        logger.info(`PaymentWarningBanner: User has a payment issue.`, user)
        copy = (
            <p>
                We need to update your payment method before you can attend
                appointments with Tia!
                <br />
                <Link href="/account-info" sx={{ fontSize: 'inherit' }}>
                    Update payment details now
                </Link>
            </p>
        )
    } else {
        return null
    }

    return (
        <Box
            sx={{
                backgroundColor: 'poppy',
                pl: [5, 0]
            }}
        >
            <Container
                sx={{
                    p: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                {copy}
            </Container>
        </Box>
    )
}
