import { Link } from '@asktia/tia-ui'

const GeneralInsuranceCWCT = () => {
    return (
        <Link href="/chat/new?label=Insurance+Question&subject=Question+about+insurance&message=I+have+a+question+about+my+insurance">
            Contact your Care Coordinator.
        </Link>
    )
}

export default GeneralInsuranceCWCT
