import { useMutation } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

async function postCancelAppointment({
    appointmentId,
    reason
}: {
    appointmentId: string
    reason: string
}) {
    await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/${appointmentId}/cancel?apiVersion=v2`,
        JSON.stringify({ reason }),
        'POST',
        {
            customReturn: async () => undefined
        }
    )
}

export const useCancelAppointment = () => {
    const mutation = useMutation(postCancelAppointment)

    return {
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        cancelAppointmentAsync: mutation.mutateAsync
    }
}
