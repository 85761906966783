import { Button, Flex, Heading, Text, TiaModal, Link } from '@asktia/tia-ui'
import { useModal } from 'react-modal-hook'
import { useAmpli } from 'src/hooks'

export const BookingConflictModal = () => {
    const { selectANewAppointmentTimeClicked } = useAmpli()

    const appointmentConflictOnClickEvent = async () => {
        selectANewAppointmentTimeClicked()

        // Go back
        window.history.go(-1)
    }

    return (
        <TiaModal shouldCloseOnOverlayClick={false}>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: 4
                }}
            >
                <Heading h2 sx={{ m: 0, p: 0 }}>
                    Select a new time
                </Heading>
            </Flex>
            <Text sx={{ mb: 5 }}>
                The time slot you selected is no longer available.
            </Text>

            <Button
                as="a"
                onClick={() => appointmentConflictOnClickEvent()}
                fullWidth
                sx={{ mb: 4 }}
            >
                Select a new appointment time
            </Button>
            <Link
                href="/r/book-appointment"
                sx={{ textAlign: 'center', display: 'block' }}
            >
                Back to home
            </Link>
        </TiaModal>
    )
}

export function useBookingConflictModal() {
    const [showBookingConflictModal] = useModal(() => <BookingConflictModal />)

    return showBookingConflictModal
}
