import { Button, Flex, Text } from '@asktia/tia-ui'
import { FC, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { useFormContext } from 'react-hook-form'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { FollowUpQuestionsCard } from 'src/flows/questionnaires/components/FollowUpQuestionsCard'
import { GluedLongOpenInputQuestion } from 'src/flows/questionnaires/components/Input/LongOpenInput'
import { GluedOpenInputQuestion } from 'src/flows/questionnaires/components/Input/OpenInput'
import { GluedSingleChoiceQuestion } from 'src/flows/questionnaires/components/Input/SingleChoice'
import { QuestionFormGlue } from 'src/flows/questionnaires/types'

export const PregnancyGroupQuestion: FC<QuestionFormGlue> = ({
    questionnaire,
    answersMap,
    ...props
}) => {
    const [pregnanciesAmount, setPregnanciesAmount] = useState(1)

    const questions = questionnaire.item
    const questionConfig = questions?.find(
        question => question.linkId === 'group.pregnancies'
    )

    useEffect(() => {
        if (!questionConfig) {
            return
        }

        // TODO: this is awful; I think we can fix,
        // if the form handles default values 👉 TM-5474
        const maxPregnancyIndex = Math.max(
            ...Array.from(answersMap.keys())
                .filter(k => k.startsWith('pregnancies.'))
                .map(k => Number(k.replace(/^pregnancies\./, '').split('.')[0]))
        )

        setPregnanciesAmount(
            Number.isInteger(maxPregnancyIndex) ? maxPregnancyIndex + 1 : 1
        )
    }, [questionConfig])

    const formMethods = useFormContext()

    function deletePregnancy(index: number) {
        setPregnanciesAmount(currentAmount => currentAmount - 1)

        /**
         * we have to manually clear fields due to batching
         *
         * triggerSave runs before fields are un-rendered
         * which means they'll get included in save
         *
         * but our saving logic ignores undefined values
         */
        for (const field of ['when', 'result', 'complications']) {
            formMethods.setValue(`pregnancies.${index}.${field}`, undefined)
        }

        props.triggerSave('group.pregnancies')
    }

    if (!questionConfig) {
        return null
    }

    return (
        <>
            {new Array(pregnanciesAmount).fill(null).map((_, index) => (
                <>
                    <FollowUpQuestionsCard key={index}>
                        <Text>PREGNANCY {index + 1}</Text>
                    </FollowUpQuestionsCard>

                    <GluedOpenInputQuestion
                        linkId="pregnancies.when"
                        questionnaire={questionConfig}
                        answersMap={answersMap}
                        placeholder="e.g. 2010"
                        variant="info"
                        index={index}
                        followupCardSx={{ mt: -5, px: 4, py: 5 }}
                        {...props}
                    />

                    <GluedSingleChoiceQuestion
                        linkId="pregnancies.result"
                        questionnaire={questionConfig}
                        answersMap={answersMap}
                        fullWidth
                        index={index}
                        variant="info"
                        followupCardSx={{ mt: -5, px: 4, py: 5 }}
                        {...props}
                    />

                    <GluedLongOpenInputQuestion
                        linkId="pregnancies.complications"
                        questionnaire={questionConfig}
                        answersMap={answersMap}
                        placeholder="Enter complications here"
                        variant="info"
                        index={index}
                        followupCardSx={{ mt: -5, px: 4, py: 5 }}
                        {...props}
                    />

                    {/* show Delete on last pregnancy */}
                    {index > 0 && index === pregnanciesAmount - 1 && (
                        <FollowUpQuestionsCard
                            key={index}
                            sx={{ mt: -7, px: 4, py: 5 }}
                        >
                            <Button
                                variant="secondaryDark"
                                sx={{ mt: 5 }}
                                onClick={() => deletePregnancy(index)}
                            >
                                Delete
                            </Button>
                        </FollowUpQuestionsCard>
                    )}
                </>
            ))}

            <Button
                sx={{
                    mt: 4
                }}
                onClick={() =>
                    setPregnanciesAmount(currentAmount => currentAmount + 1)
                }
            >
                <Flex
                    sx={{
                        justifyContent: 'space',
                        alignItems: 'center',
                        px: [6, 0]
                    }}
                >
                    <PlusIcon color={'white'} />
                    <Text sx={{ ml: 3 }}>Add another pregnancy</Text>
                </Flex>
            </Button>
        </>
    )
}
