import { Button, Card, Flex, Heading, Link, Paragraph } from '@asktia/tia-ui'
import { FC } from 'react'
import { AppointmentProfile } from 'src/types'
import { LocationModalityBadge } from 'src/components/AppointmentLocation'
import { Link as RouterLink } from 'react-router-dom'
import { useAmpli } from 'src/hooks'

export const OnboardingSuggestionItem: FC<AppointmentProfile> = props => {
    const { imageUrl, label, locations, appointmentProfileUuid, description } =
        props

    const { onboardingSuggestionClicked, onboardingSuggestionNoThanksClicked } =
        useAmpli()

    return (
        <Card>
            <Flex
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Flex sx={{ width: '70%', flexDirection: 'column' }}>
                    <Heading h4 sx={{ mb: 1 }}>
                        {label}
                    </Heading>
                    <LocationModalityBadge
                        sx={{ paddingLeft: 0 }}
                        modalities={locations}
                    />
                </Flex>

                <Flex>
                    <img src={imageUrl} width="56px" height="56px" />
                </Flex>
            </Flex>
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paragraph sx={{ fontSize: 0, my: 3, color: 'secondary' }}>
                    {description}
                </Paragraph>
                <Button
                    onClick={onboardingSuggestionClicked}
                    fullWidth
                    as={RouterLink}
                    // @ts-ignore: TS doesn't understand objects can be indexed by string
                    to={`/book-appointment/profile/${appointmentProfileUuid}/time-selection`}
                    sx={{
                        backgroundColor: 'tiaPink'
                    }}
                >
                    Book Now
                </Button>
                {/* TODO: Add amplitude event for no thanks button */}
                <Link
                    onClick={onboardingSuggestionNoThanksClicked}
                    mt="4"
                    href="/r/landing/onboarding-suggestion?details=Browse care options to support your physical, mental, and reproductive health;More options available to support your specific health concerns&title=Looking for something else? Start by exploring our appointments and services&footer=Tia does not have direct control over how your appointment is covered by your insurance plan. If you have questions about your coverage, please contact your insurance provider."
                >
                    No thanks
                </Link>
            </Flex>
        </Card>
    )
}
