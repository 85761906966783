import { Card, Flex, Text } from '@asktia/tia-ui'
import { ChevronRight } from 'src/components/Blocks/Icons'
import { format } from 'date-fns'
import { FC } from 'react'
import { AppointmentJoinedBill } from 'src/pages/AppointmentBillingList'
import { isNil } from 'lodash'
import { useNavigation } from 'src/hooks'

export const BillCard: FC<AppointmentJoinedBill> = ({ appointment, bill }) => {
    const { navigate } = useNavigation()

    const isSettled =
        bill.remainingBalanceInCents === 0 &&
        !isNil(bill.patientResponsibilityInCents)

    return (
        <Card
            sx={{
                'p': 4,
                'mb': 4,
                'backgroundColor': isSettled
                    ? 'secondaryBackground'
                    : undefined,
                'cursor': 'pointer',
                '&:hover': {
                    borderColor: !isSettled ? 'inputOutlineHover' : undefined
                }
            }}
            // @ts-ignore
            onClick={() => navigate(`${appointment.id}`)}
        >
            <Flex
                sx={{
                    w: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Flex sx={{ flexDirection: 'column' }}>
                    <Text
                        sx={{
                            fontSize: 5,
                            fontWeight: 700,
                            color: isSettled ? 'supportText' : 'text'
                        }}
                    >
                        {appointment.label}
                    </Text>
                    <Text sx={{ fontSize: 0, color: 'supportText' }}>
                        {format(appointment.scheduledTime, 'LLLL dd, yyyy')}
                    </Text>
                </Flex>
                <Flex sx={{ alignItems: 'center' }}>
                    <Flex
                        sx={{
                            pr: 4,
                            flexDirection: 'column',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {isNil(bill.patientResponsibilityInCents) ? (
                            <Text sx={{ fontSize: 0, color: 'supportText' }}>
                                Finalizing your bill
                            </Text>
                        ) : (
                            <>
                                <Text
                                    sx={{
                                        fontSize: 0,
                                        color: 'supportText',
                                        textAlign: 'right'
                                    }}
                                >
                                    ${bill.remainingBalanceInCents / 100}
                                </Text>
                                <Text
                                    sx={{
                                        fontSize: 0,
                                        color: 'supportText',
                                        textAlign: 'right'
                                    }}
                                >
                                    Remaining
                                </Text>
                            </>
                        )}
                    </Flex>
                    <ChevronRight color="supportText" sx={{ zoom: 1.2 }} />
                </Flex>
            </Flex>
        </Card>
    )
}
