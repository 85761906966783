import { useEffect } from 'react'
import { Container, LayoutGrid, Spinner } from '@asktia/tia-ui'
import { useAppointmentHistory } from 'src/hooks/useAppointmentHistory'
import { useAmpli } from 'src/hooks'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { EncounterCard } from './components/EncounterCard'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { AppointmentHistoryRow } from './components/AppointmentHistoryRow'
import { AppointmentV1 } from 'src/types'
import { StandardPageHeader } from 'src/components/PageHeader'
import Maintenace from './maintenance'

export const AppointmentHistory = () => {
    const { appointments, isLoading } = useAppointmentHistory()
    const { appointmentHistoryViewed } = useAmpli()
    useEffect(() => appointmentHistoryViewed(), [appointmentHistoryViewed])
    const showBillingHistory =
        useAmpliFeatureFlag('show-billing-history') === 'treatment'

    const maintenanceMode =
        useAmpliFeatureFlag('billing-maintenance-for-non-total') === 'on'

    return (
        <Body>
            <StandardPageHeader />
            <NavBarHeading>
                {showBillingHistory
                    ? 'Past Appointments & Bills'
                    : 'Appointment History'}
            </NavBarHeading>
            <Container>
                {maintenanceMode ? (
                    <Maintenace />
                ) : (
                    <LayoutGrid columns="one">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            appointments.map((appointment: AppointmentV1) =>
                                showBillingHistory ? (
                                    <EncounterCard
                                        appointment={appointment}
                                        key={appointment.id}
                                        variant={'link'}
                                        href={`${appointment.id}`}
                                    />
                                ) : (
                                    <AppointmentHistoryRow
                                        appointment={appointment}
                                        key={appointment.id}
                                    />
                                )
                            )
                        )}
                    </LayoutGrid>
                )}
            </Container>
        </Body>
    )
}
