import {
    Box,
    Button,
    Card,
    Container,
    Heading,
    LayoutGrid,
    Paragraph,
    Text
} from '@asktia/tia-ui'
import { FC } from 'react'
import { useAppointmentProfilesByApptProfileName } from 'src/flows/AppointmentBooking/useCareTypes'
import { useAmpli, useScrollToTopOnPageLoad } from 'src/hooks'
import { Carousel, CarouselContainer } from 'src/components/Carousel'
import { motion } from 'framer-motion'
import { Link as RouterLink } from 'react-router-dom'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'

const welcomeNextSteps: WelcomeNextStepProps[] = [
    {
        stepName: 'Step One',
        heading: 'Book your first appointment',
        description: `We recommend starting your Tia care journey with a New 
        Patient Establishment of Care Visit (conducted virtually), the first 
        step of your Tia Whole Health Journey. In this visit we will lay the 
        essential groundwork for your personalized care. Alternatively, if you’re 
        looking for something else or have a specific health concern, we have plenty 
        of other options to get you the care you need.`,
        goodToKnowNote: `Some care appointments like the New Patient Establishment 
        of Care visit and Annual Preventative Exam may have slightly longer wait 
        times than our other visits. If you have trouble finding an appointment 
        that’s right for you, please reach out to a Care Coordinator via the Chat 
        feature in your member portal.`
    },
    {
        stepName: 'Step Two',
        heading: 'Fill out your Health Record',
        description: `After you book your first appointment you’ll
            be prompted to fill out your Health Record.
            It’s designed to give our providers the fullest picture of your health in order to provide
            you with the best care possible. It takes a little time to complete, but this information
            belongs to you and you can update it at any time.`,
        goodToKnowNote: `Your Health Record is data that is securely
            shared between you and your providers.
            Having a fully complete Health Record with
            accurate information gives our providers the
            best opportunity to provide the best care
            for you and your needs.`
    },
    {
        stepName: 'Step Three',
        heading: 'Put your insurance to work',
        description: `Insurance can be tricky, but we’re here to help.
            Before attending your first appointment, please
            make sure your insurance has been verified as
            in-network or out-of-network with Tia. If you
            still need help, please reach out to a Care
            Coordinator via the Chat feature in your member
            portal.`,
        goodToKnowNote: `At Tia, we accept most major plans, however we cannot know for certain if we accept your plan until your insurance has been verified. If your insurance falls under a payer or plan that we do not accept, we still accept payment by credit or debit card. We can also work with you to develop payment plans as needed.`
    }
]
type WelcomeNextStepProps = {
    stepName: string
    heading: string
    description: string
    goodToKnowNote: string
}

const WelcomeNextStepsCard = ({
    nextSteps
}: {
    nextSteps: WelcomeNextStepProps
}) => {
    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }}
            >
                <Text
                    sx={{
                        mb: 1,
                        fontSize: 0
                    }}
                    variant="eyebrow"
                >
                    {nextSteps.stepName}
                </Text>
                <Heading h4>{nextSteps.heading}</Heading>
                <Text sx={{ lineHeight: '20px' }}>{nextSteps.description}</Text>
            </Card>
            <Card
                sx={{
                    borderTop: 'none',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    py: 4
                }}
            >
                <Box sx={{ mt: -1 }}>
                    <Text
                        sx={{
                            fontWeight: 'bold',
                            color: 'supportText',
                            fontSize: 0
                        }}
                    >
                        Good to know:
                    </Text>
                    <Paragraph
                        sx={{
                            fontSize: 0,
                            color: 'supportText',
                            mt: 1,
                            mb: 0
                        }}
                    >
                        {nextSteps.goodToKnowNote}
                    </Paragraph>
                </Box>
            </Card>
        </>
    )
}

export const TwheLandingPage: FC = () => {
    const { isLoading } = useAppointmentProfilesByApptProfileName(
        'tia_whole_health_exam'
    )
    const { clickBookNowInNewMemberOnboarding } = useAmpli()
    useScrollToTopOnPageLoad()

    const showOnboardingSuggestionQuiz = useAmpliFeatureFlag(
        'show-onboarding-suggestion-quiz'
    )

    const imReadyUrl =
        showOnboardingSuggestionQuiz === 'treatment'
            ? '/landing/quiz'
            : '/landing/recommended-actions'

    return (
        <motion.div
            animate={{ y: 20 }}
            initial={{ y: '100vh' }}
            exit={{
                y: '-100vh',
                transition: {
                    delay: 0,
                    duration: 0.6
                }
            }}
            transition={{
                type: 'spring',
                stiffness: 16,
                damping: 6,
                delay: 0.1,
                duration: 0.3
            }}
        >
            <Container>
                <LayoutGrid columns="one" variant="condensed">
                    <Heading
                        h1
                        sx={{ mb: 4, textAlign: 'left', width: '100%', pt: 6 }}
                    >
                        You're in good hands
                    </Heading>
                    <Text
                        sx={{
                            lineHeight: '20px',
                            textAlign: 'left',
                            width: '100%',
                            mb: 5
                        }}
                    >
                        Let’s get started on your journey to better health.
                    </Text>
                    <CarouselContainer
                        sx={{
                            'pl': '0 !important',
                            'ml': '0',
                            'pr': '20px !important',
                            'overflow-y': 'visible',
                            'mb': 5
                        }}
                    >
                        <Carousel
                            paginationProps={{
                                variant: 'dark',
                                sx: {
                                    justifyContent: ['center', 'start'],
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 3,
                                    backgroundColor: 'mainBackground',
                                    pt: [4, 0],
                                    mt: [0, 4]
                                }
                            }}
                            slickOverrides={{
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                responsive: [
                                    {
                                        breakpoint: 1080,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    }
                                ]
                            }}
                        >
                            {welcomeNextSteps.map(nextSteps => (
                                <WelcomeNextStepsCard nextSteps={nextSteps} />
                            ))}
                        </Carousel>
                    </CarouselContainer>
                    <Button
                        as={RouterLink}
                        // @ts-ignore: TS doesn't understand this is a RouterLink now
                        to={imReadyUrl}
                        fullWidth
                        sx={{ mb: 5 }}
                        disabled={isLoading}
                        onClick={() => {
                            clickBookNowInNewMemberOnboarding()
                        }}
                    >
                        I'm ready
                    </Button>
                </LayoutGrid>
            </Container>
        </motion.div>
    )
}
