import { Heading, Radio, LabeledCheckbox } from '@asktia/tia-ui'
import {
    InsuranceVerificationStatus,
    InsuranceEligibilityStatus
} from 'src/types'

export const PaymentMethodForm = (props: {
    canUseInsurance: boolean
    verificationStatus: InsuranceVerificationStatus
    eligibilityStatus: InsuranceEligibilityStatus
}) => {
    return (
        <>
            <Heading h2>Payment Method</Heading>
            {props.verificationStatus === 'in_network' &&
            props.eligibilityStatus === 'eligible' ? (
                <Heading h4>
                    If there is a copay or other patient responsibility for this
                    appointment, would you like a copy of your receipt to submit
                    for FSA or HSA reimbursement?
                </Heading>
            ) : (
                <Heading h4>
                    Your appointment will be charged to your card on file. Would
                    you like a copy of your receipt to submit for an FSA or HSA
                    reimbursement?
                </Heading>
            )}
            <Radio name="patientWantsReceipt" value="yes" pretty sx={{ mb: 2 }}>
                Yes
            </Radio>
            <Radio name="patientWantsReceipt" value="no" pretty sx={{ mb: 4 }}>
                No
            </Radio>
            {props.verificationStatus !== 'in_network' &&
            props.canUseInsurance ? (
                <LabeledCheckbox name="patientWantsSuperbill">
                    I want a bill to send to my insurer
                </LabeledCheckbox>
            ) : null}
        </>
    )
}
