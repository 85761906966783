import { Box, Button, Logo, TiaModal } from '@asktia/tia-ui'
import { useModal } from 'react-modal-hook'
import { ReactQueryDevtools } from 'react-query/devtools'
import { PatientEngagementLevel } from 'src/DevTools/PatientEngagementLevel'
import { ModalTitle } from 'src/components/Blocks'

const TiaDevTools = () => {
    const [showDevtools, hideDevtools] = useModal(() => (
        // TODO: some parent is preventing this width
        <TiaModal sx={{ width: '80vw' }} shouldCloseOnOverlayClick>
            <ModalTitle title="Tia DevTools" hideModal={hideDevtools} />

            <PatientEngagementLevel />
        </TiaModal>
    ))

    return (
        <Button variant="unstyled" sx={{ ml: 8 }} onClick={showDevtools}>
            <Logo variant="black" />
        </Button>
    )
}

export const DevTools = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 4,
                zIndex: 5000
            }}
            as="aside"
        >
            <ReactQueryDevtools />
            <TiaDevTools />
        </Box>
    )
}
