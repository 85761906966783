import { Route, Routes } from 'react-router'
import { Show404 } from '../../404'
import { Body } from 'src/components/Blocks/Body'
import { QuestionnairePage } from '../components/QuestionnairePage'
import { GAD7Page } from 'src/flows/questionnaires/MentalHealth/pages/GAD7'
import { PHQ9Page } from 'src/flows/questionnaires/MentalHealth/pages/PHQ9'
import { QOLPage } from 'src/flows/questionnaires/MentalHealth/pages/QOL5'
import { QuestionnaireHeader } from '../components/QuestionnaireHeader'

export const MentalHealth = () => {
    return (
        <Body>
            <QuestionnaireHeader />
            <Routes>
                <Route
                    path={`gad-7/`}
                    element={
                        <QuestionnairePage
                            title="Mental Health Check"
                            category="Anxiety Screening"
                        >
                            <GAD7Page />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`phq-9/`}
                    element={
                        <QuestionnairePage
                            title="Mental Health Check"
                            category="Depression Screening"
                        >
                            <PHQ9Page />
                        </QuestionnairePage>
                    }
                />
                <Route
                    path={`qol-5/`}
                    element={
                        <QuestionnairePage
                            title="Mental Health Check"
                            category="Quality of Life"
                        >
                            <QOLPage />
                        </QuestionnairePage>
                    }
                />
                <Route element={<Show404 />} />
            </Routes>
        </Body>
    )
}
