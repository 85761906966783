import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import {
    Form,
    Heading,
    Radio,
    Input,
    Container,
    Card,
    LayoutGrid,
    Box
} from '@asktia/tia-ui'
import { FooterButton } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import { useAmpli, useNavigation, useUserInfo } from 'src/hooks'
import { THREAD_LABELS } from 'src/globals'
import { BackButton } from 'src/components/Blocks/BackButton'
import { View } from 'src/components/Blocks/View'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'

const cancelOptions = [
    "Tia's membership is too expensive or not worth the cost",
    'I can no longer use my insurance with Tia',
    "I signed up recently and learned I'm out of network with Tia",
    "I can't find appointment availability",
    'I need a different scope or type of care than Tia can provide',
    "I don't need or use Tia",
    'I accidentally signed up',
    'I’m moving and/or Tia is too far',
    'I had a negative care experience in my appointment',
    "Tia's post-appointment follow-up care feels too fragmented or slow",
    'I had a negative billing experience',
    "I've experienced too many unexpected appointment cancellations",
    'I had issues with the tech',
    'Other'
]

type FormValues =
    | {
          cancelReason: string
      }
    | FieldValues

export const CancelMembership = () => {
    const formMethods = useForm<FormValues>()
    const { navigate } = useNavigation()
    const { user } = useUserInfo()
    const { isDirty } = formMethods.formState
    const { cancelMembershipReasonsViewed, cancelMembershipReasonsClicked } =
        useAmpli()

    useEffect(() => {
        if (user) {
            cancelMembershipReasonsViewed(user.id)
        }
    }, [cancelMembershipReasonsViewed, user])

    const onSubmit = ({ cancelReason }: FormValues) => {
        cancelMembershipReasonsClicked(user!.id, cancelReason)
        navigate(
            `/chat/new?label=${
                THREAD_LABELS.membership
            }&subject=Membership cancellation&message=I want to cancel my membership due to ${
                cancelReason === 'Other'
                    ? formMethods.watch('cancelOtherReason')
                    : cancelReason
            }`
        )
    }

    return (
        <View withFooter>
            <PageHeader
                mobileBack={<BackButton href="/membership/change-cancel" />}
            />
            <NavBarHeading href="/membership/change-cancel">
                Cancel membership
            </NavBarHeading>
            <Container>
                <LayoutGrid columns="one">
                    <Form
                        id="cancel-form"
                        onSubmit={onSubmit}
                        useFormMethods={formMethods}
                    >
                        <Box sx={{ mb: 6 }}>
                            <Heading h3>
                                Let us know why you want to cancel your
                                membership:
                            </Heading>

                            {cancelOptions.map(cancelOption => (
                                <Radio
                                    name="cancelReason"
                                    value={cancelOption}
                                    key={cancelOption}
                                    sx={{
                                        'mb': 4,
                                        'lineHeight': '120%',
                                        'alignItems': 'center',
                                        '> div': { minWidth: 40, minHeight: 40 }
                                    }}
                                    pretty
                                >
                                    {cancelOption}
                                </Radio>
                            ))}
                            <Input
                                sx={{
                                    display:
                                        formMethods.watch('cancelReason') ===
                                        'Other'
                                            ? 'inline-block'
                                            : 'none',
                                    ml: 7,
                                    width: 'calc(100% - 48px)',
                                    position: 'relative',
                                    top: '-15px'
                                }}
                                key="cancelOtherReason"
                                name="cancelOtherReason"
                                placeholder="Tell us more"
                            />

                            {isDirty && (
                                <Card variant="info">
                                    <p>
                                        Thank you for the feedback. Your care
                                        coordination team can help you cancel
                                        your membership.
                                    </p>
                                </Card>
                            )}
                        </Box>
                        <FooterButton type="submit" disabled={!isDirty}>
                            {isDirty ? 'Contact my Care Coordinator' : 'Next'}
                        </FooterButton>
                    </Form>
                </LayoutGrid>
            </Container>
        </View>
    )
}
