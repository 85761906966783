import { FC } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { useAmpli, useScrollToTopOnPageLoad } from 'src/hooks'
import { QuizTheme } from './quiz.theme'
import { PageHeader } from 'src/components/PageHeader'
import { CloseButton } from 'src/components/Blocks'
import { LayoutGrid } from '@asktia/tia-ui'
import { NoLinkBackButton } from 'src/components/Blocks/BackButton'
import { useNavigate } from 'react-router'
import { useAvoidCaching } from 'src/hooks/useAvoidCaching'
import surveyJson from './survey.json'

import 'survey-core/defaultV2.min.css'
import './quiz.override.css'

export const OnboardingQuizPage: FC = () => {
    useAvoidCaching()
    useScrollToTopOnPageLoad()
    const navigate = useNavigate()
    const ampli = useAmpli()

    const survey = new Model(surveyJson)
    survey.applyTheme(QuizTheme)

    // The values that the questions have are deterimined by the json configuration

    const surveyOnChangeHandler = (
        _: any,
        options: { name: string; value: number | string | boolean }
    ) => {
        ampli.onboardingQuizQuestionAnswered({
            name: options.name,
            answer: options.value
        })
    }

    survey.onValueChanged.add(surveyOnChangeHandler)

    return (
        <>
            <PageHeader
                mobileBack={
                    <NoLinkBackButton
                        variant="white"
                        onClick={() => {
                            if (survey.currentPageNo === 0) {
                                navigate('/landing/twhe')
                            } else {
                                survey.prevPage()
                            }
                        }}
                    />
                }
                mobileActions={<CloseButton color="white" />}
                desktopActions={<CloseButton color="white" />}
            />

            <LayoutGrid columns="one">
                <Survey model={survey} style={{ willChange: 'transform' }} />
            </LayoutGrid>
        </>
    )
}
